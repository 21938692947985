import { formatRoute } from 'react-router-named-routes';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { I18N, PERSON } from 'constants/props';
import { NETWORK_DASHBOARD } from 'urls';
import Avatar from 'generics/Avatar';
import BeachBalls from 'generics/BeachBalls';
import Icon from 'generics/Icon';
import Translation from 'generics/Translation';

import styles from './ProfileListItem.scss';

const getRoute = (accountId, profileId) => formatRoute(NETWORK_DASHBOARD, {
  accountId,
  contentType: 'individual',
  profileId,
});

const renderName = (i18n, accountId, profile) => {
  const {
    assessmentScores,
    id,
    isPublic,
    isVisible,
    name,
  } = profile;

  // Is private or pending for assessment
  const showStatus = !isPublic || assessmentScores.isNull;

  let nameContent = (
    <span
      className={styles.name}
      title={name}
    >
      {name}
    </span>
  );

  if (isVisible) {
    const networkDashboardRoute = getRoute(accountId, id);

    nameContent = (
      <Link
        className={styles['name-link']}
        href={networkDashboardRoute}
        title={name}
        to={networkDashboardRoute}
      >
        {name}
      </Link>
    );
  }

  return (
    <div className={styles.text}>
      {nameContent}
      {
        showStatus && (
          <span className={styles.status}>
            <Icon.Stroke7 name={!isPublic ? 'lock' : 'wristwatch'} />
            {
              !isPublic
                ? i18n.generics.listItem.status.private
                : i18n.generics.listItem.status.pending
            }
          </span>
        )
      }
    </div>
  );
};

const ProfileListItem = (props) => {
  const {
    accountId,
    i18n,
    profile,
  } = props;

  const {
    assessmentScores,
    email,
    fullName,
  } = profile;

  return (
    <li
      className={classnames(
        styles.item,
        assessmentScores.isNull
          ? 'assessment-pending'
          : 'assessment-complete',
      )}
    >
      <Avatar
        className={styles.avatar}
        profile={profile}
        title={`${fullName}'s avatar`}
      />

      <BeachBalls
        className={styles['peach-balls']}
        profile={profile}
      />

      <div className={styles.information}>
        <div
          className={classnames(
            styles['information-wrapper'],
            styles['information-wrapper-name'],
          )}
        >
          {renderName(i18n, accountId, profile)}
        </div>

        <div
          className={classnames(
            styles['information-wrapper'],
            styles['information-wrapper-email'],
          )}
        >
          <span
            className={styles.email}
            title={email}
          >
            {email}
          </span>
        </div>
      </div>
    </li>
  );
};

ProfileListItem.propTypes = {
  accountId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  profile: PERSON.isRequired,
};

export default withRouter(Translation(ProfileListItem, ['generics']));
