/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, size } from 'lodash';
import { sprintf } from 'sprintf-js';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ACTIONS, I18N } from 'constants/props';
import { Card, Footer, Header, Main } from 'generics/Card';
import { COLORS } from 'constants/colors';
import { FILE_INVITATION_HEADERS } from 'constants/fileHeaders';
import { InviteFileError } from 'classes/errors';
import { StyledButton } from 'generics/StyledFormComponents';
import api from 'api';
import FileReader from 'generics/FileReader';
import Icon from 'generics/Icon';
import Translation from 'generics/Translation';

import BadgeResults from '../BadgeResults';
import FormWorkshop from '../FormWorkshop';
import ModalErrors from '../ModalErrors';
import styles from './ModalCreateWorkshop.scss';

const STATUS = {
  ERROR: 'error',
  INITIAL: 'initial',
  SUCCESS: 'success',
};

const FILE_EXTENSION_REGEX = /^.*\.csv$/;
const FILE_TYPES = ['text/csv'];

const ModalCreateWorkshop = ({
  accountId,
  i18n,
  onClose,
  onComplete,
  pageActions,
}) => {
  const [failedMembersList, setFailedMembersList] = useState(null);
  const [file, setFile] = useState(null);
  const [hasFileError, setHasFileError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [messages, setMessages] = useState(null);
  const [removedMembersList, setRemovedMembersList] = useState(null);
  const [status, setStatus] = useState(STATUS.INITIAL);
  const [successMembersList, setSuccessMembersList] = useState(null);
  const [name, setName] = useState('');
  const [date, setDate] = useState(null);

  useEffect(() => {
    pageActions.switchModalView();

    return () => {
      pageActions.switchModalView();
    };
  }, []);


  const handleClose = () => {
    onClose();
  };

  const handleChange = ({ date: newDate, name: newName }, isValidParam) => {
    setDate(newDate);
    setName(newName);
    setIsValid(isValidParam);
  };

  /**
   * @description handles exceptions when creating the workshop
   */

  const handleOnFail = ({ error }) => {
    let newMessages = [];

    if (size(error.message) > 0) {
      newMessages = [error.message[0]];
    } else if (error.label) {
      newMessages = [i18n.notifications[error.label]];
    } else if (size(error.details) > 0) {
      newMessages = [
        i18n.workshops.createWorkshop.fieldErrors,
        ...error.details.map(({ field, errorMessage }) => `${field}: ${errorMessage}`),
      ];
    }

    if (isEmpty(newMessages)) {
      handleClose();
    }

    setIsFetching(false);
    setMessages(newMessages);
    setStatus(STATUS.ERROR);
  };

  const handleOnSuccess = (
    newSuccessMembersList = [],
    newFailedMembersList = [],
    newRemovedMembersList = [],
  ) => {
    setFailedMembersList(newFailedMembersList);
    setIsFetching(false);
    setRemovedMembersList(newRemovedMembersList);
    setStatus(STATUS.SUCCESS);
    setSuccessMembersList(newSuccessMembersList);
  };

  const handleCreateWorkshop = () => {
    setIsFetching(true);

    const handleResponse = ({
      successfulMembers = null,
      failedMembers = null,
      errors = null,
    }) => {
      if (!isEmpty(errors)) {
        throw new InviteFileError(errors);
      }

      handleOnSuccess(successfulMembers, failedMembers);

      if (onComplete) {
        onComplete();
      }
    };

    api.workshops.createWorkshop(
      { accountId, date, file, name },
      handleResponse,
      handleOnFail,
    );
  };

  const handleLoadFile = (uploadedFile) => {
    const newHasFileError = !FILE_EXTENSION_REGEX.test(uploadedFile.name);
    const newFile = !hasFileError ? uploadedFile : null;
    setHasFileError(newHasFileError);
    setFile(newFile);
    setIsFormDisabled(newHasFileError);
  };

  const renderCancelButton = (statusParam) => {
    if (statusParam !== STATUS.INITIAL) {
      return null;
    }

    return (
      <StyledButton
        className={styles.cancel}
        color="default"
        onClick={handleClose}
        title={i18n.generics.cancelLabel}
        variant="text"
      >
        {i18n.generics.cancelLabel}
      </StyledButton>
    );
  };

  const renderDoneButton = () => {
    let label;
    let isDisabled;
    let onClick;

    if (status === STATUS.INITIAL) {
      label = i18n.workshops.createWorkshop.createLabel;
      isDisabled = !file || !isValid;
      onClick = handleCreateWorkshop;
    } else {
      label = i18n.generics.doneLabel;
      isDisabled = false;
      onClick = handleClose;
    }

    return (
      <StyledButton
        className={styles.create}
        disabled={isDisabled}
        id="button-modal-create-workshop"
        onClick={onClick}
        color="primary"
        title={label}
      >
        {label}
      </StyledButton>
    );
  };

  const renderTitle = () => {
    switch (status) {
      case STATUS.ERROR:
        return i18n.generics.error;
      case STATUS.SUCCESS:
        return isEmpty(successMembersList) && isEmpty(removedMembersList)
          ? i18n.generics.error
          : i18n.generics.success;
      default:
        return i18n.workshops.createWorkshop.createLabel;
    }
  };

  const i18nBase = i18n.pageAdministration.formFileInvitation;

  const templateFileReader = {
    headers: FILE_INVITATION_HEADERS,
    description: sprintf(i18nBase.templateFileDescription, { headers: FILE_INVITATION_HEADERS }).replace(/,/g, ', '),
    downloadDescription: i18nBase.templateFileDownloadDescription,
    templateFileName: i18nBase.templateInvitationsFileName,
  };

  return (
    <div
      className={styles.modal}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          e.preventDefault();
          onClose();
        }
      }}
      role="dialog"
    >
      <Card
        barBackgroundColor={COLORS.primaryBlue.rgba}
        className={styles.card}
        isLoading={isFetching}
      >
        <Header className={styles.header}>
          <h1>
            {renderTitle()}
          </h1>
        </Header>
        <Icon.Stroke7
          className={styles.close}
          name="close"
          onClick={handleClose}
          title={i18n.generics.close}
        />
        <Main className={styles.main}>
          {
            status === STATUS.SUCCESS && (
              <BadgeResults
                failed={failedMembersList}
                failedTitle={i18n.workshops.createWorkshop.resultMessage.failed}
                removed={removedMembersList}
                removedTitle={i18n.workshops.createWorkshop.resultMessage.removed}
                success={successMembersList}
                successTitle={i18n.workshops.createWorkshop.resultMessage.success}
              />
            )
          }
          {
            status === STATUS.INITIAL && (
              <Container className={styles.informationFileReader}>
                <FileReader
                  descriptionMessage={i18n.workshops.createWorkshop.fileReaderDescription}
                  fileTypes={FILE_TYPES}
                  onLoadFile={handleLoadFile}
                  templateFile={templateFileReader || null}
                />
                {
                  hasFileError && (
                    <p className={styles.error}>
                      {i18n.workshops.createWorkshop.fileError}
                    </p>
                  )
                }
                <FormWorkshop
                  date={date}
                  isDisabled={isFormDisabled}
                  message={i18n.workshops.createWorkshop.formDescription}
                  name={name}
                  onChange={handleChange}
                />
              </Container>
            )
          }
          {
            status === STATUS.ERROR && <ModalErrors errors={messages} />
          }
        </Main>

        <Footer>
          <Row>
            <Col xs="6">
              {renderCancelButton(status)}
            </Col>
            <Col xs="6">
              {renderDoneButton(status)}
            </Col>
          </Row>
        </Footer>
      </Card>
    </div>
  );
};

ModalCreateWorkshop.propTypes = {
  accountId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  pageActions: ACTIONS.isRequired,
};

ModalCreateWorkshop.defaultProps = {
  onClose: undefined,
  onComplete: undefined,
};

export default Translation(ModalCreateWorkshop, ['workshops', 'notifications', 'generics', 'pageAdministration']);
