import { FORM } from 'constants/contentTypes';
import moment from 'moment';

import { saveFile } from 'helpers';

const DATE_FORMAT = 'MMDDYYYY';

/**
 * @description Settings for controller
 * @module API controller / Profiles
 */
module.exports = {
  adminUpdateEmail: {
    /**
     * @description Get request settings (Edit Profile)
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.id - The profile ID.
     * @param  {array} options.body - Fields to edit.
     * @return {object} Request Settings
     */
    settings: ({ accountId, email, profileId }) => ({
      endpoint: `/account/${accountId}/profiles/admin_update_email`,
      body: {
        profileId,
        email,
      },
      method: 'PATCH',
    }),
  },
  editEmail: {
    /**
     * @description Get request settings (Edit Profile)
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.id - The profile ID.
     * @param  {array} options.body - Fields to edit.
     * @return {object} Request Settings
     */
    settings: ({ accountId, email }) => ({
      endpoint: `/account/${accountId}/profiles/confirm_email`,
      body: {
        email,
      },
      method: 'POST',
    }),
  },

  updateEmailValidateCode: {
    /**
     * @description Get request settings (Edit Profile)
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.id - The profile ID.
     * @param  {array} options.body - Fields to edit.
     * @return {object} Request Settings
     */
    settings: ({ accountId, body }) => ({
      endpoint: `/account/${accountId}/profiles/update_email`,
      body,
      method: 'PATCH',
    }),
  },

  editProfile: {
    /**
     * @description Get request settings (Edit Profile)
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.id - The profile ID.
     * @param  {array} options.body - Fields to edit.
     * @return {object} Request Settings
     */
    settings: ({ accountId, id, ...body }) => ({
      endpoint: `/account/${accountId}/profiles/${id}`,
      body,
      method: 'PUT',
    }),
  },

  deleteProfile: {
    /**
     * @description Get request settings (Edit Profile)
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.profileId - The profile ID.
     * @return {object} Request Settings
     */
    settings: ({ accountId, profileId }) => ({
      endpoint: `/account/${accountId}/profiles/${profileId}`,
      method: 'DELETE',
    }),
  },

  updateAvatar: {
    /**
     * Get request settings (Update Avatar endpoint)
     * @param  {object} options
     * @param {number} options.accountId
     * @param {object} options.avatar
     * @return {object} Request Settings
     */
    settings({ accountId, avatar }) {
      const form = new FormData();

      if (avatar) {
        form.append('avatar', avatar);
      }

      return {
        endpoint: `/account/${accountId}/profiles/set_avatar`,
        body: form,
        headers: {
          enctype: FORM,
        },
        method: 'PATCH',
      };
    },
  },

  getMyNetwork: {
    ignoreNotFound: true,

    /**
     * Request settings (Gets my network data with search and pagination)
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {boolean} options.includeAll - Include privated and not connected profiles
     * @param  {number} options.pageIndex - The page index.
     * @param  {string} options.search - The filter to search.
     * @param  {number} options.teamId - Team ID
     * @return {object} request settings
     */
    settings({ accountId, includeAll, pageIndex, search, teamId }) {
      const { filterBy, value: searchValue } = search || {};

      let endpoint = `/account/${accountId}/profiles/my_network`;
      const cleanSearch = searchValue ? searchValue.trim() : null;
      const searchBy = searchValue ? filterBy : null;

      if (teamId) {
        endpoint = `/account/${accountId}/teams/${teamId}/non_members`;
      }

      if (includeAll) {
        endpoint = `/account/${accountId}/profiles/all_network`;
      }

      if (pageIndex) {
        endpoint += `/page/${pageIndex}`;
      }

      if (searchBy) {
        endpoint += `/search_field/${searchBy}`;
      }

      if (cleanSearch) {
        endpoint += `/search/${cleanSearch}`;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },

  getMyConnections: {
    ignoreNotFound: true,

    /**
     * Request settings (Gets my connections data with search and pagination)
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.pageIndex - The page index.
     * @param  {string} options.search - The filter to search.
     * @return {object} request settings
     */
    settings({ accountId, pageIndex, search }) {
      const { filterBy, value: searchValue } = search || {};

      let endpoint = `/account/${accountId}/profiles/my_connections`;
      const cleanSearch = searchValue ? searchValue.trim() : null;
      const searchBy = searchValue ? filterBy : null;

      if (pageIndex) {
        endpoint += `/page/${pageIndex}`;
      }

      if (searchBy) {
        endpoint += `/search_field/${searchBy}`;
      }

      if (cleanSearch) {
        endpoint += `/search/${cleanSearch}`;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },

  setPrivacy: {
    /**
     * @description Request settings - Sets account privacy
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {boolean} options.isPublic - The value to patch.
     * @return {object} request settings
     */
    settings: ({ accountId, isPublic }) => ({
      endpoint: `/account/${accountId}/profiles/set_privacy`,
      body: {
        public: isPublic,
      },
      method: 'PATCH',
    }),
  },

  getNetworkDetails: {
    /**
     * Gets information of a specific profile
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.profileId - The profile ID.
     * @return {object} API Call to get the profile information
     */
    settings: ({ accountId, profileId }) => ({
      endpoint: `/account/${accountId}/profiles/network_profile/${profileId}`,
      method: 'GET',
    }),
  },

  completeSSOProvision: {
    encrypt: {
      useOptionsToken: true,
    },
    /**
     * Completes the user information for a SSO account profile
     * @param {number} accountId
     * @param {object} profile
     * @param {string} ssoToken
     * @return {object} API Call to get the profile information
     */
    settings: ({ accountId, profile, ssoToken }) => ({
      body: { profile },
      endpoint: `/account/${accountId}/profiles/provision`,
      headers: {
        Authorization: `Token sso=${ssoToken}`,
      },
      method: 'POST',
    }),
  },

  exportMembers: {
    afterResponse(originalResponse, onFinish) {
      saveFile(originalResponse, this.fileName, onFinish);
    },

    /**
   * @description Request settings (Download Energy Map Image)
   * @param  {number} accountId - The account ID.
   * @returns {object} - Request settings.
   */
    settings(accountId) {
      this.fileName = `members_${moment().format(DATE_FORMAT)}.csv`;

      return {
        method: 'GET',
        headers: {
          enctype: FORM,
        },
        responseType: 'blob',
        endpoint: `/account/${accountId}/profiles/download_csv`,
      };
    },
  },

  exportNotes: {
    afterResponse(originalResponse, onFinish) {
      saveFile(originalResponse, this.fileName, onFinish);
    },

    /**
   * @description Request settings (Download Energy Map Image)
   * @param  {number} accountId - The account ID.
   * @returns {object} - Request settings.
   */
    settings({ accountId, profileId }) {
      this.fileName = `notes_${moment().format(DATE_FORMAT)}.csv`;

      return {
        method: 'GET',
        headers: {
          enctype: FORM,
        },
        responseType: 'blob',
        endpoint: `/account/${accountId}/profiles/download_csv_notes/${profileId}`,
      };
    },
  },

  bulkDelete: {
    /**
     * @description Get request settings (send bulk delete file)
     * @param {object} options
     * @param {number} options.accountId
     * @param {object} options.file
     * @returns {object} request settings
     */
    settings({ accountId, file }) {
      const form = new FormData();

      form.append('file', file);

      return {
        endpoint: `/account/${accountId}/profiles/bulk_delete`,
        body: form,
        headers: {
          enctype: FORM,
        },
        method: 'POST',
      };
    },
  },

  createProfileNote: {
    /**
     * Gets notes of a specific profile
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.profileNote - Note to create.
     * @return {object} API Call to create a profile note
     */
    settings: ({ accountId, profileNote }) => ({
      body: {
        ...profileNote,
      },
      endpoint: `/account/${accountId}/profiles/create_profile_note`,
      method: 'POST',
    }),
  },

  deleteProfileNote: {
    /**
     * Gets notes of a specific profile
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.noteId - The note ID.
     * @return {object} API Call to delete a profile note
     */
    settings: ({ accountId, noteId }) => ({
      endpoint: `/account/${accountId}/profiles/destroy_profile_note/${noteId}`,
      method: 'DELETE',
    }),
  },

  getProfileNotes: {
    /**
     * Gets notes of a specific profile
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.profileId - The profile ID.
     * @return {object} API Call to get the profile notes
     */
    settings: ({ accountId, profileId }) => ({
      endpoint: `/account/${accountId}/profiles/get_profile_notes/${profileId}`,
      method: 'GET',
    }),
  },

  updateProfileNote: {
    /**
     * Gets notes of a specific profile
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.profileId - The profile ID.
     * @return {object} API Call to update a profile note
     */
    settings: ({ accountId, profileNote, noteId }) => ({
      endpoint: `/account/${accountId}/profiles/update_profile_note/${noteId}`,
      body: profileNote,
      method: 'PATCH',
    }),
  },

  getProfileLanguages: {
    /**
     * Gets information of a specific profile
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @return {object} API Call to get the profile languages available for setting up profile
     */
    settings: () => ({
      endpoint: '/profiles/languages',
      method: 'GET',
    }),
  },
};
