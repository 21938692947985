/**
 * @description Settings for news controller
 * @module API controller / News
 */

module.exports = {
  getNews: {
    /**
     * @description Get request settings to get news.
     * @param {number} accountId - The account ID.
     * @returns {object} - Request settings.
     */
    settings({ accountId }) {
      return {
        endpoint: `/account/${accountId}/news_items`,
        method: 'GET',
      };
    },
  },
};
