import { formatRoute } from 'react-router-named-routes';
import { isDateExpired } from 'helpers';
import { isEmpty } from 'lodash';
import { replace } from 'connected-react-router';

import { BarError } from 'classes/errors';
import { logout } from 'app_modules/session/actions/fetchLogout';
import { MY_5_DYNAMICS } from 'constants/urls';
import { notifyError, notifyWarning } from 'app_modules/notifications/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/session/constants';

import { fetchDashboard } from './fetchDashboard';
import { fetchVideos } from './fetchVideos';

// Actions

export const addProfile = (error) => ({
  payload: error,
  type: actionTypes.ADD_PROFILE,
});

export const setCurrentProfile = (profile) => ({
  payload: profile,
  type: actionTypes.SET_CURRENT_PROFILE,
});

// Thunks

export const setCurrentProfileById = (profileId) => {
  const thunk = (dispatch, getState) => {
    const profiles = sessionSelectors.profiles(getState());
    const newProfile = profiles.find((profile) => profile.id === profileId);

    return dispatch(setCurrentProfile(newProfile));
  };

  thunk.type = actionTypes.SET_CURRENT_PROFILE_BY_ID;

  return thunk;
};

export const setDefaultProfile = () => {
  const thunk = (dispatch, getState) => {
    const state = getState();
    const defaultAccount = sessionSelectors.defaultAccount(state);
    const profiles = sessionSelectors.profiles(state);

    const defaultProfile = isDateExpired(defaultAccount.expiresAt)
      ? profiles.find(({ account }) => new Date(account.expiresAt) > new Date())
      : profiles.find(({ account }) => account.id === defaultAccount.id);

    if (!defaultProfile) {
      return dispatch(logout());
    }

    return dispatch(setCurrentProfileById(defaultProfile.id));
  };

  thunk.type = actionTypes.SET_DEFAULT_PROFILE;

  return thunk;
};

export const setCurrentProfileByAccountId = (accountId) => {
  const thunk = (dispatch, getState) => {
    const user = sessionSelectors.user(getState());

    const {
      currentProfile,
      defaultAccount,
      impersonateToken,
      profiles,
    } = user;

    const newProfile = profiles.find((profile) => profile.account.id === accountId);

    // Invalid Account
    if (!newProfile) {
      dispatch(notifyError(new BarError({ label: 'invalidAddress' })));
      return dispatch(replace(formatRoute(MY_5_DYNAMICS, { accountId: defaultAccount.id })));
    }

    const hasExpired = new Date(newProfile.account.expiresAt) < new Date();

    if (hasExpired && isEmpty(impersonateToken)) {
      return dispatch(notifyWarning({ label: 'accountExpired' }));
    }
    if (newProfile && (currentProfile.id !== newProfile.id)) {
      return Promise.all([
        dispatch(setCurrentProfile(newProfile)),
        dispatch(fetchVideos()),
        dispatch(fetchDashboard()),
      ]);
    }

    return Promise.resolve();
  };

  thunk.type = actionTypes.SET_CURRENT_PROFILE_BY_ACCOUNT_ID;

  return thunk;
};
