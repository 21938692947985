import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { ACTIONS, I18N } from 'constants/props';

import { StyledButton } from 'generics/StyledFormComponents';
import Mask from 'generics/Mask';
import ModalConfirmation from 'generics/ModalConfirmation';
import SendReportRequestModal from '../ModalSendReportRequest/ModalSendReportRequest';
import ReportsList from '../ReportsList/ReportsList';
import useUseTokens from './useUseTokens';

import styles from './UseTokens.scss';

const UseTokens = ({
  accountId,
  i18n,
  notificationActions,
  onFetchNetworkDetails,
  profileId,
}) => {
  const {
    energyMapRefs,
    handleCancelReportAccept,
    handleCancelReportCancel,
    handleCancelReportConfirmation,
    handleCreateAPGIReport,
    handleDownloadAPGIReport,
    handleSendNewInvitation,
    handleSendReportReminder,
    getBulkReportList,
    isCancelReportConfirmationOpen,
    isLoading,
    isSendReportRequestModalOpen,
    sendReportRequest,
    setIsSendReportRequestModalOpen,
    reports,
    reportsMeta,
  } = useUseTokens({ accountId, i18n, notificationActions, onFetchNetworkDetails, profileId });

  const actionSendReportRequestButton = (
    <StyledButton
      className={styles.sendRequest}
      color="primary"
      id="link-logout"
      onClick={() => setIsSendReportRequestModalOpen(true)}
      title={i18n.pageTokenAdministration.useTokens.sendReportRequestAction}
    >
      {i18n.pageTokenAdministration.useTokens.sendReportRequestAction}
    </StyledButton>
  );

  return (
    <div className={styles.assignContainer}>
      <div className={styles['reports-container']}>
        <p
          className={styles.description}
          /* eslint-disable react/no-danger */
          dangerouslySetInnerHTML={{ __html: i18n.pageTokenAdministration.useTokens.description }}
          /* eslint-enable */
        />
        <ReportsList
          accountId={accountId}
          actionButton={actionSendReportRequestButton}
          energyMapRefs={energyMapRefs}
          handleCancelReportConfirmation={handleCancelReportConfirmation}
          handleCreateAPGIReport={handleCreateAPGIReport}
          handleDownloadAPGIReport={handleDownloadAPGIReport}
          handleSendNewInvitation={handleSendNewInvitation}
          handleSendReportReminder={handleSendReportReminder}
          i18n={i18n}
          notificationActions={notificationActions}
          meta={reportsMeta}
          onFetch={getBulkReportList}
          onFetchNetworkDetails={onFetchNetworkDetails}
          profileId={profileId}
          reports={reports}
        />
        {
          isSendReportRequestModalOpen && (
            <SendReportRequestModal
              onClose={() => setIsSendReportRequestModalOpen(false)}
              onSubmit={sendReportRequest}
            />
          )
        }
        {
          isCancelReportConfirmationOpen && (
            <ModalConfirmation
              confirmationMessages={[
                i18n.pageTokenAdministration.useTokens.deleteReportConfirmationMessage,
              ]}
              barClassName={styles.bar}
              onAccept={handleCancelReportAccept}
              onCancel={handleCancelReportCancel}
            />
          )
        }
        <Mask
          className={styles.mask}
          open={isLoading}
        >
          <CircularProgress
            className={styles.loader}
            size={25}
            thickness={2}
          />
        </Mask>
      </div>
    </div>
  );
};

UseTokens.propTypes = {
  accountId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  notificationActions: ACTIONS.isRequired,
  onFetchNetworkDetails: PropTypes.func.isRequired,
  profileId: PropTypes.number.isRequired,
};

export default UseTokens;
