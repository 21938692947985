import { Person } from 'classes';

export const accountId = (state) => {
  try {
    return state.user.currentProfile.account.id;
  } catch (error) {
    return null;
  }
};

export const accountType = (state) => {
  try {
    return state.user.currentProfile.account.accountType;
  } catch (error) {
    return null;
  }
};

export const assessmentScores = (state) => state.user.assessmentScores;

export const currentProfile = (state) => (
  new Person({
    user: {
      assessmentScores: assessmentScores(state),
    },
    ...state.user.currentProfile,
  })
);

export const isPersonal = (state) => {
  try {
    return state.user.currentProfile.account.isPersonal;
  } catch (error) {
    return false;
  }
};

export const modules = (state) => state.user.modules;

export const profiles = (state) => state.user.profiles;

export const overviewSummary = (state) => state.user.overviewSummary;

export const isAuthenticated = (state) => state.session.isAuthenticated;

export const isAssessmentCompleted = (state) => state.assessment.isCompleted;

export const userProfiles = (state) => state.user.profiles.map((profile) => new Person(profile));

export const name = (state) => {
  const { firstName, lastName } = currentProfile(state);
  return `${firstName} ${lastName}`;
};

export const user = (state) => state.user;

export const isImpersonating = (state) => !!state.user.internalAdmin.id;

export const expirationMessage = (state) => state.session.expirationMessage;

export const defaultAccount = (state) => state.user.defaultAccount;
