import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { updateIntl } from 'react-intl-redux';

import { loadState, saveState } from '../localStorage/localStorage';
import createRootReducer from '../reducers';
import enTranslationMessages from '../../translation/en';
import newrelicMiddleware from '../errors/newrelic-middleware';

const configureStore = ({ history }) => {
  const initialState = loadState();

  const middlewares = applyMiddleware(
    routerMiddleware(history),
    thunk,
    newrelicMiddleware,
  );

  const store = createStore(
    createRootReducer(history),
    initialState,
    middlewares,
  );

  store.dispatch(updateIntl(enTranslationMessages));

  store.subscribe(() => {
    saveState(store.getState());
  });

  return store;
};

export default configureStore;
