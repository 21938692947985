/* eslint-disable react-hooks/exhaustive-deps */
import { formatRoute } from 'react-router-named-routes';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import React, { Fragment, useEffect, useState } from 'react';

import { ACTIONS, HISTORY, I18N, MATCH } from 'constants/props';
import { Card, Header, Main } from 'generics/Card';
import { LOGIN } from 'urls';
import { Person } from 'classes';
import { USERS_SET_FORGOT_PASSWORD_TOKEN } from 'constants/apiErrorCodes';
import api from 'api';
import FormResetPassword from 'components/FormResetPassword';
import ListItem from 'generics/ListItem';
import Translation from 'generics/Translation';
import { BarError } from 'classes/errors';

import styles from './PageResetPassword.scss';

const PageResetPassword = ({
  history,
  i18n,
  match,
  notificationActions,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [profile, setProfile] = useState(new Person());

  useEffect(() => {
    const { token } = match.params;

    const responseHandler = ({ newProfile }) => {
      setProfile(new Person(newProfile));
    };

    const errorHandler = (error) => {
      history.replace(formatRoute(LOGIN));
      let notification = error;

      if (error.errorCode === USERS_SET_FORGOT_PASSWORD_TOKEN) {
        notification = new BarError({
          label: 'tokenExpiredResetPassword',
        });
      }

      notificationActions.notifyError(notification);
    };

    api.users.getPasswordToken(token, responseHandler, errorHandler);
  }, []);

  const handleSubmit = (data) => {
    const { token } = match.params;

    setIsFetching(true);

    const responseHandler = () => {
      setIsFetching(false);
      history.replace(LOGIN);
      notificationActions.notifySuccess(i18n.login.reset.toast.success.passwordChanged);
    };

    const errorHandler = () => {
      setIsFetching(false);
    };

    api.users.resetPassword({ ...data, token }, responseHandler, errorHandler);
  };

  return (
    <DocumentTitle title="Simpli5® - Reset Password Page">
      <section className={styles.page}>
        <Card
          center
          isFetching={isFetching}
        >
          <Header
            className={styles.header}
            title={i18n.login.reset.title}
          />
          <Main className={styles.main}>
            <ListItem
              className={styles.profile}
              profile={profile}
              tag={Fragment}
              type="b"
            />

            <FormResetPassword
              className={styles.form}
              onSubmit={handleSubmit}
            />

            <Link
              className={styles.link}
              href={LOGIN}
              title={i18n.login.link.backToLogin}
              to={LOGIN}
            >
              {i18n.login.link.backToLogin}
            </Link>
          </Main>
        </Card>
      </section>
    </DocumentTitle>
  );
};

PageResetPassword.propTypes = {
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
  match: MATCH.isRequired,
  notificationActions: ACTIONS.isRequired,
};

export default Translation(PageResetPassword, ['login']);
