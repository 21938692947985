import { isNil } from 'lodash';
import { pickHTMLProps } from 'pick-react-known-prop';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { I18N } from 'constants/props';
import { StyledButton, StyledTextField } from 'generics/StyledFormComponents';
import Translation from 'generics/Translation';
import useFormLogin from './useFormLogin';

import styles from './FormLogin.scss';

const FormLogin = ({ i18n, onSubmit, ...rest }) => {
  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  } = useFormLogin({ i18n, onSubmit });

  return (
    <form
      {...pickHTMLProps(rest)}
      onSubmit={handleSubmit}
    >
      <StyledTextField
        className={styles.field}
        error={isNil(values.email) ? false : !!errors.email}
        helperText={isNil(values.email) ? ' ' : errors.email}
        label={`${i18n.login.form.email}:`}
        name="email"
        onChange={(e) => handleChange('email', e.currentTarget.value)}
        title={i18n.login.form.email}
        value={values.email || ''}
      />

      <StyledTextField
        className={classnames(
          styles.field,
          styles['field--password'],
        )}
        error={isNil(values.password) ? false : !!errors.password}
        helperText={isNil(values.password) ? ' ' : errors.password}
        label={`${i18n.login.form.password}:`}
        name="password"
        onChange={(e) => handleChange('password', e.currentTarget.value)}
        title={i18n.login.form.password}
        type="password"
        value={values.password || ''}
      />

      <StyledButton
        classes={{ root: styles.submit }}
        color="primary"
        disabled={!isValid}
        id="login-submit"
        title={i18n.login.button.submit}
        type="submit"
      >
        {i18n.login.button.submit}
      </StyledButton>
    </form>
  );
};

FormLogin.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
};

export default Translation(FormLogin, ['login', 'generics']);
