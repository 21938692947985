import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { pickHTMLProps } from 'pick-react-known-prop';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { ACTIONS, CHILDREN, I18N } from 'constants/props';
import { Card } from 'generics/Card';
import * as pageActions from 'app_modules/page/actions';
import Icon from 'generics/Icon';
import Translation from 'generics/Translation';

import styles from './modal.scss';

const Modal = ({
  actions,
  card,
  children,
  className,
  i18n,
  modalCardClassName,
  switchModalView,
  onClose,
  ...rest
}) => {
  const cardRef = useRef(null);

  useEffect(() => {
    if (switchModalView) {
      actions.switchModalView();
    }

    return () => {
      if (switchModalView) {
        actions.switchModalView();
      }
    };
  }, []);

  const handleClick = (event) => { // eslint-disable-line class-methods-use-this
    event.stopPropagation();
  };

  const {
    className: cardClassName,
    fullHeight,
    style: cardStyle,
    ...cardRest
  } = card;

  return (
    <div // eslint-disable-line
      className={classnames(
        styles.modal,
        className,
      )}
      onClick={handleClick}
      role="dialog"
      {...pickHTMLProps(rest)}
    >
      <Card
        className={classnames(
          styles.card,
          cardClassName,
          modalCardClassName,
          { [styles['is-full-height']]: fullHeight },
        )}
        componentRef={(component) => { cardRef.current = component; }}
        style={cardStyle}
        {...cardRest}
      >
        <Icon.Stroke7
          className={styles.close}
          name="close"
          onClick={onClose}
          title={i18n.generics.closeLabel}
        />
        {children}
      </Card>
    </div>
  );
};

Modal.propTypes = {
  actions: ACTIONS.isRequired,
  card: PropTypes.shape({
    className: PropTypes.string,
    fullHeight: PropTypes.bool,
    style: PropTypes.shape({}),
  }),
  children: CHILDREN,
  className: PropTypes.string,
  i18n: I18N.isRequired,
  modalCardClassName: PropTypes.string,
  onClose: PropTypes.func,
  switchModalView: PropTypes.bool,
};

Modal.defaultProps = {
  card: {},
  children: null,
  className: null,
  modalCardClassName: null,
  onClose: undefined,
  switchModalView: true,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(pageActions, dispatch),
});

export default Translation(connect(null, mapDispatchToProps)(Modal), ['generics']);
