import CryptoJS, { AES, enc, HmacSHA256, SHA256 } from 'crypto-js';

const CRYPTO_REGEX = /^[^|]+\|[^|]+\|[^|]+$/;

const {
  Base64,
  Hex,
  Utf8,
} = enc;

const getNewInitializationVector = () => Hex.parse(CryptoJS.lib.WordArray.random(16).toString());

/**
 * @class AESCrypto
 * @description Encrypt / Decrypt Data
 */
export default class ApiCrypto {
  constructor(key) {
    this.setPassphrase(key);
  }

  /**
   * @description Encrypts object using AES
   * @param  {object} data
   */
  encrypt(data) {
    const initializationVector = getNewInitializationVector();

    const encryptedData = AES.encrypt(
      JSON.stringify(data, null, 4),
      this.key,
      { iv: initializationVector },
    );

    const base64InitializationVector = initializationVector.toString(Base64);
    const base64Ciphertext = encryptedData.ciphertext.toString(Base64);
    const base64HmacSha256 = HmacSHA256(base64Ciphertext, this.key).toString(Base64);

    return `${base64InitializationVector}|${base64HmacSha256}|${base64Ciphertext}`;
  }

  /**
   * @description Decrypts string to object using AES
   * @param  {string} encryptedText
   */
  decrypt(encryptedText) {
    // encrypted text doesn't have right format
    if (!CRYPTO_REGEX.test(encryptedText)) {
      return null;
    }

    const payload = encryptedText.split('|');
    const initializationVector = payload[0];
    const signature = Base64.parse(payload[1]).toString();
    const cipherText = payload[2];

    const hmacSignature = HmacSHA256(
      cipherText,
      this.key,
    ).toString().trim();

    // compare received HMAC with HMAC signature of data (to see if key is the right one)
    if (signature !== hmacSignature) {
      return null;
    }

    const decrypted = AES.decrypt(
      { ciphertext: Base64.parse(cipherText) },
      this.key,
      { iv: Base64.parse(initializationVector) },
    );

    return JSON.parse(decrypted.toString(Utf8));
  }

  setPassphrase(passphrase) {
    this._passphrase = passphrase;
    this.key = Hex.parse(SHA256(passphrase).toString());
  }
}
