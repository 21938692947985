import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { sprintf } from 'sprintf-js';
import htmlParser from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';

import { Card, Header, Main } from 'generics/Card';
import { I18N } from 'constants/props';
import Translation from 'generics/Translation';

import SetupProfileForm from '../SetupProfileForm';
import styles from './SetupProfileCard.scss';

export const SetupProfileCard = ({ email, handleSubmit, i18n, isSubmitting }) => (
  <Card
    barClassName={styles['bar-style']}
    center
  >
    <Header className={styles.header}>
      <h1
        className={styles.title}
        title={i18n.signUp.setupProfile.headerText}
      >
        {i18n.signUp.setupProfile.headerText}
      </h1>
      {
        email && (
          <p className={styles.subtitle}>
            {sprintf(i18n.signUp.setupProfile.invitationText, email)}
          </p>
        )
      }
    </Header>
    <Main
      ariaLabel={i18n.signUp.setupProfile.headerText}
      className={styles.main}
    >
      <Container>
        <Row>
          <Col>
            <p className={styles.already}>
              { htmlParser(i18n.signUp.setupProfile.haveAccountText) }
              <Link
                className={styles.already__link}
                href="/login"
                title={i18n.signUp.setupProfile.login}
                to="/login"
              >
                {i18n.signUp.setupProfile.login}
              </Link>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={styles.already}>
              { htmlParser(i18n.signUp.setupProfile.haveRelationshipsAccountText) }
              <Link
                className={styles.already__link}
                href="/link-relationships-profile"
                title={i18n.signUp.setupProfile.linkAccount}
                to="/link-relationships-profile"
              >
                {i18n.signUp.setupProfile.linkAccount}
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
      <SetupProfileForm
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
      />
    </Main>
  </Card>
);

SetupProfileCard.propTypes = {
  email: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
  isSubmitting: PropTypes.bool,
};

SetupProfileCard.defaultProps = {
  email: null,
  isSubmitting: false,
};

export default Translation(SetupProfileCard, ['signUp']);
