import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Col, Container, Row } from 'reactstrap';

import Avatar from 'generics/Avatar';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ACCOUNT_ADMIN } from 'roles';
import { ACTIONS, I18N, MEMBERS, PERSON, TEAM } from 'constants/props';
import { Card, Main } from 'generics/Card';
import { StyledButton } from 'generics/StyledFormComponents';
import ModalConfirmation from 'generics/ModalConfirmation';
import ModalCreateTeam from 'generics/ModalCreateTeam';
import PictureSelectorModal from 'generics/PictureSelectorModal';
import TestingScores from 'generics/TestingScores';
import Translation from 'generics/Translation';

import styles from './TeamDescription.scss';

const TeamDescription = ({
  actions,
  className,
  currentProfile,
  i18n,
  membersCount,
  members,
  sharedIcon,
  team,
}) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPictureModalOpen, setIsPictureModalOpen] = useState(false);

  /**
   * Handles Open/Close Picture Selector Modal
   */

  const handleOpenClosePictureModal = () => {
    setIsPictureModalOpen(!isPictureModalOpen);
  };

  /**
   * Handles save picture event
   * @param {object} image
   */

  const handleSavePicture = (image) => {
    handleOpenClosePictureModal();
    actions.updateLogo(image);
  };

  /**
   * Handles the remove picture event
   */

  const handleRemovePicture = () => {
    handleSavePicture(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleMakeTeamPublic = () => {
    setIsConfirmationOpen(false);
    actions.setPrivacyTeam({ teamId: team.id, isPublic: true });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handlerSetPrivacy = (type) => {
    let privacyParams = {
      teamId: team.id,
    };

    if (type === 'public') {
      if (!team.isPublic) {
        setIsConfirmationOpen(true);
        return;
      }
      privacyParams = {
        ...privacyParams,
        isPublic: false,
      };
    } else {
      privacyParams = {
        ...privacyParams,
        isPublished: true,
      };
    }

    actions.setPrivacyTeam(privacyParams);
  };

  const {
    description,
    isFetching,
    isPublic,
    isPublished,
    name,
    owner,
  } = team;

  let fullName = '';

  if (owner) {
    const {
      firstName,
      lastName,
    } = owner;

    fullName = `${firstName} ${lastName}`;
  }

  const teamInfo = {
    'Team Description': {
      value: description,
    },
    'Team Members': {
      value: membersCount,
    },
    'Team Owner': {
      value: fullName,
    },
  };

  if (currentProfile.id === team.owner.id) {
    teamInfo['Team Description'].link = {
      key: 'editTeamDetails',
      label: i18n.teamDescription.editTeamDetail,
    };

    const htmlLabel = (
      <div>
        <div className={styles['private-title']}>
          {i18n.teamDescription.privacy.makeTeamPublic}
        </div>
        <div className={styles['private-sub-title']}>
          {i18n.teamDescription.privacy.visibleInfo}
        </div>
      </div>
    );

    if (currentProfile.hasRole(ACCOUNT_ADMIN) && isPublished) {
      teamInfo.Privacy = {
        value: (
          <FormControlLabel
            control={(
              <Checkbox
                checked={isPublic}
                color="primary"
                onClick={() => handlerSetPrivacy('public')}
                title={i18n.teamDescription.privacy.makeTeamPublic}
              />
            )}
            label={htmlLabel}
          />
        ),
      };
    } else {
      teamInfo.Privacy = {
        value: (
          <div className={styles['private-title']}>
            {
              isPublic
                ? i18n.teamDescription.privacy.public
                : i18n.teamDescription.privacy.private
            }
          </div>
        ),
      };
    }

    if (!isPublished) {
      teamInfo['Publish Team'] = {
        value: (
          <div>
            <div>
              {i18n.teamDescription.publishInfo}
            </div>
            <StyledButton
              className={styles.publish}
              color="primary"
              fullWidth
              title={i18n.teamDescription.publishTeam}
              onClick={() => handlerSetPrivacy('publish')}
            >
              {i18n.teamDescription.publishTeam}
            </StyledButton>
          </div>
        ),
      };
    }
  }

  const canEdit = currentProfile.id === team.owner.id;

  return (
    <Card
      barClassName={styles.bar}
      className={className}
      isFetching={isFetching}
    >
      <header className={styles.header}>
        <Avatar
          className={styles.avatar}
          hasBorder
          icon={{ name: sharedIcon || 'users' }}
          profile={team}
        />

        <Container>
          <Row>
            <Col>
              <div className={styles.name}>
                <span title={name}>
                  {name || i18n.generics.loading}
                </span>
              </div>
              <TestingScores
                profile={team}
                className={styles.scores}
              />
              {
                canEdit && (
                  <div className={styles['edit-container']}>
                    <StyledButton
                      className={styles['edit-picture']}
                      color="secondary"
                      onClick={handleOpenClosePictureModal}
                      title={i18n.teamDescription.editPicture}
                      variant="text"
                    >
                      {i18n.teamDescription.editPicture}
                    </StyledButton>
                  </div>
                )
              }
            </Col>
          </Row>
        </Container>
      </header>

      <Main className={styles.main}>
        <Container>
          <Row>
            <Col>
              <div className={styles['team-information']}>
                {
                  Object.keys(teamInfo)
                    .filter((key) => teamInfo[key] !== undefined)
                    .map((key) => {
                      const {
                        link,
                        value,
                      } = teamInfo[key];

                      const {
                        key: linkKey,
                        label,
                      } = link || {};

                      return (
                        <dl key={key}>
                          <dt title={key}>
                            <span title={key}>
                              {key}
                            </span>
                            {
                              link && (
                                <span // eslint-disable-line jsx-a11y/click-events-have-key-events
                                  className={styles['field-link']}
                                  key={linkKey}
                                  onClick={handleOpenModal}
                                  role="button"
                                  tabIndex="0"
                                  title={label}
                                >
                                  {label}
                                </span>
                              )
                            }
                          </dt>
                          <dd title={value} data-test="teamMembersCount">
                            {value}
                          </dd>
                        </dl>
                      );
                    })
                }
              </div>
            </Col>
          </Row>
        </Container>
      </Main>

      {
        isModalOpen && (
          <ModalCreateTeam
            edit
            members={members}
            onClose={handleCloseModal}
          />
        )
      }
      {
        isPictureModalOpen && (
          <PictureSelectorModal
            onClose={handleOpenClosePictureModal}
            onRemovePicture={handleRemovePicture}
            onSavePicture={handleSavePicture}
            pictureUrl={team.logo}
            uploadMessage={i18n.teamDescription.uploadMessage}
          />
        )
      }
      {
        isConfirmationOpen && (
          <ModalConfirmation
            barClassName={styles.bar}
            confirmationMessages={[i18n.teamDescription.confirmation.message]}
            confirmationTitle={i18n.teamDescription.confirmation.title}
            onAccept={handleMakeTeamPublic}
            onCancel={() => setIsConfirmationOpen(false)}
          />
        )
      }
    </Card>
  );
};

TeamDescription.propTypes = {
  actions: ACTIONS.isRequired,
  className: PropTypes.string,
  currentProfile: PERSON.isRequired,
  i18n: I18N.isRequired,
  membersCount: PropTypes.number.isRequired,
  sharedIcon: PropTypes.string,
  team: TEAM,
  members: MEMBERS.isRequired,
};

TeamDescription.defaultProps = {
  className: null,
  sharedIcon: null,
  team: {},
};

export default Translation(TeamDescription, ['teamDescription', 'generics']);
