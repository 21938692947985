/* eslint-disable react-hooks/exhaustive-deps */
import { formatRoute } from 'react-router-named-routes';
import classnames from 'classnames';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { HISTORY, I18N } from 'constants/props';
import { Card, Header, Main } from 'generics/Card';
import { MY_5_DYNAMICS, WELCOME } from 'urls';
import Icon from 'generics/Icon';
import Translation from 'generics/Translation';

import styles from './PageSSOLoginProviders.scss';

const { PUBLIC_PATH } = process.env;

const AZURE_LOGIN_URL = 'https://portal.azure.com/';
const AZURE_LOGO_URL = `${window.location.origin}${PUBLIC_PATH}/loginSSO/azure.png`;
const OKTA_LOGIN_URL = 'https://login.okta.com/';
const OKTA_LOGO_URL = `${window.location.origin}${PUBLIC_PATH}/loginSSO/okta.png`;

const PageSSOLoginProviders = ({
  accountId,
  history,
  i18n,
  isAssessmentCompleted,
  isAuthenticated,
}) => {
  useEffect(() => {
    if (isAuthenticated) {
      if (isAssessmentCompleted) {
        history.replace(formatRoute(MY_5_DYNAMICS, { accountId }));
      } else {
        history.replace(formatRoute(WELCOME, { accountId }));
      }
    }
  }, []);

  return (
    <DocumentTitle title="Simpli5® - Login Page">
      <section className={styles.page}>
        <Card
          center
        >
          <Header
            className={styles.header}
            title={i18n.loginSSOProviders.title}
            subtitle={i18n.loginSSOProviders.subtitle}
          />
          <Main className={styles.main}>
            <a
              className={classnames(
                styles.link,
                styles.sso,
              )}
              href={AZURE_LOGIN_URL}
              title={i18n.loginSSOProviders.providers.azure.action}
            >
              <figure className={styles.logo}>
                <img alt="" src={AZURE_LOGO_URL} />
              </figure>
              <span className={styles.label}>{i18n.loginSSOProviders.providers.azure.action}</span>
              <Icon.Stroke7
                className={classnames(
                  styles.icon,
                )}
                name="angle-right"
              />
            </a>
            <a
              className={classnames(
                styles.link,
                styles.sso,
              )}
              href={OKTA_LOGIN_URL}
              title={i18n.loginSSOProviders.providers.okta.action}
            >
              <figure className={styles.logo}>
                <img alt="" src={OKTA_LOGO_URL} />
              </figure>
              <span className={styles.label}>{i18n.loginSSOProviders.providers.okta.action}</span>
              <Icon.Stroke7
                className={classnames(
                  styles.icon,
                )}
                name="angle-right"
              />
            </a>
          </Main>
        </Card>
      </section>
    </DocumentTitle>
  );
};

PageSSOLoginProviders.propTypes = {
  accountId: PropTypes.number,
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
  isAssessmentCompleted: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

PageSSOLoginProviders.defaultProps = {
  accountId: null,
};

export default Translation(PageSSOLoginProviders, ['loginSSOProviders']);
