const NAME_SPACE = 'Accounts';

const actionTypes = {
  EDIT_ACCOUNT_REQUEST: `${NAME_SPACE}/EDIT_ACCOUNT_REQUEST`,
  EDIT_ACCOUNT_SUCCESS: `${NAME_SPACE}/EDIT_ACCOUNT_SUCCESS`,
  EDIT_ACCOUNT_FAILURE: `${NAME_SPACE}/EDIT_ACCOUNT_FAILURE`,
  EDIT_CUSTOMIZATIONS: `${NAME_SPACE}/EDIT_CUSTOMIZATIONS`,
  FETCH_ACCOUNT_FAILURE: `${NAME_SPACE}/FETCH_ACCOUNT_FAILURE`,
  FETCH_ACCOUNT_REQUEST: `${NAME_SPACE}/FETCH_ACCOUNT_REQUEST`,
  FETCH_ACCOUNT_SUCCESS: `${NAME_SPACE}/FETCH_ACCOUNT_SUCCESS`,
  FETCH_ACCOUNT: `${NAME_SPACE}/FETCH_ACCOUNT`,
  FETCH_MY_MEMBERS_FAILURE: `${NAME_SPACE}/FETCH_MY_MEMBERS_FAILURE`,
  FETCH_MY_MEMBERS_REQUEST: `${NAME_SPACE}/FETCH_MY_MEMBERS_REQUEST`,
  FETCH_MY_MEMBERS_SUCCESS: `${NAME_SPACE}/FETCH_MY_MEMBERS_SUCCESS`,
  FETCH_MY_MEMBERS: `${NAME_SPACE}/FETCH_MY_MEMBERS`,
  FETCH_UPDATE_LOGO_FAILURE: `${NAME_SPACE}/FETCH_UPDATE_LOGO_FAILURE`,
  FETCH_UPDATE_LOGO_REQUEST: `${NAME_SPACE}/FETCH_UPDATE_LOGO_REQUEST`,
  FETCH_UPDATE_LOGO_SUCCESS: `${NAME_SPACE}/FETCH_UPDATE_LOGO_SUCCESS`,
  FETCH_UPDATE_LOGO: `${NAME_SPACE}/FETCH_UPDATE_LOGO`,
  REMOVE_PERMISSION: `${NAME_SPACE}/REMOVE_PERMISSION`,
  REMOVE_USER_PERMISSION: `${NAME_SPACE}/REMOVE_USER_PERMISSION`,
};

export default actionTypes;
