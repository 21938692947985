import { combineReducers } from 'redux';
import { get, uniqBy } from 'lodash';

import workshopsActionTypes from 'app_modules/workshops/constants';
import initialState from 'app_modules/store/initialState.json';
import sessionActionTypes from 'app_modules/session/constants';

const currentWorkshop = combineReducers({
  isFetching: (state = initialState.workshops.currentWorkshop.isFetching, { type }) => {
    switch (type) {
      case workshopsActionTypes.FETCH_WORKSHOP_DETAILS_REQUEST:
      case workshopsActionTypes.FETCH_MEMBERS_REQUEST:
        return true;

      case workshopsActionTypes.FETCH_WORKSHOP_DETAILS_FAILURE:
      case workshopsActionTypes.FETCH_WORKSHOP_DETAILS_SUCCESS:
      case workshopsActionTypes.FETCH_MEMBERS_SUCCESS:
        return false;

      case sessionActionTypes.RESET_STATE:
        return initialState.workshops.currentWorkshop.isFetching;

      default:
        return state;
    }
  },

  workshop: (state = initialState.workshops.currentWorkshop.workshop, { type, payload }) => {
    switch (type) {
      case workshopsActionTypes.FETCH_UPDATE_WORKSHOP_SUCCESS:
      case workshopsActionTypes.FETCH_WORKSHOP_DETAILS_SUCCESS:
        return payload.workshop;

      case workshopsActionTypes.CLEAR_WORKSHOP:
      case workshopsActionTypes.FETCH_WORKSHOP_DETAILS_REQUEST:
      case sessionActionTypes.RESET_STATE:
        return initialState.workshops.currentWorkshop.workshop;

      default:
        return state;
    }
  },

  hasMoreMembers: (state = initialState.workshops.currentWorkshop.hasMoreMembers,
    { type, payload }) => {
    switch (type) {
      case workshopsActionTypes.FETCH_UPDATE_WORKSHOP_SUCCESS:
      case workshopsActionTypes.FETCH_WORKSHOP_DETAILS_SUCCESS:
      case workshopsActionTypes.FETCH_MEMBERS_SUCCESS:
        return payload.meta.morePages;

      case workshopsActionTypes.CLEAR_WORKSHOP:
      case workshopsActionTypes.FETCH_WORKSHOP_DETAILS_REQUEST:
      case workshopsActionTypes.FETCH_MEMBERS:
      case sessionActionTypes.RESET_STATE:
        return initialState.workshops.currentWorkshop.hasMoreMembers;

      default:
        return state;
    }
  },

  members: (state = initialState.workshops.currentWorkshop.members, { type, payload }) => {
    switch (type) {
      case workshopsActionTypes.FETCH_UPDATE_WORKSHOP_SUCCESS:
      case workshopsActionTypes.FETCH_WORKSHOP_DETAILS_SUCCESS:
        return payload.workshop.workshopMembers;

      case workshopsActionTypes.FETCH_MEMBERS_SUCCESS:
        return payload.pageIndex === 1
          ? payload.workshopMembers
          : uniqBy([...state, ...payload.workshopMembers], 'id');

      case workshopsActionTypes.CLEAR_WORKSHOP:
      case workshopsActionTypes.FETCH_WORKSHOP_DETAILS_REQUEST:
      case sessionActionTypes.RESET_STATE:
        return initialState.workshops.currentWorkshop.members;

      default:
        return state;
    }
  },
});

const list = combineReducers({
  isFetching: (state = initialState.workshops.list.isFetching, { type }) => {
    switch (type) {
      case workshopsActionTypes.FETCH_WORKSHOPS_REQUEST:
        return true;

      case workshopsActionTypes.FETCH_WORKSHOPS_FAILURE:
      case workshopsActionTypes.FETCH_WORKSHOPS_SUCCESS:
      case workshopsActionTypes.FETCH_WORKSHOPS_SUCCESS_NO_RESULTS:
        return false;

      case sessionActionTypes.RESET_STATE:
        return initialState.workshops.list.isFetching;

      default:
        return state;
    }
  },

  list: (state = initialState.workshops.list.list, { type, payload }) => {
    switch (type) {
      case workshopsActionTypes.FETCH_WORKSHOPS_REQUEST:
        if (payload === 1) {
          return [];
        }
        return state;

      case workshopsActionTypes.FETCH_WORKSHOPS_FAILURE:
        return [];

      case workshopsActionTypes.FETCH_WORKSHOPS_SUCCESS: {
        const { workshops = [] } = payload;

        return uniqBy([...state, ...workshops], 'id');
      }

      case workshopsActionTypes.FETCH_UPDATE_WORKSHOP_SUCCESS:
        return state
          .map((workshop) => (
            workshop.id === payload.workshop.id
              ? payload.workshop
              : workshop
          ));

      case sessionActionTypes.RESET_STATE:
        return initialState.workshops.list.list;

      default:
        return state;
    }
  },

  meta: (state = initialState.workshops.list.meta, { type, payload }) => {
    switch (type) {
      case workshopsActionTypes.FETCH_WORKSHOPS_SUCCESS: {
        const newMeta = get(payload, 'meta', initialState.workshops.list.meta);
        return payload.updateCounters ? newMeta : {
          ...newMeta,
          totalObjects: state.totalObjects,
        };
      }

      case workshopsActionTypes.FETCH_WORKSHOPS_FAILURE:
      case sessionActionTypes.RESET_STATE:
        return initialState.workshops.list.meta;

      default:
        return state;
    }
  },
});

export default combineReducers({
  currentWorkshop,
  list,
});
