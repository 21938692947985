import { Person } from 'classes';

const EMPTY_NETWORK_DETAILS = {
  numberOfConnections: 0,
  numberOfTeamsCreated: 0,
};

export const requests = {
  isFetching(state) {
    return state.network.requests.received.isFetching || state.network.requests.sent.isFetching;
  },

  received(state) {
    const receivedRequests = state.network.requests.received;

    return {
      ...receivedRequests,
      requests: receivedRequests.requests.map((request) => ({
        ...request,
        connectionRequestEmisor: new Person(request.connectionRequestEmisor),
      })),
    };
  },

  sent(state) {
    const sentRequests = state.network.requests.sent;

    return {
      ...sentRequests,
      requests: sentRequests.requests.map((request) => ({
        ...request,
        connectionRequestReceiver: new Person(request.connectionRequestReceiver),
      })),
    };
  },
};

export const thirdPerson = {
  isFetching(state) {
    return state.network.thirdPerson.isFetching;
  },

  modules(state) {
    return state.network.thirdPerson.modules;
  },

  overview(state) {
    return state.network.thirdPerson.overview;
  },

  profile(state) {
    return new Person({
      ...state.network.thirdPerson.profile,
      roles: state.network.thirdPerson.roles,
    });
  },
};

export const myNetwork = {
  isFetching(state) {
    return state.network.myNetwork.isFetching;
  },

  meta(state) {
    return state.network.myNetwork.meta;
  },

  profiles(state) {
    return state.network.myNetwork.profiles
      .map((profile) => new Person(profile));
  },
};

export const myConnections = {
  isFetching(state) {
    return state.network.myConnections.isFetching;
  },

  meta(state) {
    return state.network.myConnections.meta;
  },

  profiles(state) {
    return state.network.myConnections.profiles
      .map((profile) => new Person(profile));
  },
};


export const networkDetails = {
  isFetching: (state) => state.network.networkDetails.isFetching,

  error: (state) => state.network.networkDetails.error,

  profileNetworkDetails: (state, profileId) => {
    const { list } = state.network.networkDetails;

    // TODO: Change state arrays to use inmutable
    return list[profileId] || EMPTY_NETWORK_DETAILS;
  },
};
