export const ACCOUNT_EXPIRED = 431;
export const ACCOUNT_UPDATE = 432;
export const ACCOUNT_UPDATE_LOGO = 433;
export const API_NOT_FOUND = 477;
export const APPLICATION_AUTHENTICATE_TOKEN = 470;
export const BUNDLES_NOT_FOUND = 484;
export const CAN_NOT_ASSIGN_TOKENS = 614;
export const CAN_NOT_CONNECT = 1000;
export const CONNECTION_REQUESTS_ACCEPT_REQUEST = 435;
export const CONNECTION_REQUESTS_DECLINE_REQUEST = 436;
export const CONNECTION_REQUESTS_GET_OWN = 434;
export const INVALID_CUSTOMER_PORTAL_ROLE = 634;
export const INVALID_LICENSE_BUYER_ROLE = 635;
export const INVALID_SUBSCRIPTION_ROLE = 632;
export const INVALID_USER_OR_TOKEN = 430;
export const INVITES_ACCEPT_INVITATION = 449;
export const INVITES_DECLINE_INVITATION = 455;
export const INVITES_DESTROY = 471;
export const INVITES_REMIND_INVITATION = 457;
export const INVITES_RENEW_INVITE = 437;
export const INVITES_RESEND_INVITE = 458;
export const INVITES_VIA_CSV = 456;
export const NO_RESULTS = 1001;
export const NOT_AVAILABLE_INVITES = 473;
export const PDFS_SEND_LEARNING_CHART_PDF_BINARY = 469;
export const PDFS_SEND_NAME_BADGES_PDF_BINARY = 467;
export const PDFS_SEND_SPIDER_GRAPH_PDF_BINARY = 466;
export const PDFS_SEND_TENT_CARDS_PDF_BINARY = 468;
export const PROFILES_SET_PRIVACY = 439;
export const PROFILES_UPDATE = 438;
export const PROFILES_UPDATE_AVATAR = 440;
export const PROFILES_UPDATE_INVITE_QUANTITIES = 450;
export const RATE_LIMIT = 429;
export const RECORD_NOT_FOUND = 472;
export const RESET_PASSWORD_REQUIRED = 481;
export const SELF_PERSONAL_ALREADY_CREATED = 609;
export const SESSION_EXPIRED = 1002;
export const SSO_ERROR = 401;
export const STANDARD_ERROR = 482;
export const STRIPE_CUSTOMER_CARD = 613;
export const TEAMS_CREATE = 448;
export const TEAMS_SET_PRIVACY = 452;
export const TEAMS_UPDATE = 451;
export const TEAMS_UPDATE_BUNDLE_ALLOCATIONS_FOR_TEAMS = 464;
export const TEAMS_UPDATE_LOGO = 453;
export const TOKENS_ASSIGNED = 616;
export const USERS_ACCOUNT_BLOCKED = 606;
export const USER_ACTIVE_TOKENS = 621;
export const USERS_CREATE_ACCOUNT = 460;
export const USERS_CREATE_ASSESSMENT = 443;
export const USERS_FORGOT_PASSWORD = 461;
export const USERS_RESET_PASSWORD = 444;
export const USERS_SET_FORGOT_PASSWORD_TOKEN = 463;
export const USERS_UPDATE_ASSESSMENT_QUESTIONS = 442;
export const USERS_UPDATE_PASSWORD = 447;
export const USERS_UPDATE_PERSONAL_PROFILE = 446;
export const USERS_UPDATE_PROFILE = 445;
export const USERS_VIEW_ASSESSMENT = 441;
export const UPDATE_EMAIL_ADMIN_ERROR = 631;
export const UPDATE_EMAIL_INVALID_CODE = 630;
export const WORKSHOPS_DUPLICATED_NAME = 478;
export const WORKSHOPS_UPDATE_WORKSHOP = 479;
