
import { autobind } from 'core-decorators';
import { get } from 'lodash';
import { sprintf } from 'sprintf-js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { I18N, NETWORK_DETAILS, PERSON, THIRD_PERSON } from 'constants/props';
import { StyledButton } from 'generics/StyledFormComponents';
import AccountProfile from 'generics/AccountProfile';
import ModalAssignRole from 'components/ModalAssignRole';
import Translation from 'generics/Translation';

import styles from './ProfileDetailThirdPerson.scss';

class ProfileDetailThirdPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  @autobind
  handleGetAccountProfileData() {
    const {
      currentProfile,
      i18n,
      networkDetails,
      thirdPersonProfile,
    } = this.props;

    const {
      firstName,
      isPublic,
    } = thirdPersonProfile;

    const privacy = isPublic
      ? i18n.thirdPersonProfile.accountProfileData.profilePrivacy.public
      : i18n.thirdPersonProfile.accountProfileData.profilePrivacy.private;

    const numberTeamsText = sprintf(
      i18n.thirdPersonProfile.accountProfileData.numberOfTeams,
      firstName,
    );

    const accountProfileData = [
      {
        children: get(networkDetails, 'numberOfTeamsCreated', 0),
        label: numberTeamsText,
      },
      {
        children: get(networkDetails, 'numberOfConnections', 0),
        label: i18n.thirdPersonProfile.accountProfileData.numberOfConnections,
      },
      {
        children: privacy,
        label: i18n.thirdPersonProfile.accountProfileData.privacy,
      },
    ];

    if (currentProfile.can({ role: 'getRolesByProfile' })) {
      accountProfileData.push({
        children: thirdPersonProfile.roles
          .map(({ name }) => (
            <span
              className={styles.roles}
              key={name}
              title={name}
            >
              {name}
            </span>
          )),
        label: currentProfile.can({ role: 'updateProfileRoles' }) ? (
          <>
            <span>{i18n.thirdPersonProfile.accountProfileData.role}</span>
            <StyledButton
              className={styles.edit}
              hoverColor="none"
              color="primary"
              id="link-edit"
              onClick={this.handleOpenModal}
              title={i18n.thirdPersonProfile.edit}
            >
              {i18n.thirdPersonProfile.edit}
            </StyledButton>
          </>
        )
          : i18n.thirdPersonProfile.accountProfileData.role,
      });
    }

    return accountProfileData;
  }

  @autobind
  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  @autobind
  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const {
      isFetchingProfile,
      thirdPersonProfile,
    } = this.props;

    const { isModalOpen } = this.state;

    return (
      <>
        <AccountProfile
          className={styles['account-profile']}
          onGetData={this.handleGetAccountProfileData}
          isFetching={isFetchingProfile}
          profile={thirdPersonProfile}
        />
        {
          isModalOpen && <ModalAssignRole onClose={this.handleCloseModal} />
        }
      </>
    );
  }
}

ProfileDetailThirdPerson.propTypes = {
  currentProfile: PERSON.isRequired,
  i18n: I18N.isRequired,
  isFetchingProfile: PropTypes.bool.isRequired,
  networkDetails: NETWORK_DETAILS.isRequired,
  thirdPersonProfile: THIRD_PERSON.isRequired,
};

export default Translation(ProfileDetailThirdPerson, ['thirdPersonProfile']);
