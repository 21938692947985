/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, reject, size, some } from 'lodash';
import { Tab, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ACTIONS, I18N, INVITATIONS, PERSON } from 'constants/props';
import { Card, Footer, Header, Main } from 'generics/Card';
import { COLORS } from 'constants/colors';
import { InviteFileError } from 'classes/errors';
import { StyledButton } from 'generics/StyledFormComponents';
import api from 'api';
import FormFileInvitation from 'components/FormFileInvitation';
import Icon from 'generics/Icon';
import Translation from 'generics/Translation';

import BadgeResults from '../BadgeResults';
import PersonForm from '../PersonForm';
import ModalErrors from '../ModalErrors';
import SelectedRecords from '../SelectedRecords';
import styles from './modalPeople.scss';

const STATUS = {
  ERROR: 'error',
  INITIAL: 'initial',
  SUCCESS: 'success',
};

const TABS = {
  MANUAL_INVITATION: 'manualInvitation',
  FILE_INVITATION: 'fileInvitation',
};

const ModalPeople = ({
  accountActions,
  accountId,
  i18n,
  invitations,
  isDepartmentEnabled,
  onClose,
  onComplete,
  pageActions,
  profile,
}) => {
  const [failedInvitationList, setFailedInvitationList] = useState([]);
  const [file, setFile] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [invitationList, setInvitationList] = useState(invitations);
  const [isFetching, setIsFetching] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [messages, setMessages] = useState(null);
  const [removedInvitationList, setRemovedInvitationList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(TABS.MANUAL_INVITATION);
  const [status, setStatus] = useState(STATUS.INITIAL);
  const [successInvitationList, setSuccessInvitationList] = useState([]);

  useEffect(() => {
    pageActions.switchModalView();

    return () => {
      pageActions.switchModalView();
    };
  }, []);

  /**
   * @description Checks if the user is using it's own email
   * or if the email already exist in the list
   */

  const checkDuplicatedEmail = ({ email }) => {
    const lowerCaseEmail = email.toLowerCase();

    if (lowerCaseEmail === profile.email.toLowerCase()
      || some(invitationList, ({ email: listEmail }) => listEmail.toLowerCase() === lowerCaseEmail)
    ) {
      return {
        email: i18n.generics.errors.email,
      };
    }
    return {};
  };

  const handleClose = () => {
    onClose();
  };

  const handleOnValidateForm = (newFormValues, newIsValid) => {
    setFormValues(newFormValues);
    setIsValid(newIsValid);
  };

  const inviteSuccessResults = (
    newSuccessInvitationList = [],
    newFailedInvitationList = [],
    newRemovedInvitationList = [],
  ) => {
    setFailedInvitationList(newFailedInvitationList);
    setSuccessInvitationList(newSuccessInvitationList);
    setRemovedInvitationList(newRemovedInvitationList);
    setIsFetching(false);
    setStatus(STATUS.SUCCESS);
  };

  const sendInvitations = () => {
    setIsFetching(true);

    const handleResponse = ({ failedInvitations, succesfulInvitations, errors }) => {
      // TODO: review this
      if (size(errors)) {
        if (errors[0] === 'User already accepted an invitation.') {
          throw new Error('This user already belongs to the organization');
        } else {
          throw new Error(errors);
        }
      }

      accountActions.fetchAccount(accountId);
      inviteSuccessResults(succesfulInvitations, failedInvitations);

      if (onComplete) {
        onComplete();
      }
    };

    const handleError = (error) => {
      if (isEmpty(error.errors)) {
        handleClose();
      } else {
        setIsFetching(false);
        setMessages(error.errors);
        setStatus(STATUS.ERROR);
      }
    };

    api.invites.send({
      accountId,
      invitationList,
    }, handleResponse, handleError);
  };

  /**
   * @description handles exceptions when sending invitations
   */

  const inviteFileReaderFail = ({ error }) => {
    // eslint-disable-next-line no-nested-ternary
    const newMessages = (size(error.message) > 0 && Array.isArray(error.message)
      ? error.message[0] : typeof error.message === 'string' ? error.message : null)
      || (error.label ? i18n.notifications[error.label] : '');

    if (isEmpty(newMessages)) {
      handleClose();
    }

    setIsFetching(false);
    setMessages([newMessages]);
    setStatus(STATUS.ERROR);
  };

  const sendFileInvitations = () => {
    setIsFetching(true);

    const handleResponse = ({
      succesfulInvitations = null,
      failedInvitations = null,
      errors = null,
    }) => {
      if (!isEmpty(errors)) {
        throw new InviteFileError(errors);
      }

      accountActions.fetchAccount(accountId);
      inviteSuccessResults(succesfulInvitations, failedInvitations);

      if (onComplete) {
        onComplete();
      }
    };

    api.invites.sendFile(
      { accountId, file, ...formValues },
      handleResponse,
      inviteFileReaderFail,
    );
  };

  const handleAdd = (invitation) => {
    setInvitationList([...invitationList, invitation]);
  };

  const handleChangeTab = (newSelectedTab) => {
    setSelectedTab(newSelectedTab);
  };

  const handleLoadFile = (newFile) => {
    setFile(newFile);
  };

  const handleRemoveInvitation = (invitationId) => {
    const oldInvitationList = [...invitationList];
    setInvitationList(reject(oldInvitationList, { id: invitationId }));
  };

  const renderCancelButton = (statusParam) => {
    if (statusParam !== STATUS.INITIAL) {
      return null;
    }

    return (
      <StyledButton
        className={styles.cancel}
        color="default"
        onClick={handleClose}
        title={i18n.generics.cancelLabel}
        variant="text"
      >
        {i18n.generics.cancelLabel}
      </StyledButton>
    );
  };

  const renderDoneButton = () => {
    let label;
    let isDisabled;
    let onClick;

    if (status === STATUS.INITIAL) {
      label = i18n.pageAdministration.modalPeople.invite;

      if (selectedTab === TABS.MANUAL_INVITATION) {
        isDisabled = invitationList.length === 0;
        onClick = sendInvitations;
      } else {
        isDisabled = !file || !isValid;
        onClick = sendFileInvitations;
      }
    } else {
      label = i18n.generics.doneLabel;
      isDisabled = false;
      onClick = handleClose;
    }

    return (
      <StyledButton
        className={styles.invite}
        disabled={isDisabled}
        id="button-modal-people-invite"
        onClick={onClick}
        color="primary"
        title={label}
      >
        {label}
      </StyledButton>
    );
  };

  const renderTabs = () => {
    const tabs = [
      {
        id: 'manual-invitation-tab',
        label: i18n.pageAdministration.modalPeople.tabs[0],
        value: TABS.MANUAL_INVITATION,
      },
      {
        id: 'file-invitation-tab',
        label: i18n.pageAdministration.modalPeople.tabs[1],
        value: TABS.FILE_INVITATION,
      },
    ];

    return tabs.map((tab) => (
      <Tab
        className={styles.tab}
        key={`tab-${tab.label}`}
        title={tab.label}
        {...tab}
      />
    ));
  };

  const renderTitle = () => {
    switch (status) {
      case STATUS.ERROR:
        return i18n.pageAdministration.modalPeople.title.error;
      case STATUS.SUCCESS:
        return isEmpty(successInvitationList) && isEmpty(removedInvitationList)
          ? i18n.pageAdministration.modalPeople.title.error
          : i18n.pageAdministration.modalPeople.title.success;
      default:
        return i18n.pageAdministration.modalPeople.title.invitePeople;
    }
  };

  return (
    <div
      className={styles.modal}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          e.preventDefault();
          onClose();
        }
      }}
      role="dialog"
    >
      <Card
        barBackgroundColor={COLORS.primaryBlue.rgba}
        className={styles.card}
        isLoading={isFetching}
      >
        <Header className={styles.header}>
          <h1>
            {renderTitle()}
          </h1>
        </Header>
        <Icon.Stroke7
          className={styles.close}
          name="close"
          onClick={handleClose}
          title={i18n.pageAdministration.modalPeople.close}
        />
        <Main className={styles.main}>
          {
            status === STATUS.SUCCESS && (
              <BadgeResults
                isDepartmentEnabled={isDepartmentEnabled}
                failed={failedInvitationList}
                removed={removedInvitationList}
                success={successInvitationList}
              />
            )
          }
          {
            status === STATUS.INITIAL && (
              <Container className={styles.informationFileReader}>
                <Tabs
                  className={styles.tabs}
                  indicatorColor="primary"
                  onChange={(e, value) => handleChangeTab(value)}
                  textColor="primary"
                  value={selectedTab}
                >
                  {renderTabs()}
                </Tabs>
                {
                  profile.can({ invite: 'sendInvitationManually' }) && (selectedTab === TABS.MANUAL_INVITATION)
                  && (
                    <>
                      <PersonForm
                        isDepartmentEnabled={isDepartmentEnabled}
                        message={i18n.pageAdministration.modalPeople.formInvitation.description}
                        onSubmit={handleAdd}
                        onValidate={checkDuplicatedEmail}
                        resetOnSubmit
                      />
                      {
                        !isEmpty(invitationList) && (
                          <SelectedRecords
                            className={styles['selected-records']}
                            isDepartmentEnabled={isDepartmentEnabled}
                            list={invitationList}
                            onRemove={handleRemoveInvitation}
                          />
                        )
                      }
                    </>
                  )
                }
                {
                  profile.can({ invite: 'sendInvitationViaCsv' }) && (selectedTab === TABS.FILE_INVITATION) && (
                    <FormFileInvitation
                      isDepartmentEnabled={isDepartmentEnabled}
                      onLoadFile={handleLoadFile}
                      onValidate={handleOnValidateForm}
                    />
                  )
                }
              </Container>
            )
          }
          {
            status === STATUS.ERROR && <ModalErrors errors={messages} />
          }
        </Main>

        <Footer>
          <Row>
            <Col xs="6">
              {renderCancelButton(status)}
            </Col>
            <Col xs="6">
              {renderDoneButton(status)}
            </Col>
          </Row>
        </Footer>
      </Card>
    </div>
  );
};

ModalPeople.propTypes = {
  accountActions: ACTIONS.isRequired,
  accountId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  invitations: INVITATIONS,
  isDepartmentEnabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  pageActions: ACTIONS.isRequired,
  profile: PERSON.isRequired,
};

ModalPeople.defaultProps = {
  invitations: [],
  onClose: undefined,
  onComplete: undefined,
};

export default Translation(ModalPeople, ['pageAdministration', 'notifications', 'generics']);
