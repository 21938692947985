import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { StyledButton } from 'generics/StyledFormComponents';
import Icon from 'generics/Icon';
import ListItemActions from 'generics/ListItemActions';

import styles from './CustomizationsListItem.scss';

const CustomizationsListItem = ({
  actions,
  customization,
  className,
  onManageCustomization,
}) => {
  const {
    key,
    title,
  } = customization;

  return (
    <li
      className={classnames(
        styles.item,
        className,
      )}
    >
      <Icon.Stroke7
        className={styles.icon}
        name="plugin"
      />
      <div className={styles['name-container']}>
        <StyledButton
          classes={{ root: styles['name-link'], label: styles['name-link__label'] }}
          color="default"
          onClick={() => { onManageCustomization(key); }}
          title={title}
          variant="text"
        >
          {title}
        </StyledButton>
      </div>
      <ListItemActions
        actions={actions}
        className={styles.actions}
      />
    </li>
  );
};

CustomizationsListItem.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node),
  customization:
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.number,
    }).isRequired,
  className: PropTypes.string,
  onManageCustomization: PropTypes.func.isRequired,
};

CustomizationsListItem.defaultProps = {
  actions: null,
  className: null,
};

export default CustomizationsListItem;
