import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { CHILDREN } from 'constants/props';
import * as pageSelectors from 'app_modules/page/selectors';
import ApplicationFooter from 'components/ApplicationFooter';
import ApplicationHeader from 'containers/ApplicationHeader';
import ModalNotification from 'components/ModalNotification';

import styles from './ProfileByContextLayout.scss';

const ProfileByContextLayout = ({ children, isModalView = false }) => {
  const [isStickyHeader, setIsStickyHeader] = useState(0);

  const mainFocusRef = useRef(null);

  return (
    <div className={styles.layout}>
      <ApplicationHeader
        className={classnames(
          styles.header,
          { [styles['header--hidden']]: isModalView },
        )}
        focusRef={mainFocusRef}
        onChange={(isSticky) => { setIsStickyHeader(isSticky); }}
      />
      <main
        className={classnames(
          styles.main,
          { [styles['main--has-sticky-header']]: isStickyHeader },
        )}
        ref={mainFocusRef}
      >
        {children}
      </main>
      <ApplicationFooter className={styles.footer} />
      <ModalNotification />
    </div>
  );
};

ProfileByContextLayout.propTypes = {
  children: CHILDREN,
  isModalView: PropTypes.bool,
};

ProfileByContextLayout.defaultProps = {
  children: null,
  isModalView: false,
};

const mapStateToProps = (state) => ({
  isModalView: pageSelectors.isModalView(state),
});

export default connect(mapStateToProps, null)(ProfileByContextLayout);
