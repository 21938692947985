/**
 * @description Settings for controller
 * @module API controller / Pdfs
 */
import moment from 'moment';

import { FORM, PDF } from 'constants/contentTypes';
import { saveFile } from 'helpers';

const BLOB_OPTIONS = { type: PDF };

const DATE_FORMAT = 'MMDDYYYY';

const FETCH_OPTIONS = {
  headers: {
    enctype: FORM,
  },
  method: 'POST',
  responseType: 'blob',
};

const formData = (documentData) => {
  const blob = new Blob([JSON.stringify(documentData)], BLOB_OPTIONS);
  const form = new FormData();

  form.append('report_data', blob);

  return form;
};

module.exports = {
  createAPGIReportPDF: {
    afterResponse(originalResponse, onFinish) {
      saveFile(originalResponse, this.fileName, onFinish);
    },

    /**
   * @description Request settings (Download Overview PDF)
   * @param  {object} options
   * @param  {number} options.accountId - The account ID.
   * @param  {string} options.css - The CSS selectors applied in the 'EnergyMap' component.
   * @param  {array} options.profile - The profile required by template.
   * @returns {object} - Request settings.
   */
    settings({ accountId, css, profile: { fullName, id, svg } }) {
      this.fileName = `${fullName}_Overview_${moment().format(DATE_FORMAT)}.pdf`;

      return {
        ...FETCH_OPTIONS,
        endpoint: `/account/${accountId}/tokens/bulk_report/${id}/create`,
        body: formData({ css, profiles: [{ id, svg }] }),
      };
    },
  },
  downloadAPGIReportPDF: {
    afterResponse(originalResponse, onFinish) {
      saveFile(originalResponse, this.fileName, onFinish);
    },

    /**
   * @description Request settings (Download Overview PDF)
   * @param  {object} options
   * @param  {number} options.accountId - The account ID.
   * @param  {string} options.css - The CSS selectors applied in the 'EnergyMap' component.
   * @param  {array} options.profile - The profile required by template.
   * @returns {object} - Request settings.
   */
    settings({ accountId, css, profile: { fullName, id, svg } }) {
      this.fileName = `${fullName}_Overview_${moment().format(DATE_FORMAT)}.pdf`;

      return {
        ...FETCH_OPTIONS,
        endpoint: `/account/${accountId}/tokens/bulk_report/${id}/download`,
        body: formData({ css, profiles: [{ id, svg }] }),
      };
    },
  },
  downloadPDF: {
    afterResponse(originalResponse, onFinish) {
      saveFile(originalResponse, this.fileName, onFinish);
    },

    /**
   * @description Request settings (PDF download)
   * @param  {object} options
   * @param  {number} options.accountId - The account ID.
   * @param  {string} options.css - The CSS selectors applied in the 'EnergyMap' component.
   * @param  {string} options.name - The pdf name.
   * @param  {string} options.pdfId - The template to use.
   * @param  {array} options.profiles - The profiles array required by template.
   * @param  {number} options.teamId - The team ID.
   * @returns {object} - Request settings.
   */
    settings({ accountId, css, name, pdfId, profiles, teamId }) {
      this.fileName = (name && `${name}.pdf`) || `${pdfId.replace(/-/g, '_')}_${moment().format(DATE_FORMAT)}.pdf`;

      return {
        ...FETCH_OPTIONS,
        endpoint: `/account/${accountId}/pdfs/team/${teamId}/download_pdf/${pdfId}`,
        body: formData({ css, name, profiles }),
      };
    },
  },
  downloadLearningOverviewPDF: {
    /**
   * @description Request settings (Download Team Overview PDF)
   * @param  {object} options
   * @param  {number} options.accountId - The account ID.
   * @param  {string} options.css - The CSS selectors applied in the 'EnergyMap' component.
   * @param  {array} options.profiles - The profiles array required by template.
   * @param  {number} options.teamId - The team ID.
   * @returns {object} - Request settings.
   */
    settings: ({ accountId, css, profiles }) => ({
      ...FETCH_OPTIONS,
      endpoint: `/account/${accountId}/pdfs/download_pdf/learning_overview_report`,
      body: formData({ css, profiles }),
    }),
  },
  downloadOverviewPDF: {
    afterResponse(originalResponse, onFinish) {
      saveFile(originalResponse, this.fileName, onFinish);
    },

    /**
   * @description Request settings (Download Overview PDF)
   * @param  {object} options
   * @param  {number} options.accountId - The account ID.
   * @param  {string} options.css - The CSS selectors applied in the 'EnergyMap' component.
   * @param  {array} options.profile - The profile required by template.
   * @returns {object} - Request settings.
   */
    settings({ accountId, css, isLearningAccount, profile: { fullName, id, svg } }) {
      this.fileName = `${fullName}_${isLearningAccount ? 'Learning' : 'Overview'}_${moment().format(DATE_FORMAT)}.pdf`;

      return {
        ...FETCH_OPTIONS,
        endpoint: `/account/${accountId}/pdfs/profile/${id}/download_pdf/overview-report`,
        body: formData({ css, profiles: [{ id, svg }] }),
      };
    },
  },

  downloadEnergyMap: {
    afterResponse(originalResponse, onFinish) {
      saveFile(originalResponse, this.fileName, onFinish);
    },

    /**
   * @description Request settings (Download Energy Map Image)
   * @param  {object} options
   * @param  {number} options.accountId - The account ID.
   * @param  {string} options.css - The CSS selectors applied in the 'EnergyMap' component.
   * @param  {array} options.profile - The profile required by template.
   * @returns {object} - Request settings.
   */
    settings({ accountId, css, profile: { fullName, id, svg } }) {
      this.fileName = `${fullName}_energy_map.png`;

      return {
        ...FETCH_OPTIONS,
        endpoint: `/account/${accountId}/files/profile/${id}/download_png/energy-map-report`,
        body: formData({ css, profiles: [{ id, svg }] }),
      };
    },
  },

  downloadTeamOverviewPDF: {
    /**
   * @description Request settings (Download Team Overview PDF)
   * @param  {object} options
   * @param  {number} options.accountId - The account ID.
   * @param  {string} options.css - The CSS selectors applied in the 'EnergyMap' component.
   * @param  {array} options.profiles - The profiles array required by template.
   * @param  {number} options.teamId - The team ID.
   * @returns {object} - Request settings.
   */
    settings: ({ accountId, css, profiles, teamId }) => ({
      ...FETCH_OPTIONS,
      endpoint: `/account/${accountId}/pdfs/team/${teamId}/download_pdf/overview-report`,
      body: formData({ css, profiles }),
    }),
  },

  downloadTeamEnergyMap: {
    /**
   * @description Request settings (Download Team Overview PDF)
   * @param  {object} options
   * @param  {number} options.accountId - The account ID.
   * @param  {string} options.css - The CSS selectors applied in the 'EnergyMap' component.
   * @param  {array} options.profiles - The profiles array required by template.
   * @param  {number} options.teamId - The team ID.
   * @returns {object} - Request settings.
   */
    settings: ({ accountId, css, profiles, teamId }) => ({
      ...FETCH_OPTIONS,
      endpoint: `/account/${accountId}/files/team/${teamId}/download_png/team-energy-map-report`,
      body: formData({ css, profiles }),
    }),
  },

  downloadSpiderGraphPDF: {
    afterResponse(originalResponse, onFinish) {
      saveFile(originalResponse, this.fileName, onFinish);
    },

    /**
   * @description Request settings (Download Spider Graph PDF)
   * @param  {object} options
   * @param  {string} options.accountId - The account ID.
   * @param  {String} options.html
   * @param  {array} options.profiles - The profiles array required by template.
   * @param  {Team} options.team - The team instance.
   * @returns {object} - Request settings.
   */
    settings({ accountId, html, profiles, team }) {
      const {
        id: teamId,
        name: teamName,
      } = team;

      this.fileName = `${teamName.replace(/\s+/g, '_')}_SpiderGraph_${moment().format(DATE_FORMAT)}.pdf`;

      return {
        endpoint: `/account/${accountId}/pdfs/team/${teamId}/download_pdf/spider-graph`,
        body: formData({ html, profiles }),
        ...FETCH_OPTIONS,
      };
    },
  },
  sendNewInvitation: {
    /**
   * @description Request settings (Send New Token Invitation)
   * @param  {object} options
   * @param  {number} options.accountId - The account ID.
   * @param  {number} options.reportId - The report ID.
   * @returns {object} - Request settings.
   */
    settings: ({ accountId, reportId }) => ({
      ...FETCH_OPTIONS,
      endpoint: `/account/${accountId}/tokens/send_exp_can_bulk_report`,
      body: {
        id: reportId,
      },
    }),
  },
};
