/* eslint-disable react-hooks/exhaustive-deps */
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { EnergyMap } from 'generics/Charts';
import { PERSON } from 'constants/props';

import styles from './PDFEnergyMaps.scss';

const PDFEnergyMaps = ({ innerRef, profiles }) => {
  const [newProfiles, setNewProfiles] = useState([]);
  const [svgs, setSvgs] = useState([]);

  useEffect(() => {
    if (!!svgs.length && innerRef) {
      innerRef({ svgs });
    }
  }, [svgs]);

  useEffect(() => {
    if (!isEqual(profiles, newProfiles)) {
      setNewProfiles(profiles);
    }
  }, [profiles]);

  const newSvgs = [];

  return (
    <div
      className={styles['energy-maps']}
    >
      {
        isEqual(profiles, newProfiles) && newProfiles
          .map((profile, index) => {
            const { id } = profile;

            return (
              <EnergyMap
                className={styles['energy-map']}
                data={profile}
                key={id}
                innerRef={(componentProps) => {
                  if (componentProps) {
                    newSvgs[index] = {
                      componentNode: componentProps.nodeElementRef.current,
                      id,
                      svg: componentProps.outerHTML(),
                    };
                    if (index === profiles.length - 1) {
                      setSvgs([...newSvgs]);
                    }
                  }
                }}
              />
            );
          })
      }
    </div>
  );
};

PDFEnergyMaps.propTypes = {
  innerRef: () => {},
  profiles: PropTypes.arrayOf(PERSON),
};

PDFEnergyMaps.defaultProps = {
  innerRef: null,
  profiles: [],
};

export default PDFEnergyMaps;
