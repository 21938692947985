import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { CHILDREN } from 'constants/props';
import * as pageSelectors from 'app_modules/page/selectors';
import * as sessionSelectors from 'app_modules/session/selectors';
import ApplicationFooter from 'components/ApplicationFooter';
import ApplicationHeader from 'containers/ApplicationHeader';
import ErrorBoundary from 'generics/ErrorBoundary';

import styles from './TrainingLayout.scss';

const TrainingLayout = ({ children, isImpersonating = false, isModalView = false }) => {
  const [isStickyHeader, setIsStickyHeader] = useState(0);

  const mainFocusRef = useRef(null);

  return (
    <div className={styles.layout}>
      <ApplicationHeader
        className={classnames(
          styles.header,
          { [styles['header--hidden']]: isModalView },
        )}
        focusRef={mainFocusRef}
        onChange={(isSticky) => { setIsStickyHeader(isSticky); }}
      />
      <main
        className={classnames(
          styles.main,
          { [styles['main--has-impersonate-label']]: isImpersonating },
          { [styles['main--has-sticky-header']]: isStickyHeader },
        )}
        ref={mainFocusRef}
      >
        <ErrorBoundary className={styles['error-boundary']}>
          {children}
        </ErrorBoundary>
      </main>
      <ApplicationFooter className={styles.footer} />
    </div>
  );
};

TrainingLayout.propTypes = {
  children: CHILDREN,
  isImpersonating: PropTypes.bool,
  isModalView: PropTypes.bool,
};

TrainingLayout.defaultProps = {
  children: null,
  isImpersonating: false,
  isModalView: false,
};

const mapStateToProps = (state) => ({
  isImpersonating: sessionSelectors.isImpersonating(state),
  isModalView: pageSelectors.isModalView(state),
});

export default connect(mapStateToProps, null)(TrainingLayout);
