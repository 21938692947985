import { CircularProgress } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { ACTIONS, I18N } from 'constants/props';

import { StyledButton } from 'generics/StyledFormComponents';
import Mask from 'generics/Mask';
import ModalConfirmation from 'generics/ModalConfirmation';
import ModalAssign from '../ModalAssign/ModalAssign';
import ReportsList from '../ReportsList/ReportsList';
import useReAssignTokens from './useReAssignTokens';

import styles from './ReAssignTokens.scss';

const ReAssignTokens = ({
  accountId,
  i18n,
  isAdmin,
  notificationActions,
  onFetchNetworkDetails,
  profileId,
}) => {
  const {
    handleOwnerFilter,
    handleSelectReport,
    getAssignedTokens,
    isLoading,
    isAssignModalOpen,
    isWarningAvailableOwnersModalOpen,
    owners,
    reAssignTokens,
    reports,
    reportsMeta,
    selectedTokens,
    setIsAssignModalOpen,
    setIsWarningAvailableOwnersModalOpen,
  } = useReAssignTokens({
    accountId,
    i18n,
    isAdmin,
    notificationActions,
    onFetchNetworkDetails,
    profileId,
  });

  const actionReassignOwnerButton = (
    <StyledButton
      className={styles.sendRequest}
      color="primary"
      disabled={isEmpty(selectedTokens) || !owners?.length}
      id="link-logout"
      onClick={() => setIsAssignModalOpen(true)}
      title={i18n.pageTokenAdministration.ownerChange.reAssignTokensAction}
    >
      {i18n.pageTokenAdministration.ownerChange.reAssignTokensAction}
    </StyledButton>
  );

  return (
    <>
      <div className={styles['reports-container']}>
        <ReportsList
          accountId={accountId}
          actionButton={actionReassignOwnerButton}
          handleOwnerFilter={handleOwnerFilter}
          handleSelectReport={handleSelectReport}
          i18n={i18n}
          isAdmin={isAdmin}
          isAssign
          isLoading={isLoading}
          notificationActions={notificationActions}
          meta={reportsMeta}
          onChange={() => {}}
          onFetch={getAssignedTokens}
          onFetchNetworkDetails={onFetchNetworkDetails}
          owners={owners}
          profileId={profileId}
          reports={reports}
          selectedTokens={selectedTokens}
        />
        {
          isAssignModalOpen && (
            <ModalAssign
              onClose={() => setIsAssignModalOpen(false)}
              onSubmit={reAssignTokens}
              owners={owners}
            />
          )
        }
        {
          isWarningAvailableOwnersModalOpen && (
            <ModalConfirmation
              confirmationMessages={[
                i18n.pageTokenAdministration.ownerChange.noAvailableOwnersToReAssign,
              ]}
              barClassName={styles.bar}
              onAccept={() => setIsWarningAvailableOwnersModalOpen(false)}
            />
          )
        }
        <Mask
          className={styles.mask}
          open={isLoading}
        >
          <CircularProgress
            className={styles.loader}
            size={25}
            thickness={2}
          />
        </Mask>
      </div>
    </>
  );
};

ReAssignTokens.propTypes = {
  accountId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  notificationActions: ACTIONS.isRequired,
  onFetchNetworkDetails: PropTypes.func.isRequired,
  profileId: PropTypes.number.isRequired,
};

export default ReAssignTokens;
