import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/profile/constants';
import api from 'api';

// Actions

export const editDepartmentFailure = (error) => ({
  payload: error,
  type: actionTypes.EDIT_DEPARTMENT_FAILURE,
});

export const editDepartmentRequest = () => ({
  type: actionTypes.EDIT_DEPARTMENT_REQUEST,
});

export const editDepartmentSuccess = (response) => ({
  payload: response.profile,
  type: actionTypes.EDIT_DEPARTMENT_SUCCESS,
});

// Thunks

export const editDepartment = (options, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());

    dispatch(editDepartmentRequest());

    const handleResponse = (response) => {
      dispatch(editDepartmentSuccess(response));
      if (onSuccess) {
        onSuccess(response);
      }
    };

    const handleError = ({ error }) => {
      dispatch(editDepartmentFailure(error));
      if (onError) {
        onError(error);
      } else {
        throw error;
      }
    };

    return api.accounts.editDepartment({
      accountId,
      ...options,
    }, handleResponse, handleError);
  };

  thunk.type = actionTypes.EDIT_DEPARTMENT;

  return thunk;
};

export default editDepartment;
