export const en = {
  accept: 'Accept',
  acceptInvitation: 'Accept Invitation',
  decline: 'Decline',
  description: 'By accepting this invitation you are granting %s and its authorized agents access to your 5 Dynamics<sup>&reg;</sup>&nbsp;profile.',
  invalidInvitation: 'Invalid Invitation',
  invitationDeclined: 'Invitation Declined',
  invitationDeclinedDescription: 'We see that you have declined your invitation to join %s network. We will share your decision with the %s Administrator.',
  invitedBy: 'You have been invited by %s to join %s.',
  ok: 'Ok',
  youDeclinedInvitation: 'You have declined this invitation',
};

export const es = en;
