import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import { isEqual } from 'lodash';
import { Redirect, Route as RRoute } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { LEARNING_ACCOUNT_TYPE } from 'constants/accountTypes';
import { LOGIN, MY_5_DYNAMICS } from 'constants/urls';
import { PERSON } from 'props';
import { setCurrentProfileByAccountId } from 'app_modules/session/actions';
import * as accountSelectors from 'app_modules/accounts/selectors';
import * as sessionSelectors from 'app_modules/session/selectors';
import DefaultLayout from 'components/Application/components/layouts/DefaultLayout';

// TODO: Check if this function is better to relocate outside this file
function handleUpdateState(props) {
  const {
    computedMatch,
    dispatch,
    isAuthenticated,
    private: isPrivate,
  } = props;

  if (isPrivate && !isAuthenticated) {
    return;
  }

  const { accountId } = computedMatch.params;

  if (accountId) {
    dispatch(setCurrentProfileByAccountId(parseInt(accountId, 0)));
  }
}

class Route extends RRoute {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    handleUpdateState(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    if (!isEqual(nextProps, this.props)) {
      handleUpdateState(nextProps);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      isAuthenticated,
      isStudentWaitingResults,
      layout,
      location,
      permissions,
      private: isPrivate,
      profile,
      validatePersonalAccount,
    } = this.props;

    const {
      accountType,
      id: accountId,
      flags,
    } = profile.account;

    const {
      administrationTab: isAdministrationTabVisible,
      networkTab: isNetworkTabVisible,
      newsTab: isNewsTabVisible,
      teamsTab: isTeamsTabVisible,
      tokenAdministrationTab: isTokenAdministrationTabVisible,
      trainingCenterTab: isTrainingCenterTabVisible,
    } = flags || {};

    const isLearningAccount = accountType === LEARNING_ACCOUNT_TYPE;

    const learningMenuTabsVisibility = {
      administration: isAdministrationTabVisible,
      'administration/people': isAdministrationTabVisible,
      'administration/content-modules': isAdministrationTabVisible,
      'administration/customization-modules': isAdministrationTabVisible,
      'my-5-dynamics': true,
      network: isNetworkTabVisible,
      news: isNewsTabVisible,
      relationships: !isStudentWaitingResults,
      teams: isTeamsTabVisible && !isStudentWaitingResults,
      'token-administration': isTokenAdministrationTabVisible,
      'token-administration/people': isTokenAdministrationTabVisible,
      'training-center': isTrainingCenterTabVisible,
    };

    const canNavigate = learningMenuTabsVisibility[`${location.pathname.replace(`/account/${accountId}/`, '')}`];

    if (isLearningAccount && !canNavigate && canNavigate !== undefined) {
      return (
        <Redirect
          to={{
            pathname: formatRoute(MY_5_DYNAMICS, { accountId }),
            state: { from: location },
          }}
        />
      );
    }

    if (permissions && !profile.can(permissions)) {
      if (this.context.router) {
        this.context.router.history.go(-1);
      }
      return null;
    }

    const Layout = layout || DefaultLayout;

    if (isPrivate && !isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: formatRoute(LOGIN),
            state: { from: location },
          }}
        />
      );
    }

    if (validatePersonalAccount) {
      const {
        isPersonal: isPersonalAccount,
      } = profile.account;

      if (isPersonalAccount) {
        return (
          <Redirect
            to={{
              pathname: formatRoute(MY_5_DYNAMICS, { accountId }),
              state: { from: location },
            }}
          />
        );
      }
    }

    return (
      <Layout>
        {super.render()}
      </Layout>
    );
  }
}

Route.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  computedMatch: PropTypes.shape({}).isRequired,
  isAuthenticated: PropTypes.bool,
  private: PropTypes.bool,
  profile: PERSON,
  validatePersonalAccount: PropTypes.bool,
};

Route.defaultProps = {
  isAuthenticated: false,
  isPersonalAccount: false,
  permissions: null,
  private: false,
  validatePersonalAccount: false,
};

const mapStateToProps = (state) => ({
  isAuthenticated: sessionSelectors.isAuthenticated(state),
  isStudentWaitingResults: accountSelectors.isStudentWaitingResults(state),
  profile: sessionSelectors.currentProfile(state),
});

export default connect(mapStateToProps)(Route);
