/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { REQUIRED, NAME, PASSWORD, PASSWORD_CONFIRMATION } from 'constants/inputTypes';
import { I18N } from 'constants/props';
import api from 'api';
import useForm from 'custom_hooks/useForm';
import useFormValidation from 'custom_hooks/useFormValidation';

const useRegistrationForm = ({ i18n, initialValues, onSubmit }) => {
  const { validator } = useFormValidation({ i18n });

  const [languages, setLanguages] = useState([]);

  const handleValidate = (values) => {
    const {
      agreedToTermsOfService,
      firstName,
      genderPronounPreference,
      language,
      lastName,
      password,
      passwordConfirmation,
    } = values;

    return {
      agreedToTermsOfService: validator.validate(REQUIRED, agreedToTermsOfService),
      firstName: validator.validate(NAME, firstName),
      language: validator.validate(REQUIRED, language),
      lastName: validator.validate(NAME, lastName),
      genderPronounPreference: validator.validate(REQUIRED, genderPronounPreference),
      password: validator.validate(PASSWORD, password),
      passwordConfirmation: validator.validate(
        PASSWORD_CONFIRMATION,
        password,
        passwordConfirmation,
      ),
    };
  };

  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values = {
      agreedToTermsOfService: null,
      firstName: null,
      genderPronounPreference: null,
      language: null,
      lastName: null,
      password: null,
      passwordConfirmation: null,
    },
  } = useForm({ onValidate: handleValidate, initialValues, onSubmit });

  const getLanguages = () => {
    const onSuccess = ({ languages: setupLanguages }) => {
      if (setupLanguages) {
        setLanguages(setupLanguages);
        handleChange('language', setupLanguages[0]);
      }
    };

    const onError = ({ error }) => {
      console.log(error);
    };

    api.profiles.getProfileLanguages(null, onSuccess, onError);
  };

  useEffect(() => {
    getLanguages();
  }, []);

  return {
    errors,
    isValid,
    languages,
    handleChange,
    handleSubmit,
    values,
  };
};

useRegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
};

export default useRegistrationForm;
