import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as bundlesActions from 'app_modules/bundles/actions';
import * as bundlesSelectors from 'app_modules/bundles/selectors';
import * as sessionSelectors from 'app_modules/session/selectors';
import BundlesCard from 'components/PageAdministration/components/BundlesCard';

const mapStateToProps = (state) => ({
  accountId: sessionSelectors.accountId(state),
  bundlesList: bundlesSelectors.list(state),
});

const mapDispatchToProps = (dispatch) => ({
  bundlesActions: bindActionCreators(bundlesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BundlesCard);
