/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from 'reactstrap';
import DocumentTitle from 'react-document-title';
import React, { useEffect, useState } from 'react';

import { ACCOUNT_ADMIN } from 'roles';
import { ACTIONS, I18N, LIST, PROFILE, TEAM } from 'constants/props';
import { CORPORATE_TEAMS, OTHERS_TEAMS, PRIVATE_TEAMS, PUBLISHED_TEAMS } from 'constants/teamSections';
import { LEARNING_ACCOUNT_TYPE } from 'constants/accountTypes';
import TeamList from 'containers/TeamList';
import Translation from 'generics/Translation';
import VerticalMenu from 'generics/VerticalMenu';

import styles from './PageTeams.scss';

const PageTeams = ({
  actions,
  currentProfile,
  i18n,
  publishedTeams,
  othersTeams,
  corporateTeams,
  privateTeams,
}) => {
  const {
    account: {
      accountType,
    },
  } = currentProfile;

  const isLearningAccount = accountType === LEARNING_ACCOUNT_TYPE;
  const isLearningAdminAccount = (isLearningAccount && currentProfile?.hasRole(ACCOUNT_ADMIN));

  const getSections = () => {
    const sections = [
      {
        icon: 'door-lock',
        id: PRIVATE_TEAMS,
        name: i18n.pageTeams.sections.private.title,
        sharedIcon: true,
      },
      {
        icon: 'look',
        id: PUBLISHED_TEAMS,
        name: i18n.pageTeams.sections.published.title,
        sharedIcon: true,
      },
      {
        icon: 'world',
        id: OTHERS_TEAMS,
        isMultipleAccount: true,
        name: i18n.pageTeams.sections.others.title,
        sharedIcon: true,
      },
      {
        icon: 'display1',
        id: CORPORATE_TEAMS,
        name: i18n.pageTeams.sections.corporate.title,
        sharedIcon: true,
      },
    ];

    return (
      !isLearningAccount
      || isLearningAdminAccount
        ? [...sections]
        : [...sections.filter((section) => section.isMultipleAccount)]
    );
  };

  const [sectionId, setSectionId] = useState(
    !isLearningAccount
    || isLearningAdminAccount
      ? PRIVATE_TEAMS
      : OTHERS_TEAMS);

  const setInitialIcon = () => (getSections().find(({ sharedIcon, id }) => (
    id === sectionId && sharedIcon
  ))?.icon || null);

  const [sharedIcon, setSharedIcon] = useState(setInitialIcon());

  const fetch = (pageIndex = 1, search) => {
    let action = null;

    switch (sectionId) {
      case PUBLISHED_TEAMS:
        action = actions.fetchPublishedTeams;
        break;
      case OTHERS_TEAMS:
        action = actions.fetchOthersTeams;
        break;
      case CORPORATE_TEAMS:
        action = actions.fetchCorporateTeams;
        break;
      // My teams (private)
      default:
        action = actions.fetchPrivateTeams;
        break;
    }

    action({ pageIndex, search });
  };

  useEffect(() => {
    fetch();
  }, []);

  const getTeamList = () => {
    switch (sectionId) {
      case PUBLISHED_TEAMS:
        return publishedTeams;
      case OTHERS_TEAMS:
        return othersTeams;
      case CORPORATE_TEAMS:
        return corporateTeams;
      // My teams (private)
      default:
        return privateTeams;
    }
  };

  const handleChangeSection = (newSectionId) => {
    const sections = getSections();

    const {
      icon,
      id: selectedSectionId,
      sharedIcon: hasSharedIcon,
    } = sections.find(({ id }) => id === newSectionId) || {};

    if (selectedSectionId) {
      setSectionId(newSectionId);
      setSharedIcon(hasSharedIcon ? icon : null);
    }
  };

  return (
    <DocumentTitle title="Simpli5® - Teams Page">
      <div className={styles.page}>
        <Container>
          <Row>
            <Col xs="12" md="4">
              <VerticalMenu
                className={styles['vertical-menu']}
                onChangeSection={handleChangeSection}
                sectionClassname="teams"
                sections={getSections()}
                selectedSectionId={sectionId}
              />
            </Col>
            <Col xs="12" md="8">
              <TeamList
                onFetch={fetch}
                sectionId={sectionId}
                sharedIcon={sharedIcon}
                teams={getTeamList()}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </DocumentTitle>
  );
};

PageTeams.propTypes = {
  actions: ACTIONS.isRequired,
  corporateTeams: LIST(TEAM).isRequired,
  currentProfile: PROFILE.isRequired,
  i18n: I18N.isRequired,
  othersTeams: LIST(TEAM).isRequired,
  privateTeams: LIST(TEAM).isRequired,
  publishedTeams: LIST(TEAM).isRequired,
};

export default Translation(PageTeams, ['pageTeams']);
