import { CircularProgress } from '@material-ui/core';
import { Col, Container, Row } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { COL, CONTEXT_URL, MODULES } from 'constants/props';
import { getEntityByContextRoute } from 'urls';
import { getModulesDescription } from 'helpers';
import ModulesListHeader from 'generics/ModulesListHeader';
import ModulesListItem from 'generics/ModulesListItem';

import styles from './ModulesList.scss';

const ModulesList = ({
  accountId,
  cardClassName,
  className,
  col,
  goToUrl,
  header,
  modules,
  showBullets,
}) => {
  const headerProps = header && header.constructor === String
    ? { title: header }
    : header;

  return (
    <div className={className}>
      <div className={styles['module-list']}>
        <Container fluid>
          {
            headerProps && (
              <Row>
                <Col>
                  <ModulesListHeader
                    isUpperCase
                    tag="h2"
                    {...headerProps}
                  />
                </Col>
              </Row>
            )
          }

          <Row>
            {
              modules && modules.length === 0 && (
                <Col>
                  <div
                    className={classnames(
                      styles.card,
                      styles.loader,
                    )}
                  >
                    <CircularProgress
                      size={60}
                      thickness={7}
                    />
                  </div>
                </Col>
              )
            }

            {
              modules && modules.length > 0 && modules.map((module) => {
                const {
                  categoryColor,
                  categoryKey: category,
                  categoryTitle,
                  description,
                  contextType,
                  icon,
                  key: moduleKey,
                  title,
                  ...rest
                } = getModulesDescription(module);
                // TODO: Remove this when descriptions cames fine from Epic

                let entityByContextRoute;

                if (goToUrl) {
                  entityByContextRoute = getEntityByContextRoute({
                    accountId,
                    category,
                    context: goToUrl.context,
                    contextType,
                    entity: goToUrl.entity,
                    menuItem: goToUrl.menuItem,
                    moduleKey,
                  });
                }

                return (
                  <Col
                    key={module.key}
                    {...col}
                  >
                    <ModulesListItem
                      showBullets={showBullets}
                      category={categoryTitle}
                      categoryColor={categoryColor}
                      className={classnames(
                        styles.card,
                        cardClassName,
                      )}
                      description={description}
                      goToUrl={entityByContextRoute}
                      icon={icon}
                      title={title}
                      {...rest}
                    />
                  </Col>
                );
              })
            }
          </Row>
        </Container>
      </div>
    </div>
  );
};

ModulesList.propTypes = {
  accountId: PropTypes.number.isRequired,
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  col: COL,
  goToUrl: CONTEXT_URL,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string,
      className: PropTypes.string,
    }),
  ]),
  modules: MODULES,
  showBullets: PropTypes.bool,
};

ModulesList.defaultProps = {
  cardClassName: null,
  className: null,
  col: null,
  goToUrl: null,
  header: undefined,
  modules: [],
  showBullets: true,
};

export default ModulesList;
