import { PRIVATE_TEAMS } from 'constants/teamSections';
import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/teams/constants';
import api from 'api';

// Actions

export const fetchPrivateTeamsFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_PRIVATE_TEAMS_FAILURE,
});

export const fetchPrivateTeamsRequest = (pageIndex) => ({
  payload: pageIndex,
  type: actionTypes.FETCH_PRIVATE_TEAMS_REQUEST,
});

export const fetchPrivateTeamsSuccess = (response) => ({
  payload: response,
  type: actionTypes.FETCH_PRIVATE_TEAMS_SUCCESS,
});

// Thunks

export const fetchPrivateTeams = ({ pageIndex, search }, onSuccess) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());

    dispatch(fetchPrivateTeamsRequest(pageIndex));

    const handleResponse = (response) => {
      dispatch(fetchPrivateTeamsSuccess(response));

      if (onSuccess) {
        onSuccess(response);
      }
    };

    const handleError = ({ error }) => {
      dispatch(fetchPrivateTeamsFailure(error));
      throw error;
    };

    return api.teams.getTeamList(
      { accountId, pageIndex, search, sectionName: PRIVATE_TEAMS },
      handleResponse,
      handleError,
    );
  };

  thunk.type = actionTypes.FETCH_PRIVATE_TEAMS;

  return thunk;
};

export default fetchPrivateTeams;
