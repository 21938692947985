/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { sprintf } from 'sprintf-js';
import React, { useEffect, useState } from 'react';

import { Card, Header, Main } from 'generics/Card';
import { I18N, MATCH } from 'constants/props';
import Translation from 'generics/Translation';

import styles from './SpiderGraphHistory.scss';

const SpiderGraphHistory = ({ i18n, match }) => {
  const [spiderGraphHistory, setSpiderGraphHistory] = useState([]);

  useEffect(() => {
    const { teamId } = match?.params || {};

    const savedSpiderGraphs = JSON.parse(localStorage.getItem('spiderGraphs'));

    setSpiderGraphHistory(savedSpiderGraphs && savedSpiderGraphs[teamId]
      ? savedSpiderGraphs[teamId]
      : [],
    );
  }, []);

  const renderSpiderGraphHistory = () => !!spiderGraphHistory.length
    && spiderGraphHistory.map(({ route, date, time }) => (
      <Link
        // aria-label={`Back to ${label} page.`}
        className={styles.link}
        href={route}
        id="main"
        key={`${route}-link`}
        title={sprintf(i18n.pageTeamDashboard.spiderGraphHistory.name, date)}
        to={route}
      >
        {sprintf(i18n.pageTeamDashboard.spiderGraphHistory.name, `${date} - ${time}`)}
      </Link>
    ));

  return (
    <Card
      barClassName={styles.bar}
      className={styles.card}
    >
      <Header title={i18n.pageTeamDashboard.spiderGraphHistory.title} />

      <Main>
        <Container>
          <Row>
            <Col xs="12">
              {spiderGraphHistory?.length
                ? renderSpiderGraphHistory()
                : (
                  <div className={styles.empty}>
                    <span>
                      {i18n.generics.list.noResults}
                    </span>
                  </div>
                )}
            </Col>
          </Row>
        </Container>
      </Main>
    </Card>
  );
};

SpiderGraphHistory.propTypes = {
  i18n: I18N.isRequired,
  match: MATCH.isRequired,
};

export default Translation(SpiderGraphHistory, ['pageTeamDashboard', 'generics']);
