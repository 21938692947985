const NAME_SPACE = 'Groups';

const constants = {
  CLEAR_WORKSHOP: `${NAME_SPACE}/CLEAR_WORKSHOP`,
  FETCH_MEMBERS_FAILURE: `${NAME_SPACE}/FETCH_MEMBERS_FAILURE`,
  FETCH_MEMBERS_REQUEST: `${NAME_SPACE}/FETCH_MEMBERS_REQUEST`,
  FETCH_MEMBERS_SUCCESS: `${NAME_SPACE}/FETCH_MEMBERS_SUCCESS`,
  FETCH_MEMBERS: `${NAME_SPACE}/FETCH_MEMBERS`,
  FETCH_UPDATE_WORKSHOP_FAILURE: `${NAME_SPACE}/FETCH_UPDATE_WORKSHOP_FAILURE`,
  FETCH_UPDATE_WORKSHOP_MEMBERS_FAILURE: `${NAME_SPACE}/FETCH_UPDATE_WORKSHOP_MEMBERS_FAILURE`,
  FETCH_UPDATE_WORKSHOP_MEMBERS_REQUEST: `${NAME_SPACE}/FETCH_UPDATE_WORKSHOP_MEMBERS_REQUEST`,
  FETCH_UPDATE_WORKSHOP_MEMBERS_SUCCESS: `${NAME_SPACE}/FETCH_UPDATE_WORKSHOP_MEMBERS_SUCCESS`,
  FETCH_UPDATE_WORKSHOP_MEMBERS: `${NAME_SPACE}/FETCH_UPDATE_WORKSHOP_MEMBERS`,
  FETCH_UPDATE_WORKSHOP_REQUEST: `${NAME_SPACE}/FETCH_UPDATE_WORKSHOP_REQUEST`,
  FETCH_UPDATE_WORKSHOP_SUCCESS: `${NAME_SPACE}/FETCH_UPDATE_WORKSHOP_SUCCESS`,
  FETCH_UPDATE_WORKSHOP: `${NAME_SPACE}/FETCH_UPDATE_WORKSHOP`,
  FETCH_WORKSHOP_DETAILS_FAILURE: `${NAME_SPACE}/FETCH_WORKSHOP_DETAILS_FAILURE`,
  FETCH_WORKSHOP_DETAILS_REQUEST: `${NAME_SPACE}/FETCH_WORKSHOP_DETAILS_REQUEST`,
  FETCH_WORKSHOP_DETAILS_SUCCESS: `${NAME_SPACE}/FETCH_WORKSHOP_DETAILS_SUCCESS`,
  FETCH_WORKSHOP_DETAILS: `${NAME_SPACE}/FETCH_WORKSHOP_DETAILS`,
  FETCH_WORKSHOPS_FAILURE: `${NAME_SPACE}/FETCH_WORKSHOPS_FAILURE`,
  FETCH_WORKSHOPS_REQUEST: `${NAME_SPACE}/FETCH_WORKSHOPS_REQUEST`,
  FETCH_WORKSHOPS_SUCCESS_NO_RESULTS: `${NAME_SPACE}/FETCH_WORKSHOPS_SUCCESS_NO_RESULTS`,
  FETCH_WORKSHOPS_SUCCESS: `${NAME_SPACE}/FETCH_WORKSHOPS_SUCCESS`,
  FETCH_WORKSHOPS: `${NAME_SPACE}/FETCH_WORKSHOPS`,
};

export default constants;
