import { BarError } from 'classes/errors';
import { notifyError } from 'app_modules/notifications/actions';
import * as groupsSelectors from 'app_modules/groups/selectors';
import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/groups/constants';
import api from 'api';

// Actions

export const fetchUpdateGroupFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_UPDATE_GROUP_FAILURE,
});

export const fetchUpdateGroupRequest = () => ({
  type: actionTypes.FETCH_UPDATE_GROUP_REQUEST,
});

export const fetchUpdateGroupSuccess = (response) => ({
  payload: response,
  type: actionTypes.FETCH_UPDATE_GROUP_SUCCESS,
});

// Thunks

export const fetchUpdateGroup = (data, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const state = getState();
    const accountId = sessionSelectors.accountId(state);
    const { id: groupId } = groupsSelectors.currentGroup(state).group;

    dispatch(fetchUpdateGroupRequest());

    const handleResponse = (response) => {
      dispatch(fetchUpdateGroupSuccess(response));
      if (onSuccess) {
        onSuccess(response);
      }
    };

    const handleError = ({ error, isConnectionError }) => {
      dispatch(fetchUpdateGroupFailure(error));
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'fetchUpdateGroup' })));
      }
      if (onError) {
        onError(error);
      } else {
        throw error;
      }
    };

    return api.groups.updateGroup({
      accountId,
      groupId,
      ...data,
    }, handleResponse, handleError);
  };

  thunk.type = actionTypes.FETCH_UPDATE_GROUP;

  return thunk;
};

export default fetchUpdateGroup;
