import { BarError } from 'classes/errors';
import { notifyError } from 'app_modules/notifications/actions';
import * as accountActions from 'app_modules/accounts/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/invites/constants';
import api from 'api';

// Actions

export const invalidateInvitationFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_INVALIDATE_INVITATION_FAILURE,
});

export const invalidateInvitationRequest = () => ({
  type: actionTypes.FETCH_INVALIDATE_INVITATION_REQUEST,
});

export const invalidateInvitationSuccess = (response) => ({
  payload: response,
  type: actionTypes.FETCH_INVALIDATE_INVITATION_SUCCESS,
});

// Thunks

export const fetchInvalidateInvitation = (invitationId, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());

    dispatch(invalidateInvitationRequest());

    const handleResponse = (response) => {
      dispatch(invalidateInvitationSuccess(invitationId));
      dispatch(accountActions.fetchAccount(accountId));

      if (onSuccess) {
        onSuccess(response);
      }
    };

    const handleError = ({ error, isConnectionError }) => {
      dispatch(invalidateInvitationFailure(error));
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'invalidateInvitationError' })));
      }
      if (onError) {
        onError(error);
      } else {
        throw error;
      }
    };

    return api.invites.invalidateInvitation({
      accountId,
      invitationId,
    }, handleResponse, handleError);
  };

  thunk.type = actionTypes.FETCH_INVALIDATE_INVITATION;

  return thunk;
};

export default fetchInvalidateInvitation;
