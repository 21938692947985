/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React from 'react';

import api from 'api';
import Modal from 'generics/Modal';
import Translation from 'generics/Translation';

import FormLicensesQuantity from '../FormLicensesQuantity';
import styles from './ModalLicensesQuantity.scss';

const ModalLicensesQuantity = ({
  accountId,
  handleRoleError,
  isFetching,
  onClose,
  title,
}) => {
  const handleSubmit = (values) => {
    const {
      quantity,
    } = values;

    const onSuccess = ({ session }) => {
      window.location.replace(session.url);
    };

    const onError = ({ error }) => {
      handleRoleError(error, onClose);
    };

    api.subscriptions.createPaymentSession({ accountId, quantity }, onSuccess, onError);
  };

  return (
    <Modal
      card={{
        barClassName: styles.bar,
        isFetching,
      }}
      modalCardClassName={styles.modal}
      onClose={onClose}
      switchModalView={false}
      title={title}
    >
      <FormLicensesQuantity
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

ModalLicensesQuantity.propTypes = {
  accountId: PropTypes.number.isRequired,
  handleRoleError: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default Translation(ModalLicensesQuantity, ['subscriptions']);
