import { combineReducers } from 'redux';
import { get, isEmpty, uniqBy } from 'lodash';

import { NOTES_SECTION } from 'constants/notesSection';
import initialState from 'app_modules/store/initialState.json';
import networkActionTypes from 'app_modules/network/constants';
import sessionActionTypes from 'app_modules/session/constants';

export const myNetwork = combineReducers({
  isFetching: (state = initialState.network.myNetwork.isFetching, { type }) => {
    switch (type) {
      case networkActionTypes.FETCH_MY_NETWORK_REQUEST:
        return true;

      case networkActionTypes.FETCH_MY_NETWORK_FAILURE:
      case networkActionTypes.FETCH_MY_NETWORK_SUCCESS:
        return false;

      case sessionActionTypes.RESET_STATE:
        return initialState.network.myNetwork.isFetching;

      default:
        return state;
    }
  },

  meta: (state = initialState.network.myNetwork.meta, { type, payload }) => {
    switch (type) {
      case networkActionTypes.FETCH_MY_NETWORK_REQUEST:
        return { morePages: false };

      case networkActionTypes.FETCH_MY_NETWORK_SUCCESS: {
        const meta = get(payload, 'meta', state);
        if (isEmpty(payload.profiles)) {
          // BE is returning morePages = true when there aren't results
          meta.morePages = false;
        }
        return meta;
      }

      case sessionActionTypes.RESET_STATE:
        return initialState.network.myNetwork.meta;

      default:
        return state;
    }
  },

  profiles: (state = initialState.network.myNetwork.profiles, { type, payload }) => {
    switch (type) {
      case networkActionTypes.FETCH_MY_NETWORK_REQUEST:
        if (payload.pageIndex === 1) {
          return [];
        }
        return state;

      case networkActionTypes.FETCH_MY_NETWORK_FAILURE:
        return [];

      case networkActionTypes.FETCH_MY_NETWORK_SUCCESS:
        return uniqBy([...state, ...payload.profiles], 'id');

      case sessionActionTypes.RESET_STATE:
        return initialState.network.myNetwork.profiles;

      default:
        return state;
    }
  },
});

export const myConnections = combineReducers({
  isFetching: (state = initialState.network.myConnections.isFetching, { type }) => {
    switch (type) {
      case networkActionTypes.FETCH_MY_CONNECTIONS_REQUEST:
        return true;

      case networkActionTypes.FETCH_MY_CONNECTIONS_FAILURE:
      case networkActionTypes.FETCH_MY_CONNECTIONS_SUCCESS:
        return false;

      case sessionActionTypes.RESET_STATE:
        return initialState.network.myConnections.isFetching;

      default:
        return state;
    }
  },

  meta: (state = initialState.network.myConnections.meta, { type, payload }) => {
    switch (type) {
      case networkActionTypes.FETCH_MY_CONNECTIONS_REQUEST:
        return { morePages: false };

      case networkActionTypes.FETCH_MY_CONNECTIONS_SUCCESS: {
        const meta = get(payload, 'meta', state);
        if (isEmpty(payload.profiles)) {
          meta.morePages = false;
        }
        return meta;
      }

      case sessionActionTypes.RESET_STATE:
        return initialState.network.myConnections.meta;

      default:
        return state;
    }
  },

  profiles: (state = initialState.network.myConnections.profiles, { type, payload }) => {
    switch (type) {
      case networkActionTypes.FETCH_MY_CONNECTIONS_REQUEST:
        if (payload.pageIndex === 1) {
          return [];
        }
        return state;

      case networkActionTypes.FETCH_MY_CONNECTIONS_FAILURE:
        return [];

      case networkActionTypes.FETCH_MY_CONNECTIONS_SUCCESS:
        return uniqBy([...state, ...payload.profiles], 'id');

      case sessionActionTypes.RESET_STATE:
        return initialState.network.myConnections.profiles;

      default:
        return state;
    }
  },
});


export const networkDetails = combineReducers({
  errors: (state = initialState.network.networkDetails.errors, { type, payload }) => {
    switch (type) {
      case networkActionTypes.FETCH_NETWORK_DETAILS_FAILURE:
        return [...state, payload];

      case sessionActionTypes.RESET_STATE:
        return initialState.network.networkDetails.errors;

      default:
        return state;
    }
  },

  isFetching: (state = initialState.network.networkDetails.isFetching, { type }) => {
    switch (type) {
      case networkActionTypes.FETCH_NETWORK_DETAILS_REQUEST:
        return true;

      case networkActionTypes.FETCH_NETWORK_DETAILS_FAILURE:
      case networkActionTypes.FETCH_NETWORK_DETAILS_SUCCESS:
        return false;

      case sessionActionTypes.RESET_STATE:
        return initialState.network.networkDetails.isFetching;

      default:
        return state;
    }
  },

  list: (state = initialState.network.networkDetails.list, { type, payload }) => {
    switch (type) {
      case networkActionTypes.FETCH_NETWORK_DETAILS_SUCCESS: {
        const {
          meta = { connectionsQuantity: 0 },
          profile,
        } = payload;

        const {
          adminToken,
          id,
          orgToken,
          totalTeamsByOwner = 0,
        } = profile;

        const list = [...state];

        list[id] = {
          ...adminToken,
          ...orgToken,
          numberOfConnections: meta.connectionsQuantity,
          numberOfTeamsCreated: totalTeamsByOwner,
        };

        return list;
      }

      case sessionActionTypes.RESET_STATE:
        return initialState.network.networkDetails.list;

      default:
        return state;
    }
  },
});

const requests = combineReducers({
  received: combineReducers({
    isFetching: (state = initialState.network.requests.received.isFetching, { type }) => {
      switch (type) {
        case networkActionTypes.FETCH_RECEIVED_REQUESTS_REQUEST:
        case networkActionTypes.FETCH_REPLY_CONNECTION_REQUEST_REQUEST:
          return true;

        case networkActionTypes.FETCH_RECEIVED_REQUESTS_FAILURE:
        case networkActionTypes.FETCH_RECEIVED_REQUESTS_SUCCESS:
        case networkActionTypes.FETCH_REPLY_CONNECTION_REQUEST_FAILURE:
        case networkActionTypes.FETCH_REPLY_CONNECTION_REQUEST_SUCCESS:
          return false;

        case sessionActionTypes.RESET_STATE:
          return initialState.network.requests.received.isFetching;

        default:
          return state;
      }
    },

    meta: (state = initialState.network.requests.received.meta, { type, payload }) => {
      switch (type) {
        case networkActionTypes.FETCH_RECEIVED_REQUESTS_FAILURE:
          return { morePages: false };

        case networkActionTypes.FETCH_RECEIVED_REQUESTS_SUCCESS:
          return { ...state, ...(payload.meta || {}) };

        case sessionActionTypes.RESET_STATE:
          return initialState.network.requests.received.meta;

        default:
          return state;
      }
    },

    requests: (state = initialState.network.requests.received.requests, { type, payload }) => {
      switch (type) {
        case networkActionTypes.FETCH_RECEIVED_REQUESTS_REQUEST:
          if (payload === 1) {
            return [];
          }
          return state;

        case networkActionTypes.FETCH_RECEIVED_REQUESTS_FAILURE:
          return [];

        case networkActionTypes.FETCH_RECEIVED_REQUESTS_SUCCESS:
          return [...state, ...get(payload, 'connectionRequests', [])];

        case networkActionTypes.FETCH_REPLY_CONNECTION_REQUEST_SUCCESS:
          return state.filter((request) => request.id !== payload.id);

        case sessionActionTypes.RESET_STATE:
          return initialState.network.requests.received.requests;

        default:
          return state;
      }
    },
  }),

  sent: combineReducers({
    isFetching: (state = initialState.network.requests.sent.isFetching, { type }) => {
      switch (type) {
        case networkActionTypes.FETCH_SENT_REQUESTS_REQUEST:
          return true;

        case networkActionTypes.FETCH_SENT_REQUESTS_FAILURE:
        case networkActionTypes.FETCH_SENT_REQUESTS_SUCCESS:
          return false;

        case sessionActionTypes.RESET_STATE:
          return initialState.network.requests.sent.isFetching;

        default:
          return state;
      }
    },

    meta: (state = initialState.network.requests.sent.meta, { type, payload }) => {
      switch (type) {
        case networkActionTypes.FETCH_SENT_REQUESTS_FAILURE:
          return initialState.network.requests.sent.meta;

        case networkActionTypes.FETCH_SENT_REQUESTS_SUCCESS:
          return { ...state, ...(payload.meta || {}) };

        case sessionActionTypes.RESET_STATE:
          return initialState.network.requests.sent.meta;

        default:
          return state;
      }
    },

    requests: (state = initialState.network.requests.sent.requests, { type, payload }) => {
      switch (type) {
        case networkActionTypes.FETCH_SENT_REQUESTS_REQUEST:
          if (payload === 1) {
            return [];
          }
          return state;

        case networkActionTypes.FETCH_SENT_REQUESTS_FAILURE:
          return [];

        case networkActionTypes.FETCH_SENT_REQUESTS_SUCCESS:
          return [...state, ...get(payload, 'connectionRequests', [])];

        case sessionActionTypes.RESET_STATE:
          return initialState.network.requests.sent.requests;

        default:
          return state;
      }
    },
  }),
});

export const thirdPerson = combineReducers({
  isFetching: (state = initialState.network.thirdPerson.isFetching, action) => {
    switch (action.type) {
      case networkActionTypes.FETCH_THIRD_PERSON_PROFILE_REQUEST:
      case networkActionTypes.FETCH_UPDATE_PROFILE_ROLES_REQUEST:
        return true;

      case networkActionTypes.FETCH_THIRD_PERSON_PROFILE_FAILURE:
      case networkActionTypes.FETCH_THIRD_PERSON_PROFILE_SUCCESS:
      case networkActionTypes.FETCH_UPDATE_PROFILE_ROLES_SUCCESS:
        return false;

      case sessionActionTypes.RESET_STATE:
        return initialState.network.thirdPerson.isFetching;

      default:
        return state;
    }
  },

  modules: (state = initialState.network.thirdPerson.modules, action) => {
    switch (action.type) {
      case networkActionTypes.FETCH_THIRD_PERSON_PROFILE_REQUEST:
      case networkActionTypes.FETCH_THIRD_PERSON_PROFILE_FAILURE:
        return [];

      case networkActionTypes.FETCH_THIRD_PERSON_PROFILE_SUCCESS:
        return [...get(action.payload, 'dashboardModules', [])]
          .filter((module) => (module.categoryKey !== 'meetings_8'))
          .map((module) => (
            NOTES_SECTION[module.key] ? {
              ...module,
              hasNotes: true,
            } : module
          ));

      case sessionActionTypes.RESET_STATE:
        return initialState.network.thirdPerson.modules;

      default:
        return state;
    }
  },

  overview: (state = initialState.network.thirdPerson.overview, action) => {
    switch (action.type) {
      case networkActionTypes.FETCH_THIRD_PERSON_PROFILE_REQUEST:
      case networkActionTypes.FETCH_THIRD_PERSON_PROFILE_FAILURE:
        return null;

      case networkActionTypes.FETCH_THIRD_PERSON_PROFILE_SUCCESS:
        return action.payload.userOverview;

      case sessionActionTypes.RESET_STATE:
        return initialState.network.thirdPerson.overview;

      default:
        return state;
    }
  },

  profile: (state = initialState.network.thirdPerson.profile, action) => {
    switch (action.type) {
      case networkActionTypes.FETCH_THIRD_PERSON_PROFILE_REQUEST:
      case networkActionTypes.FETCH_THIRD_PERSON_PROFILE_FAILURE:
        return {};

      case networkActionTypes.ADMIN_UPDATE_EMAIL_SUCCESS:
        return { ...action.payload };

      case networkActionTypes.FETCH_THIRD_PERSON_PROFILE_SUCCESS:
        return { ...action.payload.profile };

      case sessionActionTypes.RESET_STATE:
        return initialState.network.thirdPerson.profile;

      default:
        return state;
    }
  },

  roles: (state = initialState.network.thirdPerson.roles, action) => {
    switch (action.type) {
      case networkActionTypes.FETCH_THIRD_PERSON_ROLES_REQUEST:
      case networkActionTypes.FETCH_THIRD_PERSON_ROLES_FAILURE:
        return [];

      case networkActionTypes.FETCH_THIRD_PERSON_ROLES_SUCCESS:
      case networkActionTypes.FETCH_UPDATE_PROFILE_ROLES_SUCCESS:
        return [...action.payload.roles];

      case sessionActionTypes.RESET_STATE:
        return initialState.network.thirdPerson.roles;

      default:
        return state;
    }
  },
});

export default combineReducers({
  myConnections,
  myNetwork,
  networkDetails,
  requests,
  thirdPerson,
});
