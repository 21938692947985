import { formatRoute } from 'react-router-named-routes';
import React from 'react';

import { ACCOUNT_ADMIN, TOKEN_BUYER } from 'roles';
import Assessment from 'containers/Assessment';
import PageAcceptInvitation from 'components/PageAcceptInvitation';
import PageAdministration from 'containers/PageAdministration';
import PageAssessment from 'components/PageAssessment';
import PageForgotPassword from 'components/PageForgotPassword';
import PageGeneralAccountInformation from 'containers/PageGeneralAccountInformation';
import PageImpersonate from 'containers/PageImpersonate';
import PageInvitation from 'containers/PageInvitation';
import PageLinkRelationshipsProfile from 'containers/PageLinkRelationshipsProfile';
import PageLogin from 'containers/PageLogin';
import PageMy5Dynamics from 'components/PageMy5Dynamics';
import PageMyProfile from 'containers/PageMyProfile';
import PageNetwork from 'containers/PageNetwork';
import PageNetworkDashboard from 'containers/PageNetworkDashboard';
import PageNews from 'containers/PageNews';
import PageNotFound from 'components/PageNotFound';
import PageProfileByContext from 'components/PageProfileByContext';
import PageProfileThirdPerson from 'containers/PageProfileThirdPerson';
import PageRelationships from 'containers/PageRelationships';
import PageResetPassword from 'containers/PageResetPassword';
import PageSignUp from 'components/PageSignUp';
import PageSignUpSSO from 'containers/PageSignUpSSO';
import PageSpiderGraph from 'components/PageSpiderGraph';
import PageSSOLogin from 'containers/PageSSOLogin';
import PageSSOLoginError from 'containers/PageSSOLoginError';
import PageSSOLoginProviders from 'containers/PageSSOLoginProviders';
import PageTeams from 'containers/PageTeams';
import PageTeamsDashboard from 'containers/PageTeamsDashboard';
import PageTermsOfUse from 'components/PageTermsOfUse';
import PageTokenAdministration from 'containers/PageTokenAdministration';
import PageTraining from 'containers/PageTraining';
import PageTrainingCenter from 'containers/PageTrainingCenter';
import ProfileByContextLayout from 'components/Application/components/layouts/ProfileByContextLayout';
import SimpleLayout from 'components/Application/components/layouts/SimpleLayout';
import TrainingLayout from 'components/Application/components/layouts/TrainingLayout';
import WelcomeLayout from 'components/Application/components/layouts/WelcomeLayout';

import {
  ACCEPT_INVITATION,
  ADMINISTRATION,
  ASSESSMENT,
  GENERAL_ACCOUNT_INFORMATION,
  INVITATION,
  LINK_RELATIONSHIPS_PROFILE,
  LOGIN_FORGOT_PASSWORD,
  LOGIN_IMPERSONATE,
  LOGIN_RESET_PASSWORD,
  LOGIN_SSO_ERROR,
  LOGIN_SSO_PROVIDERS,
  LOGIN_SSO,
  LOGIN,
  MY_5_DYNAMICS_PROFILE,
  MY_5_DYNAMICS,
  NETWORK_DASHBOARD,
  NETWORK,
  NEWS,
  PROFILE_BY_CONTEXT,
  SETUP_RELATIONSHIPS_ACCOUNT,
  SIGNUP_SSO,
  SIGNUP,
  TEAMS_DASHBOARD,
  TEAMS_SPIDER_GRAPH,
  TEAMS,
  TERMS_OF_USE,
  THIRD_PERSON_PROFILE,
  TOKEN_ADMINISTRATION,
  TRAINING_CENTER,
  TRAINING,
  WELCOME,
} from './urls';

export const routes = [
  {
    component: (props) => (<Assessment {...props} />),
    exact: true,
    layout: SimpleLayout,
    path: ASSESSMENT,
    private: true,
  },
  {
    component: (props) => (<PageAdministration {...props} />),
    exact: true,
    path: ADMINISTRATION,
    permissions: ACCOUNT_ADMIN,
    private: true,
    validatePersonalAccount: true,
  },
  {
    component: (props) => (<PageTokenAdministration {...props} />),
    exact: true,
    path: TOKEN_ADMINISTRATION,
    permissions: TOKEN_BUYER || ACCOUNT_ADMIN,
    private: true,
    validatePersonalAccount: true,
  },
  {
    component: (props) => (<PageResetPassword {...props} />),
    exact: true,
    path: LOGIN_RESET_PASSWORD,
  },
  {
    component: (props) => (<PageImpersonate {...props} />),
    exact: true,
    path: LOGIN_IMPERSONATE,
  },
  {
    component: (props) => (<PageForgotPassword {...props} />),
    exact: true,
    path: LOGIN_FORGOT_PASSWORD,
  },
  {
    component: (props) => (<PageSSOLogin {...props} />),
    exact: true,
    path: LOGIN_SSO,
  },
  {
    component: (props) => (<PageSSOLoginProviders {...props} />),
    exact: true,
    path: LOGIN_SSO_PROVIDERS,
  },
  {
    component: (props) => (<PageSSOLoginError {...props} />),
    path: LOGIN_SSO_ERROR,
  },
  {
    component: (props) => (<PageGeneralAccountInformation {...props} />),
    exact: true,
    path: GENERAL_ACCOUNT_INFORMATION,
    private: true,
  },
  {
    component: (props) => (<PageInvitation {...props} />),
    exact: true,
    path: INVITATION,
  },
  {
    component: (props) => (<PageAcceptInvitation {...props} />),
    exact: true,
    path: ACCEPT_INVITATION,
  },
  {
    component: (props) => (<PageLinkRelationshipsProfile {...props} />),
    exact: true,
    path: LINK_RELATIONSHIPS_PROFILE,
  },
  {
    component: (props) => (<PageLogin {...props} />),
    exact: true,
    path: LOGIN,
  },
  {
    component: (props) => (<PageMy5Dynamics {...props} />),
    exact: true,
    path: MY_5_DYNAMICS,
    private: true,
  },
  {
    component: (props) => (<PageMyProfile {...props} />),
    exact: true,
    path: MY_5_DYNAMICS_PROFILE,
    private: true,
  },

  {
    component: (props) => (<PageNetwork {...props} />),
    exact: true,
    path: NETWORK,
    private: true,
    validatePersonalAccount: true,
  },
  {
    component: (props) => (<PageNetworkDashboard {...props} />),
    exact: true,
    path: NETWORK_DASHBOARD,
    private: true,
  },
  {
    component: (props) => (<PageProfileByContext {...props} />),
    exact: true,
    path: PROFILE_BY_CONTEXT,
    private: true,
    layout: ProfileByContextLayout,
  },
  {
    component: (props) => (<PageProfileThirdPerson {...props} />),
    exact: true,
    path: THIRD_PERSON_PROFILE,
    private: true,
  },
  {
    component: (props) => (<PageRelationships {...props} />),
    exact: true,
    path: SETUP_RELATIONSHIPS_ACCOUNT,
    private: true,
  },
  {
    component: (props) => (<PageSignUpSSO {...props} />),
    exact: true,
    path: SIGNUP_SSO,
  },
  {
    component: (props) => (<PageSignUp {...props} />),
    exact: true,
    path: SIGNUP,
  },
  {
    component: (props) => (<PageTeams {...props} />),
    exact: true,
    path: TEAMS,
    private: true,
    validatePersonalAccount: true,
  },
  {
    component: (props) => (<PageTeamsDashboard {...props} />),
    exact: true,
    path: TEAMS_DASHBOARD,
    private: true,
    validatePersonalAccount: true,
  },
  {
    component: (props) => (<PageSpiderGraph {...props} />),
    exact: true,
    path: TEAMS_SPIDER_GRAPH,
    private: true,
    validatePersonalAccount: true,
    layout: ProfileByContextLayout,
  },
  {
    component: (props) => (<PageTrainingCenter {...props} />),
    exact: true,
    layout: TrainingLayout,
    path: TRAINING_CENTER,
    private: true,
  },
  {
    component: (props) => (<PageTraining {...props} />),
    exact: true,
    layout: TrainingLayout,
    path: TRAINING,
    private: true,
  },
  {
    component: (props) => (<PageTermsOfUse {...props} />),
    exact: true,
    path: TERMS_OF_USE,
  },
  {
    component: (props) => (<PageNews {...props} />),
    exact: true,
    path: NEWS,
    private: true,
  },
  {
    component: (props) => (<PageAssessment {...props} />),
    exact: true,
    layout: WelcomeLayout,
    path: WELCOME,
    private: true,
  },
  {
    component: (props) => (<PageNotFound {...props} />),
  },
];

export { formatRoute };
