import { formatRoute } from 'react-router-named-routes';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { keyIndex } from 'helpers';
import { LOGIN, MY_5_DYNAMICS } from 'urls';
import { routes } from 'constants/routes';
import Route from 'components/Route';

const Routes = (props) => {
  const { accountId } = props;

  return (
    <Switch>
      {
        accountId !== null
          ? <Redirect from="/" to={formatRoute(MY_5_DYNAMICS, { accountId })} exact />
          : <Redirect from="/" to={LOGIN} exact />
      }
      {
        keyIndex(routes)
          .map(({ id, ...rest }) => (
            <Route
              key={id}
              {...rest}
            />
          ))
      }
    </Switch>
  );
};

Routes.propTypes = {
  accountId: PropTypes.number,
};

Routes.defaultProps = {
  accountId: null,
};

export default Routes;
