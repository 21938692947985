/* eslint-disable react-hooks/exhaustive-deps */
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import { isEmpty } from 'lodash';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { DECLINED } from 'constants/invitationStatus';
import { ACTIONS, HISTORY, I18N, INVITATION } from 'constants/props';
import { Card, Header, Main } from 'generics/Card';
import { MY_5_DYNAMICS } from 'urls';
import * as invitesSelectors from 'app_modules/invites/selectors';
import FormLogin from 'components/FormLogin';
import Translation from 'generics/Translation';

import styles from './PageLinkRelationshipsProfile.scss';

const PageLinkRelationshipsProfile = ({
  accountId,
  history,
  i18n,
  invitation,
  isAuthenticated,
  sessionActions,
}) => {
  const [isFetching, setIsFetching] = useState(false);

  const handleRedirect = (to) => {
    history.replace(to);
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.replace(formatRoute(MY_5_DYNAMICS, { accountId }));
    }

    if (isEmpty(invitation) || invitation.status === DECLINED) {
      handleRedirect('/');
    }
  }, []);

  const handleSubmit = (credentials) => {
    const { token } = invitation;

    setIsFetching(true);

    sessionActions.login({ ...credentials, token }, () => setIsFetching(false));
  };

  return (
    <DocumentTitle title="Simpli5® - Link Relationships Profile Page">
      <section className={styles.page}>
        <Card
          center
          isFetching={isFetching}
        >
          <Header
            className={styles.header}
            title={i18n.linkRelationshipsProfile.title}
            subtitle={i18n.linkRelationshipsProfile.subtitle}
          />
          <Main className={styles.main}>
            <FormLogin
              className={styles.form}
              onSubmit={handleSubmit}
            />
          </Main>
        </Card>
      </section>
    </DocumentTitle>
  );
};

PageLinkRelationshipsProfile.propTypes = {
  accountId: PropTypes.number,
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
  invitation: INVITATION.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  sessionActions: ACTIONS.isRequired,
};

PageLinkRelationshipsProfile.defaultProps = {
  accountId: null,
};

const mapStateToProps = (state) => ({
  invitation: invitesSelectors.invitation(state),
});

export default Translation(connect(mapStateToProps)(PageLinkRelationshipsProfile), ['linkRelationshipsProfile', 'login']);
