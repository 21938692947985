import { Col, Container, Row } from 'reactstrap';
import { isNil } from 'lodash';
import { pickHTMLProps } from 'pick-react-known-prop';
import PropTypes from 'prop-types';
import React from 'react';

import { Footer, Header, Main } from 'generics/Card';
import { I18N } from 'constants/props';
import { StyledButton, StyledTextField } from 'generics/StyledFormComponents';
import Translation from 'generics/Translation';

import styles from './FormEditDepartment.scss';
import useFormEditDepartment from './useFormEditDepartment';

const FormEditDepartment = ({ i18n, initialValues, onClose, onSubmit, ...rest }) => {
  const {
    errors,
    isValid,
    handleDepartmentChange,
    handleSubmit,
    values,
  } = useFormEditDepartment({ i18n, initialValues, onSubmit });

  return (
    <>
      <Header>
        <h1
          className={styles.title}
          title={i18n.pageAdministration.peopleList.editDepartment.title}
        >
          {i18n.pageAdministration.peopleList.editDepartment.title}
        </h1>
        <p>
          {i18n.pageAdministration.peopleList.editDepartment.description}
        </p>
      </Header>
      <Main>
        <Container fluid>
          <form
            className={styles.form}
            {...pickHTMLProps(rest)}
          >
            <StyledTextField
              error={isNil(values.department) ? false : !!errors.department}
              helperText={isNil(values.department) ? ' ' : errors.department}
              label={`${i18n.pageAdministration.peopleList.department}:`}
              name="department"
              max="100"
              onChange={(e) => handleDepartmentChange('department', e.currentTarget.value)}
              title={i18n.pageAdministration.peopleList.department}
              value={values.department || ''}
            />
          </form>
        </Container>
      </Main>
      <Footer>
        <Container>
          <Row>
            <Col xs="3" md="2">
              <StyledButton
                color="default"
                fullWidth
                onClick={() => onClose()}
                title={i18n.generics.cancelLabel}
                variant="text"
              >
                {i18n.generics.cancelLabel}
              </StyledButton>
            </Col>

            <Col xs={{ size: 3 }} md={{ size: 2 }}>
              <StyledButton
                color="primary"
                disabled={!isValid}
                fullWidth
                id="save-video-customization-btn"
                onClick={(e) => handleSubmit(e)}
                title={i18n.generics.doneLabel}
              >
                {i18n.generics.doneLabel}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </Footer>
    </>
  );
};

FormEditDepartment.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
};

export default Translation(FormEditDepartment, ['generics', 'pageAdministration']);
