export const en = {
  captions: {
    english: 'English',
  },
  trainings: {
    advanced: {
      description: 'Our Advanced Training guides you through the 5 Dynamics® applications. The Fundamentals program is a prerequisite to the Advanced Training.',
      internalDescription: 'The 5 Dynamics® Advanced Training focuses on various applications of our propietary methodology. These videos can be watched in any order as they were developed to support you based on your current need.',
      subtitle: '5 Dynamics® Advanced Training',
      title: 'Advanced Training',
      videos: [
        {
          name: 'Leadership Development',
          exercises: [
            'Think about how you show up in your day-to-day work. Do you consciously consider what a task or project needs and modify your approach to align your efforts? How might you daily flex in your areas of lower energy?',
            'For more insights on how you lead from where you are, review your Leadership content in Simpli5®: Leadership Style (My 5 Dynamics page), Leading 1:1 (Network or Teams page), and Leading the Team (Teams page).',
          ],
        },
        {
          name: 'Effective Meetings',
          exercises: [
            'How do you typically contribute to team meetings? Do you help move the conversation forward or hold everyone back? Are your higher energies driving nonverbal behaviors that you need to keep in check?',
            'Even when you are not leading a meeting, you still impact the meeting and everyone in it. What are one or two specific ways you can be more mindful in your next meeting to make it more productive?',
          ],
        },
        {
          name: 'Optimize Change Management',
          exercises: [
            'How do you respond to change? What are the most pressing questions that you typically want answered first? What other information do you need to help you address the fear, uncertainty, and doubt that usually comes with change? Can you see how those needs are clearly tied to your higher energies?',
            'Consider how you might express these needs in terms of a Project Completion Cycle to "take the personal out of it" and to better communicate how others can help you navigate a change.',
          ],
        },
      ],
    },
    basic: {
      description: 'The Fundamentals Training Program is designed to give you an understanding of the 5 Dynamics® core concepts as well as individual, pair, and team applications.',
      internalDescription: 'The Fundamentals Training Program is designed to give you an awareness of 5D core concepts, individual applications, pair applications, and team applications. The 5 Dynamics® Fundamentals program is a building block for all subsequent programs.',
      subtitle: '5 Dynamics® Fundamentals Training Program',
      title: 'Fundamentals Training',
      videos: [
        {
          name: 'What is 5 Dynamics®?',
          exercises: [
            'Take a few moments to reflect on how your higher and lower levels of energy have influenced the work that you do day in and day out. What are some tasks that give you energy? What are some tasks that drain your energy?',
          ],
        },
        {
          name: 'It\'s All about Energy',
          exercises: [
            'Did you notice any physical reactions you had to the two images in the video? Did you lean in or lean away from the screen in response?',
            'Do you agree that our well-worn neural pathways can both work for us (making us very efficient in some activities) as well as against us (blinding us to other possibilities and means to succeed)? Think of an example or two from your own work experience.',
          ],
        },
        {
          name: 'Energetic Preferences',
          exercises: [
            'Take a few minutes to look at your personalized content on the My 5 Dynamics page. Note any questions, insights, and surprises you may have. Note two things that most resonate for you.',
            'What changes can you make to how you approach your day-to-day work in order to address your lower energies? What do others need from you that may not entirely come naturally to you? What strategies might you develop to keep these things "front of mind" during your day?',
          ],
        },
        {
          name: 'The 4 Types of Energies',
          exercises: [
            'For your higher energies, think of some specific contributions you\'ve made from this energy. Think of an example of how overusing this energy caused a problem in your work.',
            'For your lower energies, think of a team meeting or project (current job or past) where you felt like you or others spent too much time using this energy. How did you feel? How did you view those team members? Write down your thoughts to share with your team during your next leader-led session.',
          ],
        },
        {
          name: 'Energies v. Dynamics',
          exercises: [
            'Think about activities where you may have had challenges in the past. What insights can you gain by understanding your Energetic preferences and the relationship that those preferences have to the phases of work?',
          ],
        },
        {
          name: 'Interpreting Your Energetic Preferences',
          exercises: [
            'Review your Energy Map at the My 5 Dynamics page; note the energy intensity you have in each dynamic. Now, think back to your reflection from the first video: "What are some tasks that give you energy? What are some tasks that drain your energy?"',
            'For each task, what is the primary energy required for that task? What intensity of energy do you bring to that task? Do you see a connection between the amount of energy you bring to a task and whether you feel "charged" or "drained" when working on that task?',
          ],
        },
        {
          name: 'Understanding the Project Completion Cycle',
          exercises: [
            'Consider how your preferred Energies affect how you move through a typical project completion cycle. Which dynamics usually get the most of your time and attention? What consequences have you experienced when spending too much or too little time in certain dynamics?',
            'This awareness will help you to complete several group activities in your next leader-led session.',
          ],
        },
        {
          name: 'The Power of the 5th Dynamic',
          exercises: [
            'Think of a recent project or recurring process that you\'ve completed. Was it both successful and satisfying? Using one of these labels, was it "Great", "Okay", or "Poor"? What factors made it Great/Okay/Poor?',
            'What if you consider the same project or process using criteria from a different energy\'s viewpoint? Does that change how you would rate it?',
          ],
        },
        {
          name: 'Influence',
          exercises: [
            'Do you agree that our learning preferences (how we prefer to learn new information) are mapped to our areas of higher energy? What ideas or tips regarding learning preferences resonated with you and how you prefer to learn?',
            'Think of your coworkers. Who is someone with whom you can use learning preferences to better communicate and/or improve your working relationship? If that person has a shared Simpli5® profile, use the Network page to review their individual content and the pair content between you and that person.',
          ],
        },
        {
          name: 'Working with Others',
          exercises: [
            'In a leader-led session, you will be paired with a team member to discuss your Working Together content. In preparation for this activity, consider how your preferred Energies affect how you move through a typical project completion cycle.',
            'In the meantime, use the Network page to review the pair content between you and a few coworkers with whom you interact the most.',
          ],
        },
        {
          name: 'Optimizing Team Performance',
          exercises: [
            'In a leader-led session, you will look at your team\'s combined energy profile and talk about how you all can expect to work together through a project completion cycle. In preparation for this activity, think again how you move through a typical project completion cycle. Which dynamics usually get the most of your time and attention? What consequences have you experienced when spending too much or too little time in certain dynamics?',
            'Use the Teams page to create your own draft team of people with whom you regularly work.',
          ],
        },
      ],
    },
  },
  completedOf: '%d/%d Videos Watched',
  completeToUnlock: 'Complete Fundamentals Training to Unlock',
  exercises: 'Exercises',
  launchTraining: 'Launch Training',
  launchTrainingType: 'Launch %(trainingType)s',
  subtitle: 'Watch our Tutorial Videos to learn more!',
  title: 'Learn more with Simpli5®',
  trainingCenter: 'Training Center',
  videosCount: '%d Videos',
};

export const es = en;
