import { CircularProgress } from '@material-ui/core';
import { indexOf } from 'lodash';
import { sprintf } from 'sprintf-js';
import classnames from 'classnames';
import Mask from 'generics/Mask';
import moment from 'moment';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';

import { DAILY, FREQUENCIES, NEVER, WEEKLY } from 'constants/frequencies';
import { ACTIONS, PEOPLE_LIST_ITEM, I18N } from 'constants/props';
import ActionButton from 'generics/ActionButton';
import Icon from 'generics/Icon';
import Translation from 'generics/Translation';

import styles from './GroupListItem.scss';

const GroupListItem = ({
  accountId,
  className,
  groupsActions,
  groupId,
  i18n,
  isFetching,
  item,
  onClick,
  selectedFilter,
  ...rest
}) => {
  const handleArchiveGroup = (archiveObj) => {
    groupsActions.archiveGroup(groupId, archiveObj, selectedFilter);
  };

  const handleKeyPress = (event) => {
    switch (event.key) {
      case 'Enter':
        if (onClick) {
          onClick();
        }
        break;
      default:
    }
  };

  const {
    archive,
    createdAt,
    frequency,
    frequencyType,
    name,
  } = item;

  const dateJoined = createdAt && moment.utc(new Date(createdAt)).format('L');

  const i18nBase = i18n.pageAdministration.groupListItem;

  let frequencyText;

  switch (frequency) {
    case NEVER:
      frequencyText = i18nBase.never;
      break;

    case DAILY: {
      const dayCount = indexOf(FREQUENCIES[frequency], frequencyType) + 1;
      frequencyText = sprintf(i18nBase.dailyInvitationReminder, {
        dayCount,
        dayText: pluralize(i18nBase.dayText, dayCount),
      });
      break;
    }

    case WEEKLY:
      frequencyText = sprintf(i18nBase.weeklyInvitationReminder, {
        weekDay: i18nBase.weekDays[frequencyType],
        weekly: i18nBase.weekly,
      });
      break;

    default:
      frequencyText = null;
  }

  return (
    <li
      className={classnames(
        styles.item,
        className,
      )}
      {...rest}
    >
      {archive ? (
        <Icon.Stroke7
          className={styles.icon}
          name="box1"
        />
      ) : (
        <Icon.Stroke7
          className={styles.icon}
          name="note2"
        />
      )}
      <div className={styles['item-content']}>
        <div className={styles['item-information']}>
          <span
            className={styles['name-link']}
            onClick={onClick}
            onKeyPress={(event) => { handleKeyPress(event); }}
            role="button"
            tabIndex="0"
            title={name}
          >
            {name}
          </span>
          {
            dateJoined && (
              <div
                className={styles.date}
                title={
                  sprintf(i18n.pageAdministration.groupListItem.dateJoinedTitle, { dateJoined })
                }
              >
                <span className={styles.date__title}>
                  {i18n.pageAdministration.groupListItem.dateJoined}
                </span>
                <time
                  className={styles.date__value}
                  dateTime={dateJoined}
                >
                  {dateJoined}
                </time>
              </div>
            )
          }
        </div>
        <span
          className={styles.frequency}
          title={frequencyText}
        >
          {frequencyText}
        </span>
      </div>
      <ActionButton
        actionType="decline"
        desktop={{
          props: {
            className: styles.archive__desktop,
            label: archive
              ? i18n.pageAdministration.groupListItem.unarchive
              : i18n.pageAdministration.groupListItem.archive,
            title: archive
              ? i18n.pageAdministration.groupListItem.unarchive
              : i18n.pageAdministration.groupListItem.archive,
          },
        }}
        key={`archive-${item.id}`}
        onClick={() => handleArchiveGroup({ archive: !archive, name })}
      />
      <Mask
        className={styles.mask}
        open={isFetching}
      >
        <CircularProgress
          className={styles.loader}
          size={25}
          thickness={2}
        />
      </Mask>
    </li>
  );
};

GroupListItem.propTypes = {
  accountId: PropTypes.number.isRequired,
  className: PropTypes.string,
  groupsActions: ACTIONS.isRequired,
  groupId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  isFetching: PropTypes.bool,
  item: PEOPLE_LIST_ITEM.isRequired,
  onClick: PropTypes.func,
  selectedFilter: PropTypes.string,
};

GroupListItem.defaultProps = {
  className: null,
  isFetching: false,
  onClick: undefined,
  selectedFilter: null,
};

export default Translation(GroupListItem, ['pageAdministration']);
