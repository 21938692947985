import { autobind } from 'core-decorators';
import { Col, Container, Row } from 'reactstrap';
import { debounce, isEmpty, isNil } from 'lodash';
import { MenuItem } from '@material-ui/core';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FormValidator } from 'classes';
import { hasFormErrors } from 'helpers';
import { I18N, PEOPLE } from 'constants/props';
import { REQUIRED, TEXT } from 'constants/inputTypes';
import { StyledTextField, StyledSelect } from 'generics/StyledFormComponents';
import Translation from 'generics/Translation';

import styles from './FormCreateTeam.scss';

const CREATE_TEAM_FORM = PropTypes.shape({
  description: PropTypes.string,
  name: PropTypes.string,
  owner: PropTypes.number,
});

const DEBOUNCE_TIME = 500;

const INITIAL_VALUES = {
  description: '',
  name: '',
  owner: null,
};

const OWNER = 'owner';

/**
 * @description: Create / Edit Team Form
 * @extends Component
 */
class FormCreateTeam extends Component {
  constructor(props) {
    super(props);
    const {
      i18n,
      initialValues,
    } = props;

    const {
      description = '',
      name = '',
      owner = null,
    } = initialValues;

    this.handleDebounceSubmit = debounce(this.handleSubmit, DEBOUNCE_TIME).bind(this);

    this.validator = new FormValidator(i18n);

    this.state = {
      errors: { description: null, name: null, owner: null },
      values: { description, name, owner },
    };
  }

  @autobind
  /**
   * @description Validate and save values in the component state
   * Submits the form with some delay if the change was applied in a text - input or
   * inmediately if it was applied in a dropdown.
   * @param  {string} field
   * @param  {string} value
   */
  handleChange(field, value) {
    this.setState(({ errors: oldErrors, values: oldValues }) => {
      const errorMessage = field === OWNER
        ? this.validator.validate(REQUIRED, value)
        : this.validator.validate(REQUIRED, value)
        || this.validator.validate(TEXT, value, 0, 300);

      return {
        errors: { ...oldErrors, [field]: errorMessage },
        values: { ...oldValues, [field]: value },
      };
    }, () => {
      if (field === OWNER) {
        this.handleSubmit();
      } else {
        this.handleDebounceSubmit();
      }
    });
  }

  @autobind
  /**
   * @description Submits form with errors and values
   */
  handleSubmit() {
    const { onSubmit } = this.props;

    const {
      errors,
      values,
    } = this.state;

    const {
      description,
      name,
    } = values;

    const hasErrors = hasFormErrors(errors) || isEmpty(description) || isEmpty(name);

    onSubmit(values, hasErrors);
  }

  render() {
    const {
      className,
      i18n,
      network,
    } = this.props;

    const {
      errors,
      values,
    } = this.state;

    return (
      <form
        className={className}
      >
        <Container
          className={styles.container}
          fluid
        >
          <Row>
            <Col xs="12" sm="6">
              <StyledTextField
                className={styles['text-field']}
                error={isNil(values.name) ? false : !!errors.name}
                helperText={errors.name}
                label={i18n.generics.formFields.teamName}
                id="create-team-name"
                name="name"
                onChange={(e) => this.handleChange('name', e.currentTarget.value)}
                required
                title={i18n.generics.formFields.teamName}
                value={values.name}
              />
            </Col>

            <Col xs="12" sm="6">
              <StyledSelect
                className={styles.select}
                disabled
                error={isNil(values.owner) ? false : !!errors.owner}
                helperText={errors.owner}
                iconClassName={styles['select-icon']}
                label={i18n.generics.formFields.teamOwner}
                name="owner"
                onChange={(e) => this.handleChange('owner', e.target.value)}
                title={i18n.generics.formFields.teamOwner}
                value={values.owner}
              >
                {
                  network.map(({ firstName, id, lastName }) => (
                    <MenuItem
                      key={id}
                      value={id}
                    >
                      {`${firstName} ${lastName}`}
                    </MenuItem>
                  ))
                }
              </StyledSelect>
            </Col>

            <Col xs="12">
              <StyledTextField
                className={styles['text-field']}
                error={isNil(values.description) ? false : !!errors.description}
                helperText={errors.description}
                id="create-team-description"
                label={i18n.generics.formFields.teamDescription}
                name="description"
                onChange={(e) => this.handleChange('description', e.currentTarget.value)}
                required
                rows={2}
                title={i18n.generics.formFields.teamDescription}
                value={values.description}
              />
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}

FormCreateTeam.propTypes = {
  className: PropTypes.string,
  i18n: I18N.isRequired,
  initialValues: CREATE_TEAM_FORM,
  network: PEOPLE,
  onSubmit: PropTypes.func.isRequired,
};

FormCreateTeam.defaultProps = {
  className: null,
  initialValues: INITIAL_VALUES,
  network: [],
};

export default Translation(withRouter(FormCreateTeam), ['generics']);
