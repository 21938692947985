import React from 'react';
import { render } from 'react-dom';

import Application from './components/Application';
import './assets/styles/styles.scss';

const rootElement = document.getElementById('react-root');

render(
  <Application />,
  rootElement,
);
