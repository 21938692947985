import classnames from 'classnames';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { INFO_LIST } from 'constants/props';

import styles from './DefinitionList.scss';

const DefinitionList = ({ className, data }) => {
  const infoList = data?.filter((list) => list && (!isNil(list?.children)))
    .map((info) => {
      const {
        children,
        label,
      } = info || {};

    const { title = label } = info || {};

    return (
      <div key={`content_${title}`}>
        <dt
          key={`label_${title}`}
          title={title}
        >
          {label}
        </dt>
        <dd
          key={`children_${title}`}
          title={title}
        >
          {children}
        </dd>
      </div>
    );
  });

  return (
    <dl
      className={classnames(
        styles['info-list'],
        className,
      )}
    >
      {infoList}
    </dl>
  );
};

DefinitionList.propTypes = {
  className: PropTypes.string,
  data: INFO_LIST.isRequired,
};

DefinitionList.defaultProps = { className: null };

export default DefinitionList;
