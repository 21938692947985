import { indexOf } from 'lodash';
import { sprintf } from 'sprintf-js';
import classnames from 'classnames';
import moment from 'moment';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';

import { DAILY, FREQUENCIES, NEVER, WEEKLY } from 'constants/frequencies';
import { I18N, INVITATION_GROUP } from 'constants/props';
import { Separator } from 'generics/Card';
import { StyledButton } from 'generics/StyledFormComponents';
import BackButton from 'generics/BackButton';
import Icon from 'generics/Icon';
import Translation from 'generics/Translation';

import styles from './ListPeopleGroupListHeader.scss';

const ListPeopleGroupListHeader = (props) => {
  const {
    group,
    i18n,
    onClickBackButton,
    onClickEditButton,
  } = props;

  const {
    createdAt,
    frequency,
    frequencyType,
    name,
  } = group;

  const date = moment.utc(new Date(createdAt)).format('L');

  let invitationReminder;

  switch (frequency) {
    case NEVER:
      invitationReminder = i18n.pageAdministration.peopleList.groupListHeader.never;

      break;

    case DAILY: {
      const dayCount = indexOf(FREQUENCIES[frequency], frequencyType) + 1;

      invitationReminder = sprintf(i18n.pageAdministration.peopleList.groupListHeader.frequency, {
        frequency: dayCount === 1
          ? i18n.pageAdministration.groupListItem.dayText
          : `${dayCount} ${pluralize(i18n.pageAdministration.groupListItem.dayText, dayCount)}`,
      });

      break;
    }

    case WEEKLY:
      invitationReminder = sprintf(i18n.pageAdministration.peopleList.groupListHeader.frequency, {
        frequency: i18n.pageAdministration.groupListItem.weekDays[frequencyType],
      });
      break;

    default:
      invitationReminder = null;
  }

  return (
    <>
      <div className={styles.header}>
        <BackButton
          className={styles['header__back-button']}
          onClick={onClickBackButton}
        />
        <div className={styles.header__row}>
          <div
            className={classnames(
              styles.header__col,
              styles['header__col--left'],
            )}
          >
            <Icon.Stroke7 name="note2" />
          </div>
          <div
            className={classnames(
              styles.header__col,
              styles['header__col--right'],
            )}
          >
            <div className={styles.header__name}>
              <span title={name}>
                {name}
              </span>
            </div>
            <div className={styles.header__dates}>
              <p
                className={styles['header__date-created']}
                title={sprintf(i18n.pageAdministration.peopleList.groupListHeader
                  .dateCreatedTitle, { date })}
              >
                <span className={styles['header__date-created-label']}>
                  {i18n.pageAdministration.peopleList.groupListHeader.dateCreated}
                </span>
                <time
                  className={styles['header__date-created-time']}
                  dateTime={createdAt}
                >
                  {date}
                </time>
              </p>
              <p
                className={styles['header__invitation-reminder']}
                title={sprintf(i18n.pageAdministration.peopleList.groupListHeader
                  .InvitationReminderTitle, { invitationReminder })}
              >
                <span className={styles['header__invitation-reminder-label']}>
                  {i18n.pageAdministration.peopleList.groupListHeader.InvitationReminder}
                </span>
                <span>
                  {invitationReminder}
                </span>
              </p>
            </div>
          </div>
          <StyledButton
            className={styles.header__edit}
            color="secondary"
            onClick={onClickEditButton}
            title={i18n.pageAdministration.peopleList.groupListHeader.edit}
            variant="text"
          >
            {i18n.pageAdministration.peopleList.groupListHeader.edit}
          </StyledButton>
        </div>
      </div>
      <Separator />
    </>
  );
};

ListPeopleGroupListHeader.propTypes = {
  group: INVITATION_GROUP.isRequired,
  i18n: I18N.isRequired,
  onClickBackButton: PropTypes.func,
  onClickEditButton: PropTypes.func,
};

ListPeopleGroupListHeader.defaultProps = {
  onClickBackButton: undefined,
  onClickEditButton: undefined,
};

export default Translation(ListPeopleGroupListHeader, ['pageAdministration']);
