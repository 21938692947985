import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { CHILDREN } from 'constants/props';
import * as accountSelectors from 'app_modules/accounts/selectors';
import * as pageSelectors from 'app_modules/page/selectors';
import * as sessionSelectors from 'app_modules/session/selectors';
import ApplicationFooter from 'components/ApplicationFooter';
import ApplicationHeader from 'containers/ApplicationHeader';
import ErrorBoundary from 'generics/ErrorBoundary';
import ModalNotification from 'components/ModalNotification';

import styles from './DefaultLayout.scss';

const DefaultLayout = ({
  children,
  isImpersonating = false,
  isModalView = false,
  isStudentWaitingResults,
}) => {
  const [isStickyHeader, setIsStickyHeader] = useState(0);

  const mainFocusRef = useRef(null);

  return (
    <div className={styles.layout}>
      <ApplicationHeader
        className={classnames(
          styles.header,
          { [styles['header--hidden']]: isModalView },
        )}
        focusRef={mainFocusRef}
        onChange={(isSticky) => { setIsStickyHeader(isSticky); }}
      />
      <main
        className={classnames(
          styles.main,
          { [styles['main--has-sticky-header']]: isStickyHeader },
          { [styles['main--has-impersonate-label']]: isImpersonating },
          { [styles['main--has-hidden-results-label']]: isStudentWaitingResults },
        )}
        ref={mainFocusRef}
      >
        <ErrorBoundary className={styles['error-boundary']}>
          {children}
        </ErrorBoundary>
      </main>
      <ApplicationFooter className={styles.footer} />
      <ModalNotification />
    </div>
  );
};

DefaultLayout.propTypes = {
  children: CHILDREN,
  isImpersonating: PropTypes.bool,
  isModalView: PropTypes.bool,
  isStudentWaitingResults: PropTypes.bool.isRequired,
};

DefaultLayout.defaultProps = {
  children: null,
  isImpersonating: false,
  isModalView: false,
};

const mapStateToProps = (state) => ({
  isImpersonating: sessionSelectors.isImpersonating(state),
  isModalView: pageSelectors.isModalView(state),
  isStudentWaitingResults: accountSelectors.isStudentWaitingResults(state),
});

export default connect(mapStateToProps, null)(DefaultLayout);
