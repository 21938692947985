import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as bundlesActions from 'app_modules/bundles/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import * as teamsActions from 'app_modules/teams/actions';
import * as teamsSelectors from 'app_modules/teams/selectors';

import ModalShareTeams from 'components/PageAdministration/components/ModalShareTeams';

const mapStateToProps = (state) => ({
  currentProfile: sessionSelectors.currentProfile(state),
  accountTeams:
    teamsSelectors.corporateTeams(state).list.map((team) => team.clone({ canDrag: true })),
});

const mapDispatchToProps = (dispatch) => ({
  bundlesActions: bindActionCreators(bundlesActions, dispatch),
  teamsActions: bindActionCreators(teamsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalShareTeams);
