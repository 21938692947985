/* eslint-disable no-nested-ternary */
import { autobind } from 'core-decorators';
import { CircularProgress, MenuItem } from '@material-ui/core';
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, isNil, get } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FormValidator } from 'classes';
import { hasFormErrors } from 'helpers';
import { I18N, OWNERS } from 'constants/props';
import { Separator } from 'generics/Card';
import { StyledButton, StyledSelect, StyledTextField } from 'generics/StyledFormComponents';
import { REQUIRED } from 'constants/inputTypes';
import ActionButton from 'generics/ActionButton';
import api from 'api';
import FormComponent from 'generics/FormComponent';
import InfiniteScroll from 'generics/InfiniteScroll';
import Mask from 'generics/Mask';
import Search from 'generics/Search';
import Translation from 'generics/Translation';

import styles from './FormAssignTokens.scss';

/**
 * FormAssignTokens Component
 */
export class FormAssignTokens extends FormComponent {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      isFetching: false,
      isSearchFocus: {},
      search: {},
      showOptions: {},
      values: [
        { owner: null, type: null, quantity: null },
      ],
    };

    this.search = [];
  }

  @autobind
  handleSearch(filter, searchPosition) {
    const { values, search } = this.state;
    const { owner } = values[searchPosition];
    let ownerValue = null;
    if (owner) {
      ownerValue = `${owner.firstName} ${owner.lastName}`;
    }
    if (ownerValue !== filter) {
      const newValues = [...values];
      newValues[searchPosition].owner = '';
      this.setState({ values: newValues }, () => this.validate());
    }

    if (search !== filter) {
      const newSearch = { ...search, [searchPosition]: filter };
      this.setState({
        pristine: true,
        search: newSearch,
      }, () => {
        if (get(this.infiniteScroll, 'resetIndex')) {
          this.infiniteScroll.resetIndex();
        }
        this.handleFetchTokenAllocators(null, searchPosition);
      });
    }
  }

  handleDeleteTokenAllocator = (i) => {
    const { search, values } = this.state;

    if (values.length === 1) {
      return;
    }

    const newSearch = { ...search };
    const newValues = [...values];

    newSearch[i] = '';
    newValues.splice(i, 1);

    this.setState({
      search: newSearch,
      values: newValues,
    }, () => {
      this.validate();
      if (newValues[i] && !newValues[i]?.owner && this.search[i]?.resetRef) {
        this.search[i].resetRef();
      }
    });
  }

  @autobind
  // eslint-disable-next-line no-unused-vars
  handleFetchTokenAllocators(pageIndex = 1, searchPosition) {
    const { accountId, accountName, allOwners, owners, setOwners } = this.props;
    const { search } = this.state;

    const newOrganization = [];
    const newFilter = search[searchPosition] ? search[searchPosition] : '';

    if (accountName.toLowerCase().includes(newFilter.toLowerCase())) {
      newOrganization.push({ accountId, firstName: accountName, lastName: '' });
    }

    setOwners(
      {
        ...owners,
        [searchPosition]: [
          ...newOrganization,
          ...allOwners.filter(
            ({ id, firstName, lastName }) => (
              `${firstName} ${lastName}`.toLowerCase().includes(newFilter.toLowerCase())
            ),
          ),
        ],
      },
    );
  }

  handleFocus = (i, value) => {
    this.setState((prevState) => ({ isSearchFocus: { ...prevState.isSearchFocus, [i]: true } }));
    this.handleSearch(value || '', i);
    this.showOptions(i);
  }

  handleQuantityChange = (value, position) => {
    if (!Number.isNaN(value) && (Number(value) >= 1 || value === '')) {
      this.handleChange('quantity', value, true, position);
    }
  }

  @autobind
  showOptions(position) {
    const { showOptions } = this.state;

    const newOpenOptions = { ...showOptions, [position]: true };
    this.setState({ showOptions: newOpenOptions });
  }

  @autobind
  hideOptions(position) {
    const { showOptions } = this.state;

    const newHiddenOptions = { ...showOptions, [position]: false };
    this.setState({ showOptions: newHiddenOptions });
  }

  @autobind
  validate() {
    const { i18n, isAdmin } = this.props;
    const { values } = this.state;

    const validator = new FormValidator(i18n);

    const errors = [];
    for (let i = 0; i < values.length; i += 1) {
      const {
        owner,
        type,
        quantity,
      } = values[i];

      errors[i] = {
        owner: validator.validate(REQUIRED, owner),
        type: isAdmin ? validator.validate(REQUIRED, type) : null,
        quantity: validator.validate(REQUIRED, quantity),
      };
    }

    this.setState({
      errors,
      isValid: !hasFormErrors(errors),
    });
  }

  addOwner = async (owner, i) => {
    this.setState((prevState) => ({ isSearchFocus: { ...prevState.isSearchFocus, [i]: false } }));

    if (owner.accountId) {
      await this.handleChange('type', 'false', true, i);
    }

    this.handleChange('owner', owner, true, i);

    this.hideOptions(i);
  }

  addTokenAllocator = () => {
    this.setState(({ values: oldValues }) => ({
      values: [
        ...oldValues,
        { owner: null, type: null, quantity: null },
      ],
    }));
  }

  canAddOwners = () => {
    const {
      owners,
    } = this.props;

    const {
      values,
    } = this.state;

    const filteredOwners = owners?.filter(
      (owner) => !values.find((value) => value.owner === owner.id)
    );

    return filteredOwners?.length > 0;
  }

  deleteOwner = (position) => {
    const { values } = this.state;
    const newValues = [...values];

    newValues[position].owner = '';

    this.setState({ values: newValues }, () => this.validate());
  }

  renderOwners = (searchPosition) => {
    const {
      owners,
    } = this.props;

    return owners[searchPosition]?.map((owner) => (
      <MenuItem
        key={owner.id || owner.accountId}
        value={owner.id || owner.accountId}
        onMouseDown={() => this.addOwner(owner, searchPosition)}
      >
        {`${owner.firstName} ${owner.lastName}`}
      </MenuItem>
    ));
  };

  render() {
    const {
      error,
      isAdmin,
      i18n,
      isLoading,
      isSubmitting,
    } = this.props;

    const {
      errors,
      isFetching,
      isSearchFocus,
      isValid,
      values,
    } = this.state;

    const disabled = !isValid || isSubmitting || !!error;

    return (
      <>
        <form
          className={styles.form}
          onSubmit={(e) => this.handleSubmit(e, true)}
        >
          <Container>
            {!!values?.length && values.map((value, i) => (
              <>
                {i !== 0 && (
                  <div className={styles['separator-row-container']}>
                    <Separator />
                  </div>
                )}
                <Row>
                  <Col xs="10" md="11">
                    <Row>
                      <Col xs="12" md={`${isAdmin ? 4 : 6}`}>
                        <p>
                          <strong>{i18n.pageTokenAdministration.assignTokens.ownerLabel}</strong>
                        </p>
                        <div className={styles.inputSearchContainer}>
                          <Search
                            error={isNil(value.owner) ? false : !!errors[i]?.owner}
                            floatingLabelText={value.owner ? ' ' : i18n.pageTokenAdministration.assignTokens.ownerLabelSearch}
                            helperText={isNil(value.owner) ? ' ' : errors[i]?.owner}
                            onChange={(filter) => this.handleSearch(filter, i)}
                            onFocus={(e) => this.handleFocus(i, e.currentTarget.value || '')}
                            onBlur={() => this.hideOptions(i)}
                            onSearch={(filter) => this.handleSearch(filter, i)}
                            onReset={() => this.deleteOwner(i)}
                            innerRef={(component) => {
                              this.search[i] = component;
                            }}
                            value={value.owner && !isSearchFocus[i] ? `${value.owner?.firstName} ${value.owner?.lastName}` : ''}
                          />
                          {this.state.showOptions[i] && (
                            <InfiniteScroll
                              className={styles.inputSearchList}
                              hasMorePages={false}
                              id="team-list-scroll-list"
                              onFetch={this.handleFetchTokenAllocators}
                              pageStart={1}
                              ref={(component) => { this.infiniteScroll = component; }}
                              scrollbar={{
                                className: classnames({ [styles['is-empty']]: isEmpty(this.renderOwners()) }),
                              }}
                              style={{ height: `${110}px` }}
                              tag="ul"
                            >
                              {
                                isFetching ? (
                                  <div className={styles.loader}>
                                    <CircularProgress
                                      className={styles['circular-progress']}
                                      size={30}
                                      thickness={4}
                                    />
                                  </div>
                                )
                                  : this.renderOwners(i)
                              }
                            </InfiniteScroll>
                          )}
                        </div>
                      </Col>
                      {isAdmin && (
                        <Col xs="12" md="4">
                          <p>
                            <strong>{i18n.pageTokenAdministration.assignTokens.typeLabel}</strong>
                          </p>
                          <StyledSelect
                            className={styles['text-field']}
                            disabled={value.owner?.accountId}
                            error={isNil(value.type) ? false : !!errors[i]?.type}
                            helperText={isNil(value.type) ? ' ' : errors[i]?.type}
                            label={i18n.pageTokenAdministration.assignTokens.typeInputLabel}
                            name="type"
                            onChange={(e) => this.handleChange('type', e.target?.value, true, i)}
                            value={value.type ? value.type : ''}
                          >
                            <MenuItem
                              key={i18n.pageTokenAdministration.assignTokens.unallocatedLabel}
                              value="false"
                            >
                              {i18n.pageTokenAdministration.assignTokens.unallocatedLabel}
                            </MenuItem>
                            {!value.owner?.accountId && (
                              <MenuItem
                                key={
                                  i18n.pageTokenAdministration
                                    .assignTokens.organizationUnassignedLabel
                                }
                                value="true"
                              >
                                {
                                  i18n.pageTokenAdministration
                                    .assignTokens.organizationUnassignedLabel
                                }
                              </MenuItem>
                            )}
                          </StyledSelect>
                        </Col>
                      )}
                      <Col xs="12" md={`${isAdmin ? 4 : 6}`}>
                        <p>
                          <strong>{i18n.pageTokenAdministration.assignTokens.quantityLabel}</strong>
                        </p>
                        <StyledTextField
                          className={styles['text-field']}
                          error={isNil(value.quantity) ? false : !!errors[i]?.quantity}
                          helperText={isNil(value.quantity) ? ' ' : errors[i]?.quantity}
                          label={i18n.pageTokenAdministration.assignTokens.quantityInputLabel}
                          name="quantity"
                          onChange={(e) => this.handleQuantityChange(e.target?.value, i)}
                          type="number"
                          value={value.quantity ? value.quantity : ''}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="1" md="1">
                    <ActionButton
                      actionType="decline"
                      desktop={{
                        props: {
                          className: styles.delete__desktop,
                          label: i18n.generics.deleteLabel,
                          title: i18n.generics.deleteLabel,
                        },
                      }}
                      id={`delete-${value?.owner?.id}`}
                      key={`delete-${value?.owner?.id}`}
                      onClick={() => this.handleDeleteTokenAllocator(i)}
                    />
                  </Col>
                </Row>
              </>
            ))}
            <Row>
              <StyledButton
                className={styles['add-btn']}
                color="primary"
                title={i18n.pageTokenAdministration.assignTokens.addTokenAllocatorLabel}
                onClick={() => this.addTokenAllocator()}
              >
                {i18n.pageTokenAdministration.assignTokens.addTokenAllocatorLabel}
              </StyledButton>
            </Row>
            <div className={styles['separator-container']}>
              <Separator />
            </div>
            <Row>
              <StyledButton
                className={styles['submit-btn']}
                color="primary"
                disabled={disabled}
                title={i18n.pageTokenAdministration.assignTokens.submitBtn}
                type="submit"
              >
                {i18n.pageTokenAdministration.assignTokens.submitBtn}
              </StyledButton>
            </Row>
          </Container>
        </form>
        <Mask
          className={styles.mask}
          open={isLoading}
        >
          <CircularProgress
            className={styles.loader}
            size={25}
            thickness={2}
          />
        </Mask>
      </>
    );
  }
}

FormAssignTokens.propTypes = {
  i18n: I18N.isRequired,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  owners: OWNERS,
  resetOnSubmit: PropTypes.bool,
};

FormAssignTokens.defaultProps = {
  isSubmitting: false,
  resetOnSUbmit: false,
};

export default Translation(FormAssignTokens, ['pageTokenAdministration', 'generics']);
