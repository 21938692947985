/* eslint-disable react-hooks/exhaustive-deps */
import { MdRemoveShoppingCart } from 'react-icons/md';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';

import { I18N } from 'constants/props';
import { Footer, Header, Main } from 'generics/Card';
import { StyledButton } from 'generics/StyledFormComponents';
import Modal from 'generics/Modal';
import Translation from 'generics/Translation';

import styles from './ModalSubscriptionError.scss';

const ModalSubscriptionError = ({
  i18n,
  isFetching,
  isOnlyPayment,
  onClose,
  title,
}) => (
  <Modal
    card={{
        barClassName: styles.bar,
        isFetching,
      }}
    modalCardClassName={styles.modal}
    onClose={onClose}
    switchModalView={false}
    title={title}
  >
    <Header>
      <h1 title={isOnlyPayment
          ? i18n.subscriptions.paymentStatusTitle
          : i18n.subscriptions.subcriptionStatusTitle}
      >
        {isOnlyPayment
          ? i18n.subscriptions.paymentStatusTitle
          : i18n.subscriptions.subcriptionStatusTitle}
      </h1>
    </Header>
    <Main>
      <Container fluid>
        <div className={styles['info-container']}>
          <MdRemoveShoppingCart
            className={styles.icon}
          />
          <p>
            {isOnlyPayment
              ? i18n.subscriptions.paymentFailed
              : i18n.subscriptions.subscriptionFailed}
          </p>
        </div>
      </Container>
    </Main>
    <Footer>
      <Container>
        <Row>
          <Col xs={{ size: 3, offset: 9 }} md={{ size: 2, offset: 10 }}>
            <StyledButton
              color="default"
              fullWidth
              onClick={() => onClose()}
              title={i18n.generics.closeLabel}
              variant="text"
            >
              {i18n.generics.closeLabel}
            </StyledButton>
          </Col>
        </Row>
      </Container>
    </Footer>
  </Modal>
  );

ModalSubscriptionError.propTypes = {
  i18n: I18N.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isOnlyPayment: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default Translation(ModalSubscriptionError, ['generics', 'subscriptions']);
