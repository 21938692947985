
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { values } from 'lodash';

import * as homeActions from 'app_modules/session/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import PageCategories from 'generics/PageCategories';

const mapStateToProps = (state) => ({
  modules: values(sessionSelectors.modules(state)),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(homeActions, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageCategories));
