import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/network/constants';
import api from 'api';

// Actions

export const fetchMyConnectionsFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_MY_CONNECTIONS_FAILURE,
});

export const fetchMyConnectionsRequest = (pageIndex, search) => ({
  payload: {
    pageIndex,
    search,
  },
  type: actionTypes.FETCH_MY_CONNECTIONS_REQUEST,
});

export const fetchMyConnectionsSuccess = (response) => ({
  payload: response,
  type: actionTypes.FETCH_MY_CONNECTIONS_SUCCESS,
});

// Thunks

export const fetchMyConnections = (
  { pageIndex, search },
  onSuccess,
  onError,
) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());

    dispatch(fetchMyConnectionsRequest(pageIndex, search));

    const handleResponse = (response) => {
      dispatch(fetchMyConnectionsSuccess(response));
      if (onSuccess) {
        onSuccess(response);
      }
    };

    const handleError = ({ error }) => {
      dispatch(fetchMyConnectionsFailure(error));
      if (onError) {
        onError(error);
      } else {
        throw error;
      }
    };

    return api.profiles.getMyConnections({
      accountId,
      pageIndex,
      search,
    }, handleResponse, handleError);
  };

  thunk.type = actionTypes.FETCH_MY_CONNECTIONS;

  return thunk;
};

export default fetchMyConnections;
