import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router';

import * as accountActions from 'app_modules/accounts/actions';
import * as accountSelectors from 'app_modules/accounts/selectors';
import * as networkActions from 'app_modules/network/actions';
import * as notificationActions from 'app_modules/notifications/actions';
import * as sessionActions from 'app_modules/session/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import ApplicationHeader from 'components/ApplicationHeader';
import currentNotification from 'app_modules/notifications/selectors';
import Translation from 'generics/Translation';

const actions = { ...accountActions, ...networkActions, ...notificationActions, ...sessionActions };

const mapStateToProps = (state, props) => ({
  expirationMessage: props.i18n.notifications[sessionSelectors.expirationMessage(state)],
  hasNewNotifications: accountSelectors.hasNewNotifications(state),
  hideExpirationMessage: !isEmpty(currentNotification(state)),
  isAssessmentCompleted: sessionSelectors.isAssessmentCompleted(state),
  isAuthenticated: sessionSelectors.isAuthenticated(state),
  isPersonalAccount: sessionSelectors.isPersonal(state),
  isStudentWaitingResults: accountSelectors.isStudentWaitingResults(state),
  page: state.page,
  profile: sessionSelectors.currentProfile(state),
  profiles: sessionSelectors.userProfiles(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withRouter(Translation(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationHeader), ['applicationHeader', 'notifications']));
