import PropTypes from 'prop-types';
import React from 'react';

import { ACTIONS, I18N } from 'constants/props';

import FormEstimation from '../FormEstimation/FormEstimation';
import Payment from '../Payment/Payment';
import styles from './BuyTokens.scss';
import useBuyTokens from './useBuyTokens';

const BuyTokens = (
  { accountId, email, i18n, notificationActions, onFetchNetworkDetails, profileId },
) => {
  const {
    amount,
    isLoading,
    onEstimate,
    orderQuantity,
    resetEstimationOrder,
  } = useBuyTokens({ accountId, onFetchNetworkDetails, profileId });

  return (
    <article className={styles.paymentContainer}>
      <h4
        className={styles.title}
      >
        {i18n.pageTokenAdministration.buyForm.estimateTokensLabel}
      </h4>
      <p className={styles.description}>
        {i18n.pageTokenAdministration.buyForm.estimateTokensDescription}
      </p>
      <FormEstimation
        amount={amount}
        isLoading={isLoading}
        onChange={onEstimate}
        orderQuantity={orderQuantity}
        title={i18n.pageTokenAdministration.buyForm.estimateTokensLabel}
      />
      <Payment
        accountId={accountId}
        amount={amount}
        i18n={i18n}
        notificationActions={notificationActions}
        onFetchNetworkDetails={onFetchNetworkDetails}
        onFinish={resetEstimationOrder}
        orderQuantity={orderQuantity}
        profileId={profileId}
        stripeEmail={email}
      />
    </article>
  );
};

BuyTokens.propTypes = {
  accountId: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  i18n: I18N.isRequired,
  notificationActions: ACTIONS.isRequired,
  onFetchNetworkDetails: PropTypes.func.isRequired,
  profileId: PropTypes.number.isRequired,
};

export default BuyTokens;
