import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { COLORS } from 'constants/colors';
import { Footer, Header, Main } from 'generics/Card';
import { HISTORY, MEMBERS, TEAM, I18N } from 'constants/props';
import { StyledButton } from 'generics/StyledFormComponents';
import { TEAMS_SPIDER_GRAPH } from 'urls';
import { Url } from 'classes';
import * as sessionSelectors from 'app_modules/session/selectors';
import * as teamsSelectors from 'app_modules/teams/selectors';
import api from 'api';
import Icon from 'generics/Icon';
import InformationCardHeader from 'generics/InformationCardHeader';
import ListSelection from 'generics/ListSelection';
import Modal from 'generics/Modal';
import Translation from 'generics/Translation';

import styles from './ModalGenerateSpiderGraph.scss';

const getTeamMembers = (teamMembers) => teamMembers
  .map((teamMember) => teamMember.clone({
    canDrag: teamMember.isVisible,
  }));

const ModalGenerateSpiderGraph = ({
  accountId,
  history,
  i18n,
  members,
  onClose,
  onComplete,
  onFetch,
  team,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isTeamAverageInclude, setIsTeamAverageInclude] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [teamMembers, setTeamMembers] = useState(getTeamMembers(members.list));

  useEffect(() => {
    setTeamMembers(getTeamMembers(members.list));
  }, [members]);

  const handleCloseModal = () => {
    onClose();
  };

  const handleChangeList = (newSelectedProfiles) => {
    setSelectedProfiles(newSelectedProfiles);
  };

  const handleIncludeTeamAverage = () => {
    setIsTeamAverageInclude(!isTeamAverageInclude);
  };

  const handleCreate = () => {
    const teamId = team.id;

    if (onFetch) {
      onFetch();
    }

    const data = {
      isTeamAverageInclude,
      profiles: selectedProfiles
        .map(({ id }) => id),
    };

    const spiderRoute = formatRoute(TEAMS_SPIDER_GRAPH, {
      accountId,
      teamId,
      token: new Url(data).encode(),
    });

    api.tracking.trackSpiderGraph({ accountId, teamId });

    handleCloseModal();

    if (onComplete) {
      onComplete();
    }

    history.push(spiderRoute);
  };

  const handleFetchMembers = (newPageIndex) => {
    const { onFetchMore } = members;

    setIsFetching(true);

    const onCompleteFn = () => {
      setIsFetching(false);
    };

    onFetchMore(newPageIndex, onCompleteFn);
  };

  const {
    hasMorePages: hasMoreMembers,
    pageIndex,
  } = members;

  const infiniteScroll = {
    hasMorePages: hasMoreMembers,
    listHeight: 250,
    onFetch: handleFetchMembers,
    pageStart: pageIndex + 1,
  };

  return (
    <Modal
      card={{
        barClassName: styles.bar,
        fullHeight: true,
        isFetching,
      }}
      onClose={handleCloseModal}
    >
      <Header>
        <InformationCardHeader
          category="Tools"
          categoryColor={COLORS.primaryGreen.rgba}
          icon={<Icon.Svg.TeamSpiderGraph />}
          title={i18n.pageSpiderGraph.modal.header}
        />
      </Header>

      <Main>
        <Container fluid>
          <p className={styles.description}>
            {i18n.pageSpiderGraph.modal.description}
          </p>
          <ListSelection
            className={styles.list}
            listA={{
              id: 'generate-spider-list1',
              infiniteScroll,
              profiles: teamMembers,
              title: i18n.pageSpiderGraph.modal.listATitle,
            }}
            listB={{
              emptyMessage: 'No members selected',
              id: 'generate-spider-list2',
              profiles: selectedProfiles,
              title: i18n.pageSpiderGraph.modal.listBTitle,
            }}
            maximumSelectableListItems={12}
            onChange={handleChangeList}
            showStatusLabel
          />
          <div className={styles['team-average']}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isTeamAverageInclude}
                  color="primary"
                  onClick={() => handleIncludeTeamAverage()}
                />
              )}
              label={i18n.pageSpiderGraph.modal.includeTeamAverage}
            />
          </div>
        </Container>
      </Main>

      <Footer>
        <Container>
          <Row>
            <Col xs={{ size: 4, offset: 8 }} md={{ size: 2, offset: 10 }}>
              <StyledButton
                disabled={selectedProfiles.length === 0}
                fullWidth
                onClick={handleCreate}
                color="primary"
                title={i18n.pageSpiderGraph.modal.submitText}
              >
                {i18n.pageSpiderGraph.modal.submitText}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </Footer>
    </Modal>
  );
};

ModalGenerateSpiderGraph.propTypes = {
  accountId: PropTypes.number.isRequired,
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
  members: MEMBERS.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  onFetch: PropTypes.func,
  team: TEAM.isRequired,
};

ModalGenerateSpiderGraph.defaultProps = {
  onComplete: undefined,
  onFetch: undefined,
};

const mapStateToProps = (state) => ({
  accountId: sessionSelectors.accountId(state),
  team: teamsSelectors.team(state),
});

export default withRouter(Translation(connect(mapStateToProps)(ModalGenerateSpiderGraph), ['pageSpiderGraph']));
