/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import api from 'api';

const useNews = ({ accountId, accountType, userActions }) => {
  const [news, setNews] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getNews = () => {
    const onSuccess = ({ newsItems: newNews }) => {
      setNews(newNews);
      setIsLoading(false);
      userActions.toggleNotification(accountType);
    };

    const onError = (error) => {
      console.log(error);
      setIsLoading(false);
      setNews([]);
    };

    api.news.getNews({ accountId }, onSuccess, onError);
  };

  useEffect(() => {
    getNews();
  }, []);

  return {
    isLoading,
    news,
  };
};

export default useNews;
