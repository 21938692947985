import { Container } from 'reactstrap';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import React from 'react';

import { ACTIONS, I18N, USER } from 'constants/props';
import Translation from 'generics/Translation';

import Header from './components/Header/Header';
import styles from './PageNews.scss';
import useNews from './useNews';
import NewsList from './components/NewsList/NewsList';

const PageNews = ({
  accountId,
  accountType,
  i18n,
  notificationActions,
  user,
  userActions,
}) => {
  const { selfInvitation } = user;

  const {
    isLoading,
    news,
  } = useNews({
    accountId,
    accountType,
    i18n,
    notificationActions,
    selfInvitation,
    userActions,
  });

  return (
    <DocumentTitle title="Simpli5® - News Page">
      <article aria-label="News List" className={styles.pageContainer}>
        <Container className={styles.container}>
          <Header />
          <NewsList
            col={{ xs: 12, md: 6, lg: 4 }}
            i18n={i18n}
            isLoading={isLoading}
            news={news}
          />
        </Container>
      </article>
    </DocumentTitle>
  );
};

PageNews.propTypes = {
  accountId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  i18n: I18N.isRequired,
  notificationActions: ACTIONS.isRequired,
  user: USER.isRequired,
  userActions: ACTIONS.isRequired,
};

export default Translation(PageNews, ['news']);
