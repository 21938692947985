import { formatRoute } from 'react-router-named-routes';
import { get } from 'lodash';
import { Link, Route, Switch } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ACCOUNT_ADMIN, MEMBER, TOKEN_BUYER } from 'roles';
import { ADMINISTRATION, MY_5_DYNAMICS, NETWORK, NEWS, SETUP_RELATIONSHIPS_ACCOUNT, TAB, TEAMS, TOKEN_ADMINISTRATION, TRAINING_CENTER, GENERAL_ACCOUNT_INFORMATION } from 'constants/urls';
import { I18N, PERSON } from 'constants/props';
import { LEARNING_ACCOUNT_TYPE } from 'constants/accountTypes';
import Translation from 'generics/Translation';

import styles from './menu.scss';

const MenuDesktop = ({ i18n, isStudentWaitingResults, profile }) => {
  const getMenu = () => {
    const {
      newsNotitications,
    } = profile;

    const {
      accountType,
      flags: learningMenuTabsVisibility,
      id: accountId,
      isPersonal: isPersonalAccount,
    } = profile.account;

    const {
      administrationTab: isAdministrationTabVisible,
      networkTab: isNetworkTabVisible,
      newsTab: isNewsTabVisible,
      relationshipsTab: isRelationshipsTabVisible,
      teamsTab: isTeamsTabVisible,
      tokenAdministrationTab: isTokenAdministrationTabVisible,
      trainingCenterTab: isTrainingCenterTabVisible,
    } = learningMenuTabsVisibility || {};

    // Visibility for menu tabs validations for account types
    const isCorporateAccount = accountType === 'corporate_enterprise' || accountType === 'corporate_pre_enterprise'
      || accountType === 'non_profit' || accountType === 'marketing' || accountType === 'training_partner';
    const isLearningAccount = accountType === LEARNING_ACCOUNT_TYPE;

    // Visibility for menu tabs validations for roles
    const isCorporateAdminRole = !isPersonalAccount && profile.hasRole(ACCOUNT_ADMIN);
    const isCorporateMemberRole = !isPersonalAccount && profile.hasRole(MEMBER);
    const isCorporateTokenBuyerRole = !isPersonalAccount && profile.hasRole(TOKEN_BUYER);

    return [
      {
        id: 'my-5-dynamics',
        isVisible: true,
        label: i18n.menu.my5Dynamics,
        path: formatRoute(MY_5_DYNAMICS, { accountId }),
      },
      {
        id: 'network',
        isVisible: isCorporateMemberRole
          && isNetworkTabVisible,
        label: i18n.menu.network,
        path: formatRoute(NETWORK, { accountId }),
      },
      {
        id: 'teams',
        isVisible: isCorporateMemberRole
          && isTeamsTabVisible
          && !isStudentWaitingResults,
        label: i18n.menu.teams,
        path: formatRoute(TEAMS, { accountId }),
      },
      {
        id: 'training-center',
        isVisible: isCorporateMemberRole
          && isTrainingCenterTabVisible
          && (isCorporateAccount || isLearningAccount),
        label: i18n.menu.trainingCenter,
        path: formatRoute(TRAINING_CENTER, { accountId }),
      },
      {
        id: 'administration',
        isVisible: isCorporateAdminRole
          && isAdministrationTabVisible,
        label: i18n.menu.administration,
        path: formatRoute(ADMINISTRATION, {
          accountId,
          sectionId: 'people',
        }),
      },
      {
        hasNotification: newsNotitications,
        id: 'news',
        isVisible: isCorporateMemberRole
          && isNewsTabVisible,
        label: i18n.menu.news,
        path: formatRoute(NEWS, { accountId }),
      },
      {
        id: 'relationships',
        isVisible: !isStudentWaitingResults && isRelationshipsTabVisible,
        label: i18n.menu.relationships,
        path: formatRoute(SETUP_RELATIONSHIPS_ACCOUNT, { accountId }),
      },
      {
        id: 'token-administration',
        isVisible: (isCorporateAdminRole || isCorporateTokenBuyerRole)
          && isTokenAdministrationTabVisible,
        label: i18n.menu.tokenAdministration,
        path: formatRoute(TOKEN_ADMINISTRATION, {
          accountId,
          sectionId: 'people',
        }),
      },
    ];
  };

  const renderMenuListItems = () => getMenu()
    .filter(({ isVisible }) => isVisible)
    .map(({ hasNotification, id, label, path }) => (
      <Tab
        component={Link}
        className={classnames(
          styles.link,
          { [styles.notification]: hasNotification },
        )}
        to={path}
        href={path}
        id={id}
        key={id}
        label={label}
        title={label}
        value={id}
      />
    ));

  // eslint-disable-next-line react/prop-types
  const renderNav = ({ match }) => {
    const tab = get(match, 'params.tab', 'my-5-dynamics');

    return (
      <nav
        className={
        classnames(
          styles.menu,
          { [styles.large]: profile.hasRole(ACCOUNT_ADMIN) },
        )
      }
      >
        <Tabs
          classes={{
            flexContainer: styles.justifyCenter,
            indicator: styles['tab-indicator'],
          }}
          value={tab}
        >
          {renderMenuListItems()}
        </Tabs>
      </nav>
    );
  };

  if (profile === null) {
    return null;
  }

  return (
    <Switch>
      <Route
        path={TAB}
        render={renderNav}
      />
      <Route
        path={GENERAL_ACCOUNT_INFORMATION}
        render={renderNav}
      />
    </Switch>
  );
};

MenuDesktop.propTypes = {
  i18n: I18N.isRequired,
  isStudentWaitingResults: PropTypes.bool.isRequired,
  profile: PERSON,
};

MenuDesktop.defaultProps = {
  profile: null,
};

export default Translation(MenuDesktop, ['menu']);
