/* eslint-disable camelcase */
import { autobind } from 'core-decorators';
import { Container } from 'reactstrap';
import { Drawer, MenuItem as MenuItemMUI } from '@material-ui/core';
import { formatRoute } from 'react-router-named-routes';
import { get, isEmpty } from 'lodash';
import { MdMenu } from 'react-icons/md';
import { NavLink as Link, Link as LinkDOM } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ACTIONS, HISTORY, I18N, PEOPLE, PERSON } from 'constants/props';
import { GENERAL_ACCOUNT_INFORMATION, LOGIN, MY_5_DYNAMICS } from 'urls';
import { LEARNING_ACCOUNT_TYPE } from 'constants/accountTypes';
import { StyledButton } from 'generics/StyledFormComponents';
import FixedNotification from 'components/FixedNotification';
import Icon from 'generics/Icon';
import Logo from 'components/HeaderLogo';
import Menu from 'components/Menu';
import Notifications from 'components/Notifications';
import Translation from 'generics/Translation';

import AccountsSelect from './components/AccountsSelect';
import MenuItem from './components/MenuItem';
import styles from './ApplicationHeader.scss';

const MAX_TOP = 100;
const MIN_SCROLL_DIFFERENCE = 210;

export class ApplicationHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expirationMessage: props.expirationMessage,
      isSticky: false,
      open: false,
    };

    this.skipToMainContentRef = React.createRef(null);
  }

  componentDidMount() {
    const {
      actions,
      expirationMessage,
      isAuthenticated,
      isAssessmentCompleted,
    } = this.props;

    if (expirationMessage) {
      actions.resetExpiration();
    }

    if (isAuthenticated && isAssessmentCompleted) {
      window.onscroll = this.handleStickyHeader;
    }

    if (this.skipToMainContentRef?.current) {
      this.skipToMainContentRef.current.focus();
    }
  }

  UNSAFE_componentWillReceiveProps({ hideExpirationMessage }) {
    if (hideExpirationMessage) {
      this.setState({ expirationMessage: null });
    }
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  @autobind
  getProfileListItems() {
    const {
      profile,
      profiles,
    } = this.props;

    const selectedId = get(profile, 'account.id', null);

    return profiles
      .map(({ account: { id, logo, name } }) => (
        <MenuItem
          key={id}
          label={name}
          logoUrl={logo.url}
          onClick={(e) => { this.handleOnChangeProfile(e.currentTarget.value); }}
          selected={selectedId === id}
          value={id}
        />
      ));
  }

  @autobind
  getAccountListItems() {
    const { profiles } = this.props;

    return profiles
      .map(({ account }) => account)
      .sort((a, b) => b.isPersonal - a.isPersonal)
      .map((account) => {
        const {
          id,
          logo,
          name,
        } = account;

        return (
          <MenuItem
            className={styles['menu-item-option']}
            key={id}
            label={name}
            logoUrl={logo.url}
            value={id}
          />
        );
      });
  }

  @autobind
  getProfile(accountId) {
    const { profiles } = this.props;
    return profiles.find((profile) => profile.account.id === accountId);
  }

  @autobind
  handleCloseMenu() {
    this.setState({ open: false });
  }

  @autobind
  handleLogout() {
    const { actions, history } = this.props;

    this.handleCloseMenu();

    actions.logout(true).then(() => {
      history.push(formatRoute(LOGIN));
    });
  }

  @autobind
  handleStickyHeader() {
    const { onChange } = this.props;
    const { isSticky } = this.state;
    const top = window.pageYOffset || document.documentElement.scrollTop;
    const difference = document.documentElement.scrollHeight
      - document.documentElement.clientHeight;

    if ((top > MAX_TOP) && (difference >= MIN_SCROLL_DIFFERENCE) && !isSticky) {
      this.setState({ isSticky: true }, () => {
        if (onChange) {
          onChange(true);
        }
      });
    } else if (top < MAX_TOP && isSticky) {
      this.setState({ isSticky: false }, () => {
        if (onChange) {
          onChange(false);
        }
      });
    }
  }

  @autobind
  handleToggleMenu() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  @autobind
  handleOnChangeProfile(accountId) {
    const { history } = this.props;
    history.push(formatRoute(MY_5_DYNAMICS, { accountId }));
  }

  @autobind
  handleKeyPress(event) {
    switch (event.key) {
      case 'Enter':
      case 'Escape':
        this.handleCloseMenu();
        break;
      default:
    }
  }

  @autobind
  renderSelectedAccount(accountId) {
    const profile = this.getProfile(accountId);

    const {
      logo,
      name,
    } = profile.account;

    return (
      <MenuItem
        key={accountId}
        label={name}
        logoUrl={logo.url}
        isNotClickable
        value={`${accountId}-selected`}
      />
    );
  }

  render() {
    const {
      className,
      focusRef,
      hideExpirationMessage,
      i18n,
      isAssessmentCompleted,
      isAuthenticated,
      isStudentWaitingResults,
      location,
      page,
      profile,
      profiles,
    } = this.props;

    const {
      accountType,
      id: accountId,
    } = profile.account;

    const {
      expirationMessage,
      isSticky,
      open,
    } = this.state;

    const {
      isFetching,
      bar,
    } = page;

    const accountInformationRoute = formatRoute(GENERAL_ACCOUNT_INFORMATION);
    const accountListItems = this.getAccountListItems();
    const isLearningAccount = accountType === LEARNING_ACCOUNT_TYPE;
    const profileListItems = this.getProfileListItems();

    const helpdeskLink = (
      <a
        className={classnames(
          styles.icon,
          { [styles['icon-helpdesk']]: !isAuthenticated },
          { [styles['icon-user']]: isAuthenticated },
        )}
        href={isLearningAccount ? 'https://support.college-life.simpli5.com' : 'https://support.work-life.simpli5.com'}
        id="link-helpdesk"
        rel="noopener noreferrer"
        target="_blank"
        title={i18n.applicationHeader.helpdesk}
        to={isLearningAccount ? 'https://support.college-life.simpli5.com' : 'https://support.work-life.simpli5.com'}
      >
        <Icon.Stroke7 name="headphones" />
      </a>
    );

    const resourcesLink = (
      <a
        className={classnames(
          styles.icon,
          { [styles['icon-resources']]: !isAuthenticated },
          { [styles['icon-user']]: isAuthenticated },
        )}
        href="https://resources.work-life.simpli5.com/portal/en/kb/resources"
        id="link-resources"
        rel="noopener noreferrer"
        target="_blank"
        title={i18n.applicationHeader.resources}
        to="https://resources.work-life.simpli5.com/portal/en/kb/resources"
      >
        <Icon.Stroke7 name="light" />
      </a>
    );

    const ariaLabelPage = `Simpli5®. ${location.pathname.replace('/account', '')
      .replace(`/${accountId}/`, '')
      .replace('-', ' ')} page `;

    const isLoginPage = ariaLabelPage.includes('login');

    return (
      <>
        <header
          className={classnames(
            styles.header,
            { [styles['is-authenticated']]: isAuthenticated },
            { [styles['is-open']]: open },
            { [styles['is-sticky']]: isSticky },
            className,
          )}
        >
          {!isLoginPage && (
            <div
              tabIndex={-1}
              ref={this.skipToMainContentRef}
              aria-label={ariaLabelPage}
              role="presentation"
            >
              <LinkDOM
                className={classnames(
                  styles['skip-to-main-content'],
                )}
                onClick={(e) => {
                  e.preventDefault();
                  focusRef.current.querySelector('#main').focus();
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    focusRef.current.querySelector('#main').focus();
                  }
                }}
                tabIndex={0}
              >
                Skip to main content
              </LinkDOM>
            </div>
          )}
          <Container className={styles.header__container}>
            {
              isAuthenticated && (
                <MdMenu
                  className={styles['icon-menu']}
                  onClick={this.handleToggleMenu}
                />
              )
            }
            <Logo
              route={formatRoute(MY_5_DYNAMICS, { accountId })}
            />
            {
              isAuthenticated && isAssessmentCompleted && profiles
              && profiles.length > 0 && (
                <>
                  <span className={styles.division} />
                  <AccountsSelect
                    ariaLabel={`Organizations dropdown list. ${profile.account.name} selected.`}
                    autoWidth
                    className={styles.accounts}
                    disabled={!isAssessmentCompleted}
                    iconClassName={styles.accounts__icon}
                    onChange={(e) => { this.handleOnChangeProfile(e.target.value); }}
                    renderValue={this.renderSelectedAccount}
                    value={profile.account.id}
                  >
                    {accountListItems}
                  </AccountsSelect>
                </>
              )
            }
            <div className={styles.icons}>
              {
                isAuthenticated && (
                  <>
                    {!isLearningAccount && resourcesLink}
                    <Link
                      className={classnames(
                        styles.icon,
                        styles['icon-user'],
                      )}
                      href={accountInformationRoute}
                      id="link-account-preferences"
                      title={i18n.applicationHeader.accountPreferences}
                      to={accountInformationRoute}
                    >
                      <Icon.Stroke7 name="user" />
                    </Link>
                    {helpdeskLink}
                    <StyledButton
                      className={styles.logout}
                      color="primary"
                      id="link-logout"
                      onClick={this.handleLogout}
                      title={i18n.applicationHeader.logout}
                    >
                      {i18n.applicationHeader.logout}
                    </StyledButton>
                  </>
                )
              }
              {
                !isAuthenticated && helpdeskLink
              }
            </div>
          </Container>
          {
            isAuthenticated && isAssessmentCompleted && (
              <div
                onClick={this.handleCloseMenu}
                onKeyPress={this.handleKeyPress}
                role="button"
                tabIndex="-1"
              >
                <Menu.Desktop
                  className={styles.menu}
                  isStudentWaitingResults={isStudentWaitingResults}
                  profile={profile}
                />

                <Drawer
                  onClose={this.handleCloseMenu}
                  open={open}
                  width={260}
                >
                  {profileListItems}

                  <Menu.Mobile
                    className={styles.menu}
                    isStudentWaitingResults={isStudentWaitingResults}
                    profile={profile}
                  />

                  <MenuItemMUI>
                    <Link
                      href={accountInformationRoute}
                      to={accountInformationRoute}
                    >
                      {i18n.applicationHeader.accountPreferences}
                    </Link>
                  </MenuItemMUI>

                  <MenuItemMUI>
                    <a
                      href="https://support.simpli5.com"
                      rel="noopener noreferrer"
                      target="_blank"
                      title={i18n.applicationHeader.helpdesk}
                      to="https://support.simpli5.com"
                    >
                      {i18n.applicationHeader.helpdesk}
                    </a>
                  </MenuItemMUI>

                  <MenuItemMUI
                    onClick={this.handleLogout}
                  >
                    {i18n.applicationHeader.logout}
                  </MenuItemMUI>
                </Drawer>
              </div>
            )
          }

          {
            !hideExpirationMessage && (
              <FixedNotification
                message={expirationMessage}
                type="error"
              />
            )
          }

          <Notifications className={styles.notifications} />

          <div
            className={classnames(
              styles.bar,
              { [styles['is-Fetching']]: isFetching },
              { [styles['has-bar']]: !isEmpty(bar) },
            )}
            style={bar}
          >
            <div className={styles.loader} />
          </div>
        </header>
      </>
    );
  }
}

ApplicationHeader.propTypes = {
  actions: ACTIONS.isRequired,
  className: PropTypes.string,
  expirationMessage: PropTypes.string,
  focusRef: PropTypes.shape().isRequired,
  hideExpirationMessage: PropTypes.bool.isRequired,
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
  isAssessmentCompleted: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isStudentWaitingResults: PropTypes.bool.isRequired,
  location: PropTypes.shape().isRequired,
  onChange: PropTypes.func,
  page: PropTypes.shape().isRequired,
  profile: PERSON,
  profiles: PEOPLE,
};

ApplicationHeader.defaultProps = {
  className: null,
  expirationMessage: null,
  onChange: null,
  profile: {},
  profiles: [],
};

export default Translation(ApplicationHeader, ['applicationHeader']);
