import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from 'react-simple-tooltip';

import { COLORS } from 'constants/colors';
import Icon from 'generics/Icon';

import styles from './InfoTooltip.scss';

/**
 * InfoTooltip Component
 */
const InfoTooltip = ({ className, placement, text }) => (
  <Tooltip
    background={COLORS.primaryWhite.rgba}
    border={COLORS.grey4.rgba}
    className={classnames(
      styles.tooltip,
      className,
    )}
    color={COLORS.grey1.rgba}
    content={text}
    placement={placement}
  >
    <Icon.Stroke7 name="info" />
  </Tooltip>
);

InfoTooltip.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
  text: PropTypes.string.isRequired,
};

InfoTooltip.defaultProps = {
  className: null,
  placement: 'bottom',
};

export default InfoTooltip;
