const NAME_SPACE = 'Teams';

const actionTypes = {
  FETCH_CORPORATE_TEAMS_FAILURE: `${NAME_SPACE}/FETCH_CORPORATE_TEAMS_FAILURE`,
  FETCH_CORPORATE_TEAMS_REQUEST: `${NAME_SPACE}/FETCH_CORPORATE_TEAMS_REQUEST`,
  FETCH_CORPORATE_TEAMS_SUCCESS: `${NAME_SPACE}/FETCH_CORPORATE_TEAMS_SUCCESS`,
  FETCH_CORPORATE_TEAMS: `${NAME_SPACE}/FETCH_CORPORATE_TEAMS`,
  FETCH_CREATE_TEAM_FAILURE: `${NAME_SPACE}/FETCH_CREATE_TEAM_FAILURE`,
  FETCH_CREATE_TEAM_REQUEST: `${NAME_SPACE}/FETCH_CREATE_TEAM_REQUEST`,
  FETCH_CREATE_TEAM_SUCCESS: `${NAME_SPACE}/FETCH_CREATE_TEAM_SUCCESS`,
  FETCH_CREATE_TEAM: `${NAME_SPACE}/FETCH_CREATE_TEAM`,
  FETCH_DELETE_TEAM_FAILURE: `${NAME_SPACE}/FETCH_DELETE_TEAM_FAILURE`,
  FETCH_DELETE_TEAM_REQUEST: `${NAME_SPACE}/FETCH_DELETE_TEAM_REQUEST`,
  FETCH_DELETE_TEAM_SUCCESS: `${NAME_SPACE}/FETCH_DELETE_TEAM_SUCCESS`,
  FETCH_DELETE_TEAM: `${NAME_SPACE}/FETCH_DELETE_TEAM`,
  FETCH_EDIT_TEAM_FAILURE: `${NAME_SPACE}/FETCH_EDIT_TEAM_FAILURE`,
  FETCH_EDIT_TEAM_REQUEST: `${NAME_SPACE}/FETCH_EDIT_TEAM_REQUEST`,
  FETCH_EDIT_TEAM_SUCCESS: `${NAME_SPACE}/FETCH_EDIT_TEAM_SUCCESS`,
  FETCH_EDIT_TEAM: `${NAME_SPACE}/FETCH_EDIT_TEAM`,
  FETCH_OTHERS_TEAMS_FAILURE: `${NAME_SPACE}/FETCH_OTHERS_TEAMS_FAILURE`,
  FETCH_OTHERS_TEAMS_REQUEST: `${NAME_SPACE}/FETCH_OTHERS_TEAMS_REQUEST`,
  FETCH_OTHERS_TEAMS_SUCCESS: `${NAME_SPACE}/FETCH_OTHERS_TEAMS_SUCCESS`,
  FETCH_OTHERS_TEAMS: `${NAME_SPACE}/FETCH_OTHERS_TEAMS`,
  FETCH_PRIVATE_TEAMS_FAILURE: `${NAME_SPACE}/FETCH_PRIVATE_TEAMS_FAILURE`,
  FETCH_PRIVATE_TEAMS_REQUEST: `${NAME_SPACE}/FETCH_PRIVATE_TEAMS_REQUEST`,
  FETCH_PRIVATE_TEAMS_SUCCESS: `${NAME_SPACE}/FETCH_PRIVATE_TEAMS_SUCCESS`,
  FETCH_PRIVATE_TEAMS: `${NAME_SPACE}/FETCH_PRIVATE_TEAMS`,
  FETCH_PUBLISHED_TEAMS_FAILURE: `${NAME_SPACE}/FETCH_PUBLISHED_TEAMS_FAILURE`,
  FETCH_PUBLISHED_TEAMS_REQUEST: `${NAME_SPACE}/FETCH_PUBLISHED_TEAMS_REQUEST`,
  FETCH_PUBLISHED_TEAMS_SUCCESS: `${NAME_SPACE}/FETCH_PUBLISHED_TEAMS_SUCCESS`,
  FETCH_PUBLISHED_TEAMS: `${NAME_SPACE}/FETCH_PUBLISHED_TEAMS`,
  FETCH_SET_PRIVACY_TEAM_FAILURE: `${NAME_SPACE}/FETCH_SET_PRIVACY_TEAM_FAILURE`,
  FETCH_SET_PRIVACY_TEAM_REQUEST: `${NAME_SPACE}/FETCH_SET_PRIVACY_TEAM_REQUEST`,
  FETCH_SET_PRIVACY_TEAM_SUCCESS: `${NAME_SPACE}/FETCH_SET_PRIVACY_TEAM_SUCCESS`,
  FETCH_SET_PRIVACY_TEAM: `${NAME_SPACE}/FETCH_SET_PRIVACY_TEAM`,
  FETCH_SHARE_TEAMS_WITH_A_BUNDLE_FAILURE: `${NAME_SPACE}/FETCH_SHARE_TEAMS_WITH_A_BUNDLE_FAILURE`,
  FETCH_SHARE_TEAMS_WITH_A_BUNDLE_REQUEST: `${NAME_SPACE}/FETCH_SHARE_TEAMS_WITH_A_BUNDLE_REQUEST`,
  FETCH_SHARE_TEAMS_WITH_A_BUNDLE_SUCCESS: `${NAME_SPACE}/FETCH_SHARE_TEAMS_WITH_A_BUNDLE_SUCCESS`,
  FETCH_SHARE_TEAMS_WITH_A_BUNDLE: `${NAME_SPACE}/FETCH_SHARE_TEAMS_WITH_A_BUNDLE`,
  FETCH_TEAM_DASHBOARD_FAILURE: `${NAME_SPACE}/FETCH_TEAM_DASHBOARD_FAILURE`,
  FETCH_TEAM_DASHBOARD_REQUEST: `${NAME_SPACE}/FETCH_TEAM_DASHBOARD_REQUEST`,
  FETCH_TEAM_DASHBOARD_SUCCESS: `${NAME_SPACE}/FETCH_TEAM_DASHBOARD_SUCCESS`,
  FETCH_TEAM_DASHBOARD: `${NAME_SPACE}/FETCH_TEAM_DASHBOARD`,
  FETCH_TEAM_DETAILS_FAILURE: `${NAME_SPACE}/FETCH_TEAM_DETAILS_FAILURE`,
  FETCH_TEAM_DETAILS_REQUEST: `${NAME_SPACE}/FETCH_TEAM_DETAILS_REQUEST`,
  FETCH_TEAM_DETAILS_SUCCESS: `${NAME_SPACE}/FETCH_TEAM_DETAILS_SUCCESS`,
  FETCH_TEAM_DETAILS: `${NAME_SPACE}/FETCH_TEAM_DETAILS`,
  FETCH_TEAM_MEMBERS_FAILURE: `${NAME_SPACE}/FETCH_TEAM_MEMBERS_FAILURE`,
  FETCH_TEAM_MEMBERS_REQUEST: `${NAME_SPACE}/FETCH_TEAM_MEMBERS_REQUEST`,
  FETCH_TEAM_MEMBERS_SUCCESS: `${NAME_SPACE}/FETCH_TEAM_MEMBERS_SUCCESS`,
  FETCH_TEAM_MEMBERS: `${NAME_SPACE}/FETCH_TEAM_MEMBERS`,
  FETCH_TEAM_MODULES_FAILURE: `${NAME_SPACE}/FETCH_TEAM_MODULES_FAILURE`,
  FETCH_TEAM_MODULES_REQUEST: `${NAME_SPACE}/FETCH_TEAM_MODULES_REQUEST`,
  FETCH_TEAM_MODULES_SUCCESS: `${NAME_SPACE}/FETCH_TEAM_MODULES_SUCCESS`,
  FETCH_TEAM_MODULES: `${NAME_SPACE}/FETCH_TEAM_MODULES`,
  FETCH_UPDATE_LOGO_FAILURE: `${NAME_SPACE}/FETCH_UPDATE_LOGO_FAILURE`,
  FETCH_UPDATE_LOGO_REQUEST: `${NAME_SPACE}/FETCH_UPDATE_LOGO_REQUEST`,
  FETCH_UPDATE_LOGO_SUCCESS: `${NAME_SPACE}/FETCH_UPDATE_LOGO_SUCCESS`,
  FETCH_UPDATE_LOGO: `${NAME_SPACE}/FETCH_UPDATE_LOGO`,
};

export default actionTypes;
