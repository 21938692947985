/* eslint-disable react-hooks/exhaustive-deps */
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import DocumentTitle from 'react-document-title';
import React, { useEffect } from 'react';

import { PENDING, DECLINED } from 'constants/invitationStatus';
import { HISTORY, INVITATION } from 'constants/props';
import * as invitesSelectors from 'app_modules/invites/selectors';
import InvitationDialog from 'generics/InvitationDialog';

import SetupProfile from './components/SetupProfile';
import styles from './PageSignUp.scss';

const PageSignUp = ({
  history,
  invitation,
}) => {
  const handleRedirect = (to) => {
    history.replace(to);
  };

  useEffect(() => {
    if (isEmpty(invitation) || invitation.status === DECLINED) {
      handleRedirect('/');
    }
  }, []);

  const handleErrorDecline = () => {
    handleRedirect('/');
  };

  return (
    <DocumentTitle title="Simpli5® - Sign Up Page">
      <article className={styles.page}>
        <SetupProfile />
        {
        invitation
        && (invitation.status === PENDING || invitation.status === DECLINED) && (
          <InvitationDialog
            onDecline={handleErrorDecline}
            onError={handleErrorDecline}
          />
        )
      }
      </article>
    </DocumentTitle>
  );
};

PageSignUp.propTypes = {
  history: HISTORY.isRequired,
  invitation: INVITATION.isRequired,
};

const mapStateToProps = (state) => ({
  invitation: invitesSelectors.invitation(state),
});

export default connect(mapStateToProps)(PageSignUp);
