/* eslint-disable react-hooks/exhaustive-deps */
import { get, isEmpty, isEqual, kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { Card, Header, Separator } from 'generics/Card';
import { I18N, META, PEOPLE } from 'constants/props';
import { StyledButton } from 'generics/StyledFormComponents';
import InfiniteScrollList from 'generics/InfiniteScrollList';
import SearchBar from 'generics/SearchBar';
import Translation from 'generics/Translation';

import ProfileListItem from '../ProfileListItem/ProfileListItem';
import NetworkModal from '../NetworkModal';
import styles from './ProfilesCard.scss';

/**
 * Profiles Card component class
 * @extends Component
 */
const ProfilesCard = ({
  accountId,
  className,
  description,
  filter,
  hasShareProfile,
  i18n,
  isDepartmentEnabled,
  isFetching,
  isPersonalAccount,
  meta,
  onFetch,
  pageIndex,
  profiles,
  setFilter,
  setPageIndex,
  title,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  let infiniteScrollList = useRef(null);

  /**
   * Gets component initial state
   * Set Initial States
  */
  const getInitialState = () => {
    setIsModalOpen(false);
    setPageIndex(1);
    setFilter(null);
  };

  /**
   * Fetch Profiles based on page index and search text
   * @param {number} pageIndex
   * @param {string} text
   */

  const handleFetchProfiles = (newPageIndex = 1) => {
    setPageIndex(newPageIndex);
    return onFetch({ pageIndex: newPageIndex, search: filter });
  };

  /**
   * Changes state when the share modal is opened
   */

  const handleShareProfile = () => {
    setIsModalOpen(true);
  };

  const getTopActions = () => (hasShareProfile
    ? [
      <StyledButton
        disabled={isPersonalAccount || isModalOpen}
        onClick={handleShareProfile}
        color="primary"
        title={i18n.network.connectWithMe}
      >
        {i18n.network.connectWithMe}
      </StyledButton>,
    ]
    : []);

  /**
   * Changes state when the modal is closed
   */

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  /**
   * Fetch profiles when the search text changes
   * @param {string} text
   */

  const handleSearch = (filterInput) => {
    if (isEqual(filter, filterInput)) {
      return;
    }

    if (get(infiniteScrollList, 'infiniteScrollRef')) {
      infiniteScrollList.infiniteScrollRef.resetIndex();
    }

    setFilter(filterInput);
  };

  /**
   * Component Render method
   * @return component
   */
  const { morePages } = meta;

  const infiniteScroll = {
    hasMorePages: morePages,
    onFetch: handleFetchProfiles,
  };

  const profileListItems = !isEmpty(profiles)
    ? profiles.map((profile) => (
      <ProfileListItem
        accountId={accountId}
        key={`profile-list-item-${profile.id}`}
        profile={profile}
      />
    ))
    : (
      <div className={styles['empty-list']}>
        {i18n.network.list.empty}
      </div>
    );

  return (
    <Card
      barClassName={styles.bar}
      className={className}
      isFetching={pageIndex === 1 && isFetching}
    >
      <Header
        title={title}
        actions={getTopActions()}
        ariaLabel={title}
      />

      <Separator />

      <p className={styles.description}>
        {description}
      </p>

      <SearchBar
        className={styles['search-form']}
        floatingLabelText={i18n.network.search.floatingLabel}
        onChange={handleSearch}
        onSearch={handleSearch}
      />

      <InfiniteScrollList
        className={styles.list}
        infiniteScroll={infiniteScroll}
        listId={`${kebabCase(title)}-list`}
        innerRef={(component) => { infiniteScrollList = component; }}
      >
        {profileListItems}
      </InfiniteScrollList>

      {
        hasShareProfile && isModalOpen && (
          <NetworkModal
            isDepartmentEnabled={isDepartmentEnabled}
            onClose={handleCloseModal}
          />
        )
      }
    </Card>
  );
};

ProfilesCard.propTypes = {
  accountId: PropTypes.number.isRequired,
  className: PropTypes.string,
  filter: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  hasShareProfile: PropTypes.bool,
  i18n: I18N.isRequired,
  isDepartmentEnabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
  isPersonalAccount: PropTypes.bool,
  meta: META.isRequired,
  onFetch: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  profiles: PEOPLE.isRequired,
  setFilter: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

ProfilesCard.defaultProps = {
  className: null,
  hasShareProfile: false,
  isFetching: false,
  isPersonalAccount: false,
};

export default Translation(ProfilesCard, ['network']);
