import { merge } from 'lodash';

import ApiEndpoint from 'classes/api/ApiEndpoint.class';

/**
 * @class ApiController
 * @description Creates an Api Controller with all the endpoints request features
 * */
export default class ApiController {
  constructor(options) {
    const {
      api,
      controller,
    } = options;

    const instances = {};
    const endpoints = {};

    Object.keys(controller)
      .forEach((endpoint) => {
        instances[endpoint] = new ApiEndpoint({
          api,
          ...controller[endpoint],
        });
        endpoints[endpoint] = function request() {
          return instances[endpoint].request(...arguments);
        };
      });

    merge(this, { instances }, endpoints);
  }
}
