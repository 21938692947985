import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'generics/Icon';

import styles from './PageTitle.scss';

const PageTitle = (props) => {
  const {
    categoryColor,
    categoryTitle,
    className,
    icon,
    title,
  } = props;

  return (
    <div className={className}>
      <div
        className={styles['category-title']}
        style={{ color: categoryColor }}
      >
        {categoryTitle && categoryTitle.toUpperCase()}
      </div>

      <div>
        <span className={styles.icon}>
          <Icon.Stroke7
            name={icon}
            style={{ color: categoryColor }}
          />
        </span>
        <span className={styles.title}>
          {title}
        </span>
      </div>
    </div>
  );
};

PageTitle.propTypes = {
  categoryColor: PropTypes.string,
  categoryTitle: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
};

PageTitle.defaultProps = {
  categoryColor: null,
  categoryTitle: null,
  className: null,
  icon: null,
  title: null,
};

export default PageTitle;
