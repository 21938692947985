import * as expiration from './expiration';
import * as fetchDashboard from './fetchDashboard';
import * as fetchDocuments from './fetchDocuments';
import * as fetchImpersonate from './fetchImpersonate';
import * as fetchLogin from './fetchLogin';
import * as fetchLogout from './fetchLogout';
import * as fetchSetProfile from './fetchSetProfile';
import * as fetchSSOLogin from './fetchSSOLogin';
import * as resetState from './resetState';
import * as setDefaultAccount from './setDefaultAccount';
import * as setProfile from './setProfile';
import * as setUser from './setUser';

module.exports = {
  ...expiration,
  ...fetchDashboard,
  ...fetchDocuments,
  ...fetchImpersonate,
  ...fetchLogin,
  ...fetchLogout,
  ...fetchSetProfile,
  ...fetchSSOLogin,
  ...resetState,
  ...setDefaultAccount,
  ...setProfile,
  ...setUser,
};
