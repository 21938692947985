/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { bindActionCreators } from 'redux';
import { CircularProgress } from '@material-ui/core';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import { Set } from 'immutable';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { ACTIONS, I18N, MATCH, TEAM } from 'constants/props';
import { BarError } from 'classes/errors';
import { COLORS } from 'constants/colors';
import { getCSSFromSVGs } from 'helpers';
import { LEARNING_ACCOUNT_TYPE } from 'constants/accountTypes';
import { Person, Url } from 'classes';
import { Separator } from 'generics/Card';
import { SpiderGraph } from 'generics/Charts';
import { StyledButton } from 'generics/StyledFormComponents';
import { TEAMS, TEAMS_DASHBOARD, TEAMS_SPIDER_GRAPH } from 'urls';
import * as notificationActionsModule from 'app_modules/notifications/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import * as teamSelectors from 'app_modules/teams/selectors';
import api from 'api';
import Breadcrumbs from 'generics/Breadcrumbs';
import Module from 'components/Module';
import PageTitle from 'generics/PageTitle';
import PDFEnergyMaps from 'generics/PDFEnergyMaps';
import ProfileSelector from 'generics/ProfileSelector';
import teamsActionsModule from 'app_modules/teams/actions';
import Translation from 'generics/Translation';

import styles from './PageSpiderGraph.scss';

const PageSpiderGraph = ({
  accountType,
  history,
  i18n,
  match,
  notificationActions,
  team,
  teamMembers,
}) => {
  const [isFetching, setIsFetching] = useState(new Set());
  const [isTeamAverageInclude, setIsTeamAverageInclude] = useState(false);
  const [profiles, setProfiles] = useState([]);

  const spiderGraph = useRef(null);
  const energyMaps = useRef(null);

  const spiderGraphHistoryLimit = 5;

  const isLearningAccount = accountType === LEARNING_ACCOUNT_TYPE;

  const setSpiderGraphHistory = () => {
    const {
      accountId,
      teamId,
      token,
    } = match.params;

    const spiderGraphsHistory = JSON.parse(localStorage.getItem('spiderGraphs')) || {};
    let teamSpiderGraphHistory = spiderGraphsHistory[teamId] || [];

    teamSpiderGraphHistory = [{
      date: moment(new Date()).format('L'),
      route: formatRoute(TEAMS_SPIDER_GRAPH, {
        accountId,
        isHistory: 'isHistory',
        teamId,
        token,
      }),
      time: moment(new Date()).format('HH:mm:ss'),
    }, ...teamSpiderGraphHistory].slice(0, spiderGraphHistoryLimit);

    spiderGraphsHistory[teamId] = teamSpiderGraphHistory;

    localStorage.setItem('spiderGraphs', JSON.stringify({ ...spiderGraphsHistory }));
  };

  const getInitialState = () => {
    const { isHistory, token } = match.params;
    let data = null;

    try {
      data = new Url(token).decode();
    } catch {
      notificationActions.notifyError(new BarError({ label: 'invalidAddress' }));
      history.go(-1);
    }

    const {
      isTeamAverageInclude: newIsTeamAverageInclude,
      profiles: profilesIds,
    } = data;

    if (profilesIds.length === 0 || newIsTeamAverageInclude === undefined) {
      history.go(-1);
    }

    const newProfiles = teamMembers
      .map((profile) => new Person(profile))
      .filter(({ id, isVisible }) => isVisible && profilesIds.includes(id));

    if (newProfiles.length === 0) {
      history.go(-1);
    }

    setIsFetching(new Set());
    setIsTeamAverageInclude(newIsTeamAverageInclude);
    setProfiles(newProfiles);

    if (!isHistory) {
      setSpiderGraphHistory();
    }
  };

  useEffect(() => {
    getInitialState();
  }, []);

  const getBreadcrums = () => {
    const {
      accountId,
      teamId,
    } = match.params;

    return [
      {
        label: i18n.pageSpiderGraph.breadcrumbs.level0,
        to: formatRoute(TEAMS, { accountId }),
      },
      {
        label: team.name,
        to: formatRoute(TEAMS_DASHBOARD, { accountId, teamId }),
      },
      {
        label: i18n.pageSpiderGraph.breadcrumbs.level2,
      },
    ];
  };

  const handleDownloadEnergyMap = () => {
    if (isFetching.has('energy')) {
      return;
    }

    const {
      accountId,
      teamId,
    } = match.params;

    const {
      notifyError,
      notifySuccess,
    } = notificationActions;

    const { svgs } = energyMaps.current;

    const css = getCSSFromSVGs(svgs);

    setIsFetching(isFetching.add('energy'));

    const handleResponse = () => {
      setIsFetching(isFetching.delete('energy'));
      notifySuccess(i18n.pageSpiderGraph.downloadMessage);
    };

    const handleError = () => {
      setIsFetching(isFetching.delete('overview'));
      notifyError(new BarError({ message: i18n.pageSpiderGraph.downloadEnergyError }));
    };

    api.files.downloadTeamEnergyMap(
      {
        accountId,
        css,
        profiles: svgs.map(({ id, svg }) => ({ id, svg })),
        teamId,
      },
      handleResponse,
      handleError,
    );
  };

  const handleDownloadOverviewPDF = () => {
    if (isFetching.has('overview')) {
      return;
    }

    const {
      accountId,
      teamId,
    } = match.params;

    const {
      notifyError,
      notifySuccess,
    } = notificationActions;

    const { svgs } = energyMaps.current;

    const css = getCSSFromSVGs(svgs);

    setIsFetching(isFetching.add('overview'));

    const handleResponse = () => {
      setIsFetching(isFetching.delete('overview'));
      notifySuccess(i18n.pageSpiderGraph.downloadMessage);
    };

    const handleError = () => {
      setIsFetching(isFetching.delete('overview'));
      notifyError(new BarError({ message: i18n.pageSpiderGraph.downloadOverviewError }));
    };

    api.files.downloadTeamOverviewPDF(
      {
        accountId,
        css,
        profiles: svgs.map(({ id, svg }) => ({ id, svg })),
        teamId,
      },
      handleResponse,
      handleError,
    );
  };

  const handleDownloadSpiderGraphPDF = () => {
    if (isFetching.has('spider-graph')) {
      return;
    }

    const { accountId } = match.params;

    setIsFetching(isFetching.add('spider-graph'));

    const html = spiderGraph.current.outerHTML();

    const onComplete = () => {
      setIsFetching(isFetching.delete('spider-graph'));
    };

    api.files.downloadSpiderGraphPDF({
      accountId,
      html,
      team,
      profiles: profiles.map(({ id }) => id),
    }, onComplete, onComplete);
  };

  const isFetchingEnergyMap = isFetching.has('energy');
  const isFetchingOverviewReport = isFetching.has('overview');
  const isFetchingSpiderGraph = isFetching.has('spider-graph');

  if (!team || !profiles) {
    return (
      <CircularProgress
        size={2}
        thickness={1.5}
      />
    );
  }

  const energiesDescriptionList = i18n.pageSpiderGraph.energies
    .map(([text0, text1, text2], index) => (
      <p
        className={styles.page__p}
        key={text1}
      >
        {text0}

        <span
          className={styles[`page__energy${index}`]}
          title={text1}
        >
          {text1}
        </span>

        {text2}
      </p>
    ));

  return (
    <div className={styles.page}>
      <Container>
        <Module
          barBackgroundColor={COLORS.primaryGreen.rgba}
          breadcrumbs={(
            <Breadcrumbs
              className={styles.breadcrumbs}
              linkList={getBreadcrums()}
            />
          )}
        >
          <Container>
            <Row>
              <Col xs="12" md="6" lg={{ size: 3, offset: 3 }}>
                <div className={styles.button}>
                  <StyledButton
                    disabled={isFetchingOverviewReport}
                    fullWidth
                    onClick={handleDownloadEnergyMap}
                    color="primary"
                    title={i18n.pageSpiderGraph.label.energyMap}
                  >
                    {i18n.pageSpiderGraph.label.energyMap}
                  </StyledButton>
                  {
                    isFetchingEnergyMap && (
                      <CircularProgress
                        className={styles.button__loader}
                        size={2}
                        thickness={1.5}
                      />
                    )
                  }
                </div>
              </Col>
              <Col xs="12" md="6" lg="3">
                <div className={styles.button}>
                  <StyledButton
                    disabled={isFetchingOverviewReport}
                    fullWidth
                    onClick={handleDownloadOverviewPDF}
                    color="primary"
                    title={!isLearningAccount
                      ? i18n.pageSpiderGraph.label.overview
                      : i18n.pageSpiderGraph.label.learning}
                  >
                    {!isLearningAccount
                      ? i18n.pageSpiderGraph.label.overview
                      : i18n.pageSpiderGraph.label.learning}
                  </StyledButton>
                  {
                    isFetchingOverviewReport && (
                      <CircularProgress
                        className={styles.button__loader}
                        size={2}
                        thickness={1.5}
                      />
                    )
                  }
                </div>
              </Col>
              <Col xs="12" md="6" lg="3">
                <div className={styles.button}>
                  <StyledButton
                    disabled={isFetchingSpiderGraph}
                    fullWidth
                    onClick={handleDownloadSpiderGraphPDF}
                    color="primary"
                    title={i18n.pageSpiderGraph.label.pdf}
                  >
                    {i18n.pageSpiderGraph.label.pdf}
                  </StyledButton>
                  {
                    isFetchingSpiderGraph && (
                      <CircularProgress
                        className={styles.button__loader}
                        size={2}
                        thickness={1.5}
                      />
                    )
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <PageTitle
                  categoryColor={COLORS.primaryGreen.rgba}
                  categoryTitle={i18n.pageSpiderGraph.page.category}
                  className={styles.title}
                  icon="users"
                  title={i18n.pageSpiderGraph.page.title}
                />

                <ProfileSelector
                  className={styles['profile-selector']}
                  profile={team.clone({ teamMembers: profiles })}
                />

                <Separator className={styles.separator} />

                <SpiderGraph
                  className={styles['spider-graph']}
                  innerRef={(component) => {
                    spiderGraph.current = component;
                  }}
                  isTeamAverageInclude={isTeamAverageInclude}
                  profiles={profiles}
                  team={team}
                />

                {energiesDescriptionList}

                <p className={styles.page__p}>
                  {i18n.pageSpiderGraph.note}
                </p>
              </Col>
            </Row>
          </Container>
        </Module>
      </Container>
      <PDFEnergyMaps
        profiles={profiles}
        innerRef={(nodeElement) => {
          energyMaps.current = nodeElement;
        }}
      />
    </div>
  );
};

PageSpiderGraph.propTypes = {
  i18n: I18N.isRequired,
  match: MATCH.isRequired,
  notificationActions: ACTIONS.isRequired,
  team: TEAM.isRequired,
};

const mapStateToProps = (state) => ({
  accountType: sessionSelectors.accountType(state),
  team: teamSelectors.team(state),
  teamMembers: teamSelectors.teamMembers(state),
});

const mapDispatchToProps = (dispatch) => ({
  notificationActions: bindActionCreators(notificationActionsModule, dispatch),
  teamsActions: bindActionCreators(teamsActionsModule, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Translation(PageSpiderGraph, ['pageSpiderGraph']));
