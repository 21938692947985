import * as fetchAccount from './fetchAccount';
import * as setPersonalsSelfInvitation from './setPersonalsSelfInvitation';
import * as toggleNotification from './toggleNotification';
import * as updateCurrentProfile from './updateCurrentProfile';

module.exports = {
  ...fetchAccount,
  ...setPersonalsSelfInvitation,
  ...toggleNotification,
  ...updateCurrentProfile,
};
