import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/profile/constants';
import api from 'api';

// Actions

export const editProfileFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_EDIT_PROFILE_FAILURE,
});

export const editProfileRequest = () => ({
  type: actionTypes.FETCH_EDIT_PROFILE_REQUEST,
});

export const editProfileSuccess = (response) => ({
  payload: response.profile,
  type: actionTypes.FETCH_EDIT_PROFILE_SUCCESS,
});

// Thunks

export const fetchEditProfile = (options, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());

    dispatch(editProfileRequest());

    const handleResponse = (response) => {
      dispatch(editProfileSuccess(response));
      if (onSuccess) {
        onSuccess(response);
      }
    };

    const handleError = ({ error }) => {
      dispatch(editProfileFailure(error));
      if (onError) {
        onError(error);
      } else {
        throw error;
      }
    };

    return api.profiles.editProfile({
      accountId,
      ...options,
    }, handleResponse, handleError);
  };

  thunk.type = actionTypes.FETCH_EDIT_PROFILE;

  return thunk;
};

export default fetchEditProfile;
