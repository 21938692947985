import { combineReducers } from 'redux';
import { get, uniqBy } from 'lodash';

import accountsActionTypes from 'app_modules/accounts/constants';
import initialState from 'app_modules/store/initialState.json';
import profileActionTypes from 'app_modules/profile/constants';
import sessionActionTypes from 'app_modules/session/constants';

export const isFetching = (state = initialState.accounts.isFetching, action) => {
  switch (action.type) {
    case accountsActionTypes.FETCH_MY_MEMBERS_REQUEST:
      return true;

    case accountsActionTypes.FETCH_MY_MEMBERS_FAILURE:
    case accountsActionTypes.FETCH_MY_MEMBERS_SUCCESS:
      return false;

    case sessionActionTypes.RESET_STATE:
      return initialState.accounts.isFetching;

    default:
      return state;
  }
};

export const list = (state = initialState.accounts.list, { type, payload }) => {
  switch (type) {
    case accountsActionTypes.FETCH_MY_MEMBERS_REQUEST:
      return payload === 1
        ? []
        : state;

    case accountsActionTypes.FETCH_MY_MEMBERS_FAILURE:
      return [];

    case accountsActionTypes.FETCH_MY_MEMBERS_SUCCESS:
      return uniqBy([
        ...state,
        ...get(payload, 'profiles', []),
      ], 'id');

    case profileActionTypes.EDIT_DEPARTMENT_SUCCESS:
      return state.map((profile) => {
        if (profile.id === payload.id) {
          return {
            ...profile,
            department: payload.department,
          };
        }
        return profile;
      });

    case sessionActionTypes.RESET_STATE:
      return initialState.accounts.list;

    default:
      return state;
  }
};

export const meta = (state = initialState.accounts.meta, { type, payload }) => {
  switch (type) {
    case accountsActionTypes.FETCH_MY_MEMBERS_FAILURE:
      return {
        morePages: false,
      };

    case accountsActionTypes.FETCH_MY_MEMBERS_SUCCESS: {
      const newMeta = get(payload, 'meta', initialState.accounts.meta);
      return payload.updateCounters ? newMeta : {
        ...newMeta,
        totalObjects: state.totalObjects,
      };
    }

    case sessionActionTypes.RESET_STATE:
      return initialState.accounts.meta;

    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  list,
  meta,
});
