import PropTypes from 'prop-types';

import { EMAIL, NAME, PHONE, REQUIRED, TEXT } from 'constants/inputTypes';
import { I18N } from 'constants/props';
import useForm from '../../../custom_hooks/useForm';
import useFormValidation from '../../../custom_hooks/useFormValidation';

const useFormAccountInformation = ({ i18n, initialValues, onSubmit }) => {
  const { validator } = useFormValidation({ i18n });

  const handleValidate = (values) => {
    const {
      address,
      city,
      division,
      email,
      firstName,
      genderPronounPreference,
      jobTitle,
      lastName,
      mobilePhone,
      postalCode,
      state,
      workPhone,
    } = values;

    return {
      address: validator.validate(TEXT, address, 2),
      city: validator.validate(TEXT, city, 2),
      division: validator.validate(TEXT, division, 0),
      email: validator.validate(REQUIRED, email) || validator.validate(EMAIL, email),
      firstName: validator.validate(NAME, firstName),
      genderPronounPreference: validator.validate(REQUIRED, genderPronounPreference),
      jobTitle: validator.validate(TEXT, jobTitle, 0),
      lastName: validator.validate(NAME, lastName),
      mobilePhone: validator.validate(PHONE, mobilePhone),
      postalCode: validator.validate(TEXT, postalCode, 2),
      state: validator.validate(TEXT, state, 2),
      workPhone: validator.validate(PHONE, workPhone),
    };
  };

  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values = {
      address: null,
      city: null,
      division: null,
      email: null,
      firstName: null,
      genderPronounPreference: null,
      jobTitle: null,
      lastName: null,
      mobilePhone: null,
      postalCode: null,
      state: null,
      workPhone: null,
    },
  } = useForm({ initialValues, onSubmit, onValidate: handleValidate });

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  };
};

useFormAccountInformation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
  initialValues: PropTypes.shape({}),
};

useFormAccountInformation.defaultProps = {
  initialValues: {},
};

export default useFormAccountInformation;
