import { autobind } from 'core-decorators';
import { bindActionCreators } from 'redux';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { get, isEmpty, reject, size, some, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ACTIONS, I18N, MEMBERS, PERSON, TEAM } from 'constants/props';
import { ADD, REMOVE } from 'constants/actions';
import { Footer, Header, Main } from 'generics/Card';
import { orderByFirstNameLastName } from 'helpers';
import { Person } from 'classes';
import { StyledButton } from 'generics/StyledFormComponents';
import * as accountActions from 'app_modules/accounts/actions';
import * as networkActions from 'app_modules/network/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import * as teamsActions from 'app_modules/teams/actions';
import * as teamsSelectors from 'app_modules/teams/selectors';
import ListSelection from 'generics/ListSelection';
import Modal from 'generics/Modal';
import ModalConfirmation from 'generics/ModalConfirmation';
import SearchBar from 'generics/SearchBar';
import Translation from 'generics/Translation';

import FormCreateTeam from './components/FormCreateTeam';
import styles from './ModalCreateTeam.scss';

const MIN_SIZE_SCROLL = 7;

const getInitialSelectedProfiles = ({ edit, members, team }) => {
  const { owner: { id: ownerId } } = team;

  return edit && members
    ? members.list
      .map((teamMember) => teamMember.clone({
        _isOwner: teamMember.id === ownerId,
        canDrag: true,
      }))
    : [];
};

/**
 * @description: Create / Edit Team Modal Component
 * @extends Component
 */
class ModalCreateTeam extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  /**
   * @description: Gets the initial state
   */
  getInitialState(props) {
    const {
      edit,
      team,
    } = props;

    const {
      description,
      name,
      owner,
    } = team;

    const initialSelectedProfiles = getInitialSelectedProfiles(props);

    return {
      addedMembers: [],
      deletedMembers: [],
      filter: null,
      formValues: edit ? { description, name, owner: owner.id } : undefined,
      hasFormErrors: !edit,
      initialSelectedProfiles,
      isConfirmationOpen: false,
      isFetching: false,
      isFetchingNetwork: false,
      network: {
        hasMorePages: false,
        list: [],
      },
      pristine: true,
      selectedProfiles: [...initialSelectedProfiles],
    };
  }

  componentDidMount() {
    this.handleFetchNetwork();
  }


  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { members } = this.props;
    const membersWereUpdated = size(get(members, 'list', []))
      !== size(get(nextProps.members, 'list', []));

    const initialSelectedProfiles = getInitialSelectedProfiles(nextProps);

    if (membersWereUpdated) {
      let { network } = this.state;

      const { selectedProfiles: oldSelectedProfiles } = this.state;

      const selectedProfiles = orderByFirstNameLastName(
        uniqBy([...oldSelectedProfiles, ...initialSelectedProfiles], 'id'),
      );

      // if team members were updated we need to update
      // network state list to concat new profiles
      if (membersWereUpdated) {
        const list = this.getProfiles(false, selectedProfiles);
        network = {
          ...network,
          list,
        };
      }

      this.setState({
        selectedProfiles,
        network,
      }, () => {
        this.handleFetchNetwork();
      });
    }
  }

  /**
   * @description Get the list of profiles (ListA) depending on pageIndex,
   * receivedProfiles, search filter, initialSelectedProfiles
   * and current list of profiles loaded (network)
   * @param  {number} pageIndex
   * @param  {array} receivedProfiles
   */
  @autobind
  getProfiles(isFirstPage, receivedProfiles) {
    const {
      edit,
      team,
    } = this.props;

    const { owner } = team;

    const {
      filter,
      initialSelectedProfiles,
      network,
    } = this.state;

    let profiles = [];

    if (isFirstPage) {
      if (isEmpty(filter) || isEmpty(filter?.value)) {
        profiles = [...receivedProfiles, ...initialSelectedProfiles];
      } else {
        const searchText = filter.value.toLowerCase();

        const filteredProfiles = initialSelectedProfiles.filter(
          ({ firstName, lastName }) => `${firstName} ${lastName}`.toLowerCase().includes(searchText),
        );

        profiles = [...receivedProfiles, ...filteredProfiles];
      }
    } else {
      profiles = [...network.list, ...receivedProfiles];
    }

    profiles = orderByFirstNameLastName(uniqBy(profiles, 'id'));

    return profiles.map((networkProfile) => new Person({
      ...networkProfile,
      canDrag: true,
      _isOwner: edit
        ? networkProfile.id === owner.id
        : false,
    }));
  }

  /**
 * @description: method called when the search filter is changed
 * Changes the filter in the component state and gets network
 * profiles according the filter updated
 * @param  {string} filter
 */
  @autobind
  handleChangeFilter(filter) {
    const { filter: oldFilter } = this.state;

    if (oldFilter !== filter) {
      this.setState({ filter }, () => {
        if (get(this.listSelection.InfiniteScrollList, 'infiniteScrollRef')) {
          this.listSelection.InfiniteScrollList.infiniteScrollRef.resetIndex();
        }

        this.handleFetchNetwork();
      });
    }
  }

  /**
 * @description: Changes the selectedProfiles in the component state
 * @param {array} selectedProfiles
 */
  @autobind
  handleChangeList(selectedProfiles, profile, action) {
    const { id } = profile;

    this.setState(({ addedMembers: addedList, deletedMembers: deletedList }) => {
      let addedMembers = addedList;
      let deletedMembers = deletedList;

      if (action === ADD) {
        if (some(deletedMembers, { id })) {
          deletedMembers = reject(deletedMembers, { id });
        } else {
          addedMembers.push(profile);
        }
      } else if (action === REMOVE) {
        if (some(addedMembers, { id })) {
          addedMembers = reject(addedMembers, { id });
        } else {
          deletedMembers.push(profile);
        }
      }

      return {
        addedMembers,
        deletedMembers,
        selectedProfiles,
      };
    });
  }

  /**
   * @description: handles "Done" button click event
   */
  @autobind
  handleClickDone() {
    const {
      edit,
    } = this.props;

    if (edit) {
      this.handleCreateEdit();
    } else {
      this.setState({ isConfirmationOpen: true });
    }
  }

  /**
   * @description: completes the action (edit or create team)
   */
  @autobind
  handleCreateEdit() {
    const {
      actions,
      edit,
      onSuccess,
      team: { id: teamId },
    } = this.props;

    const {
      addedMembers,
      formValues,
      deletedMembers,
    } = this.state;

    this.setState({ isFetching: true });

    const action = edit
      ? actions.fetchEditTeam
      : actions.fetchCreateTeam;

    const onFinish = () => {
      this.setState({
        isConfirmationOpen: false,
        isFetching: false,
      });

      this.handleCloseModal();
      actions.fetchAccount();

      if (onSuccess) {
        onSuccess();
      }
    };

    const onError = () => {
      this.setState({ isFetching: false });
    };

    const data = {
      addedMembers: addedMembers.map((profile) => profile.id),
      deletedMembers: deletedMembers.map((profile) => profile.id),
      teamId,
      ...formValues,
    };

    action(data, onFinish, onError);
  }

  /**
   * @description: Handles close modal event
   */
  @autobind
  handleCloseModal() {
    const { onClose } = this.props;
    onClose();
  }

  /**
   * @description: Checks if form is valid
   */
  @autobind
  handleIsDisabled() {
    const {
      hasFormErrors,
      selectedProfiles,
    } = this.state;

    // has errors or is empty team without members
    if (!hasFormErrors && size(selectedProfiles) > 0) {
      return false;
    }

    return true;
  }

  /**
   * @description Save formValues and hasFormErrors in component state
   * @param  {object} formValues
   * @param  {boolean} hasFormErrors
   */
  @autobind
  handleEditForm(formValues, hasFormErrors) {
    this.setState({
      formValues,
      hasFormErrors,
    });
  }


  /**
   * @description: Gets network profiles (api call) according the pageIndex and the state filter
   * @param  {int} pageIndex
   */
  @autobind
  handleFetchNetwork(pageIndex = 1) {
    const {
      actions,
      edit,
      team,
    } = this.props;

    const {
      filter: search,
    } = this.state;

    this.setState({ isFetchingNetwork: true });

    const teamId = edit ? team.id : null;

    const onSuccess = ({ profiles: receivedProfiles, meta: { morePages } }) => {
      const list = this.getProfiles(pageIndex === 1, receivedProfiles);

      this.setState({
        network: {
          hasMorePages: morePages,
          list,
        },
      });

      if (size(list) <= MIN_SIZE_SCROLL && morePages) {
        this.handleFetchNetwork(pageIndex + 1);
      } else {
        this.setState({
          isFetchingNetwork: false,
        });
      }
    };

    actions.fetchMyNetwork({
      includeAll: !edit,
      pageIndex,
      search,
      teamId,
    }, onSuccess);
  }

  render() {
    const {
      edit,
      i18n,
      members,
      profile,
      team,
    } = this.props;

    let profiles = [];

    const {
      filter,
      isConfirmationOpen,
      isFetching,
      isFetchingNetwork,
      network,
      selectedProfiles,
    } = this.state;

    let initialValues;

    if (edit) {
      const { owner } = team;
      profiles = uniqBy([owner, ...selectedProfiles], 'id');
      initialValues = {
        ...team,
        owner: get(owner, 'id'),
      };
    } else {
      profiles = uniqBy([profile, ...selectedProfiles], 'id');
      initialValues = {
        owner: profile.id,
      };
    }

    const title = !edit
      ? i18n.pageTeams.createTeam.title.createTeam
      : i18n.pageTeams.createTeam.title.editTeam;

    const description = !edit
      ? i18n.pageTeams.createTeam.description.createTeam
      : i18n.pageTeams.createTeam.description.editTeam;

    const networkInfiniteScroll = {
      hasMorePages: network.hasMorePages,
      listHeight: 250,
      onFetch: this.handleFetchNetwork,
    };

    const membersInfiniteScroll = edit ? {
      hasMorePages: members.hasMorePages,
      listHeight: 250,
      onFetch: members.onFetchMore,
      pageStart: members.pageIndex + 1,
    } : null;

    return !isConfirmationOpen ? (
      <Modal
        card={{
          barClassName: styles.bar,
          fullHeight: true,
          isFetching,
        }}
        onClose={this.handleCloseModal}
      >
        <Header>
          <h1 title={title}>
            {title}
          </h1>
        </Header>

        <Main>
          <Container fluid>
            <p className={styles.description}>
              {description}
            </p>

            <FormCreateTeam
              className={styles.form}
              edit={edit}
              network={orderByFirstNameLastName(profiles)}
              profile={profile}
              initialValues={initialValues}
              onSubmit={this.handleEditForm}
            />

            <h2 className={styles['team-members']}>
              {i18n.pageTeams.createTeam.teamMembers}
            </h2>

            <p>
              {i18n.pageTeams.createTeam.instructions}
            </p>

            <SearchBar
              className={styles.form}
              floatingLabelText={i18n.generics.filterLabel}
              onChange={this.handleChangeFilter}
              onSearch={this.handleChangeFilter}
            />
            <ListSelection
              filter={filter}
              listA={{
                infiniteScroll: networkInfiniteScroll,
                id: 'create-team-list1',
                isFetching: isFetchingNetwork,
                profiles: network.list,
                title: i18n.pageTeams.createTeam.list.listATitle,
              }}
              listB={{
                emptyMessage: i18n.pageTeams.createTeam.list.emptyMessage,
                id: 'create-team-list2',
                infiniteScroll: membersInfiniteScroll,
                isFetching: isFetchingNetwork,
                profiles: selectedProfiles,
                title: i18n.pageTeams.createTeam.list.listBTitle,
              }}
              onChange={this.handleChangeList}
              componentRefProp={(component) => { this.listSelection = component; }}
            />
          </Container>
        </Main>

        <Footer>
          <Container>
            <Row>
              <Col xs="4" md="2">
                <StyledButton
                  color="default"
                  fullWidth
                  onClick={this.handleCloseModal}
                  title={i18n.generics.cancelLabel}
                  variant="text"
                >
                  {i18n.generics.cancelLabel}
                </StyledButton>
              </Col>

              <Col xs={{ size: 4, offset: 4 }} md={{ size: 2, offset: 8 }}>
                <StyledButton
                  color="primary"
                  disabled={this.handleIsDisabled()}
                  fullWidth
                  id="create-team-btn"
                  onClick={this.handleClickDone}
                  title={i18n.generics.doneLabel}
                >
                  {i18n.generics.doneLabel}
                </StyledButton>
              </Col>
            </Row>
          </Container>
        </Footer>
      </Modal>
    )
      : (
        <ModalConfirmation
          acceptLabel={i18n.pageTeams.createTeam.confirmation.acceptLabel}
          barClassName={styles.bar}
          confirmationMessages={i18n.pageTeams.createTeam.confirmation.messages}
          confirmationTitle={i18n.pageTeams.createTeam.confirmation.title}
          onAccept={this.handleCreateEdit}
        />
      );
  }
}

const mapStateToProps = (state) => ({
  profile: sessionSelectors.currentProfile(state),
  team: teamsSelectors.team(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...accountActions,
    ...networkActions,
    ...teamsActions,
  }, dispatch),
});

ModalCreateTeam.propTypes = {
  actions: ACTIONS.isRequired,
  edit: PropTypes.bool,
  i18n: I18N.isRequired,
  members: MEMBERS,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  profile: PERSON.isRequired,
  team: TEAM.isRequired,
};

ModalCreateTeam.defaultProps = {
  edit: false,
  members: null,
  onSuccess: null,
};

export default Translation(connect(mapStateToProps, mapDispatchToProps)(ModalCreateTeam), ['pageTeams', 'generics']);
