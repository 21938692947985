/**
 * @description Settings for training videos controller
 * @module API controller / Training Videos
 */
module.exports = {
  getAll: {
    /**
     * @description Get request settings (gamification endpoint - get all trainings information)
     * @param {number} accountId
     * @returns {object} request settings
     */
    settings: (accountId) => ({
      endpoint: `/account/${accountId}/gamification`,
      method: 'GET',
    }),
  },

  completeVideo: {
    /**
     * @description Get request settings (gamification endpoint - complete video)
     * @param {object} options
     * @param {number} options.accountId
     * @param {string} options.videoName
     * @returns {object} request settings
     */
    settings: ({ accountId, videoName: name }) => ({
      endpoint: `/account/${accountId}/gamification/watched`,
      body: {
        name,
      },
      method: 'POST',
    }),
  },
};
