const NAME_SPACE = 'Assessment';

const constants = {
  ADD_NEW_ANSWER: `${NAME_SPACE}/ADD_NEW_ANSWER`,
  FETCH_ASSESSMENT_FAILURE: `${NAME_SPACE}/FETCH_ASSESSMENT_FAILURE`,
  FETCH_ASSESSMENT_REQUEST: `${NAME_SPACE}/FETCH_ASSESSMENT_REQUEST`,
  FETCH_ASSESSMENT_SUCCESS: `${NAME_SPACE}/FETCH_ASSESSMENT_SUCCESS`,
  FETCH_ASSESSMENT: `${NAME_SPACE}/FETCH_ASSESSMENT`,
  FETCH_SAVING_FAILURE: `${NAME_SPACE}/FETCH_SAVING_FAILURE`,
  FETCH_SAVING_REQUEST: `${NAME_SPACE}/FETCH_SAVING_REQUEST`,
  FETCH_SAVING_SUCCESS: `${NAME_SPACE}/FETCH_SAVING_SUCCESS`,
  FETCH_SAVING: `${NAME_SPACE}/FETCH_SAVING`,
  SET_NEXT_QUESTION: `${NAME_SPACE}/SET_NEXT_QUESTION`,
  SET_TIEBREAK: `${NAME_SPACE}/SET_TIEBREAK`,
  UPDATE_STATE: `${NAME_SPACE}/UPDATE_STATE`,
};

export default constants;
