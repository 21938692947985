import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';

import { ACTIONS, I18N, NETWORK_DETAILS, PROFILE } from 'constants/props';
import { LEARNING_ACCOUNT_TYPE } from 'constants/accountTypes';
import InfoTooltip from 'generics/InfoTooltip';
import ProfileDetail from 'components/ProfileDetail';
import Translation from 'generics/Translation';

import accountProfileStyles from 'generics/AccountProfile/AccountProfile.scss';

/**
 *  TODO: Add breadcrumbs for MyProfile / Edit here
 */
function getBreadcrumbs() {
  return null;
}

/**
 * MyProfile component
 * @extends Component
 */
const MyProfile = ({
  i18n,
  networkActions,
  networkDetails,
  notificationActions,
  profile,
  profileActions,
  sessionActions,
  userActions,
}) => {
  const {
    account,
    isPublic,
  } = profile;

  const {
    accountType,
    isPersonal,
  } = account;

  const isLearningAccount = accountType === LEARNING_ACCOUNT_TYPE;
  /**
   * Handles set privacy event
   */
  const handleSetPrivacy = () => {
    sessionActions.setProfilePrivacy(!isPublic);
  };

  /**
   * Gets Account Profile Data
   */
  const handleGetAccountProfileData = () => {
    if (!networkDetails) {
      return [];
    }

    const {
      numberOfConnections,
      numberOfTeamsCreated,
    } = networkDetails;

    return [
      {
        children: isLearningAccount
          ? null
          : numberOfTeamsCreated,
        label: i18n.myProfile.numberOfTeams,
      },
      {
        children: isLearningAccount
          ? null
          : numberOfConnections,
        label: i18n.myProfile.numberOfConnections,
      },
      {
        children: (isPersonal || isLearningAccount)
          ? null
          : (
            <div className={accountProfileStyles['privacy-container']}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={!isPublic}
                    color="primary"
                    onClick={handleSetPrivacy}
                    title={i18n.myProfile.makeProfilePrivate}
                  />
                )}
                label={(
                  <div className={accountProfileStyles.privacy}>
                    <div className={accountProfileStyles.privacy__title}>
                      {i18n.myProfile.makeProfilePrivate}
                    </div>
                    <p className={accountProfileStyles['privacy__sub-title']}>
                      {i18n.myProfile.privacyDescription}
                    </p>
                  </div>
                )}
              />
              <InfoTooltip
                className={accountProfileStyles['privacy-tooltip']}
                placement="top"
                text={i18n.myProfile.privacyTooltip}
              />
            </div>
          ),
        label: i18n.myProfile.privacy,
      },
    ];
  };

  /**
   * Component main render
   */
  return (
    <ProfileDetail
      breadcrumbs={getBreadcrumbs()}
      canEdit
      isLearningAccount={isLearningAccount}
      notificationActions={notificationActions}
      onFetchEditProfile={profileActions.fetchEditProfile}
      onFetchNetworkDetails={networkActions.fetchNetworkDetails}
      onGetAccountProfileData={handleGetAccountProfileData}
      onUpdateAvatar={profileActions.updateAvatar}
      profile={profile}
      userActions={userActions}
    />
  );
};

MyProfile.propTypes = {
  i18n: I18N.isRequired,
  networkActions: ACTIONS.isRequired,
  networkDetails: NETWORK_DETAILS,
  notificationActions: ACTIONS.isRequired,
  profile: PROFILE.isRequired,
  profileActions: ACTIONS.isRequired,
  sessionActions: ACTIONS.isRequired,
  userActions: ACTIONS.isRequired,
};

MyProfile.defaultProps = {
  networkDetails: {
    numberOfConnections: 0,
    numberOfTeamsCreated: 0,
  },
};

export default Translation(MyProfile, ['myProfile']);
