import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/teams/constants';
import api from 'api';

// Actions

export const fetchCreateTeamFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_CREATE_TEAM_FAILURE,
});

export const fetchCreateTeamRequest = () => ({
  type: actionTypes.FETCH_CREATE_TEAM_REQUEST,
});

export const fetchCreateTeamSuccess = (response) => ({
  payload: response,
  type: actionTypes.FETCH_CREATE_TEAM_SUCCESS,
});

// Thunks

export const fetchCreateTeam = (options, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());

    dispatch(fetchCreateTeamRequest());

    const handleResponse = (response) => {
      dispatch(fetchCreateTeamSuccess(response));
      if (onSuccess) {
        onSuccess(response);
      }
    };

    const handleError = ({ error }) => {
      dispatch(fetchCreateTeamFailure(error));
      if (onError) {
        onError(error);
      } else {
        throw error;
      }
    };

    return api.teams.createTeam({ accountId, ...options }, handleResponse, handleError);
  };

  thunk.type = actionTypes.FETCH_CREATE_TEAM;

  return thunk;
};

export default fetchCreateTeam;
