import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { I18N, PROFILE } from 'constants/props';
import { SORT_DIRECTION, TEAM_GRID_SORT_COLUMNS } from 'constants/sort';
import Icon from 'generics/Icon';
import Translation from 'generics/Translation';

import styles from './TeamGridHeader.scss';
import TeamGridRow from '../TeamGridRow';

const DEFAULT_DYNAMICS = ['Explore', 'Excite', 'Examine', 'Execute'];

const TeamGridHeader = ({
  className,
  dynamics,
  i18n,
  onClick,
  onSort,
  profile,
  selected,
  sort,
}) => {
  const handleSort = (column) => {
    const {
      column: oldColumn,
      direction: oldDirection,
    } = sort;

    if (onSort) {
      const direction = column === oldColumn && oldDirection === SORT_DIRECTION.ASC
        ? SORT_DIRECTION.DESC
        : SORT_DIRECTION.ASC;

      onSort({ column, direction });
    }
  };

  const handleKeyPress = (event, criteria, index) => {
    switch (event.key) {
      case 'Enter':
        handleSort(criteria, index);
        break;
      default:
    }
  };

  const renderDynamics = () => {
    const {
      column: sortColumn,
      direction: sortDirection,
    } = sort;

    return (
      dynamics && dynamics.map((dynamic, index) => {
        const dynamicValue = TEAM_GRID_SORT_COLUMNS[`E${(index + 1)}`];
        return (
          <div
            className={classnames(
              styles.cell,
              styles['cell--header'],
              styles['cell--dynamic'],
              styles[`cell--dynamic-${index}`],
            )}
            key={dynamic}
            onClick={() => { handleSort(dynamicValue); }}
            onKeyPress={(event) => { handleKeyPress(event, dynamicValue); }}
            role="button"
            tabIndex="0"
          >
            <div
              className={classnames(
                styles.title,
                { [styles['is-visible']]: sortColumn === dynamicValue },
              )}
            >
              <span
                className={styles.title__dynamic}
                title={dynamic}
              >
                {dynamic}
              </span>
              <Icon.Stroke7
                className={classnames(
                  styles.title__icon,
                  { [styles['is-up']]: sortDirection === SORT_DIRECTION.ASC },
                )}
                name="angle-down"
              />
            </div>
          </div>
        );
      })
    );
  };

  if (!profile) {
    return null;
  }

  const {
    column: sortColumn,
    direction: sortDirection,
  } = sort;

  return (
    <div
      className={classnames(
        styles.header,
        className,
      )}
    >
      <div className={styles.row}>
        <div
          className={classnames(
            styles.cell,
            styles['cell--header'],
            styles['cell--name'],
          )}
        >
          <div
            className={classnames(
              styles.title,
              { [styles['is-visible']]: sortColumn === TEAM_GRID_SORT_COLUMNS.NAME },
            )}
            onClick={() => { handleSort(TEAM_GRID_SORT_COLUMNS.NAME); }}
            onKeyPress={(event) => { handleKeyPress(event, TEAM_GRID_SORT_COLUMNS.NAME); }}
            role="button"
            tabIndex="0"
          >
            <span
              className={styles.title__name}
              title={i18n.generics.formFields.name}
            >
              {i18n.generics.formFields.name}
            </span>
            <Icon.Stroke7
              className={classnames(
                styles.title__icon,
                { [styles['is-up']]: sortDirection === SORT_DIRECTION.ASC },
              )}
              name="angle-down"
            />
          </div>
        </div>
        <div className={styles['cell--balloons']}>
          {
            renderDynamics()
          }
        </div>
      </div>

      <TeamGridRow
        className={styles['team-row']}
        onClick={onClick}
        profile={profile}
        selected={selected}
      />
    </div>
  );
};

TeamGridHeader.propTypes = {
  className: PropTypes.string,
  dynamics: PropTypes.arrayOf(PropTypes.string),
  i18n: I18N.isRequired,
  onClick: PropTypes.func,
  onSort: PropTypes.func,
  peachBall: PropTypes.shape({}),
  profile: PROFILE,
  score: PropTypes.number,
  selected: PropTypes.bool,
  sort: PropTypes.shape({
    column: PropTypes.string,
    direction: PropTypes.string,
  }),
};

TeamGridHeader.defaultProps = {
  className: null,
  dynamics: DEFAULT_DYNAMICS,
  onClick: undefined,
  onSort: undefined,
  peachBall: {},
  profile: null,
  score: null,
  selected: false,
  sort: {},
};

export default Translation(TeamGridHeader, ['generics']);
