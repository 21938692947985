/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, size } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ACTIONS, I18N } from 'constants/props';
import { Card, Footer, Header, Main } from 'generics/Card';
import { COLORS } from 'constants/colors';
import { StyledButton } from 'generics/StyledFormComponents';
import api from 'api';
import FileReader from 'generics/FileReader';
import Icon from 'generics/Icon';
import Translation from 'generics/Translation';

import BadgeResults from '../BadgeResults';
import ModalErrors from '../ModalErrors';
import styles from './ModalBulkDelete.scss';

const STATUS = {
  ERROR: 'error',
  INITIAL: 'initial',
  SUCCESS: 'success',
};

const FILE_EXTENSION_REGEX = /^.*\.csv$/;
const FILE_TYPES = ['text/csv'];

const ModalBulkDelete = ({
  accountId,
  i18n,
  onClose,
  onComplete,
  pageActions,
}) => {
  const [failedMembersList, setFailedMembersList] = useState(null);
  const [file, setFile] = useState(null);
  const [hasFileError, setHasFileError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [messages, setMessages] = useState(null);
  const [removedMembersList, setRemovedMembersList] = useState(null);
  const [status, setStatus] = useState(STATUS.INITIAL);

  useEffect(() => {
    pageActions.switchModalView();

    return () => {
      pageActions.switchModalView();
    };
  }, []);

  const handleClose = () => {
    onClose();
  };

  /**
   * @description handles exceptions when deleting members
   */

  const handleOnFail = ({ error }) => {
    let newMessages = [];

    if (size(error.message) > 0) {
      newMessages = [error.message[0]];
    } else if (error.label) {
      newMessages = [i18n.notifications[error.label]];
    } else if (size(error.details) > 0) {
      newMessages = [
        i18n.pageAdministration.bulkDelete.fieldErrors,
        ...error.details.map(({ field, errorMessage }) => `${field}: ${errorMessage}`),
      ];
    }

    if (isEmpty(newMessages)) {
      handleClose();
    }

    setIsFetching(false);
    setMessages(newMessages);
    setStatus(STATUS.ERROR);
  };

  const handleDelete = () => {
    setIsFetching(true);

    const onSuccess = ({ success = [], fails = [] }) => {
      const newFailedMembersList = fails.map((email) => ({ email }));
      const newRemovedMembersList = success.map((email) => ({ email }));

      if (!isEmpty(newFailedMembersList) || !isEmpty(newRemovedMembersList)) {
        setFailedMembersList(newFailedMembersList);
        setIsFetching(false);
        setRemovedMembersList(newRemovedMembersList);
        setStatus(STATUS.SUCCESS);

        if (onComplete) {
          onComplete();
        }
      } else {
        setIsFetching(false);
        setMessages([i18n.pageAdministration.bulkDelete.fileError]);
        setStatus(STATUS.ERROR);
      }
    };

    api.profiles.bulkDelete(
      { accountId, file },
      onSuccess,
      handleOnFail,
    );
  };

  const handleLoadFile = (uploadedFile) => {
    const newHasFileError = !FILE_EXTENSION_REGEX.test(uploadedFile.name);
    const newFile = !hasFileError ? uploadedFile : null;
    setHasFileError(newHasFileError);
    setFile(newFile);
  };

  const renderCancelButton = (statusParam) => {
    if (statusParam !== STATUS.INITIAL) {
      return null;
    }

    return (
      <StyledButton
        className={styles.cancel}
        color="default"
        onClick={handleClose}
        title={i18n.generics.cancelLabel}
        variant="text"
      >
        {i18n.generics.cancelLabel}
      </StyledButton>
    );
  };

  const renderDoneButton = () => {
    let label;
    let isDisabled;
    let onClick;

    if (status === STATUS.INITIAL) {
      label = i18n.generics.deleteLabel;
      isDisabled = !file || hasFileError;
      onClick = handleDelete;
    } else {
      label = i18n.generics.doneLabel;
      isDisabled = false;
      onClick = handleClose;
    }

    return (
      <StyledButton
        className={styles.delete}
        disabled={isDisabled}
        id="button-modal-bulk-delete"
        onClick={onClick}
        color="primary"
        title={label}
      >
        {label}
      </StyledButton>
    );
  };

  const renderTitle = () => {
    switch (status) {
      case STATUS.ERROR:
        return i18n.generics.error;
      case STATUS.SUCCESS:
        return isEmpty(removedMembersList)
          ? i18n.generics.error
          : i18n.generics.success;
      default:
        return i18n.pageAdministration.bulkDelete.title;
    }
  };

  return (
    <div className={styles.modal}>
      <Card
        barBackgroundColor={COLORS.primaryBlue.rgba}
        className={styles.card}
        isLoading={isFetching}
      >
        <Header className={styles.header}>
          <h1>
            {renderTitle()}
          </h1>
        </Header>
        <Icon.Stroke7
          className={styles.close}
          name="close"
          onClick={handleClose}
          title={i18n.generics.close}
        />
        <Main className={styles.main}>
          {
            status === STATUS.SUCCESS && (
              <BadgeResults
                failed={failedMembersList}
                failedTitle={i18n.pageAdministration.bulkDelete.resultMessage.failed}
                removed={removedMembersList}
                removedTitle={i18n.pageAdministration.bulkDelete.resultMessage.removed}
              />
            )
          }
          {
            status === STATUS.INITIAL && (
              <Container className={styles.informationFileReader}>
                <FileReader
                  descriptionMessage={i18n.pageAdministration.bulkDelete.fileReaderDescription}
                  fileTypes={FILE_TYPES}
                  onLoadFile={handleLoadFile}
                />
                {
                  hasFileError && (
                    <p className={styles.error}>
                      {i18n.pageAdministration.bulkDelete.fileFormatError}
                    </p>
                  )
                }
              </Container>
            )
          }
          {
            status === STATUS.ERROR && <ModalErrors errors={messages} />
          }
        </Main>

        <Footer>
          <Row>
            <Col xs="6">
              {renderCancelButton(status)}
            </Col>
            <Col xs="6">
              {renderDoneButton(status)}
            </Col>
          </Row>
        </Footer>
      </Card>
    </div>
  );
};

ModalBulkDelete.propTypes = {
  accountId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  pageActions: ACTIONS.isRequired,
};

ModalBulkDelete.defaultProps = {
  onClose: undefined,
  onComplete: undefined,
};

export default Translation(ModalBulkDelete, ['pageAdministration', 'notifications', 'generics']);
