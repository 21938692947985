/**
 * @description Bundles controller endpoints settings
 * @module API controller / Bundles
 */

module.exports = {
  getBundles: {
    ignoreNotFound: true,

    /**
     * @description (Get Settings - Get Bundles Endpoint)
     * @param {number} accountId - The account ID.
     * @returns {object} - Request settings.
     */
    settings: (accountId) => ({
      endpoint: `/account/${accountId}/bundles/bundle_allocation`,
      method: 'GET',
    }),
  },

  getBundleAllocationDetails: {
    /**
     * @description Get request settings for bundle allocation details.
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.bundleId - - The bundle ID.
     * @returns {object} - Request settings.
     */
    settings({ accountId, bundleId }) {
      return {
        endpoint: `/account/${accountId}/bundles/bundle_allocation_details/${bundleId}`,
        method: 'GET',
      };
    },
  },
};
