import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { COLORS } from 'constants/colors';

const lightGray = COLORS.grey3.rgba;
const borderColor = 'rgba(155, 155, 155, 0.5)';

const borderBottom = `1px solid ${borderColor}`;

const useStyles = makeStyles({
  root: {
    '& .MuiFormLabel-root': {
      color: lightGray,
    },
    '& .MuiFormLabel-asterisk': {
      color: 'red',
    },
    '& .MuiInput-underline:before': {
      borderBottom,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom,
    },
  },
});

const StyledCheckbox = ({ ariaLabel, className, ...rest }) => {
  const classes = useStyles();
  return (
    <Checkbox
      inputProps={{
        'aria-label': ariaLabel,
      }}
      className={classnames(className, classes.root)}
      fullWidth
      {...rest}
    />
  );
};

StyledCheckbox.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
};

StyledCheckbox.defaultProps = {
  ariaLabel: '',
  className: null,
};

export default StyledCheckbox;
