export const en = {
  accountInformation: {
    editEmailLabel: 'Update Email',
    confirmationCodeEmailLabel: 'We have sent an email to the new email address with a code required to complete the update.',
    validateCodeLabel: 'Code validation for email update',
    codeLabel: 'Code',
    emailUpdatedLabel: 'Email has been successfully updated',
    header: {
      cancelButtonLabel: 'Cancel',
      editButtonLabel: 'Edit',
      title: 'Account Information',
    },
  },
  accountInformationForm: {
    employeeInformation: 'Employee Information',
    errors: {
      phone: {
        invalid: 'Please enter a valid phone.',
      },
      postalCode: {
        invalid: 'Please enter a valid ZIP or postal code.',
        length: 'Postal code should be composed by 5 digits.',
      },
    },
    fields: {
      city: 'City',
      company: 'Company',
      country: 'Country',
      department: 'Department',
      division: 'Division',
      emailAddress: 'Email address',
      genderPronoun: 'Pronoun Preference',
      jobTitle: 'Job title',
      mobilePhone: 'Mobile phone',
      name: 'Name',
      organization: 'Organization',
      selectCountry: 'Select Country',
      state: 'State',
      street: 'Street',
      workPhone: 'Work phone',
      zipCode: 'Zip code',
    },
    personalInformation: 'Personal Information',
    saveButtonLabel: 'SAVE',
    selectGender: 'Select Gender Pronoun',
    studentInformation: 'Student Information',
  },
  accountType: 'Account type',
  makeProfilePrivate: 'Make profile private',
  numberOfConnections: 'My number of connections',
  numberOfTeams: 'Number of teams I have created',
  privacy: 'Privacy',
  privacyTooltip: 'If your profile is set to private, it is only visible to yourself and any member that you have a direct connection with via the Share Profile. A profile that is not set to private is visible to the entire network.',
  shareDescription: '(5D results only shared with the people you\'ve shared your profile with)',
};

export const es = en;
