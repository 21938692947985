import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as sessionActions from 'app_modules/session/actions';
import PageSSOLogin from 'components/PageSSOLogin';

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
});

export default connect(null, mapDispatchToProps)(PageSSOLogin);
