/* eslint-disable react-hooks/exhaustive-deps */
import DocumentTitle from 'react-document-title';
import React, { useEffect } from 'react';

import { ACTIONS, MATCH } from 'constants/props';
import ErrorCard from 'generics/ErrorCard';
import LinkedinErrorContent from 'components/tenants/linkedin/ErrorContent';

const PageSSOLoginError = ({
  match,
  sessionActions,
}) => {
  useEffect(() => {
    sessionActions.logout(true);
  }, []);

  const { company, code = '0' } = match.params;

  const errorCode = parseInt(code, 10);

  const content = company === 'linkedin' ? (
    <LinkedinErrorContent errorCode={errorCode} />
  ) : null;

  return (
    <DocumentTitle title="Simpli5® - SSO Login Error Page">
      <ErrorCard errorCode={errorCode}>
        {content}
      </ErrorCard>
    </DocumentTitle>
  );
};

PageSSOLoginError.propTypes = {
  match: MATCH.isRequired,
  sessionActions: ACTIONS.isRequired,
};

export default PageSSOLoginError;
