/* eslint-disable react-hooks/exhaustive-deps */
import { get, isNil } from 'lodash';
import { useRef, useState } from 'react';

import { PEOPLE_FILTERS, TEAM_FILTERS } from 'constants/searchFilters';

const useSearch = ({
  defaultFilterOption,
  filters,
  isDepartmentEnabled,
  isTeamSearch,
  onReset,
  onSearch,
  value,
}) => {
  const initFilter = () => ({
    filterBy: defaultFilterOption
      || (isTeamSearch ? TEAM_FILTERS[0].id : PEOPLE_FILTERS(isDepartmentEnabled)[1].id),
    value: value || null,
  });

  const initOtherFilters = () => (filters ? filters.map(({
    filterLabel,
    options,
  }) => ({
    filterBy: options[0].id,
    filterLabel,
  })) : null);

  const [filter, setFilter] = useState(initFilter());
  const [filterOptions] = useState(isTeamSearch
    ? [...TEAM_FILTERS]
    : [...PEOPLE_FILTERS(isDepartmentEnabled)],
  );

  const [otherFilters, setOtherFilters] = useState(initOtherFilters());

  const searchRef = useRef(null);

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    onSearch(filter, otherFilters);
  };

  const handleChange = (name, newValue) => {
    setFilter({ ...filter, [name]: newValue });
  };

  const handleOtherFilterChange = (newValue, position) => {
    const newOtherFilters = [...otherFilters];
    newOtherFilters[position] = { ...newOtherFilters[position], filterBy: newValue };
    setOtherFilters(newOtherFilters);
  };

  const reset = (fn) => {
    if (searchRef) {
      searchRef.current.value = '';
      handleChange('value', null, fn);
      setFilter(initFilter());
      setOtherFilters(initOtherFilters());
      onSearch({ ...filter, value: null });

      if (onReset) {
        onReset();
      }
    }
  };

  const resetRef = () => {
    if (searchRef) {
      searchRef.current.value = '';
    }
  };

  const selectedValue = filter.value ? { value: filter.value } : null;
  const hasValue = !!get(searchRef, 'current.value');
  const isValid = !isNil(filter.value) || !isNil(otherFilters?.find((f) => f.filterBy));

  return {
    filter,
    filterOptions,
    handleChange,
    handleOtherFilterChange,
    handleSubmit,
    hasValue,
    isValid,
    otherFilters,
    reset,
    resetRef,
    searchRef,
    selectedValue,
  };
};

export default useSearch;
