import { CircularProgress } from '@material-ui/core';
import { Col, Container, Row } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { COL, I18N, NEWS } from 'constants/props';

import NewsListItem from '../NewsListItem/NewsListItem';
import styles from './NewsList.scss';

const NewsList = ({
  cardClassName,
  className,
  col,
  i18n,
  isLoading,
  news,
}) => (
  <div className={className}>
    <div className={styles['module-list']}>
      <Container fluid>
        <Row>
          <Col>
            <h3 className={styles['news-title']}>
              <span>
                {i18n.news.list.title}
              </span>
            </h3>
          </Col>
        </Row>
        <Row>
          {
            isLoading && (
              <Col>
                <div
                  className={classnames(
                    styles.card,
                    styles.loader,
                  )}
                >
                  <CircularProgress
                    size={60}
                    thickness={7}
                  />
                </div>
              </Col>
            )
          }
          {
            news && news.length === 0 && (
              <Col>
                <div
                  className={classnames(
                    styles.card,
                    styles.empty,
                  )}
                >
                  <span>
                    {i18n.news.list.empty}
                  </span>
                </div>
              </Col>
            )
          }

          {
            news && news.length > 0 && news.map((resourcesItem, index) => {
              const {
                createdAt,
                description,
                id,
                title,
                image: {
                  url: imageUrl,
                },
              } = resourcesItem;

              return (
                <Col
                  key={id}
                  {...col}
                >
                  <NewsListItem
                    ariaLabel={`${index === 0 ? ('Simpli5® News List.') : ''} Item ${index + 1} of ${news.length}`}
                    className={classnames(
                      styles.card,
                      cardClassName,
                    )}
                    date={createdAt}
                    description={description}
                    focusId={index === 0 ? 'main' : null}
                    imageUrl={imageUrl}
                    title={title}
                  />
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </div>
  </div>
);

NewsList.propTypes = {
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  col: COL,
  i18n: I18N.isRequired,
  isLoading: PropTypes.bool.isRequired,
  news: NEWS,
};

NewsList.defaultProps = {
  cardClassName: null,
  className: null,
  col: null,
  news: [],
};

export default NewsList;
