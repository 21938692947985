/* eslint-disable react-hooks/exhaustive-deps */
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { StyledTextField } from 'generics/StyledFormComponents';
import Icon from 'generics/Icon';

import styles from './Search.scss';
import useSearch from './useSearch';

const Search = ({
  className,
  error,
  debounce: isDebounce,
  floatingLabelText,
  helperText,
  innerRef,
  onBlur,
  onChange,
  onFocus,
  onReset,
  onSearch,
  value,
}) => {
  const {
    hasValue,
    onChangeFn,
    reset,
    resetRef,
    searchRef,
    selectedValue,
  } = useSearch({ isDebounce, onChange, onReset, onSearch, value });

  useEffect(() => {
    if (innerRef) {
      innerRef({ reset, resetRef });
    }
  }, [innerRef]);

  return (
    <form
      className={classnames(
        styles.search,
        className,
      )}
      onSubmit={(event) => { event.preventDefault(); }}
    >
      <StyledTextField
        color="primary"
        className={styles['search-field']}
        error={error}
        helperText={helperText}
        label={floatingLabelText}
        fullWidth
        onBlur={onBlur}
        onChange={(e) => onChangeFn(e.target.value)}
        onFocus={onFocus}
        inputRef={searchRef}
        title={floatingLabelText}
        {...selectedValue}
      />
      <Icon.Stroke7
        className={classnames(
          styles.close,
          { [styles.hide]: !hasValue },
        )}
        name="close"
        onClick={() => reset()}
      />
    </form>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  debounce: PropTypes.bool,
  error: PropTypes.string,
  floatingLabelText: PropTypes.string,
  helperText: PropTypes.string,
  innerRef: () => {},
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onReset: PropTypes.func,
  onSearch: PropTypes.func,
  value: PropTypes.string,
};

Search.defaultProps = {
  className: null,
  debounce: true,
  error: null,
  floatingLabelText: null,
  helperText: null,
  innerRef: null,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  onReset: undefined,
  onSearch: undefined,
  value: null,
};

export default Search;
