import { Person, Team } from 'classes';

// team
export function team(state) {
  return new Team({
    ...state.teams.currentTeam.details,
    modules: state.teams.currentTeam.modules.list,
  });
}

// details
export const teamDetails = (state) => state.teams.currentTeam.details;

export const teamMembers = (state) => state.teams.currentTeam.teamMembers.map(
  (profile) => new Person(profile),
);

export const membersPageIndex = (state) => state.teams.currentTeam.membersPageIndex;

export const teamId = (state) => state.teams.currentTeam.details.id;

export const teamOwner = (state) => new Person(state.teams.currentTeam.details.owner);

export const isFetchingTeamDetails = (state) => state.teams.currentTeam.isFetchingDetails;

export const isFetchingTeamMembers = (state) => state.teams.currentTeam.isFetchingMembers;

// meta
export const teamMeta = (state) => state.teams.currentTeam.meta;

// modules
export const modules = (state) => state.teams.currentTeam.modules.list;

// teams
export const corporateTeams = (state) => ({
  isFetching: state.teams.corporateTeams.isFetching,
  list: state.teams.corporateTeams.list.map((teamItem) => new Team(teamItem)),
  meta: state.teams.corporateTeams.meta,
});

export const othersTeams = (state) => ({
  isFetching: state.teams.othersTeams.isFetching,
  list: state.teams.othersTeams.list.map((teamItem) => new Team(teamItem)),
  meta: state.teams.othersTeams.meta,
});

export const privateTeams = (state) => ({
  isFetching: state.teams.privateTeams.isFetching,
  list: state.teams.privateTeams.list.map((teamItem) => new Team(teamItem)),
  meta: state.teams.privateTeams.meta,
});

export const publishedTeams = (state) => ({
  isFetching: state.teams.publishedTeams.isFetching,
  list: state.teams.publishedTeams.list.map((teamItem) => new Team(teamItem)),
  meta: state.teams.publishedTeams.meta,
});
