import { Col, Container, Row } from 'reactstrap';
import { sprintf } from 'sprintf-js';
import PropTypes from 'prop-types';
import React from 'react';

import { Footer, Header } from 'generics/Card';
import { I18N } from 'constants/props';
import { StyledButton, StyledCheckbox } from 'generics/StyledFormComponents';
import Translation from 'generics/Translation';

import styles from './FormToggleRenewalEmailNotifications.scss';
import useFormToggleRenewalEmailNotifications from './useFormToggleRenewalEmailNotifications';

const FormToggleRenewalEmailNotifications = ({
  hasSubscriptionNotifications,
  i18n,
  onClose,
  onSubmit,
  renewalDate,
}) => {
  const {
    enableSubscriptionNotifications,
    onChange,
  } = useFormToggleRenewalEmailNotifications({ hasSubscriptionNotifications, i18n, onSubmit });

  return (
    <>
      <Header>
        <h1
          className={styles.title}
          title={i18n.subscriptions.emailNotifications.title}
        >
          {i18n.subscriptions.emailNotifications.title}
        </h1>
        <p
          /* eslint-disable react/no-danger */
          dangerouslySetInnerHTML={{
            __html: sprintf(i18n.subscriptions.emailNotifications.description,
              {
                renewalDate,
              }),
          }}
          /* eslint-enable */
        />
        <div className={styles['checkbox-container']}>
          <p>{i18n.subscriptions.emailNotifications.checkboxLabels[0]}</p>
          <StyledCheckbox
            className={styles.checkbox}
            checked={enableSubscriptionNotifications}
            color="primary"
            onChange={() => onChange(!enableSubscriptionNotifications)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                onChange(!enableSubscriptionNotifications);
              }
            }}
          />
        </div>
        <p>{i18n.subscriptions.emailNotifications.checkboxLabels[1]}</p>
      </Header>
      <Footer>
        <Container>
          <Row>
            <Col xs="3" md="2">
              <StyledButton
                color="default"
                fullWidth
                onClick={() => onClose()}
                title={i18n.generics.cancelLabel}
                variant="text"
              >
                {i18n.generics.cancelLabel}
              </StyledButton>
            </Col>

            <Col xs={{ size: 3, offset: 6 }} md={{ size: 3, offset: 7 }}>
              <StyledButton
                color="primary"
                fullWidth
                id="save-video-customization-btn"
                onClick={() => onSubmit(enableSubscriptionNotifications)}
                title={i18n.generics.submitLabel}
              >
                {i18n.generics.submitLabel}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </Footer>
    </>
  );
};

FormToggleRenewalEmailNotifications.propTypes = {
  hasSubscriptionNotifications: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  renewalDate: PropTypes.string.isRequired,
  i18n: I18N.isRequired,
};

export default Translation(FormToggleRenewalEmailNotifications, ['generics', 'subscriptions']);
