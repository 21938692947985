const NAME_SPACE = 'Invites';

const constants = {
  CLEAR_INVITATION: `${NAME_SPACE}/CLEAR_INVITATION`,
  FETCH_ACCEPT_INVITATION_FAILURE: `${NAME_SPACE}/FETCH_ACCEPT_INVITATION_FAILURE`,
  FETCH_ACCEPT_INVITATION_REQUEST: `${NAME_SPACE}/FETCH_ACCEPT_INVITATION_REQUEST`,
  FETCH_ACCEPT_INVITATION_SUCCESS: `${NAME_SPACE}/FETCH_ACCEPT_INVITATION_SUCCESS`,
  FETCH_DECLINE_INVITATION_FAILURE: `${NAME_SPACE}/FETCH_DECLINE_INVITATION_FAILURE`,
  FETCH_DECLINE_INVITATION_REQUEST: `${NAME_SPACE}/FETCH_DECLINE_INVITATION_REQUEST`,
  FETCH_DECLINE_INVITATION_SUCCESS: `${NAME_SPACE}/FETCH_DECLINE_INVITATION_SUCCESS`,
  FETCH_INVALIDATE_INVITATION_FAILURE: `${NAME_SPACE}/FETCH_INVALIDATE_INVITATION_FAILURE`,
  FETCH_INVALIDATE_INVITATION_REQUEST: `${NAME_SPACE}/FETCH_INVALIDATE_INVITATION_REQUEST`,
  FETCH_INVALIDATE_INVITATION_SUCCESS: `${NAME_SPACE}/FETCH_INVALIDATE_INVITATION_SUCCESS`,
  FETCH_INVALIDATE_INVITATION: `${NAME_SPACE}/FETCH_INVALIDATE_INVITATION`,
  FETCH_INVITATION_FAILURE: `${NAME_SPACE}/FETCH_INVITATION_FAILURE`,
  FETCH_INVITATION_REQUEST: `${NAME_SPACE}/FETCH_INVITATION_REQUEST`,
  FETCH_INVITATION_SUCCESS: `${NAME_SPACE}/FETCH_INVITATION_SUCCESS`,
  FETCH_SENT_INVITATIONS_FAILURE: `${NAME_SPACE}/FETCH_SENT_INVITATIONS_FAILURE`,
  FETCH_SENT_INVITATIONS_REQUEST: `${NAME_SPACE}/FETCH_SENT_INVITATIONS_REQUEST`,
  FETCH_SENT_INVITATIONS_SUCCESS_NO_RESULTS: `${NAME_SPACE}/FETCH_SENT_INVITATIONS_SUCCESS_NO_RESULTS`,
  FETCH_SENT_INVITATIONS_SUCCESS: `${NAME_SPACE}/FETCH_SENT_INVITATIONS_SUCCESS`,
  FETCH_SENT_INVITATIONS: `${NAME_SPACE}/FETCH_SENT_INVITATIONS`,
  TRIGGER_ACCEPT_INVITATION: `${NAME_SPACE}/TRIGGER_ACCEPT_INVITATION`,
  TRIGGER_DECLINE_INVITATION: `${NAME_SPACE}/TRIGGER_DECLINE_INVITATION`,
  TRIGGER_GET_INVITATION: `${NAME_SPACE}/TRIGGER_GET_INVITATION`,
};

export default constants;
