import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { sprintf } from 'sprintf-js';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import DocumentTitle from 'react-document-title';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { CUSTOMIZATIONS, I18N, INVITATION, MODULES, PERSON, VIDEOS } from 'constants/props';
import { LEARNING_ACCOUNT_TYPE } from 'constants/accountTypes';
import { ACCEPTED, PRE_ACCEPTED } from 'constants/invitationStatus';
import { VIDEO_MODULE, VIDEO_MODULE_SECOND } from 'constants/customizationModules';
import * as accountSelectors from 'app_modules/accounts/selectors';
import * as invitesSelectors from 'app_modules/invites/selectors';
import * as sessionSelectors from 'app_modules/session/selectors';
import * as userSelectors from 'app_modules/user/selectors';
import InvitationDialog from 'generics/InvitationDialog';
import ModulesList from 'generics/ModulesList';
import Translation from 'generics/Translation';
import VideoCard from 'generics/VideoCard';

import styles from './PagePersonalDashboard.scss';
import WelcomeBack from './components/WelcomeBack';

const PagePersonalDashboard = (props) => {
  const {
    accountId,
    customizations,
    i18n,
    invitation,
    isStudentWaitingResults,
    modules,
    profile,
    videos,
  } = props;

  const { account: { accountType, hideResultsUntil } } = profile || {};
  const isLearningAccount = accountType === LEARNING_ACCOUNT_TYPE;

  const firstVideo = videos?.length && videos?.find((video) => video.index === 1);
  const secondVideo = videos?.length && videos?.find((video) => video.index === 2);

  return (
    <DocumentTitle title="Simpli5® - My 5 Dynamics Dashboard">
      <article className={classnames(
        styles.page,
        { [styles['page--has-hidden-results-label']]: isStudentWaitingResults },
      )}
      >
        <Container>
          <Row>
            {!isStudentWaitingResults ? (
              <>
                <Col xs={12} lg={8}>
                  <WelcomeBack isLearningAccount={isLearningAccount} className={styles.card} />
                </Col>
                <Col xs={12} lg={4}>
                  <div className={styles['about-5-dynamics']}>
                    <VideoCard
                      accountId={accountId}
                      category={!isLearningAccount
                        ? i18n.pagePersonalDashboard.videos[0].category
                        : i18n.pagePersonalDashboard.videos[2].category}
                      createdAt={firstVideo?.createdAt}
                      description={customizations[VIDEO_MODULE].bodyContent
                        || firstVideo?.description}
                      embedCode={customizations[VIDEO_MODULE].embedCode || null}
                      src={firstVideo?.video.url}
                      title={customizations[VIDEO_MODULE].heading
                        || firstVideo?.title}
                      videoTitle={firstVideo?.title || ''}
                    />
                    <VideoCard
                      accountId={accountId}
                      category={!isLearningAccount
                        ? i18n.pagePersonalDashboard.videos[1].category
                        : i18n.pagePersonalDashboard.videos[3].category}
                      createdAt={secondVideo?.createdAt}
                      description={customizations[VIDEO_MODULE_SECOND].bodyContentSecond
                        || secondVideo?.description}
                      embedCode={customizations[VIDEO_MODULE_SECOND].embedCodeSecond || null}
                      src={secondVideo?.video.url}
                      title={customizations[VIDEO_MODULE_SECOND].headingSecond
                        || secondVideo?.title}
                      videoTitle={secondVideo?.title || ''}
                    />
                  </div>
                </Col>
              </>
            ) : (
              <Col xs="12">
                <h2 className={styles.hiddenResults}>
                  {sprintf(i18n.pagePersonalDashboard.hiddenResults, moment(new Date(hideResultsUntil)).utc().format('MM/DD/YYYY'))}
                </h2>
              </Col>
            )}
          </Row>

          {!isStudentWaitingResults && (
            <Row>
              <Col>
                <ModulesList
                  accountId={accountId}
                  col={{ xs: 12, md: 6, lg: 4 }}
                  goToUrl={{
                    menuItem: 'my-5-dynamics',
                    entity: profile,
                  }}
                  header={{
                    isUpperCase: false,
                    title: 'Apply 5 Dynamics',
                  }}
                  modules={modules}
                />
              </Col>
            </Row>
          )}
        </Container>

        {
          (!isEmpty(invitation))
            && (invitation.status !== PRE_ACCEPTED
                || invitation.status !== ACCEPTED)
            && (
            <InvitationDialog clearInvitationOnAccept />
          )
        }
      </article>
    </DocumentTitle>
  );
};

PagePersonalDashboard.propTypes = {
  accountId: PropTypes.number.isRequired,
  customizations: CUSTOMIZATIONS.isRequired,
  i18n: I18N.isRequired,
  invitation: INVITATION,
  isStudentWaitingResults: PropTypes.bool.isRequired,
  modules: MODULES.isRequired,
  profile: PERSON.isRequired,
  videos: VIDEOS.isRequired,
};

PagePersonalDashboard.defaultProps = {
  invitation: null,
};

const mapStateToProps = (state) => ({
  accountId: sessionSelectors.accountId(state),
  customizations: accountSelectors.customizations(state),
  invitation: invitesSelectors.invitation(state),
  isStudentWaitingResults: accountSelectors.isStudentWaitingResults(state),
  modules: sessionSelectors.modules(state),
  profile: sessionSelectors.currentProfile(state),
  videos: userSelectors.videos(state),
});

export default withRouter(connect(mapStateToProps)(Translation(PagePersonalDashboard, ['pagePersonalDashboard'])));
