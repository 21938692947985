import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ACTIONS, CHILDREN, HISTORY, I18N, ICON, STYLE } from 'constants/props';
import { Card } from 'generics/Card';
import { COLORS } from 'constants/colors';
import { StyledButton } from 'generics/StyledFormComponents';
import * as pageActions from 'app_modules/page/actions';
import InformationCardHeader from 'generics/InformationCardHeader';
import Modal from 'components/Modal';
import Translation from 'generics/Translation';

import styles from './ModulesListItem.scss';

const ModulesListItem = ({
  actions,
  category,
  categoryColor,
  children,
  className,
  description,
  disabled,
  goToUrl,
  history,
  i18n,
  icon,
  isComingSoon,
  modalCardClassName,
  onClick,
  showBullets,
  style,
  title,
  viewDetails,
  ...rest
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleClose = () => {
    setModalIsOpen(false);
  };

  const handleOpen = () => {
    if (disabled) {
      return;
    }

    setModalIsOpen(true);
  };

  const handleClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (goToUrl) {
      actions.setModule({
        bar: {
          backgroundColor: categoryColor,
        },
        styles: {
          backgroundColor: COLORS.primaryWhite.rgba,
        },
      });
      history.push(goToUrl);
    }

    if (children) {
      handleOpen();
    }
  };

  return (
    <Card
      barBackgroundColor={categoryColor}
      barClassName={styles.bar}
      category={category}
      className={classnames(
        styles.card,
        className,
        { [styles['card--is-not-clickeable']]: isComingSoon },
      )}
      disabled={disabled}
      onClick={onClick || handleClick}
      style={style}
      title={title}
      {...rest}
    >
      <InformationCardHeader
        className={styles.header}
        category={category}
        categoryColor={categoryColor}
        handleClick={handleClick}
        icon={icon}
        title={title}
      />

      <main className={styles.main}>
        {!showBullets && (
          <p>
            {description}
          </p>
        )}
        {
          showBullets && (
            <div
              className={classnames(
                'epic',
                styles['epic-content'],
              )}
              /* eslint-disable react/no-danger */
              dangerouslySetInnerHTML={{ __html: description }}
            /* eslint-enable */
            />
          )
        }
        {
          isComingSoon && (
            <div className={styles['commnig-soon']}>
              <StyledButton
                disabled
                title={i18n.modulesListItem.comingSoon}
              >
                {i18n.modulesListItem.comingSoon}
              </StyledButton>
            </div>
          )
        }
        <div
          className={styles['learn-more-container']}
        >
          <Link
            className={classnames(
              styles['learn-more'],
            )}
            onClick={onClick || handleClick}
            title={i18n.modulesListItem.learnMore}
          >
            {i18n.modulesListItem.learnMore}
          </Link>
        </div>
      </main>

      {
        viewDetails && (
          <footer className={styles.footer}>
            <span
              className={styles.link}
              title={i18n.generics.viewDetailsLabel}
            >
              {i18n.generics.viewDetailsLabel}
            </span>
          </footer>
        )
      }

      {
        modalIsOpen && children && (
          <Modal
            category={category}
            categoryColor={categoryColor}
            icon={icon}
            modalCardClassName={modalCardClassName}
            onClose={handleClose}
            title={title}
          >
            {children}
          </Modal>
        )
      }
    </Card>
  );
};

ModulesListItem.propTypes = {
  actions: ACTIONS.isRequired,
  category: PropTypes.string.isRequired,
  categoryColor: PropTypes.string.isRequired,
  children: CHILDREN,
  className: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  goToUrl: PropTypes.string,
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
  icon: ICON,
  isComingSoon: PropTypes.bool,
  modalCardClassName: PropTypes.string,
  onClick: PropTypes.func,
  showBullets: PropTypes.bool.isRequired,
  style: STYLE,
  title: PropTypes.string.isRequired,
  viewDetails: PropTypes.bool,
};

ModulesListItem.defaultProps = {
  children: null,
  className: null,
  description: undefined,
  disabled: false,
  goToUrl: undefined,
  icon: null,
  isComingSoon: false,
  modalCardClassName: undefined,
  onClick: undefined,
  style: undefined,
  viewDetails: false,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(pageActions, dispatch),
});

export default Translation(connect(null, mapDispatchToProps)(withRouter(ModulesListItem)), ['modulesListItem', 'generics']);
