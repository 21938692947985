/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from 'reactstrap';
import { Slider } from '@material-ui/core';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import toBlob from 'canvas-to-blob';

import { Header, Main } from 'generics/Card';
import { I18N } from 'constants/props';
import FileReader from 'generics/FileReader';
import Modal from 'generics/Modal';
import Translation from 'generics/Translation';

import Footer from './components/Footer';
import styles from './PictureSelectorModal.scss';

const IMAGE_MAX_SIZE = 1000000;
const IMAGE_TYPES = ['image/jpeg', 'image/png'];

/**
 * PictureSelectorModal Component
 * @extends Component
 */
const PictureSelectorModal = ({
  i18n,
  onClose,
  onRemovePicture,
  onSavePicture,
  pictureUrl,
  uploadMessage,
}) => {
  const [error, setError] = useState({ invalidSize: false, invalidType: false });
  const [imageSrc, setImageSrc] = useState(pictureUrl);
  const [isPictureChanged, setIsPictureChanged] = useState(false);
  const [picture, setPicture] = useState(null);
  const [range, setRange] = useState(1);

  const avatarEditor = useRef(null);

  useEffect(() => {
    // adds toBlob function to canvas
    toBlob.init();
  }, []);

  /**
   * Changes the image src if pictureUrl prop changed
   */
  useEffect(() => {
    if (pictureUrl !== imageSrc) {
      setImageSrc(pictureUrl);
    }
  }, [pictureUrl]);

  /**
   * Handles image error
   */
  const handleImageError = () => {
    setImageSrc(null);
  };

  /**
   * Handles submit event
   */
  const handleSubmit = () => {
    if (avatarEditor) {
      const canvas = avatarEditor.current.getImageScaledToCanvas();
      canvas.toBlob((pictureToSave) => {
        onSavePicture(pictureToSave);
      });
    }
  };

  /**
   * Handles upload image event
   * @param {object} image (image uploaded)
   */
  const handleUploadImage = (image) => {
    const invalidType = !(IMAGE_TYPES.includes(image.type));

    const invalidSize = image.size > IMAGE_MAX_SIZE;

    const isValidImage = !invalidType && !invalidSize;

    const newPicture = isValidImage
      ? image
      : null;

    setError({
      invalidSize,
      invalidType,
    });
    setIsPictureChanged(isValidImage);
    setPicture(newPicture);
  };

  /**
   * Handles zoom event
   * @param {object} event
   * @param {float} range (zoom range)
   */
  const handleZoom = (event, newRange) => {
    setRange(newRange);
  };

  /**
   * Renders the avatar
   */
  const renderAvatar = () => (
    <div className={styles['avatar-container']}>
      <AvatarEditor
        border={0}
        height={250}
        image={picture}
        ref={avatarEditor}
        rotate={0}
        scale={range}
        width={250}
      />
    </div>
  );

  /**
   * Renders the footer
   */
  const renderFooter = () => {
    const isRemoveDisabled = !imageSrc || isPictureChanged
      || error.invalidSize || error.invalidType;

    return (
      <Footer
        isRemoveDisabled={isRemoveDisabled}
        isSubmitDisabled={!isPictureChanged}
        onCancel={onClose}
        onRemove={onRemovePicture}
        onSubmit={handleSubmit}
      />
    );
  };

  /**
   * Renders the image content
   */
  const renderImageContent = () => {
    if (error.invalidSize || error.invalidType) {
      return renderErrors();
    }

    if (!picture) {
      return renderNoPicture();
    }

    return renderImageCrop();
  };

  /**
   * Renders the image max size error
   */
  const renderErrors = () => {
    const { invalidType } = error;

    const errorKey = invalidType ? 'wrongType' : 'imageExceedsSize';

    return (
      <div>
        <span className={styles.error}>
          {i18n.pictureSelectorModal.errors[errorKey]}
        </span>
      </div>
    );
  };

  /**
   * Renders the image crop tool
   */
  const renderImageCrop = () => (
    <div>
      <h3>
        {i18n.pictureSelectorModal.cropPicture}
      </h3>
      <p>
        {i18n.pictureSelectorModal.cropMessage}
      </p>
      {renderAvatar()}
      {renderSlider()}
    </div>
  );

  /**
   * Renders the main content
   */
  const renderMainContent = () => (
    <Main className={styles.main}>
      <Container className={styles['information-file-reader']}>
        <Row>
          <Col sm={12}>
            <FileReader
              descriptionMessage={uploadMessage}
              fileTypes={IMAGE_TYPES}
              onLoadFile={handleUploadImage}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={styles['image-content']}>
            {renderImageContent()}
          </Col>
        </Row>
      </Container>
    </Main>
  );

  /**
   * Renders the previous image uploaded or a message saying that there is no image yet
   */
  const renderNoPicture = () => (imageSrc
    ? (
      <img
        alt={i18n.pictureSelectorModal.currentFileLoaded}
        className={styles['previous-image']}
        src={imageSrc}
        onError={handleImageError}
      />
    )
    : (
      <div className={styles['empty-image-content']}>
        {i18n.pictureSelectorModal.noImageLoadedYet}
      </div>
    ));

  /**
   * Renders the slider to zoom in or out
   */
  const renderSlider = () => (
    <Slider
      className={styles.slider}
      max={2}
      min={0.5}
      onChange={handleZoom}
      step={0.2}
      value={range}
    />
  );

  return (
    <Modal
      card={{
        barClassName: styles.bar,
        fullHeight: true,
      }}
      onClose={onClose}
    >
      <Header
        className={styles.header}
        title={i18n.pictureSelectorModal.editPicture}
      />
      {renderMainContent()}
      {renderFooter()}
    </Modal>
  );
};

PictureSelectorModal.propTypes = {
  i18n: I18N.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemovePicture: PropTypes.func,
  onSavePicture: PropTypes.func.isRequired,
  pictureUrl: PropTypes.string,
  uploadMessage: PropTypes.string,
};

PictureSelectorModal.defaultProps = {
  onRemovePicture: null,
  pictureUrl: null,
  uploadMessage: '',
};

export default Translation(PictureSelectorModal, ['pictureSelectorModal']);
