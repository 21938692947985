import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as networkActions from 'app_modules/network/actions';
import * as networkSelectors from 'app_modules/network/selectors';
import * as notificationActions from 'app_modules/notifications/actions';
import * as profileActions from 'app_modules/profile/actions';
import * as sessionActions from 'app_modules/session/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import * as userActions from 'app_modules/user/actions';
import MyProfile from 'components/MyProfile';

const mapStateToProps = (state) => {
  const profile = sessionSelectors.currentProfile(state);

  const networkDetails = networkSelectors.networkDetails.profileNetworkDetails(state, profile.id);

  return {
    networkDetails,
    profile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  networkActions: bindActionCreators(networkActions, dispatch),
  notificationActions: bindActionCreators(notificationActions, dispatch),
  profileActions: bindActionCreators(profileActions, dispatch),
  sessionActions: bindActionCreators(sessionActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyProfile));
