/**
 * @description Settings for tokens controller
 * @module API controller / Tokens
 */

module.exports = {
  assignTokens: {
    /**
       * @description Post request settings (Send Stripe Report Payment)
       * @param {number} accountId - The account ID.
       * @param {number} data - Tokens to assign.
       * @return {object} Request Settings
       */
    settings(
      { accountId, data },
    ) {
      return {
        endpoint: `/account/${accountId}/tokens/assign`,
        body: {
          assign_to: [
            ...data,
          ],
        },
        method: 'POST',
      };
    },
  },
  cancelReportRequest: {
    /**
       * @description Post request settings (Send Stripe Report Payment)
       * @param {number} accountId - The account ID.
       * @param {number} data - Tokens to assign.
       * @return {object} Request Settings
       */
    settings(
      { accountId, reportId },
    ) {
      return {
        endpoint: `/account/${accountId}/tokens/bulk_report/${reportId}/cancel`,
        method: 'PATCH',
      };
    },
  },
  getAssignedTokens: {
    /**
     * @description Get request settings to tokens price.
     * @param {number} accountId - The account ID.
     * @returns {object} - Request settings.
     */
    settings({ accountId, pageIndex, search, ownerFilter }) {
      const { filterBy, value: searchValue } = search || {};

      let endpoint = `/account/${accountId}/tokens/owner_change_list`;
      const cleanSearch = searchValue ? searchValue.trim() : null;
      const searchBy = searchValue ? filterBy : null;

      if (ownerFilter) {
        endpoint += `/sender/${ownerFilter}`;
      }

      if (pageIndex) {
        endpoint += `/page/${pageIndex}`;
      }

      if (searchBy) {
        endpoint += `/search_field/${searchBy}`;
      }

      if (cleanSearch) {
        endpoint += `/search/${cleanSearch}`;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },
  getBulkReportList: {
    /**
     * @description Get request settings to tokens price.
     * @param {number} accountId - The account ID.
     * @returns {object} - Request settings.
     */
    settings({ accountId, pageIndex, search }) {
      const { filterBy, value: searchValue } = search || {};

      let endpoint = `/account/${accountId}/tokens/bulk_report_list`;
      const cleanSearch = searchValue ? searchValue.trim() : null;
      const searchBy = searchValue ? filterBy : null;

      if (pageIndex) {
        endpoint += `/page/${pageIndex}`;
      }

      if (searchBy) {
        endpoint += `/search_field/${searchBy}`;
      }

      if (cleanSearch) {
        endpoint += `/search/${cleanSearch}`;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },
  getTokensAllocators: {
    /**
     * @description Get request settings to tokens price.
     * @param {number} accountId - The account ID.
     * @returns {object} - Request settings.
     */
    settings({ accountId, pageIndex, search }) {
      let endpoint = `/account/${accountId}/tokens/tokens_allocator`;
      const cleanSearch = search ? search.trim() : null;

      if (pageIndex) {
        endpoint += `/page/${pageIndex}`;
      }

      if (cleanSearch) {
        endpoint += `/search/${cleanSearch}`;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },
  reAssignTokens: {
    /**
       * @description Post request settings (Send Stripe Report Payment)
       * @param {number} accountId - The account ID.
       * @param {number} data - Tokens & owner id to reassign.
       * @return {object} Request Settings
       */
    settings(
      { accountId, data },
    ) {
      return {
        endpoint: `/account/${accountId}/tokens/owner_change`,
        body: {
          ...data,
        },
        method: 'POST',
      };
    },
  },
  sendReportReminder: {
    /**
       * @description Post request settings (Send Stripe Report Payment)
       * @param {number} accountId - The account ID.
       * @param {number} data - Tokens to assign.
       * @return {object} Request Settings
       */
    settings(
      { accountId, reportId },
    ) {
      return {
        endpoint: `/account/${accountId}/tokens/bulk_report/${reportId}/resend`,
        method: 'PATCH',
      };
    },
  },
  sendReportRequest: {
    /**
       * @description Post request settings (Send Stripe Report Payment)
       * @param {number} accountId - The account ID.
       * @param {number} data - Tokens to assign.
       * @return {object} Request Settings
       */
    settings(
      { accountId, values },
    ) {
      return {
        endpoint: `/account/${accountId}/tokens/send_bulk_report`,
        body: {
          send_to: {
            ...values,
          },
        },
        method: 'POST',
      };
    },
  },
};
