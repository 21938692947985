/* eslint-disable react-hooks/exhaustive-deps */
import { bindActionCreators } from 'redux';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import shortid from 'shortid';
import Translation from 'generics/Translation';

import { ACTIONS, I18N } from 'constants/props';
import { Card, Header, Main } from 'generics/Card';
import { StyledButton } from 'generics/StyledFormComponents';
import * as pageActions from 'app_modules/page/actions';
import Mask from 'generics/Mask';

import styles from './ModalConfirmation.scss';

const ModalConfirmation = ({
  acceptLabel,
  actions,
  barClassName,
  confirmationMessages,
  confirmationTitle,
  onAccept,
  onCancel,
  i18n,
}) => {
  useEffect(() => {
    actions.switchModalView();

    return () => {
      actions.switchModalView();
    };
  }, []);

  const btnId = confirmationTitle ? `${kebabCase(confirmationTitle)}-btn` : shortid.generate();

  const messages = confirmationMessages.map((message, i) => (
    <p className={styles.mask__message} key={`message-${i + 1}`}>
      {message}
    </p>
  ));

  return (
    <Mask
      className={styles.mask}
      open
    >
      <Card
        barClassName={barClassName}
        className={styles.mask__card}
      >
        {
          confirmationTitle && (
            <Header
              className={styles.mask__title}
              title={confirmationTitle}
            />
          )
        }
        <Main>
          <Container>
            <Row>
              <Col>
                {messages}
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="6">
                {
                  onCancel && (
                    <StyledButton
                      className={`${styles.mask__button} ${styles['mask__button-decline']}`}
                      color="default"
                      onClick={onCancel}
                      title={i18n.generics.cancelLabel}
                      variant="text"
                    >
                      {i18n.generics.cancelLabel}
                    </StyledButton>
                  )
                }
              </Col>
              <Col xs="12" sm="6">
                <StyledButton
                  className={styles.mask__button}
                  color="primary"
                  id={btnId}
                  onClick={onAccept}
                  title={acceptLabel || i18n.generics.acceptLabel}
                >
                  {acceptLabel || i18n.generics.acceptLabel}
                </StyledButton>
              </Col>
            </Row>
          </Container>
        </Main>
      </Card>
    </Mask>
  );
};

ModalConfirmation.propTypes = {
  actions: ACTIONS.isRequired,
  acceptLabel: PropTypes.string,
  barClassName: PropTypes.string,
  confirmationMessages: PropTypes.arrayOf(PropTypes.string),
  confirmationTitle: PropTypes.string,
  i18n: I18N.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

ModalConfirmation.defaultProps = {
  acceptLabel: null,
  barClassName: null,
  confirmationMessages: [],
  confirmationTitle: undefined,
  onCancel: null,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(pageActions, dispatch),
});

export default Translation(connect(null, mapDispatchToProps)(ModalConfirmation), ['generics']);
