
export const en = {
  accountDetails: {
    editLogo: 'Edit Logo',
    logoTitle: '%s logo',
    uploadLogoMessage: 'Upload a logo image here (1 MB max file size)',
  },
  accountTypeLabel: 'Account Type',
  assignTokens: {
    allocatedLabel: 'Allocated',
    addTokenAllocatorLabel: '+ Add Owner',
    consumedLabel: 'Consumed',
    description: 'Tokens that have not yet been allocated or consumed (used by a recipient to share their APGI report) can be re-assigned to a different token administrator. This is true for tokens that belong to someone else or tokens that are not currently assigned to any token administrator.',
    myTokensLabel: 'My Tokens',
    organizationTokensLabel: 'Organization Tokens',
    organizationUnassignedLabel: 'Organization Unassigned',
    ownerLabel: 'Owner',
    ownerLabelSearch: 'Search Owner',
    ownerInputLabel: 'Select an owner',
    submitBtn: 'Update',
    typeLabel: 'Type',
    typeInputLabel: 'Select a type',
    quantityLabel: 'Tokens Quantity',
    quantityInputLabel: 'Insert quantity',
    unallocatedLabel: 'Unallocated',
    unassignedLabel: 'Unassigned',
  },
  bulkDelete: {
    title: 'Bulk Delete',
    fieldErrors: 'Errors found while deleting the members',
    fileError: 'Invalid File. Make sure you are uploading a CSV with the right format.',
    fileFormatError: 'Invalid File, only csv files are accepted',
    fileReaderDescription: 'Upload a list of people to delete them (Only CSV files with a limit of 500 rows).',
    resultMessage: {
      failed: 'The following emails have errors',
      removed: 'The following members were removed',
    },
  },
  bundleModules: {
    modulesLabel: 'Modules',
    selectedTeamsCountLabel: 'Selected Teams: %(teamsCount)s',
    shareButtonLabel: 'Add / Remove Teams',
  },
  bundlesList: {
    detailsLabel: 'Details',
  },
  bundlesListItem: {
    modulesCountLabel: '%(modulesCount)s %(subject)s',
    moduleSubject: 'Modules',
    sharedWithEntireNetworkLabel: 'Shared with the entire network',
    teamsCountSharedLabel: 'Shared with %(teamsCount)s %(subject)s',
    teamSubject: 'Teams',
  },
  buyForm: {
    estimateTokensDescription: 'Tokens are used to share the individual Advanced Professional Growth Insights (APGI) report with people who are outside of your organization. They are not full licenses to be shared with users within your organization. Possible uses of the APGI report would be hiring, sales, coaching, etc.',
    estimateTokensLabel: 'How many tokens do you want?',
    inputLabel: 'Enter your tokens quantity',
    submitBtn: 'Estimate',
  },
  expirationDateLabel: 'Expiration Date',
  formFileInvitation: {
    description: 'Description',
    fileError: 'Invalid file format',
    fileReaderDescription: 'Upload a list of people to invite here.',
    frequency: ['Daily', 'Weekly', 'Never'],
    frequencyLabel: 'Frequency',
    frequencyType: {
      day: '%(dayCount)d %(text)s',
      dayText: 'Day',
      weekDays: {
        10: 'Sunday',
        4: 'Monday',
        5: 'Tuesday',
        6: 'Wednesday',
        7: 'Thursday',
        8: 'Friday',
        9: 'Saturday',
      },
    },
    frequencyTypeLabel: 'Frequency Type',
    groupName: 'Group Name',
    instructions: 'Please add a Group Name and the frequency of the invitation reminders for it',
    tooltip: {
      description: 'This will set the frequency of the reminder email to be sent to the members of the group.',
      title: 'What is this?',
    },
  },
  formGroupSearch: {
    date: {
      startDate: 'From',
      endDate: 'To',
    },
    labelGroupName: 'Name',
    btnTextSubmit: 'Filter',
    titleError: 'Error message',
  },
  groupListItem: {
    dateJoined: 'Date Joined:',
    dateJoinedTitle: 'Date Joined: %(dateJoined)s',
    dailyInvitationReminder: 'Invitation Reminder every %(dayCount)s %(dayText)s ',
    weeklyInvitationReminder: 'Invitation Reminder %(weekly)s every %(weekDay)s',
    never: 'Never',
    weekly: 'weekly',
    dayText: 'day',
    weekDays: {
      10: 'Sunday',
      4: 'Monday',
      5: 'Tuesday',
      6: 'Wednesday',
      7: 'Thursday',
      8: 'Friday',
      9: 'Saturday',
    },
  },
  invitationsSentLabel: 'Total Sent Invitations',
  licenseLimitLabel: 'License Limit',
  licensesAllocatedLabel: 'Licenses Allocated',
  licensesAvailableLabel: 'Licenses Available',
  members: {
    bulkDelete: 'Bulk Delete',
    exportMembers: 'Export Members',
    exportMembersTitle: 'Export a CSV with all members',
    search: 'Find a Member',
    workshopOptions: {
      all: 'All Members',
      in: 'In a Workshop',
      notIn: 'Not in a Workshop',
    },
  },
  modalShareTeams: {
    listATitle: 'All Teams',
    listBEmptyMessage: 'No teams shared yet',
    listBTitle: 'Selected',
    modalDescription: 'Please select the teams you wish to share this Module Bundle with',
    modalTitle: 'Select Teams',
  },
  modalPeople: {
    close: 'Close',
    invite: 'Invite',
    formInvitation: {
      description: 'Enter an email address to send an invitation to join your organization\'s network.',
      editDescription: 'Enter an email address to send an invitation and join this group',
    },
    resultMessage: {
      failed: 'We could not send the invitation to the following people because of errors in the data submitted',
      failedEditGroup: 'We found some errors in the data submitted',
      removed: 'Invitation successfully cancelled to the following people',
      success: 'Invitation successfully sent to the following people',
    },
    tabs: ['Add a member', 'Add multiple members'],
    title: {
      editGroup: 'Edit Group',
      error: 'Error!',
      invitePeople: 'Invite People',
      success: 'Success!',
    },
  },
  tokenManagement: {
    assignTokensSuccessful: 'The tokens were successfully assigned.',
    createWorkshopLabel: 'Create Workshop',
    dateCreated: 'Date Created',
    dateSent: 'Date Sent',
    deleteMemberConfirmationMessage: 'Are you sure that you want to permanently delete the member? There is no Undo.',
    groupListHeader: {
      never: 'Never',
      edit: 'Edit',
      back: 'Back',
      dateCreated: 'Date created:',
      dateCreatedTitle: 'Date created: %(dateCreated)s',
      InvitationReminder: 'Invitation Reminder:',
      InvitationReminderTitle: 'Invitation Reminder: %(invitationReminder)s',
      frequency: 'Every %(frequency)s',
    },
    checkout: {
      countriesLabel: 'Countries',
      emailLabel: 'Email',
      nameOnCardLabel: 'Name on Card',
      orderLabel: 'Order',
      zipCodeLabel: 'Zip Code',
    },
    invalidateInvitation: 'Invalidate Invitation',
    invalidateInvitationConfirmationMessage: 'Are you sure you want to invalidate this invitation? There is no Undo.',
    invitationSuccessMessage: 'A new invitation has been sent.',
    invitePeopleLabel: 'Invite People',
    latestWorkshop: 'Go to the latest workshop: %(workshop)s',
    noResultsToDisplayYetLabel: 'No results to display yet',
    payLabel: 'Pay $%(amount)s',
    paymentError: 'An error occurred while processing the transaction, please try again later.',
    peopleLabel: 'Tokens',
    resendLabel: 'Resend Invitation',
    sendNewLabel: 'Send New Invitation',
    tab: {
      assignTokens: 'Assign Tokens',
      buyTokens: 'Buy Tokens',
      ownerChange: 'Owner Change',
      useTokens: 'Use Tokens',
    },
    transactionSucessful: 'The transaction was successful',
  },
  ownerChange: {
    description: "* As an admin, you will be able to reassign reports from other owners to yourself or to other owners. Selecting an owner's report will remove it from the owners available for reassignment.",
    filterByOwnerLabel: 'Filter By Owner',
    noAvailableOwnersToReAssign: 'There are no owners left to reassign, because you have selected all.',
    reAssignModal: {
      title: 'Reassign reports',
      description: 'Select an owner to reassign the selected reports.',
    },
    reAssignTokensAction: 'Reassign',
    reAssignReportsSuccessful: 'Reports were successfully reassigned.',
    selectOwnerLabel: 'Select an owner to reassign',
  },
  peopleList: {
    createWorkshopLabel: 'Create Workshop',
    dateCreated: 'Date Created',
    dateSent: 'Date Sent',
    deleteMemberConfirmationMessage: 'Are you sure that you want to permanently delete the member? There is no Undo.',
    groupListHeader: {
      never: 'Never',
      edit: 'Edit',
      back: 'Back',
      dateCreated: 'Date created:',
      dateCreatedTitle: 'Date created: %(dateCreated)s',
      InvitationReminder: 'Invitation Reminder:',
      InvitationReminderTitle: 'Invitation Reminder: %(invitationReminder)s',
      frequency: 'Every %(frequency)s',
    },
    invalidateInvitation: 'Invalidate Invitation',
    invalidateInvitationConfirmationMessage: 'Are you sure you want to invalidate this invitation? There is no Undo.',
    invitationSuccessMessage: 'A new invitation has been sent.',
    invitePeopleLabel: 'Invite People',
    latestWorkshop: 'Go to the latest workshop: %(workshop)s',
    noResultsToDisplayYetLabel: 'No results to display yet',
    peopleLabel: 'Tokens',
    resendLabel: 'Resend Invitation',
    sendNewLabel: 'Send New Invitation',
    tab: {
      groups: 'Groups (%(groupsCount)s)',
      members: 'Buy Tokens',
      sentInvites: 'Sent Invitations (%(invitesCount)s)',
      workshops: 'Workshops (%(workshopsCount)s)',
    },
  },
  sentInvites: {
    search: 'Find an Invitation',
  },
  totalTeamsQuantityLabel: 'Number of Teams',
  useTokens: {
    deleteReportConfirmationMessage: 'Are you sure that you want to cancel the report request? This action cannot be undone.',
    description: 'When you purchase tokens, they are placed in the Unallocated bucket. When you send a request to a recipient, a report code is generated using one of the purchased tokens and the token becomes Allocated. The token gets Consumed when the recipient accepts the request to share their report with you.<br />Unassigned tokens do not belong to a specific buyer and can be assigned to any token administrator. For example, if a token administrator has unused tokens and the user is removed from the organization, the unused tokens go into the Unassigned bucket.',
    list: {
      empty: 'No results found.',
      headers: {
        actions: 'Available Actions',
        code: 'Report Code',
        email: 'Email',
        expirationDate: 'Token Expiration',
        name: 'Full Name',
        owner: 'Owner',
        status: 'Status',
      },
    },
    pdf: {
      createLabel: 'Create PDF',
      downloadLabel: 'Download PDF',
      sendNewInvitationLabel: 'Send New Invitation',
    },
    search: 'Search for reports',
    sendNewInvitationSuccessful: 'A new invitation has been sent, and you have allocated a new token.',
    sendReportRequestModal: {
      title: 'Send Share Report Request',
      description: 'Submit a request for a user to share their Advanced Professional Growth Insights Report with the Organization.',
    },
    sendReportReminderSuccessful: 'A reminder has been successfully sent to %s',
    sendReportRequestAction: 'Send request',
    sendReportRequestSubmit: 'Send',
    sendReportRequestSuccessful: 'The request was successfully sent',
  },
};

export const es = en;
