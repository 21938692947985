import htmlParser from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';

import { Card, Header, Main } from 'generics/Card';
import { CHILDREN, I18N } from 'constants/props';
import { NOT_AVAILABLE_INVITES, SSO_ERROR } from 'constants/apiErrorCodes';
import Translation from 'generics/Translation';

import styles from './ErrorCard.scss';

/**
 * @class ErrorCard
 * @description Error Card component
 */
const ErrorCard = ({ i18n, children, errorCode }) => {
  let content = children;
  let errorName = null;

  if (!children) {
    switch (errorCode) {
      case NOT_AVAILABLE_INVITES:
        errorName = 'noLicenses';
        break;
      case SSO_ERROR:
      default:
        errorName = 'generalError';
    }

    content = (
      <>
        <Header
          className={styles.title}
          title={i18n.errorPages[errorName].title}
        />
        <p className={styles.content}>
          {htmlParser(i18n.errorPages[errorName].content)}
        </p>
      </>
    );
  }

  return (
    <Card center>
      <Main className={styles.main}>
        {content}
      </Main>
    </Card>
  );
};

ErrorCard.propTypes = {
  children: CHILDREN,
  errorCode: PropTypes.number,
  i18n: I18N.isRequired,
};

ErrorCard.defaultProps = {
  children: null,
  errorCode: null,
};

export const ErrorCardComponent = ErrorCard;

export default Translation(ErrorCard, ['errorPages']);
