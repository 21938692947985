/* eslint-disable no-unused-vars */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ACTIONS } from 'constants/props';
import * as invitesSelectors from 'app_modules/invites/selectors';
import * as profileActions from 'app_modules/profile/actions';

import SetupProfileCard from '../SetupProfileCard';

const SetupProfile = ({
  actions,
  email,
}) => {
  const [step, setStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNextStep = (userData) => {
    setIsSubmitting(true);

    const onFinish = () => {
      setIsSubmitting(false);
    };

    actions.createUser(userData, onFinish, onFinish);
  };

  switch (step) {
    case 0:
      return (
        <SetupProfileCard
          email={email}
          handleSubmit={handleNextStep}
          isSubmitting={isSubmitting}
        />
      );
    default:
      return null;
  }
};

SetupProfile.propTypes = {
  actions: ACTIONS.isRequired,
  email: PropTypes.string,
};

SetupProfile.defaultProps = {
  email: null,
};

const mapStateToProps = (state) => ({
  email: invitesSelectors.email(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(profileActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupProfile);
