import { Col, Container, Row } from 'reactstrap';
import { isNil } from 'lodash';
import { pickHTMLProps } from 'pick-react-known-prop';
import PropTypes from 'prop-types';
import React from 'react';

import { Footer, Header, Main } from 'generics/Card';
import { I18N } from 'constants/props';
import { StyledButton, StyledTextField } from 'generics/StyledFormComponents';
import Translation from 'generics/Translation';
import useFormProfileNotes from './useFormProfileNotes';

import styles from './FormProfileNotes.scss';

const FormProfileNotes = ({ i18n, initialValues, onClose, onDelete, onSubmit, ...rest }) => {
  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  } = useFormProfileNotes({ i18n, initialValues, onSubmit });

  return (
    <>
      <Header>
        <h1 title={!initialValues ? i18n.notes.addNoteTitle : i18n.notes.editNote}>
          {!initialValues ? i18n.notes.addNoteTitle : i18n.notes.editNote}
        </h1>
      </Header>
      <Main>
        <Container fluid>
          <form
            {...pickHTMLProps(rest)}
            onSubmit={(e) => handleSubmit(e)}
          >
            <StyledTextField
              className={styles.field}
              error={isNil(values.title) ? false : !!errors.title}
              helperText={isNil(values.title) ? ' ' : errors.title}
              label={`${i18n.notes.noteTitleLabel}:`}
              maxLength={50}
              name="title"
              onChange={(e) => handleChange('title', e.currentTarget.value)}
              title={i18n.notes.noteTitleLabel}
              value={values.title || ''}
            />
            <StyledTextField
              className={styles.field}
              error={isNil(values.description) ? false : !!errors.description}
              helperText={isNil(values.description) ? ' ' : errors.description}
              label={`${i18n.notes.noteDescriptionLabel}:`}
              maxLength={335}
              multiline
              name="description"
              onChange={(e) => handleChange('description', e.currentTarget.value)}
              rows={5}
              title={i18n.notes.noteDescriptionLabel}
              value={values.description || ''}
            />
          </form>
        </Container>
      </Main>
      <Footer>
        <Container>
          <Row>
            <Col xs="4" md="2">
              {initialValues && (
                <StyledButton
                  color="error"
                  fullWidth
                  onClick={() => onDelete()}
                  title={i18n.generics.cancelLabel}
                  variant="text"
                >
                  {i18n.generics.deleteLabel}
                </StyledButton>
              )}
            </Col>

            <Col xs={{ size: 4, offset: 4 }} md={{ size: 2, offset: 8 }}>
              <StyledButton
                color="primary"
                disabled={!isValid}
                fullWidth
                id="create-team-btn"
                onClick={(e) => handleSubmit(e)}
                title={i18n.generics.doneLabel}
              >
                {i18n.generics.doneLabel}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </Footer>
    </>
  );
};

FormProfileNotes.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
};

export default Translation(FormProfileNotes, ['notes', 'generics']);
