import { combineReducers } from 'redux';
import { get, uniqBy } from 'lodash';

import groupsActionTypes from 'app_modules/groups/constants';
import initialState from 'app_modules/store/initialState.json';
import sessionActionTypes from 'app_modules/session/constants';

export const currentGroup = combineReducers({
  isFetching: (state = initialState.groups.currentGroup.isFetching, { type }) => {
    switch (type) {
      case groupsActionTypes.FETCH_GROUP_DETAILS_REQUEST:
        return true;

      case groupsActionTypes.FETCH_GROUP_DETAILS_FAILURE:
      case groupsActionTypes.FETCH_GROUP_DETAILS_SUCCESS:
        return false;

      case sessionActionTypes.RESET_STATE:
        return initialState.groups.currentGroup.isFetching;

      default:
        return state;
    }
  },

  group: (state = initialState.groups.currentGroup.group, { type, payload }) => {
    switch (type) {
      case groupsActionTypes.CLEAR_GROUP:
      case groupsActionTypes.FETCH_GROUP_DETAILS_REQUEST:
        return null;

      case groupsActionTypes.FETCH_UPDATE_GROUP_SUCCESS:
        return {
          invites: state?.invites,
          ...payload.group,
        };

      case groupsActionTypes.FETCH_GROUP_DETAILS_SUCCESS:
      case groupsActionTypes.FETCH_UPDATE_GROUP_INVITATION_EMAILS_SUCCESS:
      case groupsActionTypes.FETCH_UPDATE_GROUP_INVITATIONS_SUCCESS:
        return payload.group;

      case groupsActionTypes.FETCH_RESEND_INVITATION_SUCCESS: {
        const stateInvites = state && state.invites ? state.invites : [];

        const invites = stateInvites
          .map((invite) => (
            invite.id === payload.invitationId
              ? { ...payload.invite }
              : invite
          ));

        return {
          ...state,
          invites,
        };
      }

      case sessionActionTypes.RESET_STATE:
        return initialState.groups.currentGroup.group;

      default:
        return state;
    }
  },
});

export const list = combineReducers({
  isFetching: (state = initialState.groups.list.isFetching, { type }) => {
    switch (type) {
      case groupsActionTypes.FETCH_GROUPS_REQUEST:
        return true;

      case groupsActionTypes.FETCH_GROUPS_FAILURE:
      case groupsActionTypes.FETCH_GROUPS_SUCCESS:
      case groupsActionTypes.FETCH_GROUPS_SUCCESS_NO_RESULTS:
        return false;

      case sessionActionTypes.RESET_STATE:
        return initialState.groups.list.isFetching;

      default:
        return state;
    }
  },

  list: (state = initialState.groups.list.list, { type, payload }) => {
    switch (type) {
      case groupsActionTypes.ARCHIVE_GROUP_SUCCESS:
        if (payload.selectedFilter === 'all') {
          return state.map((group) => {
            if (group.id === payload.group.id) {
              return {
                ...group,
                archive: payload.group.archive,
              };
            }
            return group;
          });
        }
        return state
          .filter((group) => (group.id !== payload.group.id));

      case groupsActionTypes.FETCH_GROUPS_REQUEST:
        if (payload === 1) {
          return [];
        }
        return state;

      case groupsActionTypes.FETCH_GROUPS_FAILURE:
        return [];

      case groupsActionTypes.FETCH_GROUPS_SUCCESS: {
        const { groups = [] } = payload;

        return uniqBy([...state, ...groups], 'id');
      }

      case groupsActionTypes.FETCH_UPDATE_GROUP_SUCCESS:
        return state
          .map((invite) => (
            invite.id === payload.group.id
              ? payload.group
              : invite
          ));

      case sessionActionTypes.RESET_STATE:
        return initialState.groups.list.list;

      default:
        return state;
    }
  },

  meta: (state = initialState.groups.list.meta, { type, payload }) => {
    switch (type) {
      case groupsActionTypes.FETCH_GROUPS_SUCCESS: {
        const newMeta = get(payload, 'meta', initialState.groups.list.meta);
        return payload.updateCounters ? newMeta : {
          totalObjects: state.totalObjects,
          ...newMeta,
        };
      }

      case groupsActionTypes.FETCH_GROUPS_FAILURE:
      case sessionActionTypes.RESET_STATE:
        return initialState.groups.list.meta;

      default:
        return state;
    }
  },
});

export default combineReducers({
  currentGroup,
  list,
});
