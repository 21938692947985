import PropTypes from 'prop-types';

import { PASSWORD, PASSWORD_CONFIRMATION } from 'constants/inputTypes';
import { I18N } from 'constants/props';
import useForm from 'custom_hooks/useForm';
import useFormValidation from 'custom_hooks/useFormValidation';

const useFormResetPassword = ({ i18n, onSubmit }) => {
  const { validator } = useFormValidation({ i18n });

  const handleValidate = (values) => {
    const {
      password,
      passwordConfirmation,
    } = values;

    return {
      password: validator.validate(
        PASSWORD,
        password,
      ),
      passwordConfirmation: validator.validate(
        PASSWORD_CONFIRMATION,
        password,
        passwordConfirmation,
      ),
    };
  };

  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values = { email: null, password: null },
  } = useForm({ onSubmit, onValidate: handleValidate });

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  };
};

useFormResetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
};

export default useFormResetPassword;
