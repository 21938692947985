/**
 * @class AppError
 * @description 5D App Error
 * @extends Error
 * */
export default class AppError extends Error {
  constructor({ label, details, notificationType, message, ...args }) {
    super(args ?? undefined);
    this.label = label;
    this.message = message;
    this.details = details;
    this.notificationType = notificationType;
  }
}
