import { omit } from 'lodash';

export default class NewRelicError extends Error {
  constructor({ action, state }) {
    const {
      payload,
      type,
    } = action;

    super(type, payload);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NewRelicError);
      Error.stackTraceLimit = Infinity;
    }

    const customStack = JSON.stringify({
      action,
      state: omit(state, ['intl', 'form', 'routing']),
    });

    this.name = 'NewRelicError';
    this.message = payload;
    this.stack = `${this.stack}\n\t${customStack}`;
  }
}
