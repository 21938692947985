export const en = {
  cancel: 'Cancel',
  cropMessage: 'Click and drag the image to fit the square',
  cropPicture: 'Crop Picture',
  currentFileLoaded: 'Current file loaded',
  editPicture: 'Edit Picture',
  noImageLoadedYet: 'No image uploaded yet',
  remove: 'Remove',
  save: 'Save',
  errors: {
    imageExceedsSize: 'The file size is too large. The maximum size is 1MB.',
    wrongType: 'The file format is invalid. Only JPEG and PNG are accepted.',
  },
};

export const es = en;
