/**
 * @description Settings for countries controller
 * @module API controller / Countries
 */
module.exports = {
  getList: {
    /**
     * @description Get request settings (country endpoint - get list)
     * @returns {object} request settings
     */
    settings: () => ({
      endpoint: '/country',
      method: 'GET',
    }),
  },
};
