import { makeStyles } from '@material-ui/core/styles';
import { Select } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { CHILDREN } from 'constants/props';
import { COLORS } from 'constants/colors';
import Icon from 'generics/Icon';

const notDisplayed = {
  transform: 'scaleX(0)',
  display: 'none',
};

const styles = makeStyles({
  select: {
    '&::before': notDisplayed,
    '&::after': notDisplayed,
    '&:hover:not(.Mui-disabled)::before': notDisplayed,
    '&.Mui-focused::after': notDisplayed,
    '& .MuiSelect-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiListItem-root.Mui-selected': {
      color: COLORS.primaryBlue.rgba,
      background: 'transparent',
    },
  },
});

const AccountSelect = ({ ariaLabel, className, children, iconClassName, ...rest }) => {
  const classes = styles();

  return (
    <Select
      className={classnames(className, classes.select)}
      fullWidth
      IconComponent={(props) => (
        <Icon.Stroke7
          {...props}
          name="angle-down"
          className={classnames('MuiSelect-icon', iconClassName)}
        />
      )}
      SelectDisplayProps={{
        'aria-label': ariaLabel,
        'aria-labelledby': null,
      }}
      {...rest}
    >
      {children}
    </Select>
  );
};

AccountSelect.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  children: CHILDREN,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

AccountSelect.defaultProps = {
  children: null,
  className: null,
  iconClassName: null,
};

export default AccountSelect;
