/* eslint-disable camelcase */
import _ from 'lodash';

import { ENERGY_LABELS } from 'constants';
import d3 from 'vendor/d3';

const RIGHT_ANGLE = Math.PI / 2;

const isEvenBand = (d, i) => i % 2 === 0;
const isOuterBand = (d, i) => i === 0; // we reverse the bands for DOM ordering

export const drawBands = ({ elements, geometry, styles }) => {
  if (elements && geometry) {
    const { bandHalfSide, cornerRadiusRatio } = geometry;
    const { g_ref } = elements;

    if (g_ref) {
      let band = g_ref.selectAll(`.${styles.band}`)
        .data(_.clone(ENERGY_LABELS).reverse());

      band.exit().remove();

      band = band.enter()
        .append('path')
        .merge(band)
        .attr('d', (d) => {
          const halfSide = bandHalfSide[d];
          const cornerRadius = cornerRadiusRatio * halfSide;
          const preArcHalfSide = halfSide - cornerRadius;

          const pathGenerator = d3.path();
          pathGenerator.moveTo(halfSide, preArcHalfSide);
          pathGenerator.arc(
            preArcHalfSide, preArcHalfSide,
            cornerRadius,
            0, RIGHT_ANGLE,
          );
          pathGenerator.lineTo(-preArcHalfSide, halfSide);
          pathGenerator.arc(
            -preArcHalfSide, preArcHalfSide,
            cornerRadius,
            RIGHT_ANGLE, Math.PI,
          );
          pathGenerator.lineTo(-halfSide, -preArcHalfSide);
          pathGenerator.arc(
            -preArcHalfSide, -preArcHalfSide,
            cornerRadius,
            Math.PI, 3 * RIGHT_ANGLE,
          );
          pathGenerator.lineTo(preArcHalfSide, -halfSide);
          pathGenerator.arc(
            preArcHalfSide, -preArcHalfSide,
            cornerRadius,
            3 * RIGHT_ANGLE, 2 * Math.PI,
          );
          pathGenerator.closePath();
          return pathGenerator.toString();
        })
        .attr('class', styles.band)
        .classed(styles.solid, isEvenBand)
        .classed(styles.outer, isOuterBand);
    }
  }
};

export const drawDot = ({ elements, geometry, styles }) => {
  if (elements) {
    const { g_ref } = elements;

    if (g_ref) {
      const dot = g_ref.selectAll(`.${styles.dot}`)
        .data([{ radius: geometry.dotRadius }]);

      dot.exit().remove();

      dot.enter()
        .append('circle')
        .attr('class', styles.dot)
        .merge(dot)
        .attr('r', (d) => d.radius);
    }
  }
};
