import { pickHTMLProps } from 'pick-react-known-prop';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { CHILDREN } from 'constants/props';

import styles from '../Card/card.scss';

const Header = (props) => {
  const {
    actions,
    ariaLabel,
    children,
    className,
    info,
    subtitle,
    title,
    ...rest
  } = props;

  return (
    <header
      className={classnames(
        styles.card__header,
        className,
      )}
      {...pickHTMLProps(rest)}
      role={ariaLabel ? 'presentation' : null}
      aria-label={ariaLabel}
    >
      <div
        className={classnames(
          styles['card__header-title'],
          { [styles['card__header-title--has-subtitle']]: subtitle },
        )}
      >
        {
          title && (
            <h1
              classnames={styles.card__title}
              title={title}
            >
              {title}
            </h1>
          )
        }
        {
          actions && (
            <div className={styles.card__actions}>
              {
                actions.map((action, index) => (
                  /* eslint-disable react/no-array-index-key */
                  <div key={index}>
                    {/* eslint-enable react/no-array-index-key */}
                    {action}
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
      {info && (
        <div className={styles['card__header-info']}>
          {info}
        </div>
      )}
      {
        subtitle && (
          <p
            className={styles.card__subtitle}
            title={subtitle}
          >
            {subtitle}
          </p>
        )
      }
      {children}
    </header>
  );
};

Header.propTypes = {
  actions: CHILDREN,
  ariaLabel: PropTypes.string,
  children: CHILDREN,
  className: PropTypes.string,
  info: CHILDREN,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

Header.defaultProps = {
  actions: null,
  ariaLabel: '',
  children: null,
  className: null,
  info: null,
  subtitle: null,
  title: null,
};

export default Header;
