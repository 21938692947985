export const en = {
  accountExpired: 'Your access to this organization needs to be renewed. Please contact your administrator for assistance.',
  archiveGroup: 'Archive group failed, please try again later.',
  assessmentExpired: 'Your assessment session expired. Please sign in again to continue.',
  canNotAssign: 'You cannot assign more than the amount of tokens you have in this account.',
  canNotConnectToServer: 'We can\'t proceed. Please check your internet connection and try again.',
  completeSSOProvisionError: 'An error happened while signing up, please contact your administrator.',
  fetchAcceptInvitation: 'An error happened while accepting the invitation, please try again later.',
  fetchBundles: 'Bundles are currently unavailable.',
  fetchDeclineInvitation: 'The invitation could not be declined, please try again later.',
  fetchResendInvitation: 'An error happened while sending the invitation, please try again later.',
  fetchUpdateGroup: 'An error happened while updating the group, please try again later.',
  fetchUpdateGroupInvitationEmails: 'Some email updates could not be applied, please try again later.',
  fetchUpdateGroupInvitations: 'An error happened while updating the group invitations, please try again later.',
  fileInvitations: 'Some errors were found sending the invitations.',
  getAccountInformationError: 'An error happened while getting Account Information, please try again later.',
  getMyMembersError: 'An error happened while getting My Members Information, please try again later.',
  impersonationTokenExpired: 'This impersonation token has expired. Please try to impersonate again.',
  invalidAddress: 'The address you tried to access doesn\'t exist',
  invalidBuyerRole: 'You are not allowed to perform payments or subscriptions because you don\'t have the valid role for that action.',
  invalidateInvitationError: 'The invitation could not be invalidated.',
  invalidEmailOrPassword: 'Invalid email or password.',
  invalidInvitation: 'Invalid Invitation.',
  invalidadOwnerAction: 'The action you are trying to perform is not possible for this owner. Please contact your administrator for assistance.',
  invitationAccountExist: 'We noticed that you already have an account with us, please login to accept the invitation.',
  invalidCodeUpdateEmail: 'The code provided is invalid',
  isImpersonatingTo: '%(internalAdminName)s is impersonating user.',
  licenseLimitExceeds: 'License limit can\'t be exceed by the total members quantity.',
  listNotDefined: 'List not defined.',
  loginAccountBlocked: 'Your account has been blocked, use the "forgot username/password" option to unblock it.',
  loginError: 'An error happened while loging into the application.',
  noBundles: 'An error happened while getting the content, please ask your administrator to check the account configuration.',
  personalInvitationAlreadyExists: 'You already have a personal invitation',
  profileRequired: 'Profile is required.',
  rateLimit: 'Too many requests. Rate limit exceeded. Please try again in a minute.',
  replyConnectionRequest: 'An error happened while replying the request, please try again later.',
  resetPasswordRequired: 'You have not yet specified a password for your profile. Please reset your password before attempting to login.',
  selfPersonalInvitation: 'An error happened while sending the invitation to Simpli5 Relationships. Please try again later.',
  sendConnectionRequest: 'An error happened while sending the connection request.',
  sessionExpired: 'Your session expired. Please sign in again to continue.',
  setDefaultAccountError: 'An error happened while setting the default network',
  ssoLoginFailed: 'An error happened during single sign in process. Please contact your administrator.',
  tokenBuyerTokensAssigned: 'You cannot remove the Token Buyer role because the user has tokens assigned. Please reassign them first.',
  tokenExpiredResetPassword: 'Token has already been used.',
  updateAvatarError: 'An error happened while updating your avatar. Please try again later.',
  updateDepartmentError: 'An error happened while updating the department. Please try again later.',
  updateLogo: 'Update logo failed, please try again later.',
  uploadLogoError: 'An error happened while uploading the logo. Please try again later.',
  videoLoadError: 'An error happened while loading the dashboard videos.',
};
export const es = en;
