import { merge } from 'lodash';
import { name } from 'helpers';

export default class Invite {
  static mapInstance(array) {
    return array
      .map((item) => new Invite(item));
  }

  constructor(options) {
    const defaults = {
      account: {
        id: null,
        isPersonal: false,
        logo: null,
        name: null,
        nameAbbreviation: null,
        siteUrl: null,
      },
      createdAt: null,
      email: null,
      firstName: null,
      invitationSender: null,
      isInvitationValid: false,
      isNewUser: false,
      lastName: null,
      status: null,
    };

    const {
      meta = {},
      ...rest
    } = options;

    merge(this, defaults, rest);

    this._isInvite = true;
    this._meta = meta;
    this._type = 'invite';
  }

  get name() {
    return name.call(this);
  }
}
