/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import { get, isEqual, uniqBy } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Person, Team } from 'classes';
import { MEMBERS, TEAM } from 'constants/props';
import { SORT_DIRECTION, TEAM_GRID_SORT_COLUMNS } from 'constants/sort';
import api from 'api';
import InfiniteScrollList from 'generics/InfiniteScrollList';

import styles from './TeamGrid.scss';
import TeamGridHeader from './components/TeamGridHeader';
import TeamGridRow from './components/TeamGridRow';

const TeamGrid = ({ accountId, className, members, onChange, team }) => {
  const [currentMembers, setCurrentMembers] = useState(null);
  const [hasMoreMembers, setHasMoreMembers] = useState(null);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [pageIndex, setPageIndex] = useState(null);
  const [sort, setSort] = useState({
    column: TEAM_GRID_SORT_COLUMNS.NAME,
    direction: SORT_DIRECTION.ASC,
  });
  const [teamMembers, setTeamMembers] = useState(null);
  const [isHandleFinished, setIsHandleFinished] = useState(false);

  let infiniteScrollRef = useRef(null);
  const thumb = useRef(null);

  const getInitialState = (newMembers) => {
    const {
      hasMorePages: newHasMoreMembers,
      list: newTeamMembers,
      pageIndex: newPageIndex,
    } = newMembers;

    setHasMoreMembers(newHasMoreMembers);
    setCurrentMembers(newMembers);
    setSelectedProfileId(null);
    setPageIndex(newPageIndex);
    setSort({
      column: TEAM_GRID_SORT_COLUMNS.NAME,
      direction: SORT_DIRECTION.ASC,
    });
    setTeamMembers(newTeamMembers);
  };

  if (!isEqual(members, currentMembers)) {
    getInitialState(members);
  }

  useEffect(() => {
    if (members) {
      if (!isEqual(currentMembers, members) && infiniteScrollRef?.infiniteScrollRef?.resetIndex) {
        infiniteScrollRef.infiniteScrollRef.resetIndex();
      }
    }
  }, [members]);

  const handleResetSelection = () => {
    handleSelectProfile();
  };

  const handleSort = (newSort) => {
    setPageIndex(1);
    setSort(newSort);
    setTeamMembers([]);
    setIsHandleFinished(true);
  };

  useEffect(() => {
    if (isHandleFinished && pageIndex === 1 && teamMembers.length === 0) {
      if (infiniteScrollRef?.infiniteScrollRef?.resetIndex) {
        infiniteScrollRef.infiniteScrollRef.resetIndex();
      }
      handleFetchMoreMembers(1);
      setIsHandleFinished(false);
    }
  }, [isHandleFinished]);

  const handleSelectProfile = (profile) => {
    if (onChange) {
      onChange(profile);
    }
    setSelectedProfileId(profile ? profile.id : null);
  };

  const handleFetchMoreMembers = (newPageIndex = 1) => {
    const teamId = team.id;

    const onSuccess = (response) => {
      const {
        profiles,
        meta,
      } = response;

      setHasMoreMembers(get(meta, 'morePages'));
      setTeamMembers(uniqBy([
        ...teamMembers,
        ...profiles.map((profile) => new Person(profile)),
      ], 'id'));
    };

    api.teams.getTeamMembers({ accountId, pageIndex: newPageIndex, sort, teamId }, onSuccess);
  };

  const infiniteScroll = {
    hasMorePages: hasMoreMembers,
    hideFullLink: true,
    onFetch: handleFetchMoreMembers,
    pageStart: pageIndex + 1,
  };

  return (
    <div
      className={classnames(
        styles['team-grid'],
        className,
      )}
    >
      <TeamGridHeader
        className={styles['team-grid__header']}
        onClick={handleResetSelection}
        onSort={handleSort}
        profile={team}
        sort={sort}
        selected={selectedProfileId === null}
      />

      <div
        className={styles['team-grid__body']}
      >
        <div
          className={classnames(
            'gm-scrollbar',
            '-vertical',
            styles.scrollbar,
          )}
        >
          <div
            className={classnames(
              'thumb',
              styles.thumb,
            )}
            ref={thumb}
          />
        </div>

        <div className="gm-scrollbar -horizontal">
          <div className="thumb" />
        </div>

        <div
          className={classnames(
            'gm-scroll-view',
            styles.body,
          )}
        >
          <InfiniteScrollList
            className={styles['scroll-list']}
            infiniteScroll={infiniteScroll}
            listId="team-grid-scroll-list"
            innerRef={(component) => { infiniteScrollRef = component; }}
          >
            {
              teamMembers?.map((profile) => (
                <TeamGridRow
                  key={profile.id}
                  onClick={handleSelectProfile}
                  profile={profile}
                  selected={profile.id === selectedProfileId}
                />
              ))
            }
          </InfiniteScrollList>
        </div>
      </div>
    </div>
  );
};

TeamGrid.propTypes = {
  accountId: PropTypes.number.isRequired,
  className: PropTypes.string,
  members: MEMBERS.isRequired,
  onChange: PropTypes.func,
  team: TEAM,
};

TeamGrid.defaultProps = {
  className: null,
  onChange: undefined,
  team: new Team(),
};

export default TeamGrid;
