import { PUBLISHED_TEAMS } from 'constants/teamSections';
import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/teams/constants';
import api from 'api';

// Actions

export const fetchPublishedTeamsFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_PUBLISHED_TEAMS_FAILURE,
});

export const fetchPublishedTeamsRequest = (pageIndex) => ({
  payload: pageIndex,
  type: actionTypes.FETCH_PUBLISHED_TEAMS_REQUEST,
});

export const fetchPublishedTeamsSuccess = (response) => ({
  payload: response,
  type: actionTypes.FETCH_PUBLISHED_TEAMS_SUCCESS,
});

// Thunks

export const fetchPublishedTeams = ({ pageIndex, search }, onSuccess) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());

    dispatch(fetchPublishedTeamsRequest(pageIndex));

    const handleResponse = (response) => {
      dispatch(fetchPublishedTeamsSuccess(response));

      if (onSuccess) {
        onSuccess(response);
      }
    };

    const handleError = ({ error }) => {
      dispatch(fetchPublishedTeamsFailure(error));
      throw error;
    };

    return api.teams.getTeamList(
      { accountId, pageIndex, search, sectionName: PUBLISHED_TEAMS },
      handleResponse,
      handleError,
    );
  };

  thunk.type = actionTypes.FETCH_PUBLISHED_TEAMS;

  return thunk;
};

export default fetchPublishedTeams;
