/** The dynamics labels. */
export const DYNAMICS_LABELS = [
  'Explore',
  'Excite',
  'Examine',
  'Execute',
];

/** The energy labels. */
export const ENERGY_LABELS = [
  'Reserved',
  'Deliberate',
  'Effortless',
  'Abundant',
];
