import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/network/constants';
import api from 'api';
import fetchSentRequests from 'app_modules/network/actions/fetchSentRequests';

// Actions

export const fetchSendConnectionRequestFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_SEND_CONNECTION_REQUEST_FAILURE,
});

export const fetchSendConnectionRequestRequest = () => ({
  type: actionTypes.FETCH_SEND_CONNECTION_REQUEST_REQUEST,
});

export const fetchSendConnectionRequestSuccess = (response) => ({
  payload: response,
  type: actionTypes.FETCH_SEND_CONNECTION_REQUEST_SUCCESS,
});

// Thunks

export const fetchSendConnectionRequest = (receiversIds, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());

    dispatch(fetchSendConnectionRequestRequest());

    const handleResponse = (response) => {
      dispatch(fetchSendConnectionRequestSuccess());
      dispatch(fetchSentRequests());

      if (onSuccess) {
        onSuccess(response);
      }
    };

    const handleError = ({ error }) => {
      dispatch(fetchSendConnectionRequestFailure(error));

      if (onError) {
        onError(error);
      } else {
        throw error;
      }
    };

    return api.connectionRequests.send({ accountId, receiversIds }, handleResponse, handleError);
  };

  thunk.type = actionTypes.FETCH_SEND_CONNECTION_REQUEST;

  return thunk;
};

export default fetchSendConnectionRequest;
