export const en = {
  acceptLabel: 'Accept',
  addLabel: 'Add',
  back: 'Back',
  cancelLabel: 'Cancel',
  closeLabel: 'Close',
  completedPercentage: '%d%% Completed',
  connectionsEmptyMessage: 'No Connections Selected',
  deleteLabel: 'Delete',
  doneLabel: 'Done',
  edit: 'Edit',
  error: 'Error',
  errors: {
    customMaxLength: 'This field must have a maximum of %d characters.',
    customMinLength: 'This field has to be at least %d characters long.',
    dateFromMoreRecent: '"Date To" must be more recent than "Date From"',
    email: 'This email is already being used',
    invalidDate: 'Invalid Date Format',
    invalidEmail: 'You need to provide a valid email format.',
    invalidEmailLength: 'Email should no have more than 120 characters.',
    invalidPhone: 'Phone number has a wrong format',
    maxLength: 'This field must have a maximum of 252 characters.',
    minLength: 'This field has to be at least 2 characters long.',
    noSpecialChars: 'This field should not contain special characters.',
    noSpecialCharsOrNumbers: 'This field should not contain special characters/numbers.',
    password: {
      confirmNoMatch: 'Password and Confirm Password do not match',
      digit: 'This field must contain at least one digit.',
      length: 'This field must contain at least 6 characters long',
      lowerCase: 'This field must contain at least one lower case letter',
      same: 'The new password should be different from the old password',
      specialChars: 'This field must contain at least one of these special characters ~!@#$%^&*()_+.\'',
      upperCase: 'This field must contain at least one upper case letter',
      wrongPassword: 'Invalid password',
    },
    required: 'This field is required.',
    zipCode: 'You need to provide a valid postal code format',
  },
  filterLabel: 'Filter',
  formFields: {
    accountId: 'Account Id',
    agreeTerms: 'I agree to the',
    agreeTermsDescription: 'I agree to the terms of use',
    confirmPassword: 'Confirm Password',
    email: 'Email Address',
    enterEmail: 'Enter an email address',
    enterName: 'Enter a name',
    enterNameOptional: 'Enter a name (Optional)',
    department: 'Enter a department name (Optional)',
    firstName: 'First Name',
    gender: 'Pronoun Preference',
    languages: 'Languages',
    lastName: 'Last Name',
    name: 'Name',
    password: 'Password',
    personalEmail: 'Personal E-mail address:',
    personalEmailTooltip: 'Add your personal E-mail address to have access to 5 Dynamics® Personal\'s application',
    teamDescription: 'Team Description',
    teamName: 'Team Name',
    teamOwner: 'Team Owner',
  },
  leadershipModuleTitle: '1:1 Leadership',
  language: {
    english: {
      title: 'English',
      text: 'EN',
    },
    spanish: {
      title: 'Español',
      text: 'ES',
    },
  },
  list: {
    noResults: 'No results to display yet',
  },
  listItem: {
    dateJoined: 'Date Joined',
    status: {
      pending: 'Assessment pending',
      private: 'Private profile',
    },
    addDepartment: 'Add Department',
  },
  loadingLabel: 'Loading',
  name: 'Name',
  next: 'Next',
  password: 'Password',
  passwordTooltip: 'Password must contain: at least 6 characters, at least 1 upper case letter, at least 1 number and at least 1 special character ~!@#$%^&*()_+.\'',
  previous: 'Previous',
  removeLabel: 'Remove',
  resendLabel: 'Resend',
  resetLabel: 'Reset',
  requiredField: 'Required field',
  play: 'Play',
  save: 'Save',
  search: {
    filter: {
      label: 'Filter',
    },
    button: {
      submit: 'Search',
    },
  },
  submitLabel: 'Submit',
  success: 'Success',
  termsOfUse: 'Terms of use',
  testingOnly: 'This is just for testing purposes only',
  typeSearchPerson: 'Search person',
  viewDetailsLabel: 'View Details',
};

export const es = en;
