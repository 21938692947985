import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { I18N } from 'constants/props';
import Translation from 'generics/Translation';

import styles from './Exercises.scss';

/**
 * @class Exercises
 * @description Renders exercises for Training and Video selected
 */
const Exercises = ({ trainingName, i18n, videoIndex }) => {
  const exercises = get(i18n.training.trainings[trainingName], `videos[${videoIndex}].exercises`, []);
  const parragraphs = exercises.map((content, index) => {
    const key = `p-${index}`;
    return (
      <p key={key}>
        {content}
      </p>
    );
  });

  return (
    <div className={styles.exercises}>
      <h5>
        <span>
          {i18n.training.exercises}
        </span>
      </h5>
      {parragraphs}
    </div>
  );
};

Exercises.propTypes = {
  i18n: I18N.isRequired,
  trainingName: PropTypes.string.isRequired,
  videoIndex: PropTypes.number.isRequired,
};

export default Translation(Exercises, ['training']);
