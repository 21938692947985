/* eslint-disable react-hooks/exhaustive-deps */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { sprintf } from 'sprintf-js';
import { Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { ACTIONS, I18N, RECEIVED_REQUESTS, SENT_REQUESTS } from 'constants/props';
import ActionButton from 'generics/ActionButton';
import * as networkActions from 'app_modules/network/actions';
import * as networkSelectors from 'app_modules/network/selectors';
import { Card, Header, Separator } from 'generics/Card';
import InfiniteScrollList from 'generics/InfiniteScrollList';
import Translation from 'generics/Translation';

import SharedProfileRequestsListItem from '../SharedProfileRequestsListItem';
import styles from './SharedProfileRequests.scss';

const SharedProfileRequests = ({
  actions,
  className,
  i18n,
  isFetching,
  list,
  onFetch,
  onReply,
  pageIndex,
  receivedRequests,
  sentRequests,
  setList,
  setPageIndex,
}) => {
  let infiniteScrollList = useRef(null);

  const handleAcceptConnectionRequest = (requestId) => {
    actions.fetchReplyConnectionRequest('accept', requestId, onReply);
  };

  const handleDeclineConnectionRequest = (requestId) => {
    actions.fetchReplyConnectionRequest('decline', requestId);
  };

  const handleChangeList = (newList) => {
    if (get(infiniteScrollList, 'infiniteScrollRef')) {
      infiniteScrollList.infiniteScrollRef.resetIndex();
    }

    setList(newList);
    setPageIndex(1);
  };

  const hasMorePages = () => {
    switch (list) {
      case 'received':
        return receivedRequests.meta.morePages;

      case 'sent':
        return sentRequests.meta.morePages;

      default:
        throw new Error(i18n.network.shareRequests.emptyList);
    }
  };

  const renderReceivedRequestsList = () => {
    const { requests = [] } = receivedRequests;

    return requests
      .map(({ id, connectionRequestEmisor: profile }) => {
        const { name } = profile;

        return (
          <SharedProfileRequestsListItem
            actions={[
              <ActionButton
                actionType="decline"
                className={styles.decline}
                onClick={() => { handleDeclineConnectionRequest(id); }}
                key="decline"
              />,
              <ActionButton
                actionType="accept"
                key="accept"
                onClick={() => { handleAcceptConnectionRequest(id); }}
              />,
            ]}
            key={id}
            profile={profile}
            title={sprintf(i18n.network.shareRequests.listItemTitle, name)}
          />
        );
      });
  };

  const renderSentRequestsList = () => {
    const { requests = [] } = sentRequests;

    return requests
      .map(({ connectionRequestReceiver: profile, id, status }) => {
        const { name } = profile;

        return (
          <SharedProfileRequestsListItem
            key={id}
            profile={profile}
            status={status}
            title={sprintf(i18n.network.shareRequests.listItemTitle, name)}
          />
        );
      });
  };

  const renderList = () => {
    let currentList;

    switch (list) {
      case 'received':
        currentList = renderReceivedRequestsList();
        break;

      case 'sent':
        currentList = renderSentRequestsList();
        break;

      default:
        throw new Error(i18n.network.shareRequests.emptyList);
    }

    if (currentList.length > 0) {
      return currentList;
    }

    return (
      <div className={styles.empty}>
        <span>
          {i18n.network.shareRequests.noResults}
        </span>
      </div>
    );
  };

  const infiniteScroll = {
    hasMorePages: hasMorePages(),
    listHeight: 60,
    onFetch,
  };

  return (
    <Card
      barClassName={styles.bar}
      className={className}
      isFetching={pageIndex === 1 && isFetching}
    >
      <Header
        ariaLabel={i18n.network.shareRequests.title}
      >
        <h1 title={i18n.network.shareRequests.title}>
          {i18n.network.shareRequests.title}
        </h1>
      </Header>
      <p className={styles.description}>
        {i18n.network.shareRequests.description}
      </p>
      <Tabs
        indicatorColor="primary"
        className={styles.tabs}
        onChange={(e, value) => handleChangeList(value)}
        textColor="primary"
        value={list}
      >
        <Tab
          label={i18n.network.shareRequests.tabs.received}
          title={i18n.network.shareRequests.tabs.received}
          value="received"
        />
        <Tab
          label={i18n.network.shareRequests.tabs.sent}
          title={i18n.network.shareRequests.tabs.sent}
          value="sent"
        />
      </Tabs>

      <Separator />

      <InfiniteScrollList
        infiniteScroll={infiniteScroll}
        listId="share-profile-requests-scroll-list"
        innerRef={(component) => { infiniteScrollList = component; }}
      >
        {renderList()}
      </InfiniteScrollList>
    </Card>
  );
};

SharedProfileRequests.propTypes = {
  actions: ACTIONS.isRequired,
  className: PropTypes.string,
  i18n: I18N.isRequired,
  isFetching: PropTypes.bool,
  list: PropTypes.string.isRequired,
  onFetch: PropTypes.func.isRequired,
  onReply: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  receivedRequests: RECEIVED_REQUESTS.isRequired,
  sentRequests: SENT_REQUESTS.isRequired,
  setList: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func.isRequired,
};

SharedProfileRequests.defaultProps = {
  className: null,
  isFetching: false,
};

const mapStateToProps = (state) => ({
  receivedRequests: networkSelectors.requests.received(state),
  sentRequests: networkSelectors.requests.sent(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(networkActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Translation(SharedProfileRequests, ['network']));
