import { formatRoute } from 'react-router-named-routes';
import { Link } from 'react-router-dom';
import { MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { ACCOUNT_ADMIN, MEMBER, TOKEN_BUYER } from 'roles';
import { ADMINISTRATION, MY_5_DYNAMICS, NETWORK, NEWS, SETUP_RELATIONSHIPS_ACCOUNT, TEAMS, TOKEN_ADMINISTRATION, TRAINING_CENTER } from 'constants/urls';
import { I18N, PERSON } from 'constants/props';
import { LEARNING_ACCOUNT_TYPE } from 'constants/accountTypes';
import Translation from 'generics/Translation';

import styles from './menu.scss';

export const MenuMobile = ({ i18n, isStudentWaitingResults, profile }) => {
  if (profile === null) {
    return null;
  }

  const {
    newsNotitications,
  } = profile;

  const {
    accountType,
    flags: learningMenuTabsVisibility,
    id: accountId,
    isPersonal: isPersonalAccount,
  } = profile.account;

  const {
    administrationTab: isAdministrationTabVisible,
    networkTab: isNetworkTabVisible,
    newsTab: isNewsTabVisible,
    relationshipsTab: isRelationshipsTabVisible,
    teamsTab: isTeamsTabVisible,
    tokenAdministrationTab: isTokenAdministrationTabVisible,
    trainingCenterTab: isTrainingCenterTabVisible,
  } = learningMenuTabsVisibility || {};

  // Visibility for menu tabs validations for account types
  const isCorporateAccount = accountType === 'corporate_enterprise' || accountType === 'corporate_pre_enterprise'
    || accountType === 'non_profit' || accountType === 'marketing' || accountType === 'training_partner';
  const isLearningAccount = accountType === LEARNING_ACCOUNT_TYPE;

  // Visibility for menu tabs validations for roles
  const isCorporateAdminRole = !isPersonalAccount && profile.hasRole(ACCOUNT_ADMIN);
  const isCorporateMemberRole = !isPersonalAccount && profile.hasRole(MEMBER);
  const isCorporateTokenBuyerRole = !isPersonalAccount && profile.hasRole(TOKEN_BUYER);

  const menu = [
    {
      id: 'my-5-dynamics',
      isVisible: true,
      label: i18n.menu.my5Dynamics,
      path: formatRoute(MY_5_DYNAMICS, { accountId }),
    },
    {
      id: 'network',
      isVisible: isCorporateMemberRole
        && isNetworkTabVisible,
      label: i18n.menu.network,
      path: formatRoute(NETWORK, { accountId }),
    },
    {
      id: 'teams',
      isVisible: isCorporateMemberRole
        && isTeamsTabVisible
        && !isStudentWaitingResults,
      label: i18n.menu.teams,
      path: formatRoute(TEAMS, { accountId }),
    },
    {
      id: 'training-center',
      isVisible: isCorporateMemberRole
        && isTrainingCenterTabVisible
        && (isCorporateAccount || isLearningAccount),
      label: i18n.menu.trainingCenter,
      path: formatRoute(TRAINING_CENTER, { accountId }),
    },
    {
      id: 'administration',
      isVisible: isCorporateAdminRole
        && isAdministrationTabVisible,
      label: i18n.menu.administration,
      path: formatRoute(ADMINISTRATION, {
        accountId,
        sectionId: 'people',
      }),
    },
    {
      hasNotification: newsNotitications,
      id: 'news',
      isVisible: isCorporateMemberRole
        && isNewsTabVisible,
      label: i18n.menu.news,
      path: formatRoute(NEWS, { accountId }),
    },
    {
      id: 'relationships',
      isVisible: !isStudentWaitingResults && isRelationshipsTabVisible,
      label: i18n.menu.relationships,
      path: formatRoute(SETUP_RELATIONSHIPS_ACCOUNT, { accountId }),
    },
    {
      id: 'token-administration',
      isVisible: (isCorporateAdminRole || isCorporateTokenBuyerRole)
        && isTokenAdministrationTabVisible,
      label: i18n.menu.tokenAdministration,
      path: formatRoute(TOKEN_ADMINISTRATION, {
        accountId,
        sectionId: 'people',
      }),
    },
  ];

  return menu
    .filter(({ isVisible }) => isVisible)
    .map(({ hasNotification, id, label, path }) => (
      <MenuItem key={id}>
        <Link
          className={hasNotification ? styles.notification : undefined}
          id={id}
          to={path}
          href={path}
        >
          {label}
        </Link>
      </MenuItem>
    ));
};

MenuMobile.propTypes = {
  i18n: I18N.isRequired,
  isStudentWaitingResults: PropTypes.bool.isRequired,
  profile: PERSON,
};

MenuMobile.defaultProps = {
  profile: null,
};

export default Translation(MenuMobile, ['menu']);
