/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';

import { EMAIL } from 'constants/inputTypes';
import { I18N } from 'constants/props';
import useForm from 'custom_hooks/useForm';
import useFormValidation from 'custom_hooks/useFormValidation';

const useFormSendPersonalsInvitation = ({ i18n, initialValues, onSubmit }) => {
  const { validator } = useFormValidation({ i18n });

  const handleValidate = (values) => {
    const {
      email,
    } = values;

    return {
      email: validator.validate(EMAIL, email),
    };
  };

  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values = { email: null, password: null },
  } = useForm({ onValidate: handleValidate, initialValues, onSubmit });

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  };
};

useFormSendPersonalsInvitation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
};

export default useFormSendPersonalsInvitation;
