import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './ModulesListHeader.scss';

const ModulesListHeader = (props) => {
  const {
    className,
    isUpperCase,
    tag: Tag,
    title,
  } = props;

  return (
    <div
      className={classnames(
        styles.header,
        className,
      )}
    >
      <Tag
        className={classnames(
          styles.title,
          { [styles['is-uppercase']]: isUpperCase },
        )}
        title={title}
      >
        {title}
      </Tag>
      <div className={styles.line} />
    </div>
  );
};

ModulesListHeader.propTypes = {
  className: PropTypes.string,
  isUpperCase: PropTypes.bool,
  tag: PropTypes.string,
  title: PropTypes.string.isRequired,
};

ModulesListHeader.defaultProps = {
  className: null,
  isUpperCase: false,
  tag: 'h1',
};

export default ModulesListHeader;
