import { FormValidator } from 'classes';
import { I18N } from 'constants/props';

const useFormValidation = ({ i18n }) => ({
  validator: new FormValidator(i18n),
});

useFormValidation.propTypes = {
  i18n: I18N.isRequired,
};

export default useFormValidation;
