import { BarError } from 'classes/errors';
import { fetchLoginSuccess, setUser } from 'app_modules/session/actions';
import { Person } from 'classes/';
import * as notificationActions from 'app_modules/notifications/actions';
import * as userSelectors from 'app_modules/user/selectors';
import actionTypes from 'app_modules/profile/constants';
import api from 'api';

// Actions
/**
 * failure action dispatched when there is an error
 */
export const fetchCompleteSSOProvisionFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_COMPLETE_SSO_PROVISION_FAILURE,
});

/**
 * request action dispatched
 */
export const fetchCompleteSSOProvisionRequest = () => ({
  type: actionTypes.FETCH_COMPLETE_SSO_PROVISION_REQUEST,
});

/**
 * Success action dispatched
 */
export const fetchCompleteSSOProvisionSuccess = () => ({
  type: actionTypes.FETCH_COMPLETE_SSO_PROVISION_SUCCESS,
});

// Thunks

export const fetchCompleteSSOProvision = (profile) => {
  const thunk = (dispatch, getState) => {
    const tempUserData = userSelectors.ssoProvisionData(getState());
    const { id: accountId } = tempUserData.defaultAccount;
    const { ssoToken } = tempUserData;

    dispatch(fetchCompleteSSOProvisionRequest());

    const handleResponse = ({ profile: updatedProfile }) => {
      const newProfile = new Person({
        ...updatedProfile,
      });

      const user = {
        ...tempUserData,
        profiles: [newProfile],
        ssoToken,
      };

      Promise.all([
        dispatch(setUser(user)),
        dispatch(fetchLoginSuccess()),
        dispatch(fetchCompleteSSOProvisionSuccess()),
      ]);
    };

    const handleError = ({ error, isConnectionError }) => {
      dispatch(fetchCompleteSSOProvisionFailure(error));
      if (!isConnectionError) {
        dispatch(notificationActions.notifyError(
          new BarError({
            ...error,
            label: 'fetchCompleteSSOProvisionError',
          }),
        ));
      }
    };

    return api.profiles.completeSSOProvision(
      { accountId, profile, ssoToken },
      handleResponse,
      handleError,
    );
  };

  thunk.type = actionTypes.FETCH_COMPLETE_SSO_PROVISION;

  return thunk;
};

export default fetchCompleteSSOProvision;
