import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as groupActions from 'app_modules/groups/actions';
import * as groupsSelectors from 'app_modules/groups/selectors';
import * as pageActions from 'app_modules/page/actions';
import * as sessionSelectors from 'app_modules/session/selectors';

import ModalGroup from 'components/PageAdministration/components/ModalGroup';

const mapStateToProps = (state) => ({
  group: groupsSelectors.currentGroup(state).group,
  profile: sessionSelectors.currentProfile(state),
});

const mapDispatchToProps = (dispatch) => ({
  groupActions: bindActionCreators(groupActions, dispatch),
  pageActions: bindActionCreators(pageActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalGroup);
