import * as archiveGroup from './archiveGroup';
import * as fetchGroupDetails from './fetchGroupDetails';
import * as fetchGroups from './fetchGroups';
import * as fetchResendInvitation from './fetchResendInvitation';
import * as fetchUpdateGroup from './fetchUpdateGroup';
import * as fetchUpdateGroupInvitationEmails from './fetchUpdateGroupInvitationEmails';
import * as fetchUpdateGroupInvitations from './fetchUpdateGroupInvitations';

module.exports = {
  ...archiveGroup,
  ...fetchGroupDetails,
  ...fetchGroups,
  ...fetchResendInvitation,
  ...fetchUpdateGroup,
  ...fetchUpdateGroupInvitationEmails,
  ...fetchUpdateGroupInvitations,
};
