import { MODAL_NOTIFICATION } from 'constants/notificationTypes';

import AppError from './AppError.class';

export default class ModalError extends AppError {
  constructor(error, options) {
    super({
      ...error,
      notificationType: MODAL_NOTIFICATION,
    });
    this.options = options;
  }
}
