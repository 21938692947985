export const invitation = (state) => state.invites.invitation;

export const email = (state) => state.invites.invitation.email;

export const name = (state) => {
  const {
    firstName,
    lastName,
  } = invitation(state);

  return {
    firstName,
    lastName,
  };
};

export const sent = (state) => state.invites.sent;
