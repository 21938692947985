import * as fetchCompleteSSOProvision from './fetchCompleteSSOProvision';
import * as fetchCreateUser from './fetchCreateUser';
import * as fetchDeleteProfile from './fetchDeleteProfile';
import * as fetchEditProfile from './fetchEditProfile';
import * as updateAvatar from './updateAvatar';

module.exports = {
  ...fetchCompleteSSOProvision,
  ...fetchCreateUser,
  ...fetchDeleteProfile,
  ...fetchEditProfile,
  ...updateAvatar,
};
