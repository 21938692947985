/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@material-ui/core';
import DocumentTitle from 'react-document-title';
import React, { useEffect, useState } from 'react';

import { ACTIONS, MATCH } from 'constants/props';
import Mask from 'generics/Mask';
import styles from './PageSSOLogin.scss';

/**
 * @class PageSSOLogin
 * @description receives SSO redirection once backend assertion is successful
 */
const PageSSOLogin = ({
  match,
  sessionActions,
}) => {
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const { token } = match.params;

    sessionActions.fetchSSOLogin(token)
      .then(() => {
        setIsFetching(false);
      })
      .catch(() => {
        setIsFetching(false);
      });

    setIsFetching(true);
  }, []);

  return (
    <DocumentTitle title="Simpli5® - SSO Login Page">
      <Mask
        className={styles.mask}
        open={isFetching}
      >
        <CircularProgress
          className={styles.loader}
          size={60}
          thickness={7}
        />
      </Mask>
    </DocumentTitle>
  );
};

PageSSOLogin.propTypes = {
  match: MATCH.isRequired,
  sessionActions: ACTIONS.isRequired,
};

export default PageSSOLogin;
