import { BarError } from 'classes/errors';
import { notifyError } from 'app_modules/notifications/actions';
import sessionActionTypes from 'app_modules/session/constants';
import * as sessionSelectors from 'app_modules/session/selectors';
import api from 'api';

// Actions

export const setDefaultAccountFailure = (error) => ({
  payload: error,
  type: sessionActionTypes.SET_DEFAULT_ACCOUNT_FAILURE,
});

export const setDefaultAccountRequest = () => ({
  type: sessionActionTypes.SET_DEFAULT_ACCOUNT_REQUEST,
});

export const setDefaultAccountSuccess = (account) => ({
  payload: account,
  type: sessionActionTypes.SET_DEFAULT_ACCOUNT_SUCCESS,
});

// Thunks

export const setDefaultAccount = (accountId) => {
  const thunk = (dispatch, getState) => {
    const state = getState();
    const user = sessionSelectors.user(state);
    const { profiles } = user;
    const accountProfile = profiles.find((profile) => profile.account.id === accountId);
    const currentAccountId = sessionSelectors.accountId(state);

    dispatch(setDefaultAccountRequest());

    const handleResponse = () => dispatch(setDefaultAccountSuccess(accountProfile.account));

    const handleError = ({ error, isConnectionError }) => {
      dispatch(setDefaultAccountFailure(error));
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'setDefaultAccountError' })));
      }
    };

    return api.accounts.setDefaultAccount(
      { currentAccountId, accountId },
      handleResponse,
      handleError,
    );
  };

  thunk.type = sessionActionTypes.SET_DEFAULT_ACCOUNT;

  return thunk;
};

export default setDefaultAccount;
