import * as notificationTypes from 'constants/notificationTypes';

import actionTypes from '../constants';

const ERROR_TYPE = 'error';
const WARNING_TYPE = 'warning';
const SUCCESS_TYPE = 'success';

export const addBarNotification = (notification) => ({
  type: actionTypes.ADD_BAR_NOTIFICATION,
  payload: notification,
});

export const clearBarNotification = () => ({
  type: actionTypes.CLEAR_BAR_NOTIFICATION,
});

export const addModalNotification = (notification) => ({
  type: actionTypes.ADD_MODAL_NOTIFICATION,
  payload: notification,
});

export const clearModalNotification = () => ({
  type: actionTypes.CLEAR_MODAL_NOTIFICATION,
});

export const notifyError = ({ notificationType, label, message, options }) => {
  const thunk = (dispatch) => {
    switch (notificationType) {
      case notificationTypes.MODAL_NOTIFICATION:
        dispatch(addModalNotification({ options }));
        break;

      case notificationTypes.BAR_NOTIFICATION:
        dispatch(addBarNotification({
          label,
          message,
          options: { ...(options || {}), type: ERROR_TYPE },
        }));
        break;

      default:
        throw new Error('No error to notify');
    }
  };

  thunk.type = actionTypes.NOTIFY_ERROR;
  return thunk;
};

export const notifySuccess = (message) => {
  const thunk = (dispatch) => {
    dispatch(addBarNotification({
      message,
      options: { type: SUCCESS_TYPE },
    }));
  };
  thunk.type = actionTypes.NOTIFY_SUCCESS;
  return thunk;
};

export const notifyWarning = ({ label, message }, options) => {
  const thunk = (dispatch) => {
    dispatch(addBarNotification({
      label,
      message,
      options: { ...(options || {}), type: WARNING_TYPE },
    }));
  };
  thunk.type = actionTypes.NOTIFY_SUCCESS;
  return thunk;
};
