import { CircularProgress } from '@material-ui/core';
import { formatRoute } from 'react-router-named-routes';
import { isNil, kebabCase } from 'lodash';
import { Link } from 'react-router-dom';
import { FaPencilAlt } from 'react-icons/fa';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { ACCOUNT_ADMIN } from 'roles';
import { I18N, PEOPLE_LIST_ITEM, PERSON } from 'constants/props';
import { LEARNING_ACCOUNT_TYPE } from 'constants/accountTypes';
import { NETWORK_DASHBOARD } from 'urls';
import { StyledCheckbox } from 'generics/StyledFormComponents';
import Avatar from 'generics/Avatar';
import Icon from 'generics/Icon';
import ListItemActions from 'generics/ListItemActions';
import Mask from 'generics/Mask';
import Translation from 'generics/Translation';

import styles from './peopleListItem.scss';

const formatDate = (date) => date && moment(new Date(date)).format('L');

const renderNameContent = (accountId, item, isDisabled, isLinkProfile, hasDate, testAttribute) => {
  const {
    id: profileId,
    isVisible,
    name,
  } = item;

  if (isVisible && !isDisabled && isLinkProfile && accountId) {
    const networkDashboardRoute = formatRoute(NETWORK_DASHBOARD, {
      accountId,
      contentType: 'individual',
      profileId,
    });

    return (
      <Link
        className={classnames(
          styles.item__name,
          styles['item__name--link'],
          { [styles['item__name--has-date']]: hasDate },
        )}
        href={networkDashboardRoute}
        title={name}
        data-test={testAttribute}
        to={networkDashboardRoute}
      >
        {name}
      </Link>
    );
  }
  return (
    <span
      className={styles.item__name}
      title={name}
    >
      {name}
    </span>
  );
};

const PeopleListItem = (props) => {
  const {
    accountId,
    actions,
    assessmentStatus,
    className,
    currentProfile,
    dateLabel,
    hasDate,
    i18n,
    isCheckboxDisabled,
    isChecked,
    isDisabled,
    isDepartmentEnabled,
    isFetching,
    isMultipleSelection,
    isWorkshop,
    item,
    listItemTestAttribute,
    listItemNameTestAttribute,
    onChange,
    setIsEditDepartment,
    setSelectedDepartment,
    showEmail,
  } = props;

  const {
    assessmentScores,
    createdAt,
    department,
    isPublic,
    name,
    status,
    email,
  } = item;

  const {
    account: {
      accountType,
    } = {},
  } = currentProfile || {};

  const date = formatDate(createdAt);
  const dateText = dateLabel || i18n.generics.listItem.dateJoined;

  const showLabelStatus = isNil(status) && (!isPublic || assessmentScores.isNull);
  const showWorkshopMemberPrivateLabel = isWorkshop && isPublic === false;

  const isLinkProfile = (
    currentProfile?.hasRole(ACCOUNT_ADMIN)
      && accountType !== LEARNING_ACCOUNT_TYPE
  );

  const handleSelectDepartment = () => {
    setSelectedDepartment(
      {
        profileId: item?.id,
        department,
      },
    );
    setIsEditDepartment(true);
  };

  return (
    <li
      className={classnames(
        styles.item,
        className,
      )}
      data-test={listItemTestAttribute}
    >
      {isMultipleSelection && (
        <StyledCheckbox
          ariaLabel={item.name}
          aria-disabled={(isCheckboxDisabled && !isChecked)}
          className={classnames(
            styles.checkbox,
            { [styles['is-not-visible']]: assessmentStatus !== 0 },
          )}
          checked={isChecked}
          color="primary"
          disabled={(isCheckboxDisabled && !isChecked)}
          onChange={onChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onChange(e);
            }
          }}
        />
      )}
      <div
        className={classnames(
          styles.item__col,
          styles['item__col--avatar'],
          { [styles['student--avatar']]: isMultipleSelection },
        )}
      >
        <Avatar
          className={styles.item__avatar}
          icon={{
            className: styles['item__avatar-icon'],
            name: 'user',
          }}
          profile={item}
          title={`${name}'s avatar`}
        />
      </div>

      <div
        className={classnames(
          styles.item__col,
          styles['item__col--content'],
          { [styles['content-name-department']]: isDepartmentEnabled },
          { [styles['content-multiple-selection']]: isMultipleSelection },
        )}
      >
        {renderNameContent(accountId, item, isDisabled, isLinkProfile, (hasDate && !!date), listItemNameTestAttribute)}
        {
          showLabelStatus && (
            <span className={styles.item__status}>
              <Icon.Stroke7 name={!isPublic ? 'lock' : 'wristwatch'} />
              {
                !isPublic
                  ? i18n.generics.listItem.status.private
                  : i18n.generics.listItem.status.pending
              }
            </span>
          )
        }
        {
          showWorkshopMemberPrivateLabel && (
            <span className={styles.item__status}>
              <Icon.Stroke7 name="lock" />
              {
                i18n.generics.listItem.status.private
              }
            </span>
          )
        }
        {
          hasDate && date && (
            <p
              className={styles.item__date}
              title={`${dateText}: ${date}`}
            >
              <span className={styles['item__date-title']}>
                {`${dateText}:`}
              </span>
              <time dateTime={createdAt}>
                {date}
              </time>
            </p>
          )
        }
        {
          showEmail && email && (
            <span
              className={styles.item__email}
              title={email}
            >
              {email}
            </span>
          )
        }
      </div>

      {
        (!!department && isDepartmentEnabled) && (
          <div
            className={classnames(
              styles.item__col,
              styles['item__col--content'],
              styles['department-container'],
            )}
            onClick={() => handleSelectDepartment()}
            onKeyDown={() => handleSelectDepartment()}
            role="button"
            tabIndex={0}
          >
            <FaPencilAlt
              className={styles['edit-department']}
            />
            <span
              title={department}
            >
              {department}
            </span>
          </div>
        )
      }

      {
        (!department && isDepartmentEnabled) && (
          <div
            className={classnames(
              styles.item__col,
              styles['item__col--content'],
              styles['add-department-container'],
            )}
            onClick={() => handleSelectDepartment()}
            onKeyDown={() => handleSelectDepartment()}
            role="button"
            tabIndex={0}
          >
            <div
              className={styles['add-department']}
            >
              <FaPencilAlt />
            </div>
            <span
              title={i18n.generics.listItem.addDepartment}
            >
              {i18n.generics.listItem.addDepartment}
            </span>
          </div>
        )
      }

      <div className={classnames(
          styles['left-margin'],
          { [styles['has-department']]: isDepartmentEnabled },
        )}
      >
        {
          !!status && (
            <div
              className={classnames(
                styles.item__col,
                styles['item__col--badge'],
              )}
            >
              <span
                className={classnames(
                  styles.item__badge,
                  styles[`item__badge--${kebabCase(status.toLowerCase())}`],
                )}
                title={status}
              >
                {status}
              </span>
            </div>
          )
        }

        {
          actions && (
            <ListItemActions
              actions={actions}
              className={classnames(
                styles.item__col,
                styles['item__col--actions'],
              )}
            />
          )
        }
      </div>

      <Mask
        className={styles.item__mask}
        open={isFetching}
      >
        <CircularProgress
          className={styles.item__loader}
          size={25}
          thickness={2}
        />
      </Mask>
    </li>
  );
};

PeopleListItem.propTypes = {
  accountId: PropTypes.number,
  actions: PropTypes.arrayOf(PropTypes.node),
  assessmentStatus: PropTypes.number,
  className: PropTypes.string,
  currentProfile: PERSON,
  dateLabel: PropTypes.string,
  hasDate: PropTypes.bool,
  i18n: I18N.isRequired,
  isCheckboxDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  isDepartmentEnabled: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  isFetching: PropTypes.bool,
  isMultipleSelection: PropTypes.bool,
  isWorkshop: PropTypes.bool,
  item: PEOPLE_LIST_ITEM.isRequired,
  listItemTestAttribute: PropTypes.string,
  listItemNameTestAttribute: PropTypes.string,
  onChange: PropTypes.func,
  setIsEditDepartment: PropTypes.func.isRequired,
  setSelectedDepartment: PropTypes.func.isRequired,
  showEmail: PropTypes.bool,
};

PeopleListItem.defaultProps = {
  accountId: null,
  actions: null,
  assessmentStatus: null,
  className: null,
  currentProfile: null,
  dateLabel: null,
  hasDate: true,
  isCheckboxDisabled: false,
  isChecked: null,
  isDisabled: false,
  isFetching: false,
  isMultipleSelection: false,
  isWorkshop: false,
  listItemTestAttribute: null,
  listItemNameTestAttribute: null,
  onChange: null,
  showEmail: false,
};

export default Translation(PeopleListItem, ['generics']);
