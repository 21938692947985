/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@material-ui/core';
import { Col, Container, Row } from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { COLORS } from 'constants/colors';
import { I18N } from 'constants/props';
import { StyledButton } from 'generics/StyledFormComponents';
import api from 'api';
import Icon from 'generics/Icon';
import Mask from 'generics/Mask';
import ModulesListItem from 'generics/ModulesListItem';
import Translation from 'generics/Translation';

import FormProfileNotes from './components/FormProfileNotes';
import ModalProfileNotes from './components/ModalProfileNotes/ModalProfileNotes';
import styles from './ProfileNotes.scss';

const ProfileNotes = ({
  accountId,
  i18n,
  name,
  profileId,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isOpenProfileNotesModal, setIsOpenProfileNotesModal] = useState(false);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);

  useEffect(() => {
    if (selectedNote) {
      setIsOpenProfileNotesModal(true);
    }
  }, [selectedNote]);

  const createProfileNote = (values) => {
    const onSuccess = ({ profileNote }) => {
      setNotes([...notes, profileNote]);
      setIsOpenProfileNotesModal(false);
    };

    const onError = (error) => {
      console.log(error);
    };

    const profileNote = {
      profileNote: {
        friendProfileId: profileId,
        ...values,
      },
    };

    api.profiles.createProfileNote({ accountId, profileNote }, onSuccess, onError);
  };

  const deleteProfileNote = (noteId) => {
    const onSuccess = () => {
      const newNotes = [...notes];
      newNotes.splice(selectedNote.index, 1);
      setNotes(newNotes);
      setSelectedNote(null);
      setIsOpenProfileNotesModal(false);
    };

    const onError = (error) => {
      console.log(error);
    };

    api.profiles.deleteProfileNote({ accountId, noteId }, onSuccess, onError);
  };

  const fetchProfileNotes = () => {
    setIsFetching(true);
    const onSuccess = ({ profileNotes }) => {
      setNotes(profileNotes);
      setIsFetching(false);
    };

    const onError = (error) => {
      console.log(error);
      setIsFetching(false);
    };

    api.profiles.getProfileNotes({ accountId, profileId }, onSuccess, onError);
  };

  const updateProfileNote = (values, noteId) => {
    const onSuccess = ({ profileNote }) => {
      const newNotes = [...notes];
      newNotes[selectedNote.index].description = profileNote.description;
      newNotes[selectedNote.index].title = profileNote.title;
      newNotes[selectedNote.index].updatedAt = profileNote.updatedAt;
      setNotes(newNotes);
      setSelectedNote(null);
      setIsOpenProfileNotesModal(false);
    };

    const onError = (error) => {
      console.log(error);
    };

    const profileNote = {
      profileNote: {
        ...values,
      },
    };

    api.profiles.updateProfileNote({ accountId, profileNote, noteId }, onSuccess, onError);
  };

  useEffect(() => {
    if (!notes.length) {
      fetchProfileNotes();
    }
  }, []);

  const handleCloseProfileNotesModal = () => {
    setSelectedNote(null);
    setIsOpenProfileNotesModal(false);
  };

  const handleExport = () => {
    api.profiles.exportNotes({ accountId, name, profileId });
  };

  return (
    <div className={styles.container}>
      <div className={styles['notes-menu']}>
        {!!notes.length && (
          <StyledButton
            className={styles['menu-opt']}
            onClick={handleExport}
            title={i18n.notes.exportNotes}
            variant="text"
          >
            <Icon.Stroke7
              className={styles['menu-opt__icon']}
              name="download"
            />
            <span className={styles['menu-opt__text']}>
              {i18n.notes.exportNotes}
            </span>
          </StyledButton>
        )}
      </div>
      <Container fluid>
        <Row>
          <Col xs="12" lg="6">
            <ModulesListItem
              categoryColor={COLORS.orangePumpkin.rgba}
              className={classnames(
                styles.card,
              )}
              description={(
                <div className={styles['add-note-info-container']}>
                  <Icon.Stroke7 className={styles['add-note-icon']} name="plus" />
                  <p>{i18n.notes.addNoteTitle}</p>
                </div>
              )}
              onClick={() => setIsOpenProfileNotesModal(true)}
            />
          </Col>
          {notes.map(({
            description,
            id,
            title,
            updatedAt,
          }, index) => (
            <Col xs="12" lg="6">
              <ModulesListItem
                category={`${i18n.notes.dateLabel} ${moment(updatedAt).format('MM/DD/YYYY')}`}
                categoryColor={COLORS.orangePumpkin.rgba}
                className={classnames(
                  styles.card,
                )}
                description={description}
                icon="note"
                onClick={() => setSelectedNote({ description, id, index, title })}
                title={title}
                updatedAt={updatedAt}
              />
            </Col>
          ))}
        </Row>
      </Container>
      {isOpenProfileNotesModal && (
        <ModalProfileNotes
          onClose={() => handleCloseProfileNotesModal()}
        >
          <FormProfileNotes
            initialValues={!selectedNote ? null : { ...selectedNote }}
            onDelete={() => deleteProfileNote(selectedNote.id)}
            onSubmit={!selectedNote
              ? createProfileNote
              : (values) => updateProfileNote(values, selectedNote.id)}
          />
        </ModalProfileNotes>
      )}
      {isFetching && (
        <Mask
          className={styles.mask}
          open={isFetching}
        >
          <CircularProgress
            className={styles.loader}
            size={60}
            thickness={7}
          />
        </Mask>
      )}
    </div>
  );
};

ProfileNotes.propTypes = {
  accountId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  name: PropTypes.string.isRequired,
  profileId: PropTypes.number.isRequired,
};

export default Translation(ProfileNotes, ['notes']);
