import { combineReducers } from 'redux';

import initialState from 'app_modules/store/initialState.json';
import pageActionTypes from 'app_modules/page/constants';
import sessionActionTypes from 'app_modules/session/constants';

export const bar = (state = initialState.page.bar, action) => {
  const {
    payload,
    type: actionType,
  } = action;

  switch (actionType) {
    case pageActionTypes.SET_MODULE:
      return payload.bar;

    case sessionActionTypes.RESET_STATE:
      return initialState.page.bar;

    default:
      return state;
  }
};

export const isFetching = (state = initialState.page.isFetching, action) => {
  switch (action.type) {
    case pageActionTypes.FETCH_REQUEST:
      return true;

    case pageActionTypes.FETCH_FAILURE:
    case pageActionTypes.FETCH_SUCCESS:
      return false;

    case sessionActionTypes.RESET_STATE:
      return initialState.page.isFetching;
    default:
      return state;
  }
};

export const isModalView = (state = initialState.page.isModalView, { type }) => {
  switch (type) {
    case pageActionTypes.SWITCH_MODAL_VIEW:
      return !state;
    case sessionActionTypes.RESET_STATE:
      return initialState.page.isModalView;
    default:
      return state;
  }
};

export const style = (state = initialState.page.style, action) => {
  const {
    payload,
    type: actionType,
  } = action;

  switch (actionType) {
    case pageActionTypes.SET_MODULE:
      return payload.styles;

    case sessionActionTypes.RESET_STATE:
      return initialState.page.style;

    default:
      return state;
  }
};

export default combineReducers({
  bar,
  isFetching,
  isModalView,
  style,
});
