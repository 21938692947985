export const en = {
  administration: 'Administration',
  my5Dynamics: 'My 5 Dynamics',
  network: 'Network',
  news: 'News',
  relationships: 'Relationships',
  teams: 'Teams',
  tokenAdministration: 'Token Administration',
  trainingCenter: 'Training Center',
};

export const es = en;
