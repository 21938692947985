import { get } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ACCOUNT, AVATAR_ICON, INVITATION, PROFILE } from 'constants/props';
import Icon from 'generics/Icon';

import styles from './Avatar.scss';

const Avatar = ({
  className,
  default: isDefault,
  hasBorder,
  icon: propsIcon,
  profile: nextProfile,
  src: nextSrc,
  title: titleProps,
}) => {
  const [imageSrc, setImageSrc] = useState(nextSrc || get(nextProfile, 'avatar') || get(nextProfile, 'logo'));
  const [currentProfile, setCurrentProfile] = useState(null);
  const [currentSrc, setCurrentSrc] = useState(null);

  /**
   * Changes the image src if the src or profile/avatar were updated
   */
  useEffect(() => {
    if (!currentProfile) {
      setCurrentProfile(nextProfile);
    }
    const avatar = get(currentProfile, 'avatar') || get(currentProfile, 'logo');
    const nextAvatar = get(nextProfile, 'avatar') || get(nextProfile, 'logo');

    if (avatar !== nextAvatar) {
      setImageSrc(nextAvatar);
      setCurrentProfile(nextProfile);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextProfile]);

  useEffect(() => {
    if (!currentSrc) {
      setCurrentSrc(nextSrc);
    }

    if (currentSrc !== nextSrc) {
      setImageSrc(nextSrc);
      setCurrentSrc(nextSrc);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextSrc]);

  /**
   * handles image error, changes the image src in order to use default avatar
   */
  const handleError = () => {
    setImageSrc(null);
  };

  const defaultIcon = {
    className: null,
    name: 'user',
  };

  const icon = {
    ...defaultIcon,
    ...propsIcon,
  };

  const {
    className: classNameAvatarIcon,
    name,
  } = icon;

  let title;

  if (currentProfile) {
    const {
      firstName,
      lastName,
    } = currentProfile;

    // TODO: Replace by 'name' from 'Person' instance
    title = `${firstName} ${lastName}` || titleProps;
  }

  return (
    <div
      className={classnames(
        styles.avatar,
        { [styles['has-border']]: hasBorder },
        { [styles.default]: isDefault },
        className,
      )}
      title={title}
    >
      {
        imageSrc && (
          <img
            alt={title}
            data-test="avatar_img"
            className={styles.avatar__img}
            src={imageSrc}
            onError={handleError}
          />
        )
      }

      {
        !imageSrc && (
          <div
            className={classnames(
              styles.avatar__icon,
              classNameAvatarIcon,
            )}
          >
            <Icon.Stroke7 name={name} />
          </div>
        )
      }
    </div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  default: PropTypes.bool,
  hasBorder: PropTypes.bool,
  icon: AVATAR_ICON,
  profile: PropTypes.oneOfType([
    ACCOUNT,
    INVITATION,
    PROFILE,
  ]),
  src: PropTypes.string,
  title: PropTypes.string,
};

Avatar.defaultProps = {
  className: null,
  default: false,
  hasBorder: false,
  icon: {
    className: null,
    name: 'user',
  },
  profile: null,
  src: null,
  title: 'Avatar',
};

export default Avatar;
