import { useState } from 'react';

import { ACCEPTED } from 'constants/invitationStatus';
import { BarError } from 'classes/errors';
import { SELF_PERSONAL_ALREADY_CREATED } from 'constants/apiErrorCodes';
import api from 'api';

const useRelationships = ({ accountId, notificationActions, selfInvitation, userActions }) => {
  const invite = selfInvitation?.invite;
  const [isFetching, setIsFetching] = useState(false);
  const [isEditing, setIsEditing] = useState(!invite);
  const [emailError, setEmailError] = useState('');
  const ssoToken = invite?.linkAccToken ?? '';

  const updatePersonalState = (selfInvite) => {
    userActions.setPersonalsSelfInvitation(selfInvite);
    setIsEditing(false);
    setEmailError('');
  };

  const onSuccess = ({ anyErrors, invite: invitation = null }) => {
    if (anyErrors) {
      setEmailError(invitation.errors[0]);
    } else {
      updatePersonalState({ hasPersonal: false, invite: invitation });
    }
    setIsFetching(false);
  };

  const onError = ({ error }) => {
    setIsFetching(false);
    let label = 'selfPersonalInvitation';

    if (error.errorCode === SELF_PERSONAL_ALREADY_CREATED) {
      label = 'personalInvitationAlreadyExists';
      updatePersonalState({
        hasPersonal: true,
        invite: {
          ...invite,
          status: ACCEPTED,
        },
      });
    }

    notificationActions.notifyError(
      new BarError({
        ...error,
        label,
      }),
    );
  };

  const handleSubmit = ({ email }) => {
    setIsFetching(true);
    const isResend = !!invite;
    api.invites.sendPersonalInvitation({ accountId, email, isResend }, onSuccess, onError);
  };

  const handleResend = () => {
    const { email } = invite;
    handleSubmit({ email });
  };

  const handleCopyToken = () => {
    navigator.clipboard.writeText(ssoToken);
  };

  return {
    email: invite?.email,
    emailError,
    hasPersonalAccount: !!selfInvitation.hasPersonal,
    isEditing,
    isFetching,
    isInviteAccepted: selfInvitation?.invite?.status === ACCEPTED,
    onChangeEmail: () => setIsEditing(true),
    onClearError: () => setEmailError(''),
    onCopyToken: handleCopyToken,
    onResend: handleResend,
    onSubmit: handleSubmit,
    ssoToken,
  };
};

export default useRelationships;
