import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as notificationActions from 'app_modules/notifications/actions';
import PageResetPassword from 'components/PageResetPassword';

const mapDispatchToProps = (dispatch) => ({
  notificationActions: bindActionCreators(notificationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(PageResetPassword);
