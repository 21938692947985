import { get, isEmpty } from 'lodash';
import { replace } from 'connected-react-router';

import { ACCEPTED, PENDING, PRE_ACCEPTED } from 'constants/invitationStatus';
import { addProfile } from 'app_modules/session/actions/setProfile';
import { AppError, BarError } from 'classes/errors';
import { notifyError } from 'app_modules/notifications/actions';
import actionTypes from 'app_modules/invites/constants';
import api from 'api';

// Actions

export const clearInvitation = () => ({
  type: actionTypes.CLEAR_INVITATION,
});

export const fetchAcceptInvitationFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_ACCEPT_INVITATION_FAILURE,
});

export const fetchAcceptInvitationRequest = () => ({
  type: actionTypes.FETCH_ACCEPT_INVITATION_REQUEST,
});

export const fetchAcceptInvitationSuccess = (invitation) => ({
  payload: invitation,
  type: actionTypes.FETCH_ACCEPT_INVITATION_SUCCESS,
});

// Thunks

export const acceptInvitation = (clear, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const { invitation } = getState().invites;
    const accountId = get(invitation, 'account.id');
    let isPreAcceptedInvitation = false;

    const {
      isNewUser,
      status,
      token,
    } = invitation;

    if (isNewUser && status === PENDING) {
      if (!clear) {
        dispatch(fetchAcceptInvitationSuccess({
          ...invitation,
          status: PRE_ACCEPTED,
        }));
        isPreAcceptedInvitation = true;
      } else {
        dispatch(clearInvitation());
      }

      if (onSuccess) {
        onSuccess();
      }
    } else {
      dispatch(fetchAcceptInvitationRequest());

      const handleResponse = ({ profile }) => {
        if (isEmpty(profile)) {
          throw new AppError({ label: 'profileRequired' });
        }

        if (!clear) {
          dispatch(fetchAcceptInvitationSuccess({ ...invitation, status: ACCEPTED }));
        } else if (!isPreAcceptedInvitation) {
          dispatch(clearInvitation());
        }

        dispatch(addProfile(profile));
        if (onSuccess) {
          onSuccess();
        }
      };

      const handleError = ({ error, isConnectionError }) => {
        dispatch(fetchAcceptInvitationFailure(error));
        if (!clear) {
          dispatch(clearInvitation());
        }
        if (onError) {
          onError();
        }
        dispatch(replace('/login'));
        if (!isConnectionError) {
          dispatch(notifyError(new BarError({ ...error, label: 'fetchAcceptInvitation' })));
        }
      };

      api.invites.accept({ accountId, token }, handleResponse, handleError);
    }
  };

  thunk.type = actionTypes.TRIGGER_ACCEPT_INVITATION;

  return thunk;
};

export default acceptInvitation;
