import { pickHTMLProps } from 'pick-react-known-prop';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { CHILDREN } from 'constants/props';

import styles from '../Card/card.scss';

const Main = (props) => {
  const {
    ariaLabel,
    children,
    className,
    ...rest
  } = props;

  return (
    <main
      className={classnames(
        styles.card__main,
        className,
      )}
      {...pickHTMLProps(rest)}
      role={ariaLabel ? 'presentation' : null}
      aria-label={ariaLabel}
    >
      {children}
    </main>
  );
};

Main.propTypes = {
  ariaLabel: PropTypes.string,
  children: CHILDREN,
  className: PropTypes.string,
};

Main.defaultProps = {
  ariaLabel: '',
  children: null,
  className: null,
};

export default Main;
