import { Col, Container, Row } from 'reactstrap';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { I18N } from 'constants/props';
import { StyledButton, StyledTextField } from 'generics/StyledFormComponents';
import FieldTitle from 'generics/FieldTitle';
import Translation from 'generics/Translation';
import useFormChangePassword from './useFormChangePassword';

import styles from './FormChangePassword.scss';

/**
 * FormChangePassword Component
 */
const FormChangePassword = ({
  error,
  i18n,
  isSubmitting,
  onSubmit,
}) => {
  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  } = useFormChangePassword({ i18n, onSubmit });

  const currentPasswordError = errors.currentPassword || error;

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit}
    >
      <Container>
        <Row>
          <Col xs="12" md="6">
            <FieldTitle
              fieldName={
                i18n.pageGeneralAccountInformation.formChangePassword.labelCurrentPassword
              }
              isRequired
            />
            <StyledTextField
              className={styles['text-field']}
              error={isNil(values.currentPassword) ? false : !!currentPasswordError}
              fullWidth
              helperText={isNil(values.currentPassword) ? ' ' : currentPasswordError}
              name="currentPassword"
              onChange={(e) => handleChange('currentPassword', e.currentTarget.value)}
              type="password"
              value={values.currentPassword ? values.currentPassword : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <FieldTitle
              fieldName={i18n.pageGeneralAccountInformation.formChangePassword.labelNewPassword}
              infoTooltip={i18n.generics.passwordTooltip}
              isRequired
            />
            <StyledTextField
              className={styles['text-field']}
              error={isNil(values.password) ? false : !!errors.password}
              helperText={isNil(values.password) ? ' ' : errors.password}
              name="password"
              onChange={(e) => handleChange('password', e.currentTarget.value)}
              type="password"
              value={values.password ? values.password : ''}
            />
          </Col>
          <Col xs="12" md="6">
            <FieldTitle
              fieldName={
                i18n.pageGeneralAccountInformation.formChangePassword.labelPasswordConfirmation
              }
              isRequired
            />
            <StyledTextField
              className={styles['text-field']}
              error={isNil(values.passwordConfirmation) ? false : !!errors.passwordConfirmation}
              fullWidth
              helperText={isNil(values.passwordConfirmation) ? ' ' : errors.passwordConfirmation}
              name="passwordConfirmation"
              onChange={(e) => handleChange('passwordConfirmation', e.currentTarget.value)}
              type="password"
              value={values.passwordConfirmation ? values.passwordConfirmation : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ size: 2, offset: 10 }}>
            <StyledButton
              className={styles['submit-btn']}
              color="primary"
              disabled={!isValid || isSubmitting}
              fullWidth
              title={i18n.pageGeneralAccountInformation.formChangePassword.btnTextSubmit}
              type="submit"
            >
              {i18n.pageGeneralAccountInformation.formChangePassword.btnTextSubmit}
            </StyledButton>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

FormChangePassword.propTypes = {
  error: PropTypes.string,
  i18n: I18N.isRequired,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

FormChangePassword.defaultProps = {
  error: null,
  isSubmitting: false,
};

export default Translation(FormChangePassword, ['pageGeneralAccountInformation', 'generics']);
