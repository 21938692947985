export const en = {
  accountInformationTitle: 'Account Information',
  employeeInformation: {
    labels: {
      company: 'Company',
      department: 'Department',
      division: 'Division',
      jobTitle: 'Job Title',
    },
    title: 'Employee Information',
  },
  personalInformation: {
    labels: {
      address: 'Address',
      email: 'E-mail Address',
      genderPronoun: 'Pronoun Preference',
      mobilePhone: 'Mobile Phone',
      name: 'Name',
      workPhone: 'Work Phone',
    },
    title: 'Personal Information',
  },
  studentInformation: {
    labels: {
      organization: 'Organization',
    },
    title: 'Student Information',
  },
};

export const es = en;
