export const en = {
  title: 'SSO Login',
  providers: {
    azure: {
      action: 'Sign in with Azure SSO',
    },
    okta: {
      action: 'Sign in with OKTA SSO',
    },
  },
};

export const es = en;
