import { uniq } from 'lodash';

import { CORPORATE_TEAMS, OTHERS_TEAMS, PUBLISHED_TEAMS } from 'constants/teamSections';
import { FORM } from 'constants/contentTypes';
import { SORT_DIRECTION, TEAM_GRID_SORT_COLUMNS } from 'constants/sort';

const DEFAULT_SORT_COLUMN = TEAM_GRID_SORT_COLUMNS.NAME;
const DEFAULT_SORT_DIRECTION = SORT_DIRECTION.ASC;

/**
 * @description Settings for controller
 * @module API controller / Teams
 */
module.exports = {
  createTeam: {
    /**
     * @description Get request settings (Create team)
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {array} options.addedMembers - Team members
     * @param  {string} options.description - Team description
     * @param  {string} options.name - Team Name
     * @return {object} Request Settings
     */
    settings({ accountId, addedMembers, description, name }) {
      return {
        endpoint: `/account/${accountId}/teams`,
        body: {
          addedMembers: uniq(addedMembers),
          deletedMembers: [],
          description,
          name,
        },
        method: 'POST',
      };
    },
  },

  getTeamList: {
    /**
     * @description Get request settings (Gets all teams list)
     * @param  {object} {options}
     * @param  {number} {options.accountId}
     * @param  {number} {options.pageIndex}
     * @param  {string} {options.search}
     * @returns {object} request settings
     */
    settings({ accountId, pageIndex, search, sectionName }) {
      const { filterBy, value: searchValue } = search || {};

      let endpoint = null;
      const cleanSearch = searchValue ? searchValue.trim() : null;
      const searchBy = searchValue ? filterBy : null;

      switch (sectionName) {
        case PUBLISHED_TEAMS:
          endpoint = `/account/${accountId}/teams/my_published_teams`;
          break;
        case OTHERS_TEAMS:
          endpoint = `/account/${accountId}/teams/my_teams_created_by_others`;
          break;
        case CORPORATE_TEAMS:
          endpoint = `/account/${accountId}/teams/my_corporate_teams`;
          break;
        // My teams (private)
        default:
          endpoint = `/account/${accountId}/teams/my_private_teams`;
      }

      if (pageIndex) {
        endpoint += `/page/${pageIndex}`;
      }

      if (searchBy) {
        endpoint += `/search_field/${searchBy}`;
      }

      if (cleanSearch) {
        endpoint += `/search/${cleanSearch}`;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },

  getTeam: {
    /**
     * @description Get request settings (Get Team)
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.teamId - The profile ID.
     * @return {object} Request Settings
     */
    settings({ accountId, teamId }) {
      return {
        endpoint: `/account/${accountId}/teams/${teamId}`,
        method: 'GET',
      };
    },
  },

  getTeamMembers: {
    /**
     * @description Get request settings (Get Team)
     * @param  {object} options
     * @param  {number} options.accountId
     * @param  {number} options.pageIndex
     * @param  {object} options.sort
     * @param  {number} options.teamId
     * @return {object} Request Settings
     */
    settings({
      accountId,
      pageIndex = 1,
      search,
      sort = {},
      teamId,
    }) {
      const {
        column = DEFAULT_SORT_COLUMN,
        direction = DEFAULT_SORT_DIRECTION,
      } = sort;

      const { filterBy, value: searchValue } = search || {};

      const cleanSearch = searchValue ? searchValue.trim() : null;
      const searchBy = searchValue ? filterBy : null;

      let sortOptions = `${column}:${direction}`;

      if (column === DEFAULT_SORT_COLUMN) {
        sortOptions = `first_name:${direction},last_name:${direction}`;
      }

      let endpoint = `/account/${accountId}/teams/${teamId}/members`;

      if (pageIndex) {
        endpoint = `${endpoint}/page/${pageIndex}`;
      }

      if (searchBy) {
        endpoint += `/search_field/${searchBy}`;
      }

      if (cleanSearch) {
        endpoint = `${endpoint}/search/${cleanSearch}`;
      }

      return {
        endpoint: `${endpoint}?sort=${sortOptions}`,
        method: 'GET',
      };
    },
  },

  editTeam: {
    /**
     * @description Get request settings (Edit team)
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {array} options.addedMembers - Added team members
     * @param  {array} options.deletedMembers - Removed team members
     * @param  {string} options.description - Team description
     * @param  {string} options.name - Team name
     * @param  {number} options.owner - Team Owner
     * @param  {number} options.teamId - The team ID
     * @return {object} Request Settings
     */
    settings({ accountId, addedMembers, deletedMembers, description, name, owner, teamId }) {
      return {
        endpoint: `/account/${accountId}/teams/${teamId}`,
        body: {
          addedMembers: uniq(addedMembers),
          description,
          name,
          ownerId: owner,
          deletedMembers: uniq(deletedMembers),
        },
        method: 'PUT',
      };
    },
  },

  deleteTeam: {
    /**
     * @description Get request settings (Delete team)
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.teamId - The team ID.
     * @return {object} Request Settings
     */
    settings({ accountId, teamId }) {
      return {
        endpoint: `/account/${accountId}/teams/${teamId}`,
        method: 'DELETE',
      };
    },
  },

  setPrivacy: {
    /**
     * @description Get request settings (Set Privacy)
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {boolean} options.isPublic - The profile ID.
     * @param  {boolean} options.isPublished - Fields to edit.
     * @param  {number} options.teamId - Fields to edit.
     * @return {object} Request Settings
     */
    settings({ accountId, isPublic, isPublished, teamId }) {
      return {
        endpoint: `/account/${accountId}/teams/${teamId}/set_privacy`,
        body: {
          isPublic,
          isPublished,
        },
        method: 'PATCH',
      };
    },
  },

  shareTeamsWithABundle: {
    /**
     * @description Gets request settings (share teams with a bundle)
     * @param {Object} - settings
     * @param {Number} - settings.accountId
     * @param {Number} - settings.bundleId
     * @param {Array} - settings.teamIds
     */
    settings({ accountId, bundleId, teamIds }) {
      return {
        endpoint: `/account/${accountId}/teams/update_bundle_allocations_for_teams`,
        body: {
          bundleAllocationId: bundleId,
          teams: teamIds,
        },
        method: 'POST',
      };
    },
  },

  updateLogo: {
    /**
     * @description Get Request Settings (Updates profile avatar).
     * @param  {Object} - settings
     * @param  {Number} - settings.accountId - The account Id.
     * @param  {Number} - settings.teamId - The team Id.
     * @param  {File} - settings.logo - The logo file.
     * @return {Object} Request settings
     */
    settings({ accountId, teamId, logo }) {
      const form = new FormData();

      if (logo) {
        form.append('logo', logo);
      }

      return {
        body: form,
        endpoint: `/account/${accountId}/teams/${teamId}/set_logo`,
        headers: {
          enctype: FORM,
        },
        method: 'PATCH',
      };
    },
  },
};
