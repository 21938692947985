import { isEmpty } from 'lodash';
import { MenuItem as MenuItemMUI } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Avatar from 'generics/Avatar';

import styles from './MenuItem.scss';

// eslint-disable-next-line react/prefer-stateless-function
const MenuItem = ({
  className,
  isNotClickable,
  label,
  logoUrl,
  ...rest
}) => {
  const getContent = () => (
    <div
      className={classnames(
        styles['menu-item'],
        { [styles['menu-item--dropdown-value']]: isNotClickable },
        className,
      )}
      title={label}
    >
      <Avatar
        className={classnames(
          styles.icon,
          { [styles['icon--has-avatar']]: !isEmpty(logoUrl) },
        )}
        hasBorder
        icon={{ className: styles['icon-image'] }}
        src={logoUrl}
        title={label}
      />
      <div className={styles.label}>
        {label}
      </div>
    </div>
  );

  const content = getContent();

  return !isNotClickable
    ? (
      <MenuItemMUI {...rest}>
        {content}
      </MenuItemMUI>
    )
    : (
      <div>
        {content}
      </div>
    );
};

MenuItem.propTypes = {
  className: PropTypes.string,
  isNotClickable: PropTypes.bool,
  label: PropTypes.string,
  logoUrl: PropTypes.string,
};

MenuItem.defaultProps = {
  className: null,
  isNotClickable: false,
  label: undefined,
  logoUrl: undefined,
};

MenuItem.muiName = 'MenuItem';

export default MenuItem;
