import propTypes from 'prop-types';
import React, { useState } from 'react';

import { ACTIONS, I18N } from 'constants/props';
import useCheckoutForm from '../CheckoutForm/useCheckoutForm';
import CheckoutForm from '../CheckoutForm/CheckoutForm';

const CheckoutFormWrapper = ({
  accountId,
  amount,
  i18n,
  notificationActions,
  onFinish,
  onFetchNetworkDetails,
  orderQuantity,
  profileId,
  stripeEmail,
}) => {
  const [stripeError, setStripeError] = useState('');

  const {
    countries,
    defaultCountryId,
    handleSubmit,
    isLoading,
    stripe,
  } = useCheckoutForm({
    accountId,
    i18n,
    notificationActions,
    onFinish,
    onFetchNetworkDetails,
    profileId,
    setStripeError,
    stripeEmail,
  });

  return (
    <CheckoutForm
      amount={amount}
      countries={countries}
      defaultCountryId={defaultCountryId}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      orderQuantity={orderQuantity}
      stripe={stripe}
      stripeEmail={stripeEmail}
      stripeError={stripeError}
      resetOnSubmit
    />
  );
};

CheckoutFormWrapper.propTypes = {
  accountId: propTypes.number.isRequired,
  amount: propTypes.number,
  i18n: I18N.isRequired,
  notificationActions: ACTIONS.isRequired,
  onFinish: propTypes.func,
  onFetchNetworkDetails: propTypes.func.isRequired,
  orderQuantity: propTypes.string,
  profileId: propTypes.number.isRequired,
  stripeEmail: propTypes.string,
};

CheckoutFormWrapper.defaultProps = {
  amount: 0,
  onFinish: null,
  orderQuantity: '',
  stripeEmail: '',
};

export default CheckoutFormWrapper;
