import { BarError } from 'classes/errors';
import { notifyError } from 'app_modules/notifications/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/groups/constants';
import api from 'api';

// Actions
/**
 * failure action dispatched when there is an error updating the logo
 */
export const archiveGroupFailure = (error) => ({
  payload: error,
  type: actionTypes.ARCHIVE_GROUP_FAILURE,
});

/**
 * update logo request action dispatched
 */
export const archiveGroupRequest = () => ({
  type: actionTypes.ARCHIVE_GROUP_REQUEST,
});

/**
 * Success action dispatched when updating the logo
 */
export const archiveGroupSuccess = (response) => ({
  payload: response,
  type: actionTypes.ARCHIVE_GROUP_SUCCESS,
});

// Thunks

export const archiveGroup = (groupId, archiveObj, selectedFilter) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());
    dispatch(archiveGroupRequest());

    const handleResponse = (response) => dispatch(archiveGroupSuccess({ ...response, selectedFilter }));

    const handleError = ({ error, isConnectionError }) => {
      dispatch(archiveGroupFailure(error));
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'archiveGroup' })));
      }
    };

    return api.groups.archiveGroup({
      accountId,
      groupId,
      ...archiveObj,
    }, handleResponse, handleError);
  };

  thunk.type = actionTypes.ARCHIVE_GROUP;

  return thunk;
};

export default archiveGroup;
