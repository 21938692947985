import { OTHERS_TEAMS } from 'constants/teamSections';
import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/teams/constants';
import api from 'api';

// Actions

export const fetchOthersTeamsFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_OTHERS_TEAMS_FAILURE,
});

export const fetchOthersTeamsRequest = (pageIndex) => ({
  payload: pageIndex,
  type: actionTypes.FETCH_OTHERS_TEAMS_REQUEST,
});

export const fetchOthersTeamsSuccess = (response) => ({
  payload: response,
  type: actionTypes.FETCH_OTHERS_TEAMS_SUCCESS,
});

// Thunks

export const fetchOthersTeams = ({ pageIndex, search }, onSuccess) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());

    dispatch(fetchOthersTeamsRequest(pageIndex));

    const handleResponse = (response) => {
      dispatch(fetchOthersTeamsSuccess(response));

      if (onSuccess) {
        onSuccess(response);
      }
    };

    const handleError = ({ error }) => {
      dispatch(fetchOthersTeamsFailure(error));
      throw error;
    };

    return api.teams.getTeamList(
      { accountId, pageIndex, search, sectionName: OTHERS_TEAMS },
      handleResponse,
      handleError,
    );
  };

  thunk.type = actionTypes.FETCH_OTHERS_TEAMS;

  return thunk;
};

export default fetchOthersTeams;
