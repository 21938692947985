export const ACCEPT_INVITATION = '/accept-invitation';
export const ADMINISTRATION = '/account/:accountId/administration/:sectionId/:stripeSubscription?/:subscriptionStatus?';
export const ASSESSMENT = '/assessment';
export const GENERAL_ACCOUNT_INFORMATION = '/general-account-information';
export const INVITATION = '/invitation/:token?';
export const LINK_RELATIONSHIPS_PROFILE = '/link-relationships-profile';
export const LOGIN = '/login';
export const LOGIN_CORPORATE_SINGLE_SIGN_ON = '/login/corporate-single-sign-on';
export const LOGIN_FORGOT_PASSWORD = '/login/forgot-password';
export const LOGIN_IMPERSONATE = '/login/impersonation-token/:token';
export const LOGIN_RESET_PASSWORD = '/login/reset-password/:token';
export const LOGIN_SSO = '/login/sso/:token';
export const LOGIN_SSO_ERROR = '/login/sso-error/company/:company/error-code/:code';
export const LOGIN_SSO_PROVIDERS = '/login/sso-providers';
export const MY_5_DYNAMICS = '/account/:accountId/my-5-dynamics';
export const MY_5_DYNAMICS_PROFILE = `${MY_5_DYNAMICS}/profile`;
export const NETWORK = '/account/:accountId/network';
export const NETWORK_DASHBOARD = `${NETWORK}/content-type/:contentType/profile/:profileId`;
export const NEWS = '/account/:accountId/news';
export const PROFILE_BY_CONTEXT = '/account/:accountId/:menuItem/content-type/:contentType/:bundle/:moduleId/:context?/:contextId?';
export const SETUP_RELATIONSHIPS_ACCOUNT = '/account/:accountId/relationships';
export const SIGNUP = '/signup';
export const SIGNUP_SSO = '/signup/sso';
export const TAB = '/account/:accountId/:tab?';
export const TEAMS = '/account/:accountId/teams';
export const TEAMS_DASHBOARD = `${TEAMS}/team/:teamId/:sharedIcon`;
export const TEAMS_SPIDER_GRAPH = `${TEAMS_DASHBOARD}/spider-graph/:token/:isHistory?`;
export const TERMS_OF_USE = '/terms-of-use';
export const THIRD_PERSON_PROFILE = '/account/:accountId/network/profile-detail/profile/:profileId';
export const TOKEN_ADMINISTRATION = '/account/:accountId/token-administration/:sectionId';
export const TRAINING = '/account/:accountId/training-center/:trainingName';
export const TRAINING_CENTER = '/account/:accountId/training-center';
export const WELCOME = '/account/:accountId/welcome';

export const getInvitationRoute = (token) => `/invitation/${token}?`;

export const getEntityByContextRoute = ({
  accountId,
  category,
  context,
  contextType,
  menuItem,
  moduleKey,
  entity,
}) => {
  const { id } = entity;
  const contextRoute = context && entity ? `${context}/${id}` : '';
  return `/account/${accountId}/${menuItem}/content-type/${contextType}/${category}/${moduleKey}/${contextRoute}`;
};
