import base64url from 'base64url';
import CryptoJS, { AES } from 'crypto-js';

export default class Url {
  constructor(data) {
    this._data = data;
    this._PASS_PHRASE = '5D';
  }

  encode() {
    const parsedData = JSON.stringify(this._data);
    const cipherText = AES.encrypt(parsedData, this._PASS_PHRASE).toString();
    return base64url.encode(cipherText);
  }

  decode() {
    const cipherText = base64url.decode(this._data);
    const parsedData = AES.decrypt(cipherText, this._PASS_PHRASE).toString(CryptoJS.enc.Utf8);
    return JSON.parse(parsedData);
  }
}
