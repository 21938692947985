import { Container } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './FixedNotification.scss';

const NOTIFICATION_TYPES = ['error', 'info', 'success', 'warning'];

const FixedNotification = (props) => {
  const {
    message,
    type,
  } = props;

  if (!message) {
    return null;
  }

  return (
    <div
      className={classnames(
        styles.notifications,
        styles[type],
      )}
    >
      <Container>
        <p
          className={styles.message}
          title={message}
        >
          {message}
        </p>
      </Container>
    </div>
  );
};

FixedNotification.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(NOTIFICATION_TYPES),
};

FixedNotification.defaultProps = {
  message: null,
  type: 'success',
};

export default FixedNotification;
