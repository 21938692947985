export const en = {
  description: 'If applicable, any prorated amount based on the renewal date of the organization will be factored into the cost at payment.',
  emailNotifications: {
    title: 'Renewal Management',
    description: 'Your access will remain active until your renewal date of %(renewalDate)s.<br /><br />In order to prevent any disruption to your service on your renewal date, it is recommended that you save your credit card information on the following page. This will allow for the automatic renewal of your licenses on your renewal date.<br /><br />If you do not wish to renew your licenses on your renewal date, do not save your credit card information on the following page, or, if you have saved it, just remove it. This will prevent the automatic renewal and your access will be terminated on the renewal date.<br /><br />Before your renewal date, you will receive automated email messages at 60 days prior, 30 days prior, and 24 hours prior indicating the number of licenses to be renewed and the renewal amount.<br /><br />',
    checkboxLabels: [
      'If you do not wish to receive these emails, uncheck this box:',
      'to turn off email notifications and click Submit.',
    ],
  },
  title: 'Purchase Licenses',
  quantity: 'Number of licenses requested',
  paymentStatusTitle: 'Order Confirmation',
  paymentFailed: 'Payment Failed. Please contact your credit card issuer. If you still need assistance you can contact 5 Dynamics customer support here.',
  paymentSuccess: 'Your payment has been approved and your licenses are now available for use.',
  renewalManagementButton: 'Renewal Management',
  subscriptionStatusTitle: 'Subscription Status',
  subscriptionFailed: 'Subscription Failed. Please contact your credit card issuer. If you still need assistance you can contact 5 Dynamics customer support here.',
  subscriptionSuccess: 'Thanks for renewing! You can continue your unlimited annual access to the Simpli5 Platform and Resources. Thanks for joining us in making the world a better place, one relationship at a time.',
};

export const es = en;
