/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from 'reactstrap';
import { formatRoute } from 'react-router-named-routes';
import { isEmpty, size } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ACTIONS, I18N, MATCH, MODULES, TEAM, PROFILES } from 'constants/props';
import { COLORS } from 'constants/colors';
import { TEAMS } from 'urls';
import Breadcrumbs from 'generics/Breadcrumbs';
import Icon from 'generics/Icon';
import ModulesList from 'generics/ModulesList';
import TeamDescription from 'containers/TeamDescription';
import Translation from 'generics/Translation';

import LearningChartForm from './components/LearningChartForm';
import ModalCreatePdf from './components/ModalCreatePdf';
import SpiderGraphHistory from './components/SpiderGraphHistory';
import styles from './PageTeamsDashboard.scss';
import TeamEnergy from './components/TeamEnergy';

const PageTeamsDashboard = ({
  accountId: accountIdParam,
  actions,
  hasMoreMembers,
  i18n,
  match,
  membersPageIndex,
  modules,
  team,
  teamMembers,
}) => {
  const [formValues, setFormValues] = useState({});
  const [isFetching, setIsFetching] = useState(true);
  const [isFormValid, setIsFormValid] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [module, setModule] = useState(null);

  const handleOnComplete = () => {
    setFormValues({});
    setIsFetching(false);
  };

  useEffect(() => {
    const { teamId } = match.params;

    actions.fetchTeamDashboard({
      contentType: 'team',
      teamId,
    })
      .then(handleOnComplete)
      .catch(handleOnComplete);
  }, []);

  const getBreadcrums = () => {
    const { accountId } = match.params;

    if (isEmpty(team)) {
      return [];
    }

    return [
      {
        label: i18n.menu.teams,
        to: formatRoute(TEAMS, { accountId }),
      },
      {
        label: team.name,
      },
    ];
  };

  const handleOpenModal = (newModule) => {
    setModule(newModule);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFetch = (moduleKey) => {
    setIsFetching(moduleKey);
  };

  const handleFetchMoreMembers = (pageIndex, onFinish) => {
    const { id: teamId } = team;

    setIsFetching(true);

    const onComplete = () => {
      setIsFetching(false);
      if (onFinish) {
        onFinish();
      }
    };

    actions.fetchTeamMembers({ pageIndex, teamId }, onComplete, onComplete);
  };

  const handleSubmitLearningChart = (newFormValues, newIsFormValid) => {
    setFormValues(newFormValues);
    setIsFormValid(newIsFormValid);
  };

  const members = {
    hasMorePages: hasMoreMembers,
    list: teamMembers,
    pageIndex: membersPageIndex,
    onFetchMore: handleFetchMoreMembers,
  };

  const goToUrl = {
    context: 'team',
    entity: team,
    menuItem: 'teams',
  };

  const modulesList = [];
  const teamSubmodules = modules.filter((submodule) => submodule.contextType === 'team' || submodule.contextType === 'role');
  const pairSubmodules = modules
    .filter((submodule) => submodule.contextType === 'pair')
    .map((submodule) => {
      if (submodule.key === 'leading___name_s_12') {
        return {
          ...submodule,
          title: i18n.pageTeamDashboard.modules.workingWithTeamMembers.module.leadership,
        };
      }
      return submodule;
    });

  if (size(teamSubmodules)) {
    modulesList.push({
      className: styles['team-dynamic'],
      goToUrl,
      modules: teamSubmodules,
      name: i18n.pageTeamDashboard.modules.teamDynamics.title,
    });
  }

  if (size(pairSubmodules)) {
    modulesList.push({
      className: styles['working-with-team-members'],
      goToUrl,
      modules: pairSubmodules,
      name: i18n.pageTeamDashboard.modules.workingWithTeamMembers.title,
    });
  }

  const resources = {
    modules: [
      {
        category: i18n.pageTeamDashboard.modules.resources.module.category,
        categoryColor: COLORS.resourcesCategory.rgba,
        description: i18n.pageTeamDashboard.modules.resources.module.nameBadges.description,
        icon: 'id',
        isFetching: isFetching === 'name-badges',
        key: 'name-badges',
        onClick: () => {
          handleOpenModal({
            icon: 'id',
            children: (
              <div className={styles['learning-chart__description']}>
                <p>
                  {i18n.pageTeamDashboard.modules.resources.module.nameBadges.modal
                    .description[0]}
                </p>
                <p>
                  {i18n.pageTeamDashboard.modules.resources.module.nameBadges.modal
                    .description[1]}
                </p>
              </div>
            ),
            key: 'name-badges',
            maximumTeamMembers: 24,
            title: i18n.pageTeamDashboard.modules.resources.module.nameBadges.title,
          });
        },
        title: i18n.pageTeamDashboard.modules.resources.module.nameBadges.title,
      },
      {
        category: i18n.pageTeamDashboard.modules.resources.module.category,
        categoryColor: COLORS.resourcesCategory.rgba,
        description: i18n.pageTeamDashboard.modules.resources.module.tentCards.description,
        icon: <Icon.Svg.TentCards />,
        isFetching: isFetching === 'tent-cards',
        key: 'tent-cards',
        onClick: () => {
          handleOpenModal({
            icon: <Icon.Svg.TentCards />,
            children: (
              <div className={styles['learning-chart__description']}>
                <p>
                  {i18n.pageTeamDashboard.modules.resources.module.tentCards.modal
                    .description[0]}
                </p>
                <p>
                  {i18n.pageTeamDashboard.modules.resources.module.tentCards.modal
                    .description[1]}
                </p>
              </div>
            ),
            key: 'tent-cards',
            maximumTeamMembers: 24,
            title: i18n.pageTeamDashboard.modules.resources.module.tentCards.title,
          });
        },
        title: i18n.pageTeamDashboard.modules.resources.module.tentCards.title,
      },
      {
        category: i18n.pageTeamDashboard.modules.resources.module.category,
        categoryColor: COLORS.resourcesCategory.rgba,
        description: i18n.pageTeamDashboard.modules.resources.module.learningChart.description,
        icon: 'display1',
        isFetching: isFetching === 'learning-chart',
        key: 'learning-chart',
        onClick: () => {
          handleOpenModal({
            icon: 'display1',
            children: (
              <>
                <div className={styles['learning-chart__description']}>
                  <p>
                    {i18n.pageTeamDashboard.modules.resources.module.learningChart.modal
                      .description[0]}
                  </p>
                  <p>
                    {i18n.pageTeamDashboard.modules.resources.module.learningChart.modal
                      .description[1]}
                  </p>
                </div>
                <span
                  className={styles['learning-chart__form-title']}
                  title={i18n.pageTeamDashboard.modules.resources.module.learningChart.modal
                    .form.chartName}
                >
                  {i18n.pageTeamDashboard.modules.resources.module.learningChart.modal
                    .form.chartName}
                </span>
                <LearningChartForm
                  className={styles['learning-chart__form']}
                  onSubmit={handleSubmitLearningChart}
                />
              </>
            ),
            key: 'learning-chart',
            maximumTeamMembers: 100,
            title: i18n.pageTeamDashboard.modules.resources.module.learningChart.title,
          });
        },
        title: i18n.pageTeamDashboard.modules.resources.module.learningChart.title,
      },
    ],
    name: i18n.pageTeamDashboard.modules.resources.title,
  };

  modulesList.push(resources);

  const modulesListItems = modulesList
    .map((moduleList) => {
      const {
        name,
        ...rest
      } = moduleList;

      return (
        <ModulesList
          accountId={accountIdParam}
          cardClassName={styles.card}
          col={{
            xs: 12,
            lg: 6,
          }}
          header={{ title: name }}
          key={name}
          {...rest}
        />
      );
    });

  return (
    <div className={styles.page}>
      <Container>
        <Row>
          <Col>
            <Breadcrumbs
              className={styles.breadcrums}
              linkList={getBreadcrums()}
            />
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="6" lg="4">
            <TeamDescription
              className={styles['team-description']}
              members={members}
              sharedIcon={match?.params?.sharedIcon}
            />
            <SpiderGraphHistory
              match={match}
            />
          </Col>

          <Col xs="12" md="6" lg="8">
            <Row>
              <Col>
                <TeamEnergy
                  accountId={accountIdParam}
                  className={styles['team-energy']}
                  isFetching={isFetching}
                  team={team}
                  members={members}
                />
              </Col>
            </Row>

            {modulesListItems}

            {
              isModalOpen && (
                <ModalCreatePdf
                  disableSubmit={!isFormValid}
                  formValues={formValues}
                  members={members}
                  module={module}
                  onClose={handleCloseModal}
                  onComplete={handleOnComplete}
                  onFetch={handleFetch}
                  team={team}
                />
              )
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
};

PageTeamsDashboard.propTypes = {
  accountId: PropTypes.number.isRequired,
  actions: ACTIONS.isRequired,
  hasMoreMembers: PropTypes.bool,
  i18n: I18N.isRequired,
  match: MATCH.isRequired,
  membersPageIndex: PropTypes.number.isRequired,
  modules: MODULES,
  team: TEAM,
  teamMembers: PROFILES.isRequired,
};

PageTeamsDashboard.defaultProps = {
  hasMoreMembers: false,
  modules: [],
  team: null,
};

export default Translation(PageTeamsDashboard, ['pageTeamDashboard', 'menu']);
