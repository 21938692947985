export const en = {
  continue: 'Continue',
  copyRight: '&copy; %s 5 Dynamics<sup>&reg;</sup>, LLC | All Rights Reserved.',
  successDescription: 'Thank you %s. You have successfully completed the 5 Dynamics® Starting Point assessment. Click continue to view your results.',
  successTitle: 'Successfully completed',
  wordPairDescription: [
    ' choose the word-pair that is more like you.',
    'Click on that word-pair.',
    'Please do not hit the back button at any time.',
  ],
};

export const es = en;
