export const en = {
  page: {
    category: 'Fundamentals',
    title: 'Spider Graph',
  },
  breadcrumbs: {
    level0: 'My teams',
    level2: 'Spider Graph',
  },
  errors: {
    1: 'Schema missing data',
    2: 'No profiles found',
  },
  label: {
    energyMap: 'Email Energy Maps',
    learning: 'Email Learning Reports',
    overview: 'Email Overview Reports',
    pdf: 'Spider Graph PDF',
    teamAverage: 'Team Average',
  },
  downloadEnergyError: 'An error happened while getting energy map images.',
  downloadOverviewError: 'An error happened while creating the Overview Report.',
  downloadMessage: 'You will receive an email with the attached file when it is ready. Thank you.',
  energies: [
    [
      'People with higher',
      'Explore',
      'Energy contribute visionary or futuristic thinking. They tend to take an end-to-end systems perspective. They see relationships between actions taken by the team and implications up and down the process. They are interested in unorthodox or at least unusual solutions. This Energy is conflict-avoidant and, for better or worse, can be a calming influence on the team.',
    ],
    [
      'Those with higher',
      'Excite',
      'Energy contribute communication, optimism, cohesiveness, enthusiasm, and evangelism to a team\'s work. People higher in Excite raise their Energy by "selling" ideas and using their charm, influence, and persuasiveness to win people over to their side. This Energy will focus on fun and productive interactions and teamwork, as well as on weaving people together.',
    ],
    [
      'People with higher',
      'Examine',
      'Energy are highly focused on detail and compliance with procedures, and they can foresee difficulties or mistakes before and after they occur. They tend to see matters in black/white and right/wrong. This Energy promotes caution and rigor; it is most comfortable when following or developing plans, rules, procedures and timelines.',
    ],
    [
      'Those with higher',
      'Execute',
      'Energy want to complete the objective before them in the most direct and pragmatic way possible. These people want clarity of roles and objectives; they prefer to minimize options and often circumspection in the service of getting the job done. This Energy pushes and drives; it is directive, generally rules-based, and appreciates hierarchy and clarity.',
    ],
  ],
  note: 'NOTE: None of the above tendencies are all good or all bad - it depends on the context of the situation or the project at hand. Having perfectly balanced Energies on a team may not be a precursor to success, depending on the purpose and goals of the team; however, an imbalance of Energies might contribute to performance pitfalls or potential conflict within the team. 5 Dynamics® offers a Team Optimization course to help you identify and correct these types of team issues; contact support@5dynamics.com for more information.',
  modal: {
    description: 'You can create a Spider Graph with up to 12 members.',
    header: 'Generate Spider Graph',
    includeTeamAverage: 'Include Team Average',
    listATitle: 'Team members',
    listBTitle: 'Selected',
    submitText: 'Create',
  },
};

export const es = en;
