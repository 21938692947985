import truncateHtml from 'html-truncate';

import { ACCOUNT_EXPIRED, BUNDLES_NOT_FOUND } from 'constants/apiErrorCodes';
import { BarError } from 'classes/errors';
import { fromCallbacksToPromise, sortModules } from 'helpers';
import { logout } from 'app_modules/session/actions/fetchLogout';
import { notifyError, notifyWarning } from 'app_modules/notifications/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/session/constants';
import api from 'api';

// Actions

export const fetchDashboardFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_DASHBOARD_FAILURE,
});

export const fetchDashboardRequest = () => ({
  type: actionTypes.FETCH_DASHBOARD_REQUEST,
});

export const fetchDashboardSuccess = (response) => ({
  payload: response,
  type: actionTypes.FETCH_DASHBOARD_SUCCESS,
});

// Thunks

export const fetchDashboard = () => {
  const thunk = (dispatch, getState) => {
    if (!getState().assessment.isCompleted) {
      return Promise.resolve();
    }

    const accountId = sessionSelectors.accountId(getState());

    dispatch(fetchDashboardRequest());

    const handleResponse = ({ dashboardModules, userOverview }) => {
      const unescapedUserOverview = unescape(userOverview);
      const overviewSummary = truncateHtml(unescapedUserOverview, 560);

      return dispatch(fetchDashboardSuccess({
        modules: sortModules(dashboardModules),
        overview: userOverview,
        overviewSummary,
      }));
    };

    const handleError = ({ error }) => {
      dispatch(fetchDashboardFailure(error));

      if (error.errorCode === BUNDLES_NOT_FOUND) {
        dispatch(logout());
        dispatch(notifyError(new BarError({ ...error, label: 'noBundles' })));
      } else if (error.errorCode === ACCOUNT_EXPIRED) {
        dispatch(notifyWarning({ ...error, label: 'accountExpired' }));
      }
      throw error;
    };

    return fromCallbacksToPromise(api.contentManager.getContent, {
      accountId,
      contentType: 'individual',
    })
      .then((response) => handleResponse(response))
      .catch((error) => handleError(error));
  };

  thunk.type = actionTypes.FETCH_DASHBOARD;

  return thunk;
};

export default fetchDashboard;
