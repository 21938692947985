import { formatRoute } from 'react-router-named-routes';
import { replace } from 'connected-react-router';

import { BarError } from 'classes/errors';
import { fromCallbacksToPromise } from 'helpers';
import { LOGIN, SIGNUP_SSO } from 'urls';
import { notifyError } from 'app_modules/notifications/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import api from 'api';
import sessionActionTypes from 'app_modules/session/constants';

import { logout } from './fetchLogout';
import { setUser } from './setUser';

// Actions

export const fetchSSOLoginFailure = (error) => ({
  payload: error,
  type: sessionActionTypes.FETCH_SSO_LOGIN_FAILURE,
});

export const fetchSSOLoginRequest = () => ({
  type: sessionActionTypes.FETCH_SSO_LOGIN_REQUEST,
});

export const fetchSSOLoginSuccess = () => ({
  type: sessionActionTypes.FETCH_SSO_LOGIN_SUCCESS,
});

export const continueProvisioning = (user) => ({
  payload: user,
  type: sessionActionTypes.CONTINUE_PROVISIONING,
});

// Thunks

export const fetchSSOLogin = (token) => {
  const thunk = (dispatch, getState) => {
    const loggedUser = sessionSelectors.user(getState());

    if (loggedUser.ssoToken !== token) {
      return Promise.all([dispatch(logout(true))])
        .then(() => Promise.all([
          dispatch(fetchSSOLoginRequest()),
          fromCallbacksToPromise(api.session.ssoLogin, token)
            .then(({ user }) => {
              const {
                hasOngoingProvisioning,
              } = user.profiles[0];

              const newUser = {
                ...user,
                token: null,
              };

              if (hasOngoingProvisioning) {
                return Promise.all([
                  dispatch(continueProvisioning(newUser)),
                  dispatch(replace(formatRoute(SIGNUP_SSO))),
                ]);
              }

              return Promise.all([
                dispatch(fetchSSOLoginSuccess()),
                dispatch(setUser(newUser)),
              ]);
            })
            .catch(({ error, isConnectionError }) => {
              dispatch(logout());
              if (!isConnectionError) {
                dispatch(notifyError(new BarError({ ...error, label: 'ssoLoginFailed' })));
              }
              dispatch(fetchSSOLoginFailure(error?.message));
              throw error;
            }),
        ]));
    }

    return dispatch(replace(formatRoute(LOGIN)));
  };

  thunk.type = sessionActionTypes.FETCH_SSO_LOGIN;

  return thunk;
};

export default fetchSSOLogin;
