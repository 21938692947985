import { createTheme } from '@material-ui/core/styles';

import { COLORS } from 'constants/colors';

export const fiveDynamicsTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.secondaryBlue.rgba,
      dark: COLORS.blueDeepCerulean.rgba,
    },
    error: {
      main: COLORS.primaryPink.rgba,
    },
  },
});


export default fiveDynamicsTheme;
