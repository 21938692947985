import PropTypes from 'prop-types';
import React from 'react';

import { I18N, RECORDS } from 'constants/props';
import Translation from 'generics/Translation';

import BadgeResultsList from '../BadgeResultsList';

export const BadgeResults = ({
  i18n, isDepartmentEnabled, failed, failedTitle, removed, removedTitle, success, successTitle,
}) => {
  const failedListTitle = failedTitle
    || i18n.pageAdministration.modalPeople.resultMessage.failed;

  const successListTitle = successTitle
    || i18n.pageAdministration.modalPeople.resultMessage.success;

  const removedListTitle = removedTitle
    || i18n.pageAdministration.modalPeople.resultMessage.removed;

  return (
    <>
      <BadgeResultsList
        isDepartmentEnabled={isDepartmentEnabled}
        list={success}
        title={successListTitle}
      />
      <BadgeResultsList
        isDepartmentEnabled={isDepartmentEnabled}
        list={removed}
        title={removedListTitle}
      />
      <BadgeResultsList
        isDepartmentEnabled={isDepartmentEnabled}
        list={failed}
        title={failedListTitle}
      />
    </>
  );
};

BadgeResults.propTypes = {
  failed: RECORDS,
  failedTitle: PropTypes.string,
  i18n: I18N.isRequired,
  isDepartmentEnabled: PropTypes.bool,
  removed: RECORDS,
  removedTitle: PropTypes.string,
  success: RECORDS,
  successTitle: PropTypes.string,
};

BadgeResults.defaultProps = {
  failed: [],
  failedTitle: undefined,
  isDepartmentEnabled: null,
  removed: [],
  removedTitle: undefined,
  success: [],
  successTitle: undefined,
};

export default Translation(BadgeResults, ['pageAdministration']);
