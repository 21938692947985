import { Col, Container, Row } from 'reactstrap';
import DocumentTitle from 'react-document-title';
import React from 'react';

import { ACTIONS } from 'constants/props';
import TokenManagement from 'containers/TokenManagement';

import styles from './PageTokenAdministration.scss';

const PageTokenAdministration = ({ notificationActions }) => (
  <DocumentTitle title="Simpli5® - Token Administration Page">
    <div className={styles.page}>
      <Container>
        <Row>
          <Col>
            <TokenManagement notificationActions={notificationActions} />
          </Col>
        </Row>
      </Container>
    </div>
  </DocumentTitle>
);

PageTokenAdministration.propTypes = {
  notificationActions: ACTIONS.isRequired,
};

export default PageTokenAdministration;
