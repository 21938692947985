/**
 * @description Settings for Workshops controller
 * @module API controller / Workshops
 */
import moment from 'moment';

import { FORM } from 'constants/contentTypes';

const formatDate = (date) => moment(date).utc().format('YYYY-MM-DD');

module.exports = {
  createWorkshop: {
    /**
     * @description Get request settings (send file invitations)
     * @param {object} options
     * @param {number} options.accountId
     * @param {object} options.file
     * @param {string} options.name
     * @returns {object} request settings
     */
    settings({ accountId, file, name, date }) {
      const form = new FormData();

      form.append('file', file);

      form.append(
        'name',
        name,
      );

      form.append(
        'established_date',
        formatDate(date),
      );

      return {
        endpoint: `/account/${accountId}/workshops/via_csv`,
        body: form,
        headers: {
          enctype: FORM,
        },
        method: 'POST',
      };
    },
  },

  getWorkshopDetails: {
    /**
     * @description Get request settings (Workshop endpoint - getWorkshopDetails)
     * @param {object} options
     * @param {number} options.accountId
     * @param {number} options.workshopId
     * @returns {object} request settings
     */
    settings: ({ accountId, workshopId }) => ({
      endpoint: `/account/${accountId}/workshops/${workshopId}`,
      method: 'GET',
    }),
  },

  getMembers: {
    /**
     * @description Get request settings (Workshop endpoint - getMembers)
     * @param {object} options
     * @param {number} options.accountId
     * @param {number} options.pageIndex
     * @param {number} options.search
     * @param {number} options.workshopId
     * @returns {object} request settings
     */
    settings({ accountId, pageIndex, search, workshopId }) {
      let endpoint = `/account/${accountId}/workshops/${workshopId}/members`;
      const cleanSearch = search ? search.trim() : null;

      if (pageIndex) {
        endpoint += `/page/${pageIndex}`;
      }

      if (cleanSearch) {
        endpoint += `?search_criteria=${cleanSearch}`;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },


  getWorkshops: {
    ignoreNotFound: true,

    /**
     * @description Get request settings (Workshop endpoint - getWorkshops)
     * @param {object} options
     * @param {number} options.accountId
     * @param {number} options.pageIndex
     * @param {date} options.endDate
     * @param {string} options.searchCriteria
     * @param {date} options.startDate
     * @returns {object} request settings
     */
    settings({ accountId, pageIndex, endDate, searchCriteria, startDate }) {
      const search = searchCriteria ? searchCriteria.trim() : null;

      const params = [];

      let endpoint = `/account/${accountId}/workshops`;

      if (search || startDate || endDate) {
        endpoint += '/search';

        if (search) {
          params.push(`search_criteria=${search}`);
        }

        if (startDate) {
          params.push(`start_date=${formatDate(startDate)}`);
        }

        if (endDate) {
          params.push(`end_date=${formatDate(endDate)}`);
        }

        if (pageIndex) {
          params.push(`page=${pageIndex}`);
        }
      } else if (pageIndex) {
        endpoint += `/page/${pageIndex}`;
      }

      return {
        endpoint: encodeURI(`${endpoint}?${params.join('&')}`),
        method: 'GET',
      };
    },
  },

  updateWorkshop: {
    /**
     * @description Get request settings (Workshop endpoint - updateWorkshop)
     * @param {object} options
     * @param {number} options.accountId
     * @param {number} options.WorkshopId
     * @param {string} options.name
     * @param {string} options.establishedDate,
     * @param {array} options.members
     * @param {array} options.removeMembers
     * @returns {object} request settings
     */
    settings: ({ accountId, workshopId, name, establishedDate, members, removeMembers }) => ({
      endpoint: `/account/${accountId}/workshops/${workshopId}`,
      body: {
        name,
        establishedDate: formatDate(establishedDate),
        members,
        removeMembers,
      },
      method: 'PATCH',
    }),
  },
};
