import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as sessionActions from 'app_modules/session/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import PageLogin from 'components/PageLogin';

const mapStateToProps = (state) => ({
  accountId: sessionSelectors.accountId(state),
  isAssessmentCompleted: state.assessment.isCompleted,
  isAuthenticated: state.session.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageLogin);
