import { CircularProgress } from '@material-ui/core';
import { Container } from 'reactstrap';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import React from 'react';
import shortid from 'shortid';

import { ACTIONS, I18N, MATCH, TRAININGS } from 'constants/props';
import Mask from 'generics/Mask';
import Translation from 'generics/Translation';

import Header from './components/Header';
import styles from './PageTrainingCenter.scss';
import TrainingCard from './components/TrainingCard';

/**
 * @class PageTrainingCenter
 * @description Training Center Page
 */
class PageTrainingCenter extends React.Component {
  componentDidMount() {
    const { actions } = this.props;
    actions.fetchVideos();
  }

  render() {
    const {
      i18n,
      isFetching,
      match,
      trainings,
    } = this.props;

    const accountId = parseInt(match.params.accountId, 10);

    const trainingList = trainings.map(
      ({ name, videos }, i) => (
        <li key={`training-${shortid.generate()}`}>
          <h3 className={styles['training-title']} role="presentation">
            <span>
              {i18n.training.trainings[name].title}
            </span>
          </h3>
          <TrainingCard
            accountId={accountId}
            isFirst={i === 0}
            name={name}
            videos={videos}
          />
        </li>
      ));

    return (
      <DocumentTitle title="Simpli5® - Training Center Page">
        <Container className={styles.container}>
          <Mask
            className={styles.mask}
            open={isFetching}
          >
            <CircularProgress
              className={styles.loader}
              size={60}
              thickness={7}
            />
          </Mask>
          <Header />
          <ul>
            {trainingList}
          </ul>
        </Container>
      </DocumentTitle>
    );
  }
}

PageTrainingCenter.propTypes = {
  actions: ACTIONS.isRequired,
  i18n: I18N.isRequired,
  isFetching: PropTypes.bool.isRequired,
  match: MATCH.isRequired,
  trainings: TRAININGS.isRequired,
};

export default Translation(PageTrainingCenter, ['training']);
