import { Col, Container, Row } from 'reactstrap';
import { find } from 'lodash';
import classnames from 'classnames';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import React from 'react';

import { ACTIONS, I18N, PERSON, PEOPLE } from 'constants/props';
import AccountProfile from 'generics/AccountProfile';
import Translation from 'generics/Translation';

import AccountPreferences from './components/AccountPreferences';
import LinkedNetworks from './components/LinkedNetworks';
import styles from './PageGeneralAccountInformation.scss';

/**
 * Page General Account Information Component
 * @extends Component
 */
const PageGeneralAccountInformation = ({
  defaultAccount,
  i18n,
  notificationActions,
  profile,
  profiles,
  sessionActions,
}) => {
  /**
   * Gets Account Profile Data
   */

  const handleGetAccountProfileData = () => {
    const labelValue = i18n.pageGeneralAccountInformation.labelMyNumberOfNetworks;

    const userInfo = [
      {
        children: profiles.length,
        label: labelValue,
      },
    ];

    return userInfo;
  };

  /**
   * Component main render
   */
  const isVisiblePersonalInfo = !!find(profiles, { account: { isPersonal: true } });

  return (
    <DocumentTitle title="Simpli5® - General Account Information Page">
      <div className={styles.page}>
        <Container>
          <Row>
            <Col xs="12" md="6" lg="4">
              <AccountProfile
                className={classnames(
                  styles['section-card'],
                  styles['section-card--account-profile'],
                )}
                isVisiblePersonalInfo={isVisiblePersonalInfo}
                onGetData={handleGetAccountProfileData}
                profile={profile}
              />
              <LinkedNetworks
                actions={sessionActions}
                className={classnames(
                  styles['section-card'],
                  styles['section-card--linked-networks'],
                )}
                defaultAccount={defaultAccount}
                profiles={profiles}
              />
            </Col>
            <Col sm="12" md="6" lg="8">
              <AccountPreferences
                notificationActions={notificationActions}
                profile={profile}
                profiles={profiles}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </DocumentTitle>
  );
};

PageGeneralAccountInformation.propTypes = {
  defaultAccount: PropTypes.shape({}).isRequired,
  i18n: I18N.isRequired,
  notificationActions: ACTIONS.isRequired,
  profile: PERSON.isRequired,
  profiles: PEOPLE.isRequired,
  sessionActions: ACTIONS.isRequired,
};

export default Translation(PageGeneralAccountInformation, ['pageGeneralAccountInformation']);
