import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as accountsSelectors from 'app_modules/accounts/selectors';
import * as networkActions from 'app_modules/network/actions';
import * as networkSelectors from 'app_modules/network/selectors';
import * as sessionSelectors from 'app_modules/session/selectors';
import PageNetwork from 'components/PageNetwork';

const mapStateToProps = (state) => ({
  accountId: sessionSelectors.accountId(state),
  isDepartmentEnabled: accountsSelectors.isDepartmentEnabled(state),
  isFetchingMyConnections: networkSelectors.myConnections.isFetching(state),
  isFetchingMyNetwork: networkSelectors.myNetwork.isFetching(state),
  isFetchingRequests: networkSelectors.requests.isFetching(state),
  isPersonalAccount: sessionSelectors.isPersonal(state),
  myConnectionsMeta: networkSelectors.myConnections.meta(state),
  myConnectionsProfiles: networkSelectors.myConnections.profiles(state),
  networkMeta: networkSelectors.myNetwork.meta(state),
  networkProfiles: networkSelectors.myNetwork.profiles(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(networkActions, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageNetwork));
