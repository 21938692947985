/* eslint-disable import/prefer-default-export */

import { Team } from 'classes';

export const list = (state) => state.bundles.list
  .map((bundle) => ({
    ...bundle,
    teams: bundle.teams
      ? bundle.teams
        .map((team) => new Team(team))
      : [],
  }));
