import { combineReducers } from 'redux';
import { get } from 'lodash';

import bundlesActionTypes from 'app_modules/bundles/constants';
import initialState from 'app_modules/store/initialState.json';
import sessionActionTypes from 'app_modules/session/constants';
import teamsActionTypes from 'app_modules/teams/constants';

export const list = (state = initialState.bundles.list, { type, payload }) => {
  switch (type) {
    case bundlesActionTypes.FETCH_BUNDLES_REQUEST:
      return [];

    case bundlesActionTypes.FETCH_BUNDLES_SUCCESS:
      return get(payload, 'bundleAllocations', []);

    case bundlesActionTypes.FETCH_BUNDLE_DETAILS_SUCCESS: {
      const {
        bundleId,
        modules,
      } = payload;

      const bundlesList = bundleId && modules ? (
        state.map((bundle) => {
          if (bundle.i18NBundle.key === bundleId) {
            return {
              ...bundle,
              modules,
            };
          }
          return bundle;
        })
      ) : [];

      return bundlesList;
    }

    case teamsActionTypes.FETCH_SHARE_TEAMS_WITH_A_BUNDLE_SUCCESS: {
      const {
        bundleId,
        teams,
      } = payload;

      const bundlesList = bundleId && teams ? (
        state.map((bundle) => {
          if (bundle.id === bundleId) {
            return {
              ...bundle,
              teams,
            };
          }
          return bundle;
        })
      ) : [];

      return bundlesList;
    }

    case sessionActionTypes.RESET_STATE:
      return initialState.bundles.list;

    default:
      return state;
  }
};

export default combineReducers({
  list,
});
