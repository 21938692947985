const NAME_SPACE = 'Session';

const constants = {
  ADD_PROFILE: `${NAME_SPACE}/ADD_PROFILE`,
  CONTINUE_PROVISIONING: `${NAME_SPACE}/CONTINUE_PROVISIONING`,
  EXPIRE_SESSION_FAILURE: `${NAME_SPACE}/EXPIRE_SESSION_FAILURE`,
  EXPIRE_SESSION_REQUEST: `${NAME_SPACE}/EXPIRE_SESSION_REQUEST`,
  EXPIRE_SESSION_RESET: `${NAME_SPACE}/EXPIRE_SESSION_RESET`,
  EXPIRE_SESSION_SUCCESS: `${NAME_SPACE}/EXPIRE_SESSION_SUCCESS`,
  EXPIRE_SESSION: `${NAME_SPACE}/EXPIRE_SESSION`,
  FETCH_CATEGORY_BY_KEY_FAILURE: `${NAME_SPACE}/FETCH_CATEGORY_BY_KEY_FAILURE`,
  FETCH_CATEGORY_BY_KEY_REQUEST: `${NAME_SPACE}/FETCH_CATEGORY_BY_KEY_REQUEST`,
  FETCH_CATEGORY_BY_KEY_SUCCESS: `${NAME_SPACE}/FETCH_CATEGORY_BY_KEY_SUCCESS`,
  FETCH_CATEGORY_BY_KEY: `${NAME_SPACE}/FETCH_CATEGORY_BY_KEY`,
  FETCH_DASHBOARD_FAILURE: `${NAME_SPACE}/FETCH_DASHBOARD_FAILURE`,
  FETCH_DASHBOARD_REQUEST: `${NAME_SPACE}/FETCH_DASHBOARD_REQUEST`,
  FETCH_DASHBOARD_SUCCESS: `${NAME_SPACE}/FETCH_DASHBOARD_SUCCESS`,
  FETCH_DASHBOARD: `${NAME_SPACE}/FETCH_DASHBOARD`,
  FETCH_DOCUMENTS_FAILURE: `${NAME_SPACE}/FETCH_DOCUMENTS_FAILURE`,
  FETCH_DOCUMENTS_REQUEST: `${NAME_SPACE}/FETCH_DOCUMENTS_REQUEST`,
  FETCH_DOCUMENTS_SUCCESS: `${NAME_SPACE}/FETCH_DOCUMENTS_SUCCESS`,
  FETCH_DOCUMENTS: `${NAME_SPACE}/FETCH_DOCUMENTS`,
  FETCH_IMPERSONATE_FAILURE: `${NAME_SPACE}/FETCH_IMPERSONATE_FAILURE`,
  FETCH_IMPERSONATE_REQUEST: `${NAME_SPACE}/FETCH_IMPERSONATE_REQUEST`,
  FETCH_IMPERSONATE_SUCCESS: `${NAME_SPACE}/FETCH_IMPERSONATE_SUCCESS`,
  FETCH_IMPERSONATE: `${NAME_SPACE}/FETCH_IMPERSONATE`,
  FETCH_LOGIN_FAILURE: `${NAME_SPACE}/FETCH_LOGIN_FAILURE`,
  FETCH_LOGIN_REQUEST: `${NAME_SPACE}/FETCH_LOGIN_REQUEST`,
  FETCH_LOGIN_SUCCESS: `${NAME_SPACE}/FETCH_LOGIN_SUCCESS`,
  FETCH_LOGIN: `${NAME_SPACE}/FETCH_LOGIN`,
  FETCH_LOGOUT_FAILURE: `${NAME_SPACE}/FETCH_LOGOUT_FAILURE`,
  FETCH_LOGOUT_REQUEST: `${NAME_SPACE}/FETCH_LOGOUT_REQUEST`,
  FETCH_LOGOUT_SUCCESS: `${NAME_SPACE}/FETCH_LOGOUT_SUCCESS`,
  FETCH_LOGOUT: `${NAME_SPACE}/FETCH_LOGOUT`,
  FETCH_SET_PROFILE_PRIVACY_FAILURE: `${NAME_SPACE}/FETCH_SET_PROFILE_PRIVACY_FAILURE`,
  FETCH_SET_PROFILE_PRIVACY_REQUEST: `${NAME_SPACE}/FETCH_SET_PROFILE_PRIVACY_REQUEST`,
  FETCH_SET_PROFILE_PRIVACY_SUCCESS: `${NAME_SPACE}/FETCH_SET_PROFILE_PRIVACY_SUCCESS`,
  FETCH_SET_PROFILE_PRIVACY: `${NAME_SPACE}/FETCH_SET_PROFILE_PRIVACY`,
  FETCH_SSO_LOGIN_FAILURE: `${NAME_SPACE}/FETCH_SSO_LOGIN_FAILURE`,
  FETCH_SSO_LOGIN_REQUEST: `${NAME_SPACE}/FETCH_SSO_LOGIN_REQUEST`,
  FETCH_SSO_LOGIN_SUCCESS: `${NAME_SPACE}/FETCH_SSO_LOGIN_SUCCESS`,
  FETCH_SSO_LOGIN: `${NAME_SPACE}/FETCH_SSO_LOGIN`,
  FETCH_VIDEOS_FAILURE: `${NAME_SPACE}/FETCH_VIDEOS_FAILURE`,
  FETCH_VIDEOS_REQUEST: `${NAME_SPACE}/FETCH_VIDEOS_REQUEST`,
  FETCH_VIDEOS_SUCCESS: `${NAME_SPACE}/FETCH_VIDEOS_SUCCESS`,
  FETCH_VIDEOS: `${NAME_SPACE}/FETCH_VIDEOS`,
  GET_CATEGORY_BY_KEY: `${NAME_SPACE}/GET_CATEGORY_BY_KEY`,
  RESET_STATE: `${NAME_SPACE}/RESET_STATE`,
  SET_CURRENT_PROFILE_BY_ACCOUNT_ID: `${NAME_SPACE}/SET_CURRENT_PROFILE_BY_ACCOUNT_ID`,
  SET_CURRENT_PROFILE_BY_ID: `${NAME_SPACE}/SET_CURRENT_PROFILE_BY_ID`,
  SET_CURRENT_PROFILE: `${NAME_SPACE}/SET_CURRENT_PROFILE`,
  SET_CURRENT_USER: `${NAME_SPACE}/SET_CURRENT_USER`,
  SET_DEFAULT_ACCOUNT_FAILURE: `${NAME_SPACE}/SET_DEFAULT_ACCOUNT_FAILURE`,
  SET_DEFAULT_ACCOUNT_REQUEST: `${NAME_SPACE}/SET_DEFAULT_ACCOUNT_REQUEST`,
  SET_DEFAULT_ACCOUNT_SUCCESS: `${NAME_SPACE}/SET_DEFAULT_ACCOUNT_SUCCESS`,
  SET_DEFAULT_PROFILE: `${NAME_SPACE}/SET_DEFAULT_PROFILE`,
  SET_USER_BY_IMPERSONATION: `${NAME_SPACE}/SET_USER_BY_IMPERSONATION`,
  SET_USER: `${NAME_SPACE}/SET_USER`,
};

export default constants;
