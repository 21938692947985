const constants = {
  ADD_BAR_NOTIFICATION: 'Notification/ADD_BAR_NOTIFICATION',
  ADD_MODAL_NOTIFICATION: 'Notification/ADD_MODAL_NOTIFICATION',
  CLEAR_BAR_NOTIFICATION: 'Notification/CLEAR_BAR_NOTIFICATION',
  CLEAR_MODAL_NOTIFICATION: 'Notification/CLEAR_MODAL_NOTIFICATION',
  NOTIFY_ERROR: 'Notification/NOTIFY_ERROR',
  NOTIFY_SUCCESS: 'Notification/NOTIFY_SUCCESS',
};

export default constants;
