const NAME_SPACE = 'Groups';

const constants = {
  ARCHIVE_GROUP: `${NAME_SPACE}/ARCHIVE_GROUP`,
  ARCHIVE_GROUP_SUCCESS: `${NAME_SPACE}/ARCHIVE_GROUP_SUCCESS`,
  ARCHIVE_GROUP_FAILURE: `${NAME_SPACE}/ARCHIVE_GROUP_FAILURE`,
  ARCHIVE_GROUP_REQUEST: `${NAME_SPACE}/ARCHIVE_GROUP_REQUEST`,
  CLEAR_GROUP: `${NAME_SPACE}/CLEAR_GROUP`,
  FETCH_GROUP_DETAILS_FAILURE: `${NAME_SPACE}/FETCH_GROUP_DETAILS_FAILURE`,
  FETCH_GROUP_DETAILS_REQUEST: `${NAME_SPACE}/FETCH_GROUP_DETAILS_REQUEST`,
  FETCH_GROUP_DETAILS_SUCCESS: `${NAME_SPACE}/FETCH_GROUP_DETAILS_SUCCESS`,
  FETCH_GROUP_DETAILS: `${NAME_SPACE}/FETCH_GROUP_DETAILS`,
  FETCH_GROUPS_FAILURE: `${NAME_SPACE}/FETCH_GROUPS_FAILURE`,
  FETCH_GROUPS_REQUEST: `${NAME_SPACE}/FETCH_GROUPS_REQUEST`,
  FETCH_GROUPS_SUCCESS_NO_RESULTS: `${NAME_SPACE}/FETCH_GROUPS_SUCCESS_NO_RESULTS`,
  FETCH_GROUPS_SUCCESS: `${NAME_SPACE}/FETCH_GROUPS_SUCCESS`,
  FETCH_GROUPS: `${NAME_SPACE}/FETCH_GROUPS`,
  FETCH_RESEND_INVITATION_FAILURE: `${NAME_SPACE}/FETCH_RESEND_INVITATION_FAILURE`,
  FETCH_RESEND_INVITATION_REQUEST: `${NAME_SPACE}/FETCH_RESEND_INVITATION_REQUEST`,
  FETCH_RESEND_INVITATION_SUCCESS: `${NAME_SPACE}/FETCH_RESEND_INVITATION_SUCCESS`,
  FETCH_RESEND_INVITATION: `${NAME_SPACE}/FETCH_RESEND_INVITATION`,
  FETCH_UPDATE_GROUP_FAILURE: `${NAME_SPACE}/FETCH_UPDATE_GROUP_FAILURE`,
  FETCH_UPDATE_GROUP_INVITATION_EMAILS_FAILURE: `${NAME_SPACE}/FETCH_UPDATE_GROUP_INVITATION_EMAILS_FAILURE`,
  FETCH_UPDATE_GROUP_INVITATION_EMAILS_REQUEST: `${NAME_SPACE}/FETCH_UPDATE_GROUP_INVITATION_EMAILS_REQUEST`,
  FETCH_UPDATE_GROUP_INVITATION_EMAILS_SUCCESS: `${NAME_SPACE}/FETCH_UPDATE_GROUP_INVITATION_EMAILS_SUCCESS`,
  FETCH_UPDATE_GROUP_INVITATION_EMAILS: `${NAME_SPACE}/FETCH_UPDATE_GROUP_INVITATION_EMAILS`,
  FETCH_UPDATE_GROUP_INVITATIONS_FAILURE: `${NAME_SPACE}/FETCH_UPDATE_GROUP_INVITATIONS_FAILURE`,
  FETCH_UPDATE_GROUP_INVITATIONS_REQUEST: `${NAME_SPACE}/FETCH_UPDATE_GROUP_INVITATIONS_REQUEST`,
  FETCH_UPDATE_GROUP_INVITATIONS_SUCCESS: `${NAME_SPACE}/FETCH_UPDATE_GROUP_INVITATIONS_SUCCESS`,
  FETCH_UPDATE_GROUP_INVITATIONS: `${NAME_SPACE}/FETCH_UPDATE_GROUP_INVITATIONS`,
  FETCH_UPDATE_GROUP_REQUEST: `${NAME_SPACE}/FETCH_UPDATE_GROUP_REQUEST`,
  FETCH_UPDATE_GROUP_SUCCESS: `${NAME_SPACE}/FETCH_UPDATE_GROUP_SUCCESS`,
  FETCH_UPDATE_GROUP: `${NAME_SPACE}/FETCH_UPDATE_GROUP`,
};

export default constants;
