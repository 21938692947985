/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from 'reactstrap';
import { formatRoute } from 'react-router-named-routes';
import { isEmpty, size } from 'lodash';
import { sprintf } from 'sprintf-js';
import React, { useEffect } from 'react';

import { ACTIONS, HISTORY, I18N, MATCH, MODULES, PERSON } from 'constants/props';
import { BarError } from 'classes/errors';
import { NETWORK } from 'urls';
import Breadcrumbs from 'generics/Breadcrumbs';
import Module from 'components/Module';
import ModulesList from 'generics/ModulesList';
import Translation from 'generics/Translation';

import NetworkProfile from './NetworkProfile/NetworkProfile';
import styles from './PageNetworkDashboard.scss';

const PageNetworkDashboard = ({
  history,
  i18n,
  match,
  modules,
  networkActions,
  notificationActions,
  profile,
}) => {
  useEffect(() => {
    const { replace } = history;

    const {
      accountId,
      contentType,
      profileId,
    } = match.params;

    const onError = () => {
      replace(formatRoute(NETWORK, { accountId }));
      notificationActions.notifyError(new BarError({ label: 'invalidAddress' }));
    };

    networkActions.fetchThirdPersonProfile({ contentType, profileId }, null, onError);
  }, []);

  const getBreadcrums = () => {
    const { accountId } = match.params;

    if (isEmpty(profile)) {
      return [];
    }

    return [
      {
        label: 'Network',
        to: formatRoute(NETWORK, { accountId }),
      },
      {
        label: profile.firstName,
      },
    ];
  };

  const { accountId } = match.params;

  const firstName = profile.firstName || '';

  const pairModules = modules
    .filter((module) => (module.contextType === 'pair'))
    .map((module) => ({
      ...module,
      title: sprintf(module.title, { name: firstName }),
    }));

  const individualModules = modules.filter((module) => (module.contextType === 'individual'));
  const modulesList = [];

  if (size(pairModules)) {
    modulesList.push({
      className: styles['working-with'],
      name: i18n.network.pageNetworkDashboard.workingWith,
      submodules: pairModules,
    });
  }

  if (size(individualModules)) {
    modulesList.push({
      name: i18n.network.pageNetworkDashboard.understanding,
      submodules: individualModules,
    });
  }

  return (
    <Module
      breadcrumbs={(
        <Breadcrumbs
          className={styles.breadcrums}
          linkList={getBreadcrums()}
        />
      )}
      className={styles.page}
      context="user"
    >
      <Container>
        <Row>
          <Col xs="12" md="6" lg="4">
            <NetworkProfile className={styles['network-profile']} />
          </Col>

          <Col xs="12" md="6" lg="8">
            {
              modulesList
                .map((moduleList) => {
                  const {
                    className: moduleListClassName,
                    name,
                    submodules,
                  } = moduleList;

                  return (
                    <ModulesList
                      accountId={parseInt(accountId, 10)}
                      cardClassName={styles.card}
                      className={moduleListClassName}
                      col={{
                        xs: 12,
                        lg: 6,
                      }}
                      goToUrl={{
                        context: 'user',
                        entity: profile,
                        menuItem: 'network',
                      }}
                      header={`${name} ${firstName}`}
                      key={name}
                      modules={submodules}
                    />
                  );
                })
            }
          </Col>
        </Row>
      </Container>
    </Module>
  );
};

PageNetworkDashboard.propTypes = {
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
  match: MATCH.isRequired,
  modules: MODULES.isRequired,
  networkActions: ACTIONS.isRequired,
  notificationActions: ACTIONS.isRequired,
  profile: PERSON.isRequired,
};

export default Translation(PageNetworkDashboard, ['network']);
