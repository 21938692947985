import DocumentTitle from 'react-document-title';
import React from 'react';

import { ACTIONS, SSO_PROVISION_DATA, I18N } from 'constants/props';
import { Card, Header, Main } from 'generics/Card';

import Translation from 'generics/Translation';

import SetupSSOProfileForm from './components/SetupSSOProfileForm';
import styles from './PageSignUpSSO.scss';

const PageSignUpSSO = ({
  actions,
  i18n,
  ssoProvisionData,
}) => {
  const handleSubmit = (profile) => {
    actions.fetchCompleteSSOProvision(profile);
  };

  // eslint-disable-next-line no-empty-pattern
  const { profiles: [{}] } = ssoProvisionData;

  const {
    email,
    firstName,
    lastName,
  } = ssoProvisionData.profiles[0];

  return (
    <DocumentTitle title="Simpli5® - Sign Up SSO Page">
      <section className={styles.page}>
        <Card
          barClassName={styles['bar-style']}
          center
        >
          <Header className={styles.header}>
            <h1
              className={styles.title}
              title={i18n.signUpSSO.header}
            >
              {i18n.signUpSSO.header}
            </h1>
          </Header>
          <Main className={styles.main}>
            <SetupSSOProfileForm
              onSubmit={handleSubmit}
              initialValues={{
                email,
                firstName,
                lastName,
              }}
            />
          </Main>
        </Card>
      </section>
    </DocumentTitle>
  );
};

PageSignUpSSO.propTypes = {
  actions: ACTIONS.isRequired,
  i18n: I18N.isRequired,
  ssoProvisionData: SSO_PROVISION_DATA.isRequired,
};

export default Translation(PageSignUpSSO, ['signUpSSO']);
