import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';

import { BUNDLES, I18N } from 'constants/props';
import { Header, Main } from 'generics/Card';
import ActionButton from 'generics/ActionButton';
import Icon from 'generics/Icon';
import Translation from 'generics/Translation';

import BundlesListItem from '../BundlesListItem';
import styles from './BundlesList.scss';

const BundlesList = ({ bundles, i18n, onManageBundle }) => {
  const bundlesList = bundles.map((bundle) => (
    <BundlesListItem
      actions={[
        <ActionButton
          actionType="decline"
          className={styles.decline}
          desktop={{
            props: {
              label: i18n.pageAdministration.bundlesList.detailsLabel,
              title: i18n.pageAdministration.bundlesList.detailsLabel,
            },
          }}
          mobile={{
            props: {
              children: (
                <Icon.Stroke7
                  className={styles.button__icon}
                  name="pen"
                />
              ),
            },
          }}
          key="manage"
          onClick={() => { onManageBundle(bundle.i18NBundle.key); }}
        />,
      ]}
      bundle={bundle}
      key={bundle.i18NBundle.key}
      onManageBundle={onManageBundle}
    />
  ));

  const bundlesListContent = (
    <ul>
      {bundlesList}
    </ul>
  );

  return (
    <>
      <Header
        className={styles.header}
        title={i18n.contentModules.title}
      />
      <Main className={styles.main}>
        <Container>
          {bundlesListContent}
        </Container>
      </Main>
    </>
  );
};

BundlesList.propTypes = {
  bundles: BUNDLES,
  i18n: I18N.isRequired,
  onManageBundle: PropTypes.func.isRequired,
};

BundlesList.defaultProps = {
  bundles: [],
};

export default Translation(BundlesList, ['pageAdministration', 'contentModules']);
