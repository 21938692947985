import PropTypes from 'prop-types';

import { BarError } from 'classes/errors';
import { EMAIL, REQUIRED } from 'constants/inputTypes';
import { I18N } from 'constants/props';
import { UPDATE_EMAIL_ADMIN_ERROR } from 'constants/apiErrorCodes';
import api from 'api';

import useForm from '../../../custom_hooks/useForm';
import useFormValidation from '../../../custom_hooks/useFormValidation';

const useFormAccountInformationEditEmail = ({
  accountId,
  i18n,
  initialValues,
  isThirdPersonProfile,
  notificationActions,
  onFinish,
  profileId,
}) => {
  const { validator } = useFormValidation({ i18n });

  const handleValidate = (values) => {
    const {
      email,
    } = values;

    return {
      email: validator.validate(REQUIRED, email) || validator.validate(EMAIL, email),
    };
  };

  const onSubmit = ({ email }) => {
    const onSuccess = () => {
      onFinish(email);
    };

    const onError = ({ error }) => {
      if (isThirdPersonProfile) {
        if (error.errorCode === UPDATE_EMAIL_ADMIN_ERROR) {
          notificationActions.notifyError(new BarError({
            ...error,
            message: error?.message,
          }));
        }
        return;
      }
      console.log(error);
    };

    const editEmail = !isThirdPersonProfile
      ? api.profiles.editEmail({ accountId, email }, onSuccess, onError)
      : api.profiles.adminUpdateEmail({ accountId, email, profileId }, onSuccess, onError);

    editEmail();
  };

  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values = {
      email: null,
    },
  } = useForm({ initialValues, onSubmit, onValidate: handleValidate });

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  };
};

useFormAccountInformationEditEmail.propTypes = {
  i18n: I18N.isRequired,
  initialValues: PropTypes.shape({}),
  onFinish: PropTypes.func.isRequired,
};

useFormAccountInformationEditEmail.defaultProps = {
  initialValues: {},
};

export default useFormAccountInformationEditEmail;
