import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ACTIONS, BREADCRUMBS, I18N, PERSON } from 'constants/props';
import AccountInformation from 'generics/AccountInformation';
import AccountProfile from 'generics/AccountProfile';
import Breadcrumbs from 'generics/Breadcrumbs';
import Translation from 'generics/Translation';

import styles from './ProfileDetail.scss';

/**
 * ProfileDetail component
 * @extends Component
 */
class ProfileDetail extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const {
      onFetchNetworkDetails,
      profile,
    } = this.props;

    const {
      account: { id: accountId },
      id: profileId,
    } = profile;

    onFetchNetworkDetails(accountId, profileId);
  }

  render() {
    const {
      breadcrumbs,
      canEdit,
      i18n,
      isLearningAccount,
      notificationActions,
      onFetchEditProfile,
      onGetAccountProfileData,
      onUpdateAvatar,
      profile,
      userActions,
    } = this.props;

    return (
      <div className={styles.page}>
        <Container>
          {
            breadcrumbs && (
              <Row>
                <Col>
                  <Breadcrumbs
                    className={styles.breadcrumbs}
                    linkList={breadcrumbs}
                  />
                </Col>
              </Row>
            )
          }
          <Row>
            <Col xs="12" md="6" lg="4">
              <AccountProfile
                canEdit={canEdit}
                className={styles['account-profile']}
                onGetData={onGetAccountProfileData}
                onUpdateAvatar={onUpdateAvatar}
                profile={profile}
              />
            </Col>
            <Col sm="12" md="6" lg="8">
              <AccountInformation
                canEdit={canEdit}
                isLearningAccount={isLearningAccount}
                notificationActions={notificationActions}
                onSubmitForm={onFetchEditProfile}
                profile={profile}
                title={i18n.profileDetail.accountInformationTitle}
                userActions={userActions}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ProfileDetail.propTypes = {
  breadcrumbs: BREADCRUMBS,
  canEdit: PropTypes.bool,
  i18n: I18N.isRequired,
  isLearningAccount: PropTypes.bool.isRequired,
  notificationActions: ACTIONS.isRequired,
  onFetchEditProfile: PropTypes.func,
  onFetchNetworkDetails: PropTypes.func.isRequired,
  onGetAccountProfileData: PropTypes.func.isRequired,
  onUpdateAvatar: PropTypes.func,
  profile: PERSON.isRequired,
  userActions: ACTIONS.isRequired,
};

ProfileDetail.defaultProps = {
  breadcrumbs: null,
  canEdit: false,
  onFetchEditProfile: null,
  onUpdateAvatar: null,
};

export default Translation(ProfileDetail, ['profileDetail']);
