import * as fetchAcceptInvitation from './fetchAcceptInvitation';
import * as fetchDeclineInvitation from './fetchDeclineInvitation';
import * as fetchInvalidateInvitation from './fetchInvalidateInvitation';
import * as fetchInvitation from './fetchInvitation';
import * as fetchSentInvitations from './fetchSentInvitations';

module.exports = {
  ...fetchAcceptInvitation,
  ...fetchDeclineInvitation,
  ...fetchInvalidateInvitation,
  ...fetchInvitation,
  ...fetchSentInvitations,
};
