/* eslint-disable react-hooks/exhaustive-deps */
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { ACTIONS, I18N, PERSON, WORKSHOP, WORKSHOPS, WORKSHOP_MEMBERS } from 'constants/props';
import { Person } from 'classes';
import FormGroupSearch from 'components/FormGroupSearch';
import InfiniteScrollList from 'generics/InfiniteScrollList';
import Translation from 'generics/Translation';

import ListPeopleWorkshopListHeader from '../ListPeopleWorkshopListHeader';
import ModalWorkshop from '../ModalWorkshop';
import PeopleListItem from '../PeopleListItem';
import WorkshopListItem from '../WorkshopListItem';
import styles from './Workshops.scss';

const Workshops = ({
  currentProfile,
  hasMoreMembers,
  i18n,
  members,
  pageActions,
  workshop,
  workshopActions,
  workshops,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isFetching, setIsFetching] = useState(null);
  const [membersPageIndex, setMembersPageIndex] = useState(1);
  const [searchInfo, setSearchInfo] = useState({});
  const [membersFilter, setMembersFilter] = useState(null);

  let infiniteScrollList = useRef(null);

  const handleFetchRequests = (pageIndex = 1) => {
    if (pageIndex === 1 && get(infiniteScrollList, 'infiniteScrollRef')) {
      infiniteScrollList.infiniteScrollRef.resetIndex();
    }
    return workshopActions.fetchWorkshops({ pageIndex, searchInfo });
  };

  useEffect(() => {
    handleFetchRequests();
  }, []);

  const handleFetchMembers = (pageIndex = 1, updateInfiniteScroll, onComplete) => {
    setMembersPageIndex(pageIndex);
    if ((pageIndex === 1 || updateInfiniteScroll) && get(infiniteScrollList, 'infiniteScrollRef')) {
      infiniteScrollList.infiniteScrollRef.resetIndex();
    }

    return workshopActions.fetchMembers({
      pageIndex,
      search: membersFilter ? membersFilter.membersFilterText : membersFilter,
    }, onComplete);
  };

  const handleSearchWorkshop = (searchInfoParam) => {
    setSearchInfo(searchInfoParam);
  };

  useEffect(() => {
    handleFetchRequests(1);
  }, [searchInfo]);

  const handleSelectWorkshop = (workshopId) => {
    setIsFetching(workshopId);

    const onComplete = () => {
      setIsFetching(null);
    };

    workshopActions.fetchWorkshopDetails(workshopId, onComplete, onComplete);
    infiniteScrollList.infiniteScrollRef.resetIndex();
  };

  const handleClickOnBackButton = () => {
    infiniteScrollList.infiniteScrollRef.resetIndex();
    workshopActions.clearWorkshop();
    handleSearchWorkshop({});
  };

  const handleClickOnEditButton = () => {
    setIsEdit(true);
  };

  const handleCloseModal = () => {
    setIsEdit(false);
  };

  const handleSearchMembers = (membersFilterParam, fn) => {
    setMembersFilter({ membersFilterText: membersFilterParam, fn });
  };

  useEffect(() => {
    if (membersFilter) {
      handleFetchMembers(1, true, membersFilter?.fn);
    }
  }, [membersFilter?.membersFilterText]);

  const renderList = () => {
    const list = workshop ? (
      members.map((item, index) => {
        const { id = index, assessmentStatus } = item;
        Object.assign(item, { status: i18n.workshops.status[assessmentStatus] });

        return (
          <PeopleListItem
            showEmail
            actions={[]}
            hasDate={false}
            isFetching={isFetching === id}
            isWorkshop={!!workshop}
            item={new Person(item)}
            key={`workshop-member-${id}`}
          />
        );
      })
    ) : workshops.list.map((item) => (
      <WorkshopListItem
        item={item}
        key={`workshop-${item.id}`}
        isFetching={isFetching === item.id}
        onClick={() => { handleSelectWorkshop(item.id); }}
      />
    ));

    return !isEmpty(list) ? list : (
      <div className={styles.empty}>
        <span>
          {i18n.pageAdministration.peopleList.noResultsToDisplayYetLabel}
        </span>
      </div>
    );
  };

  const workshopsInfiniteScroll = {
    hasMorePages: workshops.meta.morePages,
    listHeight: 600,
    onFetch: handleFetchRequests,
  };

  const membersInfiniteScroll = {
    hasMorePages: hasMoreMembers,
    listHeight: 600,
    onFetch: handleFetchMembers,
    pageStart: membersPageIndex + 1,
  };

  const onComplete = () => {
    handleFetchRequests();
  };

  const onReset = () => {
    setMembersFilter({ membersFilterText: null, fn: null });
  };

  return (
    <>
      {
        !!workshop && (
          <ListPeopleWorkshopListHeader
            canEdit={currentProfile.can({ workshop: 'update' })}
            onClickBackButton={handleClickOnBackButton}
            onClickEditButton={handleClickOnEditButton}
            onReset={onReset}
            onSearch={handleSearchMembers}
            workshop={workshop}
          />
        )
      }
      {
        !workshop && <FormGroupSearch onSearch={handleSearchWorkshop} />
      }
      <InfiniteScrollList
        className={styles['scroll-list-main']}
        infiniteScroll={workshop ? membersInfiniteScroll : workshopsInfiniteScroll}
        listId="workshops-scroll-list"
        innerRef={(component) => { infiniteScrollList = component; }}
      >
        {renderList()}
      </InfiniteScrollList>
      {
        isEdit && (
          <ModalWorkshop
            hasMoreMembers={hasMoreMembers}
            members={members}
            membersPageIndex={membersPageIndex}
            onClose={handleCloseModal}
            onComplete={onComplete}
            onFetchMembers={(pageIndex) => { handleFetchMembers(pageIndex, true); }}
            pageActions={pageActions}
            workshop={workshop}
            workshopActions={workshopActions}
          />
        )
      }
    </>
  );
};

Workshops.propTypes = {
  currentProfile: PERSON.isRequired,
  hasMoreMembers: PropTypes.bool.isRequired,
  i18n: I18N.isRequired,
  members: WORKSHOP_MEMBERS.isRequired,
  pageActions: ACTIONS.isRequired,
  workshop: WORKSHOP,
  workshopActions: ACTIONS.isRequired,
  workshops: WORKSHOPS.isRequired,
};

Workshops.defaultProps = {
  workshop: null,
};

export default Translation(Workshops, ['pageAdministration', 'workshops']);
