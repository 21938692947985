import 'core-js/es6/promise';

export default async function loadPolyfills() {
  if (!('fetch' in window)) {
    await import('whatwg-fetch');
  }
  if (!('Intl' in window)) {
    await import('intl');
  }
  if (!('matchMedia' in window)) {
    await import('matchmedia-polyfill');
  }
  if (!Intl.PluralRules) {
    await import('@formatjs/intl-pluralrules/polyfill');
    await import('@formatjs/intl-pluralrules/dist/locale-data/en');
    await import('@formatjs/intl-pluralrules/dist/locale-data/es');
  }
  if (!Intl.RelativeTimeFormat) {
    await import('@formatjs/intl-relativetimeformat/polyfill');
    await import('@formatjs/intl-relativetimeformat/dist/locale-data/en');
    await import('@formatjs/intl-relativetimeformat/dist/locale-data/es');
  }
}
