/* eslint-disable react-hooks/exhaustive-deps */
import { pickHTMLProps } from 'pick-react-known-prop';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { PROFILES, PROFILE } from 'constants/props';

import lib from './lib';
import styles from './EnergyMap.scss';
import useEnergyMap from './useEnergyMap';

const EnergyMap = ({ className, data, innerRef, ...rest }) => {
  const {
    elementRefs,
    isPair,
  } = useEnergyMap({ data, innerRef, lib, styles });

  const {
    canvasRef,
    legendRef,
    mainGRef,
    nodeElementRef,
    profilesRef,
    referencesRef,
    svgRef,
    textsRef,
  } = elementRefs;

  return (
    <div
      className={classnames(
        styles['energy-map'],
        className,
      )}
      ref={nodeElementRef}
      role="img"
      {...pickHTMLProps(rest)}
    >
      <canvas
        className={styles['energy-map__canvas']}
        ref={canvasRef}
      />
      <svg
        className={styles['energy-map__svg']}
        ref={svgRef}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g ref={mainGRef}>
          <g ref={referencesRef} />

          <g ref={profilesRef} />

          <g ref={textsRef} />

          {
            isPair && (
              <g ref={legendRef} />
            )
          }
        </g>
      </svg>
    </div>
  );
};

EnergyMap.propTypes = {
  className: PropTypes.string,
  data: PropTypes.oneOfType([
    PROFILES,
    PROFILE,
  ]),
  innerRef: () => {},
};

EnergyMap.defaultProps = {
  className: null,
  data: [],
  innerRef: null,
};

export default EnergyMap;
