/* eslint-disable react-hooks/exhaustive-deps */
import { debounce, get } from 'lodash';
import { useEffect, useRef, useState } from 'react';

const useSearch = ({
  isDebounce,
  onChange,
  onReset,
  onSearch,
  value,
}) => {
  const [filter, setFilter] = useState(null);
  const [handleDebounceChange, setHandleDebounceChange] = useState(null);
  const [handleDebounceSearch, setHandleDebounceSearch] = useState(null);

  const searchRef = useRef(null);

  const handleChange = (newFilter, fn) => {
    onChange(newFilter, fn);
    setFilter(newFilter);
  };

  const handleSearch = () => {
    if (!filter) {
      return;
    }

    onSearch(filter);
  };

  useEffect(() => {
    if (isDebounce) {
      if (handleDebounceSearch === null) {
        setHandleDebounceSearch(onSearch
          ? () => debounce(handleSearch, 500)
          : undefined,
        );
      }

      if (handleDebounceChange === null) {
        setHandleDebounceChange(onChange
          ? () => debounce(handleChange, 500)
          : undefined,
        );
      }
    }
  }, [isDebounce]);

  const reset = (fn) => {
    if (searchRef) {
      searchRef.current.value = '';
      handleChange(null, fn);

      if (onReset) {
        onReset();
      }
    }
  };

  const resetRef = () => {
    if (searchRef) {
      searchRef.current.value = '';
    }
  };

  const selectedValue = value ? { value } : null;
  const onChangeFn = isDebounce ? handleDebounceChange : handleChange;
  const hasValue = !!get(searchRef, 'current.value');

  return {
    handleDebounceSearch,
    hasValue,
    onChangeFn,
    reset,
    resetRef,
    searchRef,
    selectedValue,
  };
};

export default useSearch;
