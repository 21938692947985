import { Col, Container, Row } from 'reactstrap';
import { isNil, keys } from 'lodash';
import { MenuItem } from '@material-ui/core';
import { sprintf } from 'sprintf-js';
import classnames from 'classnames';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from 'react-simple-tooltip';

import { COLORS } from 'constants/colors';
import { FILE_INVITATION_DEPARTMENT_HEADERS, FILE_INVITATION_HEADERS } from 'constants/fileHeaders';
import { DAILY, FREQUENCIES, NEVER } from 'constants/frequencies';
import { I18N, INVITATION_GROUP } from 'constants/props';
import { StyledTextField, StyledSelect } from 'generics/StyledFormComponents';

import FileReader from 'generics/FileReader';
import Translation from 'generics/Translation';

import styles from './FormFileInvitation.scss';

import useFormFileInvitation from './useFormFileInvitation';

const FILE_TYPES = ['text/csv'];

/**
 * FormFileInvitation component
 * @extends Component
 */
const FormFileInvitation = ({
  edit,
  i18n,
  initialValues,
  isDepartmentEnabled,
  onLoadFile,
  onValidate,
}) => {
  const {
    errors,
    file,
    hasFileError,
    handleChange,
    handleLoadFile,
    values,
  } = useFormFileInvitation({ edit, i18n, initialValues, onLoadFile, onValidate });

  const renderFrequencyList = () => keys(FREQUENCIES).map((frequency) => {
    const frequencyNum = parseInt(frequency, 10);

    return (
      <MenuItem
        key={`frequency-item-${frequencyNum}`}
        value={frequencyNum}
      >
        {i18n.pageAdministration.formFileInvitation.frequency[frequencyNum]}
      </MenuItem>
    );
  });

  const renderFrequencyTypes = () => {
    const i18nBase = i18n.pageAdministration.formFileInvitation.frequencyType;

    const { frequency } = values;

    return FREQUENCIES[frequency].map((frequencyTypeId, index) => {
      const dayCount = index + 1;

      const primaryText = frequency === DAILY
        ? sprintf(i18nBase.day, {
          dayCount,
          text: pluralize(i18nBase.dayText, dayCount),
        })
        : i18nBase.weekDays[frequencyTypeId];

      return (
        <MenuItem
          key={`frequency-type-${frequencyTypeId}`}
          value={frequencyTypeId}
        >
          {primaryText}
        </MenuItem>
      );
    });
  };

  const renderTooltip = () => {
    const i18nBase = i18n.pageAdministration.formFileInvitation.tooltip;
    const title = (
      <span>
        {i18nBase.title}
      </span>
    );

    return edit || (file !== null) ? (
      <Tooltip
        background={COLORS.primaryWhite.rgba}
        border={COLORS.grey4.rgba}
        className={styles.tooltip}
        color={COLORS.grey1.rgba}
        content={i18nBase.description}
        placement="right"
      >
        {title}
      </Tooltip>
    )
      : (
        <div className={styles.tooltip}>
          {title}
        </div>
      );
  };

  const i18nBase = i18n.pageAdministration.formFileInvitation;

  const templateFileReader = {
    headers: !isDepartmentEnabled ? FILE_INVITATION_HEADERS : FILE_INVITATION_DEPARTMENT_HEADERS,
    description: sprintf(i18nBase.templateFileDescription, { headers: FILE_INVITATION_HEADERS }).replace(/,/g, ', '),
    downloadDescription: i18nBase.templateFileDownloadDescription,
    templateFileName: i18nBase.templateInvitationsFileName,
  };

  const isFormDisabled = edit
    ? false
    : file === null;

  return (
    <Container className={styles.container} fluid>
      {
        !edit && (
          <Row>
            <Col xs={12}>
              <FileReader
                descriptionMessage={i18nBase.fileReaderDescription}
                fileTypes={FILE_TYPES}
                onLoadFile={handleLoadFile}
                templateFile={templateFileReader || null}
              />
              {
                hasFileError && (
                  <span className={styles.error}>
                    {i18nBase.fileError}
                  </span>
                )
              }
            </Col>
          </Row>
        )
      }
      <div
        className={classnames(
          styles.fields,
          { [styles['is-disabled']]: isFormDisabled },
        )}
      >
        <p>
          {i18nBase.instructions}
        </p>
        <Row>
          <Col xs="12">
            <StyledTextField
              className={styles.textfield}
              disabled={isFormDisabled}
              error={isNil(values.name) ? false : !!errors.name}
              fullWidth
              helperText={isNil(values.name) ? ' ' : errors.name}
              id="file-invitation-group-name"
              label={i18nBase.groupName}
              name="name"
              onChange={(e) => handleChange('name', e.currentTarget.value)}
              value={values.name || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <StyledSelect
              disabled={isFormDisabled}
              error={isNil(values.frequency) ? false : !!errors.frequency}
              fullWidth
              helperText={isNil(values.frequency) ? ' ' : errors.frequency}
              iconClassName={styles['select-icon']}
              label={i18nBase.frequencyLabel}
              name="frequency"
              onChange={(e) => handleChange('frequency', e.target.value)}
              value={values.frequency}
            >
              {renderFrequencyList()}
            </StyledSelect>
            {renderTooltip()}
          </Col>

          {
            values.frequency !== NEVER && (
              <Col xs="12" md="6">
                <StyledSelect
                  error={isNil(values.frequencyType) ? false : !!errors.frequencyType}
                  fullWidth
                  helperText={isNil(values.frequencyType) ? ' ' : errors.frequencyType}
                  iconClassName={styles['select-icon']}
                  label={i18nBase.frequencyTypeLabel}
                  name="frequencyType"
                  onChange={(e) => handleChange('frequencyType', e.target.value)}
                  value={values.frequencyType}
                >
                  {renderFrequencyTypes()}
                </StyledSelect>
              </Col>
            )
          }
        </Row>
      </div>
    </Container>
  );
};

FormFileInvitation.propTypes = {
  edit: PropTypes.bool,
  initialValues: INVITATION_GROUP,
  i18n: I18N.isRequired,
  isDepartmentEnabled: PropTypes.bool.isRequired,
  onLoadFile: PropTypes.func,
  onValidate: PropTypes.func.isRequired,
};

FormFileInvitation.defaultProps = {
  edit: false,
  initialValues: undefined,
  onLoadFile: null,
};

export default Translation((FormFileInvitation), ['pageAdministration', 'generics']);
