import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import React from 'react';

import { ACTIONS, HISTORY, INVITATION } from 'constants/props';
import { MY_5_DYNAMICS } from 'urls';
import * as invitesSelectors from 'app_modules/invites/selectors';
import * as notificationActions from 'app_modules/notifications/actions';
import * as sessionActions from 'app_modules/session/actions';
import InvitationDialog from 'generics/InvitationDialog';
import Translation from 'generics/Translation';

import styles from './PageAcceptInvitation.scss';

const PageAcceptInvitation = ({ actions, history, invitation }) => (
  <DocumentTitle title="Simpli5® - Accept Invitation Page">
    <div className={styles.page}>
      <InvitationDialog
        clearInvitationOnAccept
        onDecline={() => actions
          .logout().then(() => { actions.notifyWarning({ label: 'accountExpired' }); })}
        onAccept={() => {
          history.push(formatRoute(MY_5_DYNAMICS, { accountId: invitation.account.id }));
        }}
      />
    </div>
  </DocumentTitle>
);

PageAcceptInvitation.propTypes = {
  actions: ACTIONS.isRequired,
  history: HISTORY.isRequired,
  invitation: INVITATION.isRequired,
};

const mapStateToProps = (state) => ({
  invitation: invitesSelectors.invitation(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...sessionActions, ...notificationActions }, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Translation(PageAcceptInvitation, ['PageAcceptInvitation'])));
