import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as sessionActions from 'app_modules/session/actions';
import PageImpersonate from 'components/PageImpersonate';

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
});


export default connect(null, mapDispatchToProps)(PageImpersonate);
