import * as containers from './containers';
import * as draw from './draw';
import * as drawLabels from './drawLabels';
import * as drawLegend from './drawLegend';
import * as drawProfiles from './drawProfiles';
import * as drawRef from './drawRef';
import * as drawTeamStdev from './drawTeamStdev';
import * as geometry from './geometry';
import * as init from './init';
import * as scales from './scales';

export default {
  ...containers,
  ...draw,
  ...drawLabels,
  ...drawLegend,
  ...drawProfiles,
  ...drawRef,
  ...drawTeamStdev,
  ...geometry,
  ...init,
  ...scales,
};
