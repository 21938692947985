import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';

import { OVERVIEW_REPORT_MODULE, VIDEO_MODULE, VIDEO_MODULE_SECOND } from 'constants/customizationModules';
import { Person, Team } from 'classes';

// Common

export const ACTIONS = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.object,
]);

export const BREADCRUMS = PropTypes.shape({});

export const CHILDREN = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.func,
  PropTypes.node,
]);


export const CUSTOMIZATIONS = PropTypes.oneOfType({
  [OVERVIEW_REPORT_MODULE]: {
    overviewRptIntro: PropTypes.string,
  },
  [VIDEO_MODULE]: {
    bodyContent: PropTypes.string,
    heading: PropTypes.string,
    embedCode: PropTypes.string,
  },
  [VIDEO_MODULE_SECOND]: {
    bodyContentSecond: PropTypes.string,
    headingSecond: PropTypes.string,
    embedCodeSecond: PropTypes.string,
  },
});

export const ADMIN_LICENSE_BUYER = PropTypes.oneOfType({
  name: PropTypes.string,
  email: PropTypes.string,
});

export const HISTORY = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.object,
]);

export const LOCATION = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
});

export const MATCH = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.object,
]);

export const RAISED_BUTTON_PROPS = PropTypes.shape({
  className: PropTypes.string,
  label: PropTypes.string,
});

export const FLAT_BUTTON_PROPS = PropTypes.shape({
  className: PropTypes.string,
});

export const ACTION_BUTTON = PropTypes.shape({
  component: CHILDREN,
  id: PropTypes.string,
  props: PropTypes.oneOfType([
    RAISED_BUTTON_PROPS,
    FLAT_BUTTON_PROPS,
  ]),
});

export const STYLE = stylePropType;

export const PERSON = PropTypes.instanceOf(Person);

export const PEOPLE = PropTypes.arrayOf(PERSON);

export const TEAM = PropTypes.instanceOf(Team);

export const TEAMS = PropTypes.arrayOf(TEAM);

export const PROFILE = PropTypes.oneOfType([
  PERSON,
  TEAM,
]);

export const PROFILES = PropTypes.arrayOf(PROFILE);

// Page
export const LINK = PropTypes.shape({
  label: PropTypes.string,
  to: PropTypes.string,
});

export const BREADCRUMBS = PropTypes.arrayOf(LINK);


// Assessment

export const WORD_PAIR = PropTypes.shape({
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
});

export const QUESTION = PropTypes.oneOfType([
  PropTypes.shape({}),
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    leftWordPair: WORD_PAIR.isRequired,
    rightWordPair: WORD_PAIR.isRequired,
    selectedWordPair: WORD_PAIR,
  }),
]);

export const QUESTIONS = PropTypes.arrayOf(QUESTION);

export const ASSESSMENT = PropTypes.shape({
  created_at: PropTypes.string.isRequired,
  finished: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  questions: QUESTIONS.isRequired,
  tiebreak: PropTypes.bool.isRequired,
});

// Dashboard

export const ACCOUNT = PropTypes.shape({
  id: PropTypes.number.isRequired,
  isPersonal: PropTypes.bool.isRequired,
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  nameAbbreviation: PropTypes.string,
  siteUrl: PropTypes.string,
});

export const ACCOUNTS = PropTypes.arrayOf(ACCOUNT);

export const DOCUMENT = PropTypes.shape({
  documentKey: PropTypes.string.isRequired,
  matches: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string,
    title: PropTypes.string,
  })),
  title: PropTypes.string,
});

export const DOCUMENTS = PropTypes.arrayOf(DOCUMENT);

export const MODULE = PropTypes.oneOfType([
  PropTypes.shape({}),
  PropTypes.shape({
    categoryKey: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
]);

export const MODULES = PropTypes.arrayOf(MODULE);

// Invitation signup

export const INVITATION = PropTypes.oneOfType([
  PropTypes.shape({}),
  PropTypes.shape({
    account: ACCOUNT.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    invitationSender: PropTypes.string.isRequired,
    isInvitationValid: PropTypes.bool.isRequired,
    isNewUser: PropTypes.bool.isRequired,
    lastName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
]);

export const INVITATIONS = PropTypes.arrayOf(INVITATION);

export const INVITE_API = PropTypes.shape({});

// i18n

export const TRANSLATION = PropTypes.shape({
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    messages: PropTypes.shape({}),
  }),
});

export const TRANSLATIONS = PropTypes.arrayOf(TRANSLATION);

// TODO: Define the schema

export const I18N = PropTypes.shape({});

// charts

export const CHART_TEAM = PropTypes.shape({
  name: PropTypes.string.isRequired,
  stdev: PropTypes.arrayOf(PropTypes.number).isRequired,
  type: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
});

export const CHART_USER = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
});

export const CHART_DATA = PropTypes.oneOfType([
  PropTypes.shape({}),
  CHART_USER,
  CHART_TEAM,
  PropTypes.arrayOf(CHART_TEAM),
  PropTypes.arrayOf(CHART_USER),
]);

export const USER = PropTypes.shape({});

export const SPIDER_GRAPH = PropTypes.shape({
  isTeamAverageInclude: PropTypes.bool,
  profiles: PEOPLE,
  team: TEAM,
});

// Profile by context

export const CONTEXT_URL = PropTypes.shape({});

// Network

const CONNECTION_REQUEST = PropTypes.shape({
  avatar: PropTypes.string,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isPublic: PropTypes.bool.isRequired,
  lastName: PropTypes.string.isRequired,
});

export const META = PropTypes.shape({
  morePages: PropTypes.bool.isRequired,
  totalObjects: PropTypes.number,
});

const RECEIVED_REQUEST = PropTypes.shape({
  connectionRequestEmisor: CONNECTION_REQUEST.isRequired,
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
});

// TODO: use LIST and replace request by list in the component
export const RECEIVED_REQUESTS = PropTypes.shape({
  isFetching: PropTypes.bool.isRequired,
  meta: META.isRequired,
  requests: PropTypes.arrayOf(RECEIVED_REQUEST).isRequired,
});

const SENT_REQUEST = PropTypes.shape({
  connectionRequestReceiver: CONNECTION_REQUEST.isRequired,
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
});

// TODO: use LIST and replace request by list in the component
export const SENT_REQUESTS = PropTypes.shape({
  isFetching: PropTypes.bool.isRequired,
  meta: META.isRequired,
  requests: PropTypes.arrayOf(SENT_REQUEST),
});

export const THIRD_PERSON = PropTypes.oneOfType([
  PropTypes.shape({}),
  PropTypes.shape({
    profile: PERSON.isRequired,
    modules: MODULES,
    overview: PropTypes.string,
  }),
]);

// Welcome back

export const ASSESSMENT_SCORES = PropTypes.arrayOf(PropTypes.number);

// ModuleList

const stringOrNumberProp = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

const columnProps = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.number,
  PropTypes.string,
  PropTypes.shape({
    size: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
    push: stringOrNumberProp,
    pull: stringOrNumberProp,
    offset: stringOrNumberProp,
  }),
]);

export const COL = PropTypes.shape({
  xs: columnProps,
  sm: columnProps,
  md: columnProps,
  lg: columnProps,
  xl: columnProps,
});

// TODO: Refactor for different form types
export const FORM_FIELD = PropTypes.shape({
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
});

export const FORM_FIELDS = PropTypes.arrayOf(FORM_FIELD);

export const AVATAR_ICON = PropTypes.shape({
  iconName: PropTypes.string,
  className: PropTypes.string,
});

export const ITEM_FLAG = PropTypes.shape({
  className: PropTypes.string,
  iconName: PropTypes.string,
  label: PropTypes.string,
});

export const ITEM_FLAGS = PropTypes.arrayOf(ITEM_FLAG);

export const LIST = (typeListItem) => (
  PropTypes.shape({
    isFetching: PropTypes.bool,
    list: PropTypes.arrayOf(typeListItem),
    meta: META,
  })
);

export const NETWORK_DETAILS = PropTypes.shape({
  numberOfConnections: PropTypes.number.isRequired,
  numberOfTeamsCreated: PropTypes.number.isRequired,
});

export const PEOPLE_LIST_ITEM = PropTypes.oneOfType([
  INVITATION,
  PERSON,
]);

export const INFINITE_SCROLL = PropTypes.shape({
  className: PropTypes.string,
  hasMorePages: PropTypes.bool,
  hideFullLink: PropTypes.bool,
  listHeight: PropTypes.number,
  onFetch: PropTypes.func,
  pageStart: PropTypes.number,
});

export const COUNTRY = PropTypes.shape({
  abbreviation: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
});

export const COUNTRIES = PropTypes.arrayOf(COUNTRY);

export const OWNER = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
});

export const OWNERS = PropTypes.arrayOf(OWNER);

export const NEW = PropTypes.shape({
  createdAt: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  title: PropTypes.string,
  updatedAt: PropTypes.string,
});

export const NEWS = PropTypes.arrayOf(NEW);

export const ICON = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.shape({
    className: PropTypes.string,
    fontFamily: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
  PropTypes.string,
]);

export const SECTION = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  icon: ICON,
  children: CHILDREN,
});

export const SECTIONS = PropTypes.arrayOf(SECTION);

export const INFO_ITEM = PropTypes.shape({
  children: CHILDREN,
  label: PropTypes.oneOfType([
    PropTypes.string,
    CHILDREN,
  ]),
  title: PropTypes.string,
});

export const INFO_LIST = PropTypes.arrayOf(INFO_ITEM);

export const I18N_BUNDLE = PropTypes.shape({
  description: PropTypes.string,
  key: PropTypes.string,
  locale: PropTypes.string,
  module_keys: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
});

export const BUNDLE = PropTypes.shape({
  i18nBundle: I18N_BUNDLE,
  sharingMode: PropTypes.string,
  sharingModeLocked: PropTypes.bool,
});

export const BUNDLES = PropTypes.arrayOf(BUNDLE);

export const INVITATION_GROUP = PropTypes.shape({
  createdAt: PropTypes.string,
  description: PropTypes.string,
  frequency: PropTypes.number,
  frequencyType: PropTypes.number,
  id: PropTypes.number,
  logo: PropTypes.string,
  name: PropTypes.string,
});

export const INVITATION_GROUPS = LIST(INVITATION_GROUP);

export const WORKSHOP = PropTypes.shape({
  createdAt: PropTypes.string,
  establishedAt: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
});

export const WORKSHOPS = LIST(WORKSHOP);

export const WORKSHOP_MEMBER = PropTypes.shape({
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  assessmentStatus: PropTypes.number.isRequired,
});

export const WORKSHOP_MEMBERS = PropTypes.arrayOf(WORKSHOP_MEMBER);

export const COMPLETE_WORKSHOP = PropTypes.shape({
  hasMoreMembers: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  members: WORKSHOP_MEMBERS.isRequired,
  workshop: WORKSHOP,
});

export const VIDEOS = PropTypes.shape({
  welcome: PropTypes.string,
  welcomeBack: PropTypes.string,
});

export const MEMBERS = PropTypes.shape({
  hasMorePages: PropTypes.bool.isRequired,
  list: PROFILES.isRequired,
  onFetchMore: PropTypes.func,
  pageIndex: PropTypes.number,
});

export const SSO_PROVISION_DATA = PropTypes.shape({
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
});

export const TRAINING_VIDEO = PropTypes.shape({
  captionName: PropTypes.string.isRequired,
  captionUrl: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  videoName: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
});

export const TRAINING_VIDEOS = PropTypes.arrayOf(TRAINING_VIDEO);

export const TRAINING = PropTypes.shape({
  name: PropTypes.string.isRequired,
  videos: TRAINING_VIDEOS.isRequired,
});

export const TRAININGS = PropTypes.arrayOf(TRAINING);

export const FREQUENCY_ERRORS = PropTypes.shape({
  frequency: PropTypes.string,
  frequencyType: PropTypes.string,
  name: PropTypes.string,
});

export const FREQUENCY_VALUES = PropTypes.shape({
  frequency: PropTypes.number,
  frequencyType: PropTypes.number,
  name: PropTypes.string,
});

export const BULK_DELETE_ITEM = PropTypes.shape({
  email: PropTypes.string.isRequired,
});

export const RECORD = PropTypes.oneOfType([
  INVITATION,
  WORKSHOP_MEMBER,
  BULK_DELETE_ITEM,
]);

export const RECORDS = PropTypes.arrayOf(RECORD);

// Reports

export const REPORT = PropTypes.shape({
  code: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  expiredAt: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  lastName: PropTypes.string.isRequired,
  profile: PERSON,
  sender: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
});

export const REPORTS = PropTypes.arrayOf(REPORT);

export const ENERGY_MAP_REF = PropTypes.shape({
  current: PropTypes.arrayOf(PropTypes.element).isRequired,
});
