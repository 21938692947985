import { OVERVIEW_REPORT_MODULE, VIDEO_MODULE, VIDEO_MODULE_SECOND } from 'constants/customizationModules';

export const en = {
  customizationsList: [
    {
      title: 'Video #1 Widget Customization',
      key: VIDEO_MODULE,
    },
    {
      title: 'Video #2 Widget Customization',
      key: VIDEO_MODULE_SECOND,
    },
    {
      title: 'Report Introduction Customization',
      key: OVERVIEW_REPORT_MODULE,
    },
  ],
  overviewReport: {
    introduction: 'Introduction',
    title: 'Customize Introduction',
  },
  title: 'Edit Customizations',
  video: {
    heading: 'Heading',
    bodyContent: 'Body Content',
    embedCode: 'YouTube Embed Code',
    title: 'Customize Widget',
  },
  videoSecond: {
    heading: 'Heading',
    bodyContent: 'Body Content',
    embedCode: 'YouTube Embed Code',
    title: 'Customize Widget',
  },
};

export const es = en;
