/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import { isEqual } from 'lodash';

import { useRef, useState, useEffect, useCallback } from 'react';

const useEnergyMap = ({ data, innerRef, lib, styles }) => {
  const [baseElements, setBaseElements] = useState(null);
  const [elements, setElements] = useState(null);
  const [geometry, setGeometry] = useState(null);
  const [scales, setScales] = useState(null);
  const [isPair, setIsPair] = useState(null);
  const [oldData, setOldData] = useState(null);
  const [newData, setNewData] = useState(null);
  const [isGraphSet, setIsGraphSet] = useState(false);

  // References
  const elementRefs = {
    canvasRef: useRef(null),
    legendRef: useRef(null),
    mainGRef: useRef(null),
    nodeElementRef: useRef(null),
    profilesRef: useRef(null),
    referencesRef: useRef(null),
    svgRef: useRef(null),
    textsRef: useRef(null),
  };

  useEffect(() => () => {
    window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    if (data) {
      if (!isEqual(oldData, data)) {
        const { initializeData } = lib;
        const { isPairData, newDataInit } = initializeData(data);

        if (!oldData) {
          const {
            initContainers,
          } = lib;

          setBaseElements(initContainers(elementRefs));
        } else {
          const {
            draw,
          } = lib;

          draw({
            elements,
            geometry,
            isPair: isPairData,
            lib,
            data: newDataInit,
            profile: data,
            scales,
            styles,
          });
          setIsGraphSet(true);
          if (innerRef) {
            const { nodeElementRef } = elementRefs;
            innerRef({ outerHTML, nodeElementRef });
          }
        }

        setIsPair(isPairData);
        setNewData(newDataInit);
        setOldData(data);
      }
    }
  }, [data, lib]);

  useEffect(() => {
    if (baseElements) {
      initGraphStructure();
    }
  }, [baseElements]);

  useEffect(() => {
    if (geometry && newData && scales) {
      const { draw } = lib;
      draw({ elements, geometry, isPair, lib, data: newData, profile: data, scales, styles });
      setIsGraphSet(true);
      if (innerRef) {
        const { nodeElementRef } = elementRefs;
        innerRef({ outerHTML, nodeElementRef });
      }
    }
  }, [scales]);

  useEffect(() => {
    if (isGraphSet) {
      window.removeEventListener('resize', handleWindowResize);
      window.addEventListener('resize', handleWindowResize);
    }
  }, [isGraphSet]);

  const handleWindowResize = useCallback(() => {
    initGraphStructure(geometry);
  }, [baseElements, geometry, isPair]);

  const initGraphStructure = (oldGeometry = undefined) => {
    const {
      initGraph,
      getScales,
    } = lib;

    const newValues = initGraph(isPair, baseElements, oldGeometry);
    const scalesObj = getScales(newValues.geometry);
    setElements(newValues.elements);
    setGeometry(newValues.geometry);
    setScales(scalesObj);
  };

  const outerHTML = () => {
    if (elements?.svg?.outerHTML) {
      return elements.svg.outerHTML;
    }

    const { svgRef } = elementRefs;

    if (svgRef?.current) {
      const divElement = document.createElement('div');
      const svgCloneElement = svgRef.current.cloneNode(true);
      divElement.appendChild(svgCloneElement);
      return divElement.innerHTML;
    }

    return '';
  };

  return {
    elementRefs,
    isPair,
    newData,
    outerHTML,
  };
};

export default useEnergyMap;
