export const en = {
  connectWithMe: 'Connect With Me',
  list: { empty: 'No results found' },
  myConnections: {
    description: 'This list represents your current connections.',
    title: 'My Connections',
  },
  network: 'Network',
  networkMembers: {
    description: 'This list represents others within your organization with whom you are not yet connected.',
    title: 'Network Members',
  },
  pageNetworkDashboard: {
    understanding: 'Understanding',
    workingWith: 'Working with',
  },
  search: { floatingLabel: 'Find a Connection' },
  shareRequests: {
    description: 'This list represents pending connections that you have either sent or received.',
    emptyList: 'List not defined',
    listItemTitle: 'Profile request from %s',
    noResults: 'No results to display yet',
    tabs: {
      received: 'Received',
      sent: 'Sent',
    },
    title: 'Pending Connection Requests',
  },
  shareProfile: {
    button: {
      invite: 'Invite',
      done: 'Done',
    },
    description: 'If you are a member of a public network, you are already connected to everyone in the network by default. If you are a member of a private network and would like to share your profile with someone else, type in the email address of a person within your network with whom you would like to connect.',
    emptyMessage: 'No connections selected',
    profileUndefined: 'Profile to add is undefined',
    searchText: 'Enter an email address to filter',
    successDescription: 'You successfully sent a connection request to the following people',
    title: {
      error: 'Error',
      share: 'Share Profile',
      success: 'Success!',
    },
  },
};

export const es = en;
