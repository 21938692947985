import { CircularProgress } from '@material-ui/core';
import { pickHTMLProps } from 'pick-react-known-prop';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';

import { CHILDREN, STYLE } from 'constants/props';
import ErrorBoundary from 'generics/ErrorBoundary';
import Mask from 'generics/Mask';

import styles from './card.scss';

const Card = (props) => {
  const [error, setError] = useState(null);
  const cardRef = useRef(null);

  useEffect(() => {
    const { componentRef } = props;

    if (componentRef) {
      componentRef(cardRef.current);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-unused-vars
  const getDimensions = () => ({
    height: cardRef.current.clientHeight,
    width: cardRef.current.clientWidth,
  });

  const handleOnCatchError = (newError) => {
    setError(newError);
  };

  const {
    barBackgroundColor,
    barClassName,
    barStyle,
    center,
    children,
    className,
    disabled,
    isFetching,
    isLoading,
    type,
    ...rest
  } = props;

  return (
    <div
      className={classnames(
        styles.card,
        { [styles['card--is-center']]: center || type === 'center' },
        { [styles['card--is-disabled']]: disabled },
        { [styles['card--is-fetching']]: isFetching || isLoading },
        { [styles['card--has-error']]: error },
        className,
      )}
      ref={cardRef}
      {...pickHTMLProps(rest)}
    >
      <div
        className={classnames(
          styles.card__bar,
          barClassName,
        )}
        style={{ ...barStyle, backgroundColor: barBackgroundColor }}
      />

      <ErrorBoundary
        className={styles['card__error-boundary']}
        onCatchError={handleOnCatchError}
        stackErrorClassName={styles['card__stack-error']}
      >
        {children}

        <Mask
          className={styles.card__mask}
          open={isFetching || isLoading}
        >
          <CircularProgress
            className={styles.card__loader}
            size={60}
            thickness={7}
          />
        </Mask>
      </ErrorBoundary>
    </div>
  );
};

Card.propTypes = {
  barBackgroundColor: PropTypes.string,
  barClassName: PropTypes.string,
  barStyle: STYLE,
  center: PropTypes.bool,
  children: CHILDREN,
  className: PropTypes.string,
  componentRef: PropTypes.func,
  disabled: PropTypes.bool,
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
};

Card.defaultProps = {
  barBackgroundColor: undefined,
  barClassName: undefined,
  barStyle: {},
  center: false,
  children: undefined,
  className: null,
  componentRef: undefined,
  disabled: false,
  isFetching: false,
  isLoading: false,
  type: undefined,
};

export default Card;
