import { resetState } from 'app_modules/session/actions/resetState';
import sessionActionTypes from 'app_modules/session/constants';

// Actions

export const triggerExpireFailure = (error) => ({
  payload: error,
  type: sessionActionTypes.FETCH_DASHBOARD_FAILURE,
});

export const triggerExpireRequest = (message) => ({
  payload: message,
  type: sessionActionTypes.EXPIRE_SESSION_REQUEST,
});

export const triggerExpireSuccess = () => ({
  type: sessionActionTypes.EXPIRE_SESSION_SUCCESS,
});

export const resetExpiration = () => ({
  type: sessionActionTypes.EXPIRE_SESSION_RESET,
});

// Thunks

export const expireSession = (message) => {
  const thunk = (dispatch) => (
    Promise
      .all([
        dispatch(triggerExpireRequest(message)),
        dispatch(resetState()),
        dispatch(triggerExpireSuccess()),
      ])
      .catch((error) => {
        dispatch(triggerExpireFailure(error));

        throw error;
      })
  );

  thunk.type = sessionActionTypes.EXPIRE_SESSION;

  return thunk;
};

export default expireSession;
