import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { CHILDREN } from 'constants/props';

import styles from './ErrorBoundary.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInformation: null,
    };
  }

  componentDidCatch(error, errorInformation) {
    const { onCatchError } = this.props;

    this.setState({
      error,
      errorInformation,
    });

    if (onCatchError) {
      onCatchError(error, errorInformation);
    }
  }

  render() {
    const {
      className,
      children,
      stackErrorClassName,
      DEVELOPMENT,
    } = this.props;

    const {
      error,
      errorInformation,
    } = this.state;

    if (error) {
      return (
        <div
          className={classnames(
            styles.error,
            className,
          )}
        >
          <h2
            className={styles.error__title}
            title="Something went wrong"
          >
            Something went wrong
          </h2>

          {
            DEVELOPMENT && (
              <>
                <h3
                  className={styles.error__subtitle}
                  title="Error details"
                >
                  Error details:
                </h3>

                <p className={styles.error__details}>
                  {error.toString().slice(7, error.toString().length)}
                </p>

                <h3
                  className={styles.error__subtitle}
                  title="Component stack error details"
                >
                  Component stack error details:
                </h3>

                <pre
                  className={classnames(
                    styles['error__component-stack'],
                    stackErrorClassName,
                  )}
                >
                  {errorInformation.componentStack.slice(1, errorInformation.componentStack.length)}
                </pre>
              </>
            )
          }
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: CHILDREN,
  className: PropTypes.string,
  DEVELOPMENT: PropTypes.bool,
  onCatchError: PropTypes.func,
  stackErrorClassName: PropTypes.string,
};

ErrorBoundary.defaultProps = {
  children: null,
  className: null,
  DEVELOPMENT: true,
  onCatchError: undefined,
  stackErrorClassName: null,
};

export default ErrorBoundary;
