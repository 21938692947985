/**
 * @description Settings for content manager controller
 * @module API controller / Content Manager
 */

import { isUndefined } from 'lodash';

module.exports = {
  getDocuments: {
    ignoreNotFound: true,

    /**
     * @description get settings (get Documents endpoint)
     * @param {object} options
     * @param {number} options.accountId
     * @param {string} options.contentType
     * @param {number} options.moduleId
     * @param {number} options.profileId
     * @param {array} options.profiles
     * @param {number} options.teamId
     * @returns {object} settings: { endpoint, method }
     */
    settings({ accountId, contentType, moduleId, profileId, profiles, teamId }) {
      let endpoint = ((profileId || profiles) && !isUndefined(teamId))
        ? `/account/${accountId}/content_manager/content_type/team/module/${moduleId}/team/${teamId}`
        : `/account/${accountId}/content_manager/content_type/${contentType}/module/${moduleId}`;

      switch (false) {
        // Second person leading someone
        case isUndefined(profileId):
          endpoint += `/profile/${profileId}`;
          break;

        // Second person view two profiles (one leading other)
        case isUndefined(profiles):
          endpoint += `/profiles?ids[]=${profiles[0].toString()}&ids[]=${profiles[1].toString()}`;
          break;

        case isUndefined(teamId):
          endpoint += `/team/${teamId}`;
          break;

        default:
          break;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },

  getContent: {
    ignoreNotFound: true,

    /**
     * @description get settings (get Content endpoint)
     * @param {object} options
     * @param {number} options.accountId
     * @param {string} options.contentType
     * @param {number} options.profileId
     * @param {number} options.teamId
     * @returns {object} settings: { endpoint, method }
     */
    settings({ accountId, contentType, profileId, teamId }) {
      let endpoint = `/account/${accountId}/content_manager/content_type/${contentType}`;

      switch (false) {
        case isUndefined(profileId):
          endpoint += `/profile/${profileId}`;
          break;

        case isUndefined(teamId):
          endpoint += `/team/${teamId}`;
          break;

        default:
          break;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },
};
