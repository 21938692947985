import PropTypes from 'prop-types';

import { I18N } from 'constants/props';
import { CURRENT_PASSWORD, PASSWORD, PASSWORD_CONFIRMATION } from 'constants/inputTypes';
import useForm from 'custom_hooks/useForm';
import useFormValidation from 'custom_hooks/useFormValidation';

const useFormChangePassword = ({ i18n, onSubmit }) => {
  const { validator } = useFormValidation({ i18n });

  const handleValidate = (values) => {
    const {
      currentPassword,
      password,
      passwordConfirmation,
    } = values;

    return {
      currentPassword: validator.validate(CURRENT_PASSWORD, currentPassword),
      password: validator.validate(PASSWORD, password, currentPassword),
      passwordConfirmation: validator.validate(
        PASSWORD_CONFIRMATION,
        password,
        passwordConfirmation,
      ),
    };
  };

  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values = { email: null, password: null },
  } = useForm({ onSubmit, onValidate: handleValidate });

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  };
};

useFormChangePassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
};

export default useFormChangePassword;
