import AppError from './AppError.class';

/**
 * @class ApiError
 * @description API Error Class
 * @extends Error
 * */
export default class ApiError extends AppError {
  constructor({ errorCode, label, notificationType, type, ...args }) {
    super({ label, notificationType, ...args });
    this.errorCode = errorCode;
    this.type = type;
  }
}
