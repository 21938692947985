import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { updateIntl } from 'react-intl-redux';

import { loadState, saveState } from '../localStorage/localStorage';
import createRootReducer from '../reducers';
import enTranslationMessages from '../../translation/en';

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

const configureStore = ({ history }) => {
  const initialState = loadState();

  const middlewares = applyMiddleware(
    createLogger({ collapsed: true }),
    routerMiddleware(history),
    thunk,
  );

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(middlewares),
  );

  store.dispatch(updateIntl(enTranslationMessages));

  store.subscribe(() => {
    saveState(store.getState());
  });

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextCreateRootReducer = require('../reducers/index'); // eslint-disable-line global-require
      store.replaceReducer(nextCreateRootReducer(history));
    });
  }

  return store;
};

export default configureStore;
