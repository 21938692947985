export const en = {
  title: 'Login',
  form: {
    email: 'E-mail address',
    password: 'Password',
    errors: {
      email: 'You need to provide an email address',
      invalidEmail: 'You need to provide a valid email format',
      password: 'You need to provide a password',
    },
  },
  link: {
    backToLogin: 'Back to Login',
    forgot: 'Forgot Username/Password',
    signInWithSSO: 'Sign in with SSO',
  },
  button: {
    submit: 'Submit',
  },
  subtitle: 'Please enter your login information below',
  reset: {
    title: 'Reset Password',
    toast: {
      success: {
        passwordChanged: "Congratulations! You've successfully changed your password",
      },
    },
  },
  forgot: {
    toast: {
      success: 'Request Successfully Sent!',
    },
    step: [
      {
        title: 'Forgot Password?',
        description: 'Enter your email address below and we will send you further instructions.',
        button: 'Submit',
      },
      {
        title: 'Success!',
        subtitle: 'We’ve sent an email to ',
        description:
          'Click the link in the email to reset your password. If you don’t see an email, check other places it might be, like your junk, spam, social, or other folders.',
        button: 'Back',
      },
    ],
  },
};

export const es = en;
