import { isNil } from 'lodash';
import { pickHTMLProps } from 'pick-react-known-prop';
import PropTypes from 'prop-types';
import React from 'react';

import { I18N } from 'constants/props';
import { StyledButton, StyledTextField } from 'generics/StyledFormComponents';
import Translation from 'generics/Translation';


import styles from './FormForgotPassword.scss';
import useFormForgotPassword from './useFormForgotPassword';

const FormForgotPassword = ({
  i18n,
  onSubmit,
  submitting,
  ...rest
}) => {
  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  } = useFormForgotPassword({ i18n, onSubmit });

  const { email } = values;
  const { email: emailError } = errors;

  return (
    <form
      {...pickHTMLProps(rest)}
      onSubmit={handleSubmit}
    >
      <StyledTextField
        className={styles.field}
        error={isNil(email) ? false : !isValid}
        label={`${i18n.login.form.email}:`}
        helperText={isNil(email) ? ' ' : emailError}
        name="email"
        onChange={(e) => handleChange('email', e.currentTarget.value)}
        title={i18n.login.forgot.email}
        value={email || ''}
      />

      <StyledButton
        classes={{ root: styles.submit }}
        color="primary"
        disabled={(!isValid) || submitting || isNil(email)}
        title={i18n.login.forgot.step[0].button}
        type="submit"
      >
        {i18n.login.forgot.step[0].button}
      </StyledButton>
    </form>
  );
};

FormForgotPassword.propTypes = {
  i18n: I18N.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default Translation(FormForgotPassword, ['login', 'generics']);
