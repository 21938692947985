import actionTypes from 'app_modules/accounts/constants';

// Actions

export const removeUserPermission = (permission) => ({
  payload: permission,
  type: actionTypes.REMOVE_USER_PERMISSION,
});

// Thunks

export const removePermission = (permission) => {
  const thunk = (dispatch) => {
    dispatch(removeUserPermission(permission));
  };

  thunk.type = actionTypes.REMOVE_PERMISSION;

  return thunk;
};

export default removePermission;
