import { unescape } from 'lodash';
import { formatRoute } from 'react-router-named-routes';
import { replace } from 'connected-react-router';

import { BarError } from 'classes/errors';
import { MY_5_DYNAMICS } from 'urls';
import { notifyError } from 'app_modules/notifications/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/session/constants';
import api from 'api';

// Actions

export const fetchDocumentsFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_DOCUMENTS_FAILURE,
});

export const fetchDocumentsRequest = () => ({
  type: actionTypes.FETCH_DOCUMENTS_REQUEST,
});

export const fetchDocumentsSuccess = (response) => ({
  payload: response,
  type: actionTypes.FETCH_DOCUMENTS_SUCCESS,
});

// Thunks

export const fetchDocuments = (options, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());

    dispatch(fetchDocumentsRequest());

    const handleResponse = ({ documents }) => {
      if (!documents) {
        dispatch(replace(formatRoute(MY_5_DYNAMICS, { accountId })));
        dispatch(notifyError(new BarError({ label: 'invalidAddress' })));
      } else {
        const unescapedDocuments = documents.map((document) => ({
          ...document,
          content: unescape(document.content),
        }));

        fetchDocumentsSuccess(unescapedDocuments);

        if (onSuccess) {
          onSuccess(unescapedDocuments);
        }
      }
    };

    const handleError = ({ error, isConnectionError }) => {
      dispatch(replace(formatRoute(MY_5_DYNAMICS, { accountId })));
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'invalidAddress' })));
      }
      dispatch(fetchDocumentsFailure(error));

      if (onError) {
        onError(error);
      } else {
        throw error;
      }
    };

    return api.contentManager.getDocuments({
      accountId,
      ...options,
    }, handleResponse, handleError);
  };

  thunk.type = actionTypes.FETCH_DOCUMENTS;

  return thunk;
};

export default fetchDocuments;
