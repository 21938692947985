import actionTypes from 'app_modules/network/constants';
import fetchMyNetwork from 'app_modules/network/actions/fetchMyNetwork';
import fetchReceivedRequests from 'app_modules/network/actions/fetchReceivedRequests';
import fetchSentRequests from 'app_modules/network/actions/fetchSentRequests';

// Thunks

export const fetchNetworkLists = () => {
  const thunk = (dispatch) => {
    dispatch(fetchMyNetwork({ pageIndex: 1 }));
    dispatch(fetchReceivedRequests());
    dispatch(fetchSentRequests());
  };

  thunk.type = actionTypes.FETCH_NETWORK_LISTS;

  return thunk;
};

export default fetchNetworkLists;
