
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as teamsActions from 'app_modules/teams/actions';
import * as teamsSelectors from 'app_modules/teams/selectors';
import * as sessionSelectors from 'app_modules/session/selectors';
import TeamDescription from 'components/PageTeamsDashboard/components/TeamDescription';

const mapStateToProps = (state) => ({
  currentProfile: sessionSelectors.currentProfile(state),
  membersCount: teamsSelectors.teamMeta(state).totalObjects,
  team: teamsSelectors.team(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(teamsActions, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamDescription));
