import { isEmpty } from 'lodash';
import { logout } from 'app_modules/session/actions/fetchLogout';
import moment from 'moment';

import { BarError } from 'classes/errors';
import { fetchDashboard } from 'app_modules/session/actions/fetchDashboard';
import { fetchVideos } from 'app_modules/session/actions/fetchVideos';
import { notifyError } from 'app_modules/notifications/actions';
import { setDefaultProfile } from 'app_modules/session/actions/setProfile';
import * as assessmentSelectors from 'app_modules/assessment/selectors';
import api from 'api';
import assessmentActionTypes from 'app_modules/assessment/constants';

export const addNewAnswer = (answer) => ({
  type: assessmentActionTypes.ADD_NEW_ANSWER,
  answer,
});

export const fetchAssessmentFailure = (error) => ({
  type: assessmentActionTypes.FETCH_ASSESSMENT_FAILURE,
  error,
});

export const fetchAssessmentRequest = () => ({
  type: assessmentActionTypes.FETCH_ASSESSMENT_REQUEST,
});

export const fetchAssessmentSuccess = (payload) => ({
  payload,
  type: assessmentActionTypes.FETCH_ASSESSMENT_SUCCESS,
});

export const fetchSavingFailure = (error) => ({
  type: assessmentActionTypes.FETCH_SAVING_FAILURE,
  error,
});

export const fetchSavingRequest = () => ({
  type: assessmentActionTypes.FETCH_SAVING_REQUEST,
});

export const fetchSavingSuccess = (assessmentScores) => ({
  payload: assessmentScores,
  type: assessmentActionTypes.FETCH_SAVING_SUCCESS,
});

export const setNextQuestion = (question) => ({
  payload: question,
  type: assessmentActionTypes.SET_NEXT_QUESTION,
});

export const setTiebreak = (questions) => ({
  type: assessmentActionTypes.SET_TIEBREAK,
  questions,
});

export const updateState = ({ isCompleted, isInProgress }) => ({
  type: assessmentActionTypes.UPDATE_STATE,
  isCompleted,
  isInProgress,
});

export const saveAnswers = () => {
  const thunk = (dispatch, getState) => {
    const state = getState();
    const { answers } = state.assessment;

    const errorHandler = ({ error, isConnectionError }) => {
      dispatch(logout());
      if (!isConnectionError) {
        dispatch(notifyError(new BarError(error)));
      }
      dispatch(fetchAssessmentFailure(error));
    };

    const responseHandler = (response) => {
      try {
        const { assessment } = response;

        const {
          assessmentScores,
          finished,
          questions,
          tiebreak,
        } = assessment;

        if (tiebreak) {
          const remainingQuestions = questions.slice(18, questions.length);
          const currentQuestion = remainingQuestions.shift();
          dispatch(setTiebreak(remainingQuestions));
          dispatch(setNextQuestion(currentQuestion));
        }

        if (finished) {
          dispatch(setDefaultProfile());
          dispatch(fetchSavingSuccess(assessmentScores));
          dispatch(fetchVideos());
          dispatch(fetchDashboard());
        }
      } catch (error) {
        errorHandler({ error });
      }
    };

    dispatch(fetchSavingRequest());

    return api.users.updateAssessment({ questions: answers }, responseHandler, errorHandler);
  };

  thunk.type = assessmentActionTypes.FETCH_SAVING;

  return thunk;
};

export const addAnswer = (answer) => (
  (dispatch, getState) => {
    dispatch(addNewAnswer(answer));
    const { remainingQuestions } = getState().assessment;
    const currentQuestion = remainingQuestions.shift();
    if (currentQuestion) {
      dispatch(setNextQuestion(currentQuestion));
    } else {
      dispatch(saveAnswers());
    }
  }
);

export const fetchAssessment = () => {
  const thunk = (dispatch, getState) => {
    const state = getState();

    const {
      currentQuestion,
      isCompleted,
      isInProgress,
    } = assessmentSelectors.assessment(state);

    const errorHandler = ({ error, isConnectionError }) => {
      dispatch(logout());
      if (!isConnectionError) {
        dispatch(notifyError(new BarError(error)));
      }
      dispatch(fetchAssessmentFailure(error));
    };

    const responseHandler = (response) => {
      try {
        const {
          assessment,
          errors,
        } = response;

        // TODO: Handle errors. Pending for schema.
        if (errors && errors.length) {
          throw new Error(errors[0].assessmentFound[0]);
        }

        if (!assessment) {
          throw new Error('Assessment prop is required');
        } else if (!assessment.createdAt) {
          throw new Error('createdAt prop is required');
        }

        // TODO: Quick fix. This prop should come from BE.
        const expirationDate = moment(assessment.createdAt)
          .add(15, 'm')
          .toISOString();

        const nextQuestion = assessment.questions.shift();

        const fixedAssessment = {
          ...assessment,
          expirationDate,
        };

        dispatch(fetchAssessmentSuccess({
          assessment: fixedAssessment,
          nextQuestion,
        }));
      } catch (error) {
        errorHandler({ error });
      }
    };

    if (isCompleted) {
      const error = 'The assessment is completed';
      errorHandler({ error });
    }

    if (!isEmpty(currentQuestion)) {
      const error = 'There is a current question';
      errorHandler({ error });
    }

    dispatch(fetchAssessmentRequest());

    api.users.getAssessment(isInProgress, responseHandler, errorHandler);
  };

  thunk.type = assessmentActionTypes.FETCH_ASSESSMENT;

  return thunk;
};
