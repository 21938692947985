import { autobind } from 'core-decorators';
import { CircularProgress } from '@material-ui/core';
import { Col, Container, Row } from 'reactstrap';
import { get, isEmpty, isEqual, kebabCase } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ENERGY_MAP_REF, I18N, META, OWNERS, REPORT } from 'constants/props';
import InfiniteScrollList from 'generics/InfiniteScrollList';
import SearchBar from 'generics/SearchBar';

import ReportListItem from '../ReportListItem/ReportListItem';
import styles from './ReportsList.scss';
import FormFilterOwners from '../FormFilterOwners/FormFilterOwners';


/**
 * Profiles Card component class
 * @extends Component
 */
class ReportsList extends Component {
  /**
   * Class constructor
   * @param  {object} props
   */
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  /**
   * Gets component initial state
   * @return {object} Initial State
   */
  getInitialState() {
    return {
      isModalOpen: false,
      pageIndex: 1,
      filter: null,
      ownerFilter: null,
    };
  }

  componentDidMount() {
    this.handleFetchReportsList();
  }

  /**
   * Fetch Profiles based on page index and search text
   * @param {number} pageIndex
   * @param {string} text
   */
  @autobind
  handleFetchReportsList(pageIndex = 1, ownerFilter, isScroll = false) {
    const { onFetch } = this.props;
    const { filter: search } = this.state;

    if (!isScroll) {
      if (get(this.InfiniteScrollList, 'infiniteScrollRef')) {
        this.InfiniteScrollList.infiniteScrollRef.scrollMagicController.scrollTo(0);
      }
    }

    // eslint-disable-next-line react/no-unused-state
    this.setState({ pageIndex });
    return onFetch({ pageIndex, search, ownerFilter, isScroll });
  }

  @autobind
  handleOwnerFilter(ownerFilter) {
    if (get(this.InfiniteScrollList, 'infiniteScrollRef')) {
      this.InfiniteScrollList.infiniteScrollRef.resetIndex();
    }

    this.setState({ ownerFilter }, () => {
      this.handleFetchReportsList(1, ownerFilter);
    });
  }

  /**
   * Fetch profiles when the search text changes
   * @param {string} text
   */
  @autobind
  handleSearch(filterInput) {
    const {
      filter,
      ownerFilter,
    } = this.state;

    if (isEqual(filter, filterInput)) {
      return;
    }

    if (get(this.InfiniteScrollList, 'infiniteScrollRef')) {
      this.InfiniteScrollList.infiniteScrollRef.resetIndex();
    }

    this.setState({ filter: filterInput }, () => {
      this.handleFetchReportsList(1, ownerFilter);
    });
  }

  /**
   * Component Render method
   * @return component
   */
  render() {
    const {
      accountId,
      actionButton,
      energyMapRefs,
      isAdmin,
      isAssign,
      isLoading,
      handleCancelReportConfirmation,
      handleCreateAPGIReport,
      handleDownloadAPGIReport,
      handleSelectReport,
      handleSendNewInvitation,
      handleSendReportReminder,
      i18n,
      meta,
      owners,
      profileId,
      reports,
      title,
      selectedTokens,
    } = this.props;

    const {
      ownerFilter,
    } = this.state;

    const { morePages } = meta;

    const infiniteScroll = {
      hasMorePages: morePages,
      hideFullLink: true,
      listHeight: 200,
      onFetch: (pageIndex) => this.handleFetchReportsList(pageIndex, ownerFilter, true),
    };

    const reportListItemsHeader = (
      <li className={
        classnames(
          styles['list-item-header'],
          { [styles['is-assign']]: isAssign },
        )
      }
      >
        <div>
          <p>{i18n.pageTokenAdministration.useTokens.list.headers.name}</p>
        </div>
        <div>
          <p>{i18n.pageTokenAdministration.useTokens.list.headers.email}</p>
        </div>
        <div>
          <p>{i18n.pageTokenAdministration.useTokens.list.headers.code}</p>
        </div>
        <div>
          <p>{i18n.pageTokenAdministration.useTokens.list.headers.status}</p>
        </div>
        <div>
          <p>{i18n.pageTokenAdministration.useTokens.list.headers.owner}</p>
        </div>
        <div>
          <p>{i18n.pageTokenAdministration.useTokens.list.headers.expirationDate}</p>
        </div>
        {!isAssign && (
          <div>
            <p>{i18n.pageTokenAdministration.useTokens.list.headers.actions}</p>
          </div>
        )}
      </li>
    );

    const reportListItems = !isEmpty(reports)
      ? [...reports.map((report) => (
        <ReportListItem
          accountId={accountId}
          energyMapRefs={energyMapRefs}
          handleSelectReport={handleSelectReport}
          i18n={i18n}
          isAssign={isAssign}
          key={`report-list-item-${report.id}`}
          profileId={profileId}
          report={{
            ...report,
            handleCancelReportConfirmation,
            handleCreateAPGIReport,
            handleDownloadAPGIReport,
            handleSendNewInvitation,
            handleSendReportReminder,
          }}
          selectedTokens={selectedTokens}
        />
      ))]
      : (
        <div className={styles['empty-list']}>
          {i18n.pageTokenAdministration.useTokens.list.empty}
        </div>
      );

    return (
      <>
        <Container>
          {(isAdmin && isAssign) && (
            <Row>
              <Col xs={12}>
                <p
                  className={styles.description}
                >
                  {i18n.pageTokenAdministration.ownerChange.description}
                </p>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={isAssign && isAdmin ? 6 : 12}>
              <SearchBar
                className={styles['search-form']}
                floatingLabelText={i18n.pageTokenAdministration.useTokens.search}
                onChange={this.handleSearch}
                onSearch={this.handleSearch}
              />
            </Col>
            {(isAdmin && isAssign) && (
              <Col md={6}>
                <Row className={styles['align-center']}>
                  <Col md={2}>
                    <p>Owner: </p>
                  </Col>
                  <Col md={10}>
                    <FormFilterOwners
                      isLoading={isLoading}
                      onChange={(owner) => this.handleOwnerFilter(owner)}
                      owners={owners}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <div className={styles['list-action-container']}>
            <InfiniteScrollList
              className={styles.list}
              infiniteScroll={infiniteScroll}
              isLoading={isLoading}
              listId={`${kebabCase(title)}-list`}
              innerRef={(component) => { this.InfiniteScrollList = component; }}
            >
              {isLoading ? (
                <div className={styles.loader}>
                  <CircularProgress
                    className={styles['circular-progress']}
                    size={30}
                    thickness={4}
                  />
                </div>
              ) : (
                reportListItems
              )}
            </InfiniteScrollList>

            {actionButton}
          </div>
        </Container>
      </>
    );
  }
}

ReportsList.propTypes = {
  accountId: PropTypes.number.isRequired,
  actionButton: PropTypes.element.isRequired,
  energyMapRefs: ENERGY_MAP_REF,
  handleCancelReportConfirmation: PropTypes.func,
  handleCreateAPGIReport: PropTypes.func,
  handleDownloadAPGIReport: PropTypes.func,
  handleSelectReport: PropTypes.func,
  handleSendNewInvitation: PropTypes.func,
  handleSendReportReminder: PropTypes.func,
  i18n: I18N.isRequired,
  isAdmin: PropTypes.bool,
  isAssign: PropTypes.bool,
  isLoading: PropTypes.bool,
  meta: META.isRequired,
  onFetch: PropTypes.func.isRequired,
  owners: OWNERS,
  profileId: PropTypes.number.isRequired,
  reports: REPORT.isRequired,
  selectedTokens: PropTypes.objectOf(PropTypes.bool),
  title: PropTypes.string.isRequired,
};

ReportsList.defaultProps = {
  energyMapRefs: {},
  handleCancelReportConfirmation: () => {},
  handleCreateAPGIReport: () => {},
  handleDownloadAPGIReport: () => {},
  handleSelectReport: () => {},
  handleSendNewInvitation: () => {},
  handleSendReportReminder: () => {},
  isAdmin: false,
  isAssign: false,
  isLoading: false,
  owners: [],
  selectedTokens: {},
};

export default ReportsList;
