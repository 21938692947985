export const en = {
  descriptions: {
    completed: 'Login to your Simpli5 Relationships account:',
    editing: 'Set up your Simpli5 Relationships account today:',
    initial: 'Now that you\'ve experienced the value of the 5D methodology, you can connect with your friends and family.',
  },
  form: {
    inputLabel: 'Enter your personal email',
    loginBtn: 'Login',
    submitBtn: 'Register',
  },
  newsTitle: 'Simpli5® News',
  newsSubtitle: 'Tips, Insights & Current Events',
  list: {
    empty: 'No results found',
    title: 'Simpli5® News',
  },
  title: 'Connect with your friends & family',
  social: {
    author: 'Contributing Author',
    imageAuthor: 'Simpli5® Contributing Author',
  },
  success: {
    message: 'Last sent %s to %s',
    title: 'Check your inbox for a message with a link to get started.',
    resend: 'Resend',
    changeEmail: 'Change Email',
  },
  ssoToken: {
    title: "You'll need an access token to complete registration.",
    label: 'Access Token',
    copy: 'Copy',
  },
};

export const es = en;
