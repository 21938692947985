import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as networkActions from 'app_modules/network/actions';
import * as networkSelectors from 'app_modules/network/selectors';
import * as sessionSelectors from 'app_modules/session/selectors';
import ProfileDetailThirdPerson from 'components/ProfileDetailThirdPerson';

const mapStateToProps = (state) => {
  const thirdPersonProfile = networkSelectors.thirdPerson.profile(state);
  const networkDetails = networkSelectors.networkDetails
    .profileNetworkDetails(state, thirdPersonProfile.id);

  return {
    currentProfile: sessionSelectors.currentProfile(state),
    isFetchingProfile: networkSelectors.thirdPerson.isFetching(state),
    networkDetails,
    thirdPersonProfile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  networkActions: bindActionCreators(networkActions, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileDetailThirdPerson));
