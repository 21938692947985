import { combineReducers } from 'redux';
import { uniqBy } from 'lodash';

import { Invite } from 'classes';
import groupsActionTypes from 'app_modules/groups/constants';
import initialState from 'app_modules/store/initialState.json';
import invitesActionTypes from 'app_modules/invites/constants';
import sessionActionTypes from 'app_modules/session/constants';
import signupActionTypes from 'app_modules/profile/constants';
import { DECLINED } from 'constants/invitationStatus';

const actionTypes = { ...invitesActionTypes, ...signupActionTypes, ...groupsActionTypes };

export const errors = (state = initialState.invites.errors, action) => {
  const { type: actionType, payload } = action;

  switch (actionType) {
    case actionTypes.FETCH_INVITATION_REQUEST:
      return [];

    case actionTypes.FETCH_ACCEPT_INVITATION_FAILURE:
    case actionTypes.FETCH_DECLINE_INVITATION_FAILURE:
    case actionTypes.FETCH_INVALIDATE_INVITATION_FAILURE:
    case actionTypes.FETCH_INVITATION_FAILURE:
      return [...state, payload];

    case sessionActionTypes.RESET_STATE:
      return initialState.invites.errors;

    default:
      return state;
  }
};

export const invitation = (state = initialState.invites.invitation, action) => {
  const { type: actionType, payload } = action;

  switch (actionType) {
    case actionTypes.FETCH_CREATE_USER_SUCCESS:
    case actionTypes.FETCH_INVITATION_REQUEST:
    case actionTypes.CLEAR_INVITATION:
      return {};

    case actionTypes.FETCH_ACCEPT_INVITATION_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case actionTypes.FETCH_DECLINE_INVITATION_SUCCESS:
      return {
        ...state,
        status: DECLINED,
      };

    case actionTypes.FETCH_INVITATION_SUCCESS:
      return {
        ...payload,
      };

    case sessionActionTypes.RESET_STATE: {
      if (payload && 'omit' in payload && payload.omit.includes('invites')) {
        return state;
      }
      return initialState.invites.invitation;
    }

    default:
      return state;
  }
};

export const isFetching = (state = initialState.invites.isFetching, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCEPT_INVITATION_REQUEST:
    case actionTypes.FETCH_DECLINE_INVITATION_REQUEST:
    case actionTypes.FETCH_INVITATION_REQUEST:
      return true;

    case actionTypes.FETCH_ACCEPT_INVITATION_FAILURE:
    case actionTypes.FETCH_ACCEPT_INVITATION_SUCCESS:
    case actionTypes.FETCH_DECLINE_INVITATION_FAILURE:
    case actionTypes.FETCH_DECLINE_INVITATION_SUCCESS:
    case actionTypes.FETCH_INVALIDATE_INVITATION_FAILURE:
    case actionTypes.FETCH_INVALIDATE_INVITATION_SUCCESS:
    case actionTypes.FETCH_INVITATION_FAILURE:
    case actionTypes.FETCH_INVITATION_SUCCESS:
      return false;

    case sessionActionTypes.RESET_STATE:
      return initialState.invites.isFetching;

    default:
      return state;
  }
};

export const sent = (state = initialState.invites.sent, { type, payload }) => {
  switch (type) {
    case actionTypes.FETCH_SENT_INVITATIONS_REQUEST:
      if (payload === 1) {
        return {
          ...state,
          isFetching: true,
          list: [],
        };
      }
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.FETCH_SENT_INVITATIONS_FAILURE:
    case actionTypes.FETCH_SENT_INVITATIONS_SUCCESS_NO_RESULTS:
      return {
        ...state,
        isFetching: false,
        list: [],
        meta: {
          morePages: false,
          totalObjects: state.meta.totalObjects,
        },
      };

    case actionTypes.FETCH_SENT_INVITATIONS_SUCCESS: {
      const { meta, invites, updateCounters } = payload;
      return {
        ...state,
        isFetching: false,
        list: uniqBy([...state.list, ...Invite.mapInstance(invites)], 'id'),
        meta: updateCounters ? meta : {
          ...meta,
          totalObjects: state.meta.totalObjects,
        },
      };
    }

    case actionTypes.FETCH_RESEND_INVITATION_SUCCESS: {
      const invites = state.list
        .map((invite) => (
          invite.id === payload.invitationId
            ? new Invite({ ...payload.invite })
            : invite
        ));

      return {
        ...state,
        list: invites,
      };
    }

    case actionTypes.FETCH_RESEND_INVITATION_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case sessionActionTypes.RESET_STATE:
      return initialState.invites.sent;

    default:
      return state;
  }
};

export default combineReducers({
  errors,
  invitation,
  isFetching,
  sent,
});
