import React from 'react';

import { COLORS } from 'constants/colors';
import { getFileFormat, formatDateTitleVideoName } from 'helpers';
import api from 'api';
import ModulesListItem from 'generics/ModulesListItem';
import PropTypes from 'prop-types';
import Video from 'generics/Video';
import VideoIframe from 'generics/VideoIframe';

import styles from './VideoCard.scss';

const VideoCard = ({
  accountId,
  category,
  createdAt,
  description,
  embedCode,
  src,
  title,
  videoTitle,
}) => {
  if (!src) {
    return null;
  }
  return (
    <ModulesListItem
      category={category}
      categoryColor={COLORS.secondaryPurple.rgba}
      className={styles.card}
      description={description}
      icon="film"
      modalCardClassName={styles.modal}
      showBullets={false}
      title={title}
    >
      {!embedCode ? (
        <Video
          hasOverlay
          onEnded={() => {
            api.tracking.trackVideo({
              accountId,
              videoName: formatDateTitleVideoName(videoTitle, createdAt, getFileFormat(src)),
            });
          }}
        >
          <source src={src} type="video/mp4" />
        </Video>
      ) : (
        <VideoIframe
          embedCode={embedCode}
        />
      )}
    </ModulesListItem>
  );
};

VideoCard.propTypes = {
  accountId: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  embedCode: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string.isRequired,
  videoTitle: PropTypes.string,
};

VideoCard.defaultProps = {
  embedCode: '',
  src: null,
  videoTitle: '',
};

export default VideoCard;
