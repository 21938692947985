import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as accountActions from 'app_modules/accounts/actions';
import * as accountSelectors from 'app_modules/accounts/selectors';
import * as invitesSelectors from 'app_modules/invites/selectors';
import * as notificationActions from 'app_modules/notifications/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import PageAdministration from 'components/PageAdministration/';

const mapStateToProps = (state) => ({
  adminLicenseBuyer: accountSelectors.adminLicenseBuyer(state),
  canAutomaticRenewal: accountSelectors.canAutomaticRenewal(state),
  canPurchaseLicenses: accountSelectors.canPurchaseLicenses(state),
  currentProfile: sessionSelectors.currentProfile(state),
  hasSubscription: accountSelectors.hasSubscription(state),
  hasSubscriptionNotifications: accountSelectors.hasSubscriptionNotifications(state),
  sentInvitations: invitesSelectors.sent(state),
});

const mapDispatchToProps = (dispatch) => ({
  accountActions: bindActionCreators(accountActions, dispatch),
  notificationActions: bindActionCreators(notificationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageAdministration);
