import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import * as sessionSelectors from 'app_modules/session/selectors';

import styles from './headerLogo.scss';

const { PUBLIC_PATH } = process.env;

const HEADER_LOGO = `${window.location.origin}${PUBLIC_PATH}logo-header-white.png`;
const HORIZONTAL_LOGO = `${window.location.origin}${PUBLIC_PATH}horizontal-logo-white.png`;

export const HeaderLogo = ({ isAuthenticated, route }) => {
  const logoRef = useRef(null);

  return (
    <div
      className={styles.logo}
      title="5 Dynamics®"
    >
      <img
        alt="Simpli5®"
        className={classnames(
          styles['logo--not-authenticated'],
          { [styles['logo--hiden-image']]: isAuthenticated },
        )}
        ref={!isAuthenticated ? logoRef : null}
        src={HORIZONTAL_LOGO}
      />
      <Link
        href={route}
        to={route}
      >
        <img
          alt="Simpli5® Home Page Link"
          className={classnames(
            styles['logo--authenticated'],
            { [styles['logo--hiden-image']]: !isAuthenticated },
          )}
          ref={isAuthenticated ? logoRef : null}
          src={HEADER_LOGO}
        />
      </Link>
    </div>
  );
};

HeaderLogo.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  route: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: sessionSelectors.isAuthenticated(state),
});

export default connect(mapStateToProps)(HeaderLogo);
