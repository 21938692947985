import { Col, Row } from 'reactstrap';
import React from 'react';

import { I18N } from 'constants/props';
import Translation from 'generics/Translation';

import styles from './Header.scss';

const { PUBLIC_PATH } = process.env;

const TRAINING_CENTER_IMG = `${window.location.origin}${PUBLIC_PATH}training/training-center.png`;

/**
 * @class Header
 * @description Renders Training Center Header
 */
const Header = ({ i18n }) => (
  <div className={styles.header}>
    <Row>
      <Col className={styles.titles} xs="12" md="6">
        <h1 className={styles.title}>
          {i18n.training.title}
        </h1>
        <h2 className={styles.subtitle}>
          {i18n.training.subtitle}
        </h2>
      </Col>
      <Col className={styles['image-container']} xs="12" md="6">
        <img
          alt={i18n.training.title}
          className={styles.image}
          src={TRAINING_CENTER_IMG}
        />
      </Col>
    </Row>
  </div>
);

Header.propTypes = {
  i18n: I18N.isRequired,
};

export default Translation(Header, ['training']);
