import { Col, Container, Row } from 'reactstrap';
import { isNil } from 'lodash';
import { pickHTMLProps } from 'pick-react-known-prop';
import PropTypes from 'prop-types';
import React from 'react';

import { Footer, Header, Main } from 'generics/Card';
import { I18N } from 'constants/props';
import { StyledButton, StyledTextField } from 'generics/StyledFormComponents';
import Translation from 'generics/Translation';
import useFormEditVideoCustomization from '../FormEditVideoCustomization/useFormEditVideoCustomization';

import styles from './FormEditVideoSecondCustomization.scss';

const FormEditVideoSecondCustomization = ({ i18n, initialValues, onClose, onSubmit, ...rest }) => {
  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    reset,
    values,
  } = useFormEditVideoCustomization({ i18n, initialValues, onSubmit });

  return (
    <>
      <Header>
        <h1 title={i18n.customizations.video.title}>
          {i18n.customizations.video.title}
        </h1>
      </Header>
      <Main>
        <Container fluid>
          <form
            {...pickHTMLProps(rest)}
          >
            <StyledTextField
              error={isNil(values.heading) ? false : !!errors.heading}
              helperText={isNil(values.heading) ? ' ' : errors.heading}
              label={`${i18n.customizations.video.heading}:`}
              maxLength={46}
              name="headingSecond"
              onChange={(e) => handleChange('headingSecond', e.currentTarget.value)}
              title={i18n.customizations.video.heading}
              value={values.headingSecond || ''}
            />

            <StyledTextField
              className={styles.textarea}
              error={isNil(values.bodyContent) ? false : !!errors.bodyContent}
              helperText={isNil(values.bodyContent) ? ' ' : errors.bodyContent}
              label={`${i18n.customizations.video.bodyContent}:`}
              maxLength={305}
              multiline
              name="bodyContentSecond"
              onChange={(e) => handleChange('bodyContentSecond', e.currentTarget.value)}
              rows={4}
              title={i18n.customizations.video.bodyContent}
              value={values.bodyContentSecond || ''}
            />

            <StyledTextField
              error={isNil(values.embedCode) ? false : !!errors.embedCode}
              helperText={isNil(values.embedCode) ? ' ' : errors.embedCode}
              label={`${i18n.customizations.video.embedCode}:`}
              name="embedCodeSecond"
              onChange={(e) => handleChange('embedCodeSecond', e.currentTarget.value)}
              title={i18n.customizations.video.embedCode}
              value={values.embedCodeSecond || ''}
            />
          </form>
        </Container>
      </Main>
      <Footer>
        <Container>
          <Row>
            <Col xs="3" md="2">
              <StyledButton
                color="default"
                fullWidth
                onClick={() => onClose()}
                title={i18n.generics.cancelLabel}
                variant="text"
              >
                {i18n.generics.cancelLabel}
              </StyledButton>
            </Col>

            <Col xs={{ size: 3, offset: 3 }} md={{ size: 2, offset: 6 }}>
              <StyledButton
                color="default"
                fullWidth
                onClick={() => reset()}
                title={i18n.generics.resetLabel}
                variant="text"
              >
                {i18n.generics.resetLabel}
              </StyledButton>
            </Col>

            <Col xs={{ size: 3 }} md={{ size: 2 }}>
              <StyledButton
                color="primary"
                disabled={!isValid}
                fullWidth
                id="save-video-customization-btn"
                onClick={(e) => handleSubmit(e)}
                title={i18n.generics.doneLabel}
              >
                {i18n.generics.doneLabel}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </Footer>
    </>
  );
};

FormEditVideoSecondCustomization.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
};

export default Translation(FormEditVideoSecondCustomization, ['customizations', 'generics']);
