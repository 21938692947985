/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { BarError } from 'classes/errors';
import api from 'api';

const useAssignTokens = ({
  accountId,
  accountName,
  i18n,
  isAdmin,
  notificationActions,
  onFetchNetworkDetails,
  profileId,
}) => {
  const [allOwners, setAllOwners] = useState(null);
  const [owners, setOwners] = useState(null);

  const assignTokens = (values) => {
    const onSuccess = () => {
      onFetchNetworkDetails(accountId, profileId);
      notificationActions
        .notifySuccess(i18n.pageTokenAdministration.tokenManagement.assignTokensSuccessful);
    };

    const onError = ({ error }) => {
      notificationActions.notifyError(new BarError({
        ...error,
        message: error?.message,
      }));
    };

    const data = values.map(({ owner, type, quantity }) => ({
      [`${owner.id ? 'profile_id' : 'account_id'}`]: owner.id || owner.accountId,
      quantity: Number(quantity),
      unassigned_token: (isAdmin && type === 'true'),
    }));

    api.tokens.assignTokens({ accountId, data }, onSuccess, onError);
  };

  const getTokensAllocators = () => {
    const onSuccess = (response) => {
      if (response?.profiles) {
        setOwners(
          { 0: [{ accountId, firstName: accountName, lastName: '' }, ...response.profiles.map(
            ({ id, firstName, lastName }) => ({ id, firstName, lastName }),
          )] },
        );
        setAllOwners([...response.profiles]);
      }
    };

    const onError = (error) => {
      console.log(error);
    };

    api.tokens.getTokensAllocators({ accountId }, onSuccess, onError);
  };

  useEffect(() => {
    getTokensAllocators();
  }, []);

  return {
    allOwners,
    assignTokens,
    setOwners,
    owners,
  };
};

export default useAssignTokens;
