import { Checkbox, FormControlLabel, MenuItem } from '@material-ui/core';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { I18N } from 'constants/props';
import { StyledButton, StyledTextField, StyledSelect } from 'generics/StyledFormComponents';
import { TERMS_OF_USE } from 'urls';
import * as invitesSelectors from 'app_modules/invites/selectors';
import genderPronouns from 'constants/genderPronouns';
import InfoTooltip from 'generics/InfoTooltip';
import Translation from 'generics/Translation';

import styles from './SetupProfileForm.scss';
import useRegistrationForm from './useSetupProfileForm';

const RegistrationForm = ({
  i18n,
  initialValues,
  isSubmitting,
  onSubmit,
}) => {
  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    languages,
    values,
  } = useRegistrationForm({ i18n, initialValues, onSubmit });

  return (
    <form onSubmit={handleSubmit}>
      <p className={styles['required-message']}>{i18n.signUp.setupProfile.requiredFieldsMessage}</p>
      <Container className={styles.container}>
        <Row>
          <Col xs="12" sm="6">
            <StyledTextField
              className={styles['text-field']}
              label={`${i18n.generics.formFields.firstName}`}
              fullWidth
              name="firstName"
              title={i18n.generics.formFields.firstName}
              error={isNil(values.firstName) ? false : !!errors.firstName}
              helperText={isNil(values.firstName) ? ' ' : errors.firstName}
              onChange={(e) => handleChange('firstName', e.currentTarget.value)}
              required
              value={values.firstName ? values.firstName : ''}
            />
          </Col>
          <Col xs="12" sm="6">
            <StyledTextField
              className={styles['text-field']}
              label={`${i18n.generics.formFields.lastName}`}
              fullWidth
              name="lastName"
              title={i18n.generics.formFields.lastName}
              error={isNil(values.lastName) ? false : !!errors.lastName}
              helperText={isNil(values.lastName) ? ' ' : errors.lastName}
              onChange={(e) => handleChange('lastName', e.currentTarget.value)}
              required
              value={values.lastName ? values.lastName : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledSelect
              color="primary"
              defaultValue="0"
              error={isNil(values.genderPronounPreference) ? false
                : !!errors.genderPronounPreference}
              fullWidth
              helperText={isNil(values.genderPronounPreference) ? ' ' : errors.genderPronounPreference}
              label={`${i18n.generics.formFields.gender}`}
              name="genderPronounPreference"
              onChange={(e) => handleChange('genderPronounPreference', e.target.value)}
              required
              value={values.genderPronounPreference ? values.genderPronounPreference : ''}
            >
              {
                genderPronouns.map((genderPronoun) => (
                  <MenuItem
                    key={genderPronoun.value}
                    value={genderPronoun.value}
                  >
                    {genderPronoun.gender}
                  </MenuItem>
                ))
              }
            </StyledSelect>
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledTextField
              className={styles['text-field']}
              error={isNil(values.password) ? false : !!errors.password}
              fullWidth
              helperText={isNil(values.password) ? ' ' : errors.password}
              label={`${i18n.generics.formFields.password}`}
              name="password"
              onChange={(e) => handleChange('password', e.currentTarget.value)}
              required
              title={i18n.generics.formFields.password}
              type="password"
              value={values.password ? values.password : ''}
            />
            <InfoTooltip
              className={styles.tooltip}
              placement="left"
              text={i18n.generics.passwordTooltip}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledTextField
              className={styles['text-field']}
              error={isNil(values.passwordConfirmation) ? false : !!errors.passwordConfirmation}
              fullWidth
              helperText={isNil(values.passwordConfirmation) ? ' ' : errors.passwordConfirmation}
              label={`${i18n.generics.formFields.confirmPassword}`}
              name="passwordConfirmation"
              onChange={(e) => handleChange('passwordConfirmation', e.currentTarget.value)}
              required
              title={i18n.generics.formFields.confirmPassword}
              type="password"
              value={values.passwordConfirmation ? values.passwordConfirmation : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledSelect
              color="primary"
              defaultValue={languages ? languages[0] : ''}
              error={isNil(values.language) ? false
                : !!errors.language}
              fullWidth
              helperText={isNil(values.language) ? ' ' : errors.language}
              label={`${i18n.signUp.setupProfile.languages}`}
              name="language"
              onChange={(e) => handleChange('language', e.target.value)}
              required
              value={values.language ? values.language : ''}
            >
              {
                languages.map((language) => (
                  <MenuItem
                    key={language}
                    value={language}
                  >
                    {language}
                  </MenuItem>
                ))
              }
            </StyledSelect>
          </Col>
        </Row>
        <Row>
          <Col className={styles.agree}>
            <FormControlLabel
              classes={{ root: styles.agree__root, label: styles.agree__label }}
              control={(
                <Checkbox
                  color="primary"
                  name="agreedToTermsOfService"
                  onClick={() => handleChange('agreedToTermsOfService', !values.agreedToTermsOfService)}
                  title={i18n.generics.formFields.agreeTerms}
                  value={values.agreedToTermsOfService ? values.agreedToTermsOfService : ''}
                />
              )}
              label={i18n.generics.formFields.agreeTerms}
            />
            <Link
              className={styles.agree__link}
              href={TERMS_OF_USE}
              target="_blank"
              title={i18n.generics.termsOfUse}
              to={TERMS_OF_USE}
            >
              {i18n.generics.termsOfUse}
            </Link>
          </Col>
        </Row>
        {!isSubmitting && (
          <Row>
            <Col xs="12" sm={{ size: 8, offset: 2 }}>
              <StyledButton
                className={styles.submit}
                color="primary"
                disabled={!isValid || isSubmitting}
                fullWidth
                title={i18n.generics.save}
                type="submit"
              >
                {i18n.generics.save}
              </StyledButton>
            </Col>
          </Row>
        )}
      </Container>
    </form>
  );
};

RegistrationForm.propTypes = {
  i18n: I18N.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

RegistrationForm.defaultProps = {
  isSubmitting: false,
};


const mapStateToProps = (state) => ({
  initialValues: invitesSelectors.name(state),
});

export default connect(mapStateToProps)(Translation(RegistrationForm, ['generics', 'signUp']));
