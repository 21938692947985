/* eslint-disable react-hooks/exhaustive-deps */
import { bindActionCreators } from 'redux';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { isEqual, get, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { ACTIONS, I18N } from 'constants/props';
import { AppError } from 'classes/errors';
import { CONNECTION_REQUESTS_GET_OWN } from 'constants/apiErrorCodes';
import { Footer, Header, Main } from 'generics/Card';
import { PEOPLE_FILTERS } from 'constants/searchFilters';
import { Person } from 'classes';
import { StyledButton } from 'generics/StyledFormComponents';
import * as networkActions from 'app_modules/network/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import api from 'api';
import List from 'generics/List';
import ListItem from 'generics/ListItem';
import ListSelection from 'generics/ListSelection';
import Modal from 'generics/Modal';
import SearchBar from 'generics/SearchBar';
import Translation from 'generics/Translation';

import styles from './NetworkModal.scss';

const Network = ({
  actions,
  accountId,
  i18n,
  isDepartmentEnabled,
  onClose,
}) => {
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState(null);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [search, setSearch] = useState(null);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [status, setStatus] = useState('initial');

  let listSelection = useRef(null);

  const getInitialState = () => {
    setError(null);
    setFilter(null);
    setHasMorePages(false);
    setIsFetching(false);
    setIsSending(false);
    setPristine(true);
    setProfiles([]);
    setSearch(null);
    setSelectedProfiles([]);
    setStatus('initial');
  };

  const handleAddProfile = (profileToAdd) => {
    if (!profileToAdd) {
      throw new Error(i18n.network.shareProfile.profileUndefined);
    }

    setProfiles(profiles.filter((profile) => profile.id !== profileToAdd.id));
    setSelectedProfiles(selectedProfiles.concat(profileToAdd));
  };

  const handleCloseModal = () => {
    getInitialState();
    onClose();
  };

  const handleInvite = () => {
    setIsSending(true);

    const onSuccess = () => {
      setIsSending(false);
      setStatus('success');
    };

    const onError = (errorObj) => {
      const newError = errorObj.errorCode === CONNECTION_REQUESTS_GET_OWN
        ? errorObj
        : new AppError({ label: 'sendConnectionRequest' });

      setError(newError);
      setIsSending(false);
      setStatus('error');
    };

    actions.fetchSendConnectionRequest(
      selectedProfiles.map((profile) => profile.id),
      onSuccess,
      onError,
    );
  };

  const handleSearch = (newFilter) => {
    if (!isEqual(search, newFilter)) {
      setPristine(true);
      setProfiles([]);
      setSearch(newFilter);
    }
  };

  const handleFetchProfiles = (pageIndex = 1) => {
    const handleResponse = ({ meta: { morePages = false }, profiles: profilesList = [] }) => {
      const newProfiles = profilesList
        .map((profile) => new Person(profile).clone({
          canDrag: profile.connectionRequestStatus === 'available',
        }));

      setHasMorePages(morePages);
      setProfiles(uniqBy([...profiles, ...newProfiles]));
      setIsFetching(false);
      setPristine(false);
    };

    const handleError = () => {
      setIsFetching(false);
    };

    setIsFetching(true);

    api.profiles.getMyNetwork({
      accountId,
      pageIndex,
      search,
    }, handleResponse, handleError);
  };

  useEffect(() => {
    if (listSelection?.InfiniteScrollList && get(listSelection.InfiniteScrollList, 'infiniteScrollRef')) {
      listSelection.InfiniteScrollList.infiniteScrollRef.resetIndex();
    }
    handleFetchProfiles();
  }, [search]);

  const handleListChange = (newSelectedProfiles) => {
    setSelectedProfiles(newSelectedProfiles);
  };

  let title;

  switch (status) {
    case 'error':
      title = i18n.network.shareProfile.title.error;
      break;

    case 'success': {
      title = i18n.network.shareProfile.title.success;
      break;
    }
    default:
      title = i18n.network.shareProfile.title.share;
  }

  const infiniteScroll = {
    hasMorePages,
    listHeight: 250,
    onFetch: handleFetchProfiles,
  };

  return (
    <Modal
      card={{
        barClassName: styles.bar,
        fullHeight: true,
        isFetching: isSending,
      }}
      onClose={handleCloseModal}
    >
      <Header>
        <h1>
          {title}
        </h1>
      </Header>

      <Main>
        {
          status === 'initial' && (
            <div>
              <p className={styles.description}>
                {i18n.network.shareProfile.description}
              </p>

              <SearchBar
                defaultFilterOption={PEOPLE_FILTERS(isDepartmentEnabled)[2]?.id}
                floatingLabelText={i18n.network.shareProfile.searchText}
                isDepartmentEnabled={isDepartmentEnabled}
                className={styles.form}
                onSearch={handleSearch}
                onChange={handleSearch}
              />

              <ListSelection
                filter={filter}
                isFetching={isFetching}
                isSending={isSending}
                listA={{
                  id: 'share-connection-list1',
                  infiniteScroll,
                  profiles,
                }}
                listB={{
                  emptyMessage: i18n.network.shareProfile.emptyMessage,
                  id: 'share-connection-list2',
                  profiles: selectedProfiles,
                }}
                onChange={handleListChange}
                pristine={pristine}
                componentRefProp={(component) => { listSelection = component; }}
              />
            </div>
          )
        }

        {
          status === 'success' && (
            <div className={styles.success}>
              <p className={styles.description}>
                {i18n.network.shareProfile.successDescription}
              </p>

              <List>
                {
                  selectedProfiles
                    .map((profile) => (
                      <ListItem
                        key={profile.id}
                        profile={profile}
                      />
                    ))
                }
              </List>
            </div>
          )
        }

        {
          status === 'error' && (
            <Container fluid>
              <Row>
                <Col xs="12">
                  {error.message}
                </Col>
              </Row>
            </Container>
          )
        }
      </Main>

      <Footer>
        <Container fluid>
          <Row>
            <Col xs="12" sm={{ size: 4, offset: 8 }} md={{ size: 2, offset: 10 }}>
              <StyledButton
                disabled={selectedProfiles.length === 0}
                fullWidth
                onClick={status === 'initial' ? handleInvite : handleCloseModal}
                color="primary"
                title={status === 'initial' ? i18n.network.shareProfile.button.invite
                  : i18n.network.shareProfile.button.done}
              >
                {status === 'initial' ? i18n.network.shareProfile.button.invite
                  : i18n.network.shareProfile.button.done}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </Footer>
    </Modal>
  );
};

Network.propTypes = {
  accountId: PropTypes.number.isRequired,
  actions: ACTIONS.isRequired,
  i18n: I18N.isRequired,
  isDepartmentEnabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accountId: sessionSelectors.accountId(state),
  isPersonalAccount: sessionSelectors.isPersonal(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(networkActions, dispatch),
});

export default Translation(connect(mapStateToProps, mapDispatchToProps)(Network), ['network']);
