/* eslint-disable camelcase */
import classnames from 'classnames';

import { DYNAMICS_LABELS } from 'constants';

const isRight = (d, i) => i === 2 || i === 3;

const isBottom = (d, i) => i === 0 || i === 3;

const isOverlap = (element0, element1) => {
  const r1 = element0.getBoundingClientRect();
  const r2 = element1.getBoundingClientRect();

  return !(r2.left > r1.right
    || r2.right < r1.left
    || r2.top > r1.bottom
    || r2.bottom < r1.top);
};

export const drawQuadrantsLabels = ({ elements, isPair, data, styles }) => {
  if (elements) {
    if (data.length === 0) {
      return null;
    }

    const {
      g_quadrantsText,
    } = elements;

    let quadrantsTextData;

    if (DYNAMICS_LABELS) {
      quadrantsTextData = DYNAMICS_LABELS
        .map((dynamicText, dynamicIndex) => {
          const dynamic = `${dynamicIndex + 1}. ${dynamicText.toUpperCase()}`;

          const user0 = {
            get energy() { return data[0].energies.byWidth[dynamicIndex]; },
          };

          let user1;

          if (isPair) {
            user1 = {
              get energy() { return data[1].energies.byWidth[dynamicIndex]; },
            };
          }

          return {
            dynamic,
            dynamicIndex,
            dynamicText,
            user0,
            user1,
          };
        });
    }

    let quadrantsText;

    if (g_quadrantsText) {
      quadrantsText = g_quadrantsText
        .selectAll('text')
        .data(quadrantsTextData, (d) => d.dynamic);

      quadrantsText
        .exit()
        .remove();

      const quadrantsTextEnter = quadrantsText
        .enter()
        .append('text')
        .attr('class', styles['quadrant-text'])
        .classed(styles.right, isRight);

      quadrantsTextEnter
        .append('tspan')
        .attr('class', styles['dynamic-name']);

      quadrantsTextEnter
        .append('tspan')
        .attr('x', 0)
        .attr('class', ({ dynamicIndex }) => classnames(
          styles['energy-label'],
          { [styles[`energy-label--dynamic-${dynamicIndex}`]]: !isPair },
          { [styles['energy-label--is-highlight']]: isPair || data[0]._isTeam },
        ));

      if (isPair) {
        quadrantsTextEnter
          .append('tspan')
          .attr('class', styles.separator)
          .attr('dx', '0.15em');

        quadrantsTextEnter
          .append('tspan')
          .attr('dx', '0.15em')
          .attr('class', ({ dynamicIndex }) => classnames(
            styles['energy-label'],
            styles[`energy-label--dynamic-${dynamicIndex}`],
          ));
      }

      quadrantsText = quadrantsText
        .merge(quadrantsTextEnter);

      quadrantsText
        .select('tspan:nth-child(1)')
        .text((d) => d.dynamic);

      quadrantsText
        .select('tspan:nth-child(2)')
        .text((d) => d.user0.energy)
        .attr('font-size', 12);

      if (isPair) {
        quadrantsText
          .select('tspan:nth-child(3)')
          .text((d) => d.user0.energy && d.user1.energy && '/');

        quadrantsText
          .select('tspan:nth-child(4)')
          .text((d) => d.user1.energy)
          .attr('font-size', 12);
      }
    }

    return quadrantsText;
  }
};

export function updateQuadrantsLabelsGeometry({ geometry, isPair, quadrantsText }) {
  if (geometry) {
    const {
      labels,
      quadrantsSigns,
      side,
    } = geometry;

    const {
      dynamicNameFontSize,
      energyNameFontSize,
      energyNameLineHeight,
      margin,
    } = labels;

    quadrantsText
      .attr('transform', (d, i) => {
        const quadrantSigns = quadrantsSigns[i];

        const offset = isBottom(d, i)
          ? margin.bottom + dynamicNameFontSize
          : margin.top + energyNameLineHeight;

        return `translate(${quadrantSigns.x * side}, ${quadrantSigns.y * (side + offset)})`;
      });

    quadrantsText
      .select('tspan:nth-child(1)')
      .attr('font-size', dynamicNameFontSize);

    quadrantsText
      .select('tspan:nth-child(2)')
      .attr('font-size', !isPair ? energyNameFontSize : 12)
      .attr('dy', energyNameLineHeight);

    if (isPair) {
      quadrantsText
        .select('tspan:nth-child(4)')
        .attr('x', ((d) => (!d.user0.energy ? 0 : undefined)))
        .attr('dy', ((d) => (!d.user0.energy ? energyNameLineHeight : undefined)));

      const labelsNodes = quadrantsText.nodes();

      const labelsAreOverlap = isOverlap(labelsNodes[0], labelsNodes[3])
        || isOverlap(labelsNodes[1], labelsNodes[2]);

      if (labelsAreOverlap) {
        quadrantsText
          .select('tspan:nth-child(2)')
          .attr('font-size', !isPair ? energyNameFontSize : 10.5);

        quadrantsText
          .select('tspan:nth-child(4)')
          .attr('font-size', !isPair ? energyNameFontSize : 10.5);
      }
    }
  }
}
