import * as editAccount from './editAccount';
import * as editCustomizations from './customization';
import * as editDepartment from './editDepartment';
import * as fetchAccount from './fetchAccount';
import * as fetchMyMembers from './fetchMyMembers';
import * as removePermission from './removePermission';
import * as updateLogo from './updateLogo';

module.exports = {
  ...editAccount,
  ...editCustomizations,
  ...editDepartment,
  ...fetchAccount,
  ...fetchMyMembers,
  ...removePermission,
  ...updateLogo,
};
