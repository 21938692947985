import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { CHILDREN } from 'constants/props';
import * as accountSelectors from 'app_modules/accounts/selectors';
import * as sessionSelectors from 'app_modules/session/selectors';
import ErrorBoundary from 'generics/ErrorBoundary';
import ModalNotification from 'components/ModalNotification';

import styles from './WelcomeLayout.scss';

const { PUBLIC_PATH } = process.env;

const BACKGROUND_URL = `${window.location.origin}${PUBLIC_PATH}/welcome/welcome-background.jpg`;

const WelcomeLayout = ({
  children,
  isImpersonating = false,
  isStudentWaitingResults,
}) => {
  const [isStickyHeader] = useState(0);

  const mainFocusRef = useRef(null);

  return (
    <div
      className={styles.layout}
      style={{ backgroundImage: `url(${BACKGROUND_URL})` }}
    >
      <main
        className={classnames(
          styles.main,
          { [styles['main--has-sticky-header']]: isStickyHeader },
          { [styles['main--has-impersonate-label']]: isImpersonating },
          { [styles['main--has-hidden-results-label']]: isStudentWaitingResults },
        )}
        ref={mainFocusRef}
      >
        <ErrorBoundary className={styles['error-boundary']}>
          {children}
        </ErrorBoundary>
      </main>
      <ModalNotification />
    </div>
  );
};

WelcomeLayout.propTypes = {
  children: CHILDREN,
  isImpersonating: PropTypes.bool,
  isStudentWaitingResults: PropTypes.bool.isRequired,
};

WelcomeLayout.defaultProps = {
  children: null,
  isImpersonating: false,
};

const mapStateToProps = (state) => ({
  isImpersonating: sessionSelectors.isImpersonating(state),
  isStudentWaitingResults: accountSelectors.isStudentWaitingResults(state),
});

export default connect(mapStateToProps, null)(WelcomeLayout);
