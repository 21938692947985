import PropTypes from 'prop-types';

import { I18N } from 'constants/props';
import useForm from 'custom_hooks/useForm';

const useFormLicensesQuantity = ({ initialValues, onSubmit }) => {
  const handleValidate = () => ({
    quantity: null,
  });

  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    reset,
    values = { quantity: null },
  } = useForm({ initialValues, onSubmit, onValidate: handleValidate });

  const handleQuantityChange = (name, value) => {
    let newValue = value;
    if (!Number.isNaN(Number(value)) && (Number(value) >= 1 || value === '')) {
      if (Number(value) > 100) {
        newValue = 100;
      }
      handleChange(name, newValue);
    }
  };

  return {
    errors,
    isValid,
    handleQuantityChange,
    handleSubmit,
    reset,
    values,
  };
};

useFormLicensesQuantity.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
};

export default useFormLicensesQuantity;
