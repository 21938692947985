import PropTypes from 'prop-types';

import { EMAIL, REQUIRED } from 'constants/inputTypes';
import { I18N } from 'constants/props';
import useForm from 'custom_hooks/useForm';
import useFormValidation from 'custom_hooks/useFormValidation';

const useFormEditEmail = ({ i18n, initialValues, onSubmit, onValidate }) => {
  const { validator } = useFormValidation({ i18n });

  const handleValidate = (values) => {
    const { email } = values;

    const emailError = validator.validate(REQUIRED, email) || validator.validate(EMAIL, email)
      || onValidate({ email }).email;

    return {
      email: emailError,
    };
  };

  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values = {
      email: null,
    },
  } = useForm({ initialValues, onSubmit, onValidate: handleValidate });

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  };
};

useFormEditEmail.propTypes = {
  i18n: I18N.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  initialValues: PropTypes.shape({ email: PropTypes.string.isRequired }),
};

useFormEditEmail.defaultProps = { initialValues: { email: null } };

export default useFormEditEmail;
