import { autobind } from 'core-decorators';
import { CircularProgress } from '@material-ui/core';
import { Col, Container, Row } from 'reactstrap';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { REQUIRED } from 'constants/inputTypes';
import { FormValidator } from 'classes';
import { hasFormErrors } from 'helpers';
import { I18N } from 'constants/props';
import { StyledTextField } from 'generics/StyledFormComponents';
import FormComponent from 'generics/FormComponent';
import Mask from 'generics/Mask';
import Translation from 'generics/Translation';

import styles from './FormEstimation.scss';

/**
 * FormEstimation Component
 */
export class FormEstimation extends FormComponent {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      values: {},
    };
  }

  componentDidUpdate({ amount }) {
    if ((amount !== this.props.amount
      && this.props.amount === null
    )) {
      this.resetValues();
    }
  }

  resetValues = () => {
    this.setState({ values: {} });
  }

  @autobind
  validate() {
    const { i18n } = this.props;
    const { values } = this.state;

    const {
      quantity,
    } = values;

    const validator = new FormValidator(i18n);

    const errors = {
      quantity: validator.validate(REQUIRED, quantity),
    };

    this.setState({
      errors,
      isValid: !hasFormErrors(errors),
    });
  }

  render() {
    const {
      error,
      i18n,
      isLoading,
      onChange,
      title,
    } = this.props;

    const {
      errors,
      values,
    } = this.state;

    const quantityError = error || errors?.quantity;

    const completeChange = (value) => {
      if (!Number.isNaN(value) && (Number(value) >= 1 || value === '')) {
        this.handleChange('quantity', value);
        onChange(value);
      }
    };

    return (
      <>
        <form
          className={styles.form}
        >
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <StyledTextField
                  ariaLabel={`${title}. ${i18n.pageTokenAdministration.buyForm.inputLabel}`}
                  className={styles['text-field']}
                  error={isNil(values.quantity) ? false : !!quantityError}
                  helperText={isNil(values.quantity) ? ' ' : quantityError}
                  label={i18n.pageTokenAdministration.buyForm.inputLabel}
                  name="quantity"
                  onChange={(e) => completeChange(e.currentTarget.value)}
                  type="number"
                  value={values.quantity ? values.quantity : ''}
                />
              </Col>
            </Row>
          </Container>
        </form>
        <Mask
          className={styles.mask}
          open={isLoading}
        >
          <CircularProgress
            className={styles.loader}
            size={25}
            thickness={2}
          />
        </Mask>
      </>
    );
  }
}

FormEstimation.propTypes = {
  amount: PropTypes.number,
  error: PropTypes.string,
  i18n: I18N.isRequired,
  isSubmitting: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  orderQuantity: PropTypes.string,
};

FormEstimation.defaultProps = {
  amount: 0,
  error: null,
  isSubmitting: false,
  orderQuantity: '',
};

export default Translation(FormEstimation, ['pageTokenAdministration', 'generics']);
