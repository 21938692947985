import { combineReducers } from 'redux';

import assessmentActionTypes from 'app_modules/assessment/constants';
import initialState from 'app_modules/store/initialState.json';
import sessionActionTypes from 'app_modules/session/constants';

export const answers = (state = initialState.assessment.answers, action) => {
  switch (action.type) {
    case assessmentActionTypes.FETCH_ASSESSMENT_REQUEST:
    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
      return [];

    case assessmentActionTypes.ADD_NEW_ANSWER:
      return [...state, action.answer];

    case sessionActionTypes.RESET_STATE:
      return initialState.assessment.answers;

    default:
      return state;
  }
};

export const currentQuestion = (state = initialState.assessment.currentQuestion, action) => {
  switch (action.type) {
    case assessmentActionTypes.FETCH_ASSESSMENT_REQUEST:
    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
      return {};

    case assessmentActionTypes.SET_NEXT_QUESTION:
      return action.payload;

    case assessmentActionTypes.FETCH_ASSESSMENT_SUCCESS:
      return action.payload.nextQuestion;

    case sessionActionTypes.RESET_STATE:
      return initialState.assessment.currentQuestion;

    default:
      return state;
  }
};

export const errors = (state = initialState.assessment.errors, action) => {
  switch (action.type) {
    case assessmentActionTypes.FETCH_ASSESSMENT_REQUEST:
    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
      return [];

    case assessmentActionTypes.FETCH_ASSESSMENT_FAILURE:
    case assessmentActionTypes.FETCH_SAVING_FAILURE:
      return [...state, action.error];

    case sessionActionTypes.RESET_STATE:
      return initialState.assessment.errors;

    default:
      return state;
  }
};

export const expirationDate = (state = initialState.assessment.expirationDate, action) => {
  switch (action.type) {
    case assessmentActionTypes.FETCH_ASSESSMENT_REQUEST:
    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
      return null;

    case assessmentActionTypes.FETCH_ASSESSMENT_SUCCESS:
      return action.payload.assessment.expirationDate;

    case sessionActionTypes.RESET_STATE:
      return initialState.assessment.expirationDate;

    default:
      return state;
  }
};

export const id = (state = initialState.assessment.id, action) => {
  switch (action.type) {
    case assessmentActionTypes.FETCH_ASSESSMENT_REQUEST:
    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
      return null;

    case assessmentActionTypes.FETCH_ASSESSMENT_SUCCESS:
      return action.payload.assessment.id;

    case sessionActionTypes.RESET_STATE:
      return initialState.assessment.id;

    default:
      return state;
  }
};

export const isCompleted = (state = initialState.assessment.isCompleted, action) => {
  switch (action.type) {
    case assessmentActionTypes.FETCH_ASSESSMENT_REQUEST:
      return false;

    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
      return true;

    case assessmentActionTypes.UPDATE_STATE:
      return action.isCompleted;

    case sessionActionTypes.RESET_STATE:
      return initialState.assessment.isCompleted;

    default:
      return state;
  }
};

export const isDisable = (state = initialState.assessment.isDisable, action) => {
  switch (action.type) {
    case assessmentActionTypes.FETCH_ASSESSMENT_REQUEST:
    case assessmentActionTypes.FETCH_SAVING_REQUEST:
      return true;

    case assessmentActionTypes.FETCH_ASSESSMENT_FAILURE:
    case assessmentActionTypes.FETCH_ASSESSMENT_SUCCESS:
    case assessmentActionTypes.FETCH_SAVING_FAILURE:
    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
    case assessmentActionTypes.SET_TIEBREAK:
      return false;

    case sessionActionTypes.RESET_STATE:
      return initialState.assessment.isDisable;

    default:
      return state;
  }
};

export const isFetching = (state = initialState.assessment.isFetching, action) => {
  switch (action.type) {
    case assessmentActionTypes.FETCH_ASSESSMENT_REQUEST:
    case assessmentActionTypes.FETCH_SAVING_REQUEST:
      return true;

    case assessmentActionTypes.FETCH_ASSESSMENT_FAILURE:
    case assessmentActionTypes.FETCH_ASSESSMENT_SUCCESS:
    case assessmentActionTypes.FETCH_SAVING_FAILURE:
    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
    case assessmentActionTypes.SET_TIEBREAK:
      return false;

    case sessionActionTypes.RESET_STATE:
      return initialState.assessment.isFetching;

    default:
      return state;
  }
};

export const isInProgress = (state = initialState.assessment.isInProgress, action) => {
  switch (action.type) {
    case assessmentActionTypes.FETCH_ASSESSMENT_REQUEST:
    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
      return false;

    case assessmentActionTypes.FETCH_ASSESSMENT_SUCCESS:
      return true;

    case assessmentActionTypes.UPDATE_STATE:
      return action.isInProgress;

    case sessionActionTypes.RESET_STATE:
      return initialState.assessment.isInProgress;

    default:
      return state;
  }
};

export const isOpenDialog = (state = initialState.assessment.isOpenDialog, action) => {
  switch (action.type) {
    case assessmentActionTypes.FETCH_ASSESSMENT_REQUEST:
    case assessmentActionTypes.UPDATE_STATE:
      return false;

    case assessmentActionTypes.FETCH_SAVING_FAILURE:
    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
      return true;

    case sessionActionTypes.RESET_STATE:
      return initialState.assessment.isOpenDialog;

    default:
      return state;
  }
};

export const isSaving = (state = initialState.assessment.isSaving, action) => {
  switch (action.type) {
    case assessmentActionTypes.FETCH_ASSESSMENT_REQUEST:
    case assessmentActionTypes.FETCH_SAVING_FAILURE:
    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
    case assessmentActionTypes.SET_TIEBREAK:
      return false;

    case assessmentActionTypes.FETCH_SAVING_REQUEST:
      return true;

    case sessionActionTypes.RESET_STATE:
      return initialState.assessment.isSaving;

    default:
      return state;
  }
};

export const remainingQuestions = (state = initialState.assessment.remainingQuestions, action) => {
  switch (action.type) {
    case assessmentActionTypes.FETCH_ASSESSMENT_REQUEST:
    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
      return [];

    case assessmentActionTypes.FETCH_ASSESSMENT_SUCCESS:
      return Object.assign([], action.payload.assessment.questions);

    case assessmentActionTypes.SET_TIEBREAK:
      return Object.assign([], action.questions);

    case sessionActionTypes.RESET_STATE:
      return initialState.assessment.remainingQuestions;

    default:
      return state;
  }
};

export const timeout = (state = initialState.assessment.timeout, action) => {
  switch (action.type) {
    case assessmentActionTypes.FETCH_ASSESSMENT_REQUEST:
    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
      return false;

    case sessionActionTypes.RESET_STATE:
      return initialState.assessment.timeout;
    default:
      return state;
  }
};

export default combineReducers({
  answers,
  currentQuestion,
  errors,
  expirationDate,
  id,
  isCompleted,
  isDisable,
  isFetching,
  isInProgress,
  isOpenDialog,
  isSaving,
  remainingQuestions,
  timeout,
});
