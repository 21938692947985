import _ from 'lodash';

import d3 from 'vendor/d3';

const ENERGY_THRESHOLDS_RANGES = {
  Reserved: {
    balloonSize: 0,
    max: 1,
    min: 0,
  },
  Deliberate: {
    balloonSize: 1.5,
    max: 4,
    min: 2,
  },
  'Deliberate+': {
    balloonSize: 1.5,
    max: 8,
    min: 5,
  },
  Effortless: {
    balloonSize: 7,
    max: 11,
    min: 9,
  },
  'Effortless+': {
    balloonSize: 7,
    max: 13,
    min: 12,
  },
  Abundant: {
    balloonSize: 13,
    max: 15,
    min: 14,
  },
};

// energy thresholds
const THRESHOLDS_LABELS = {
  label: [
    'Reserved', // 0-1 = Reserved
    'Deliberate', // 2-4 = Deliberate
    'Deliberate+', // 5-8 = Deliberate+
    'Effortless', // 9-11 = Effortless
    'Effortless+', // 12-13 = Effortless+
    'Abundant', // 14-15 = Reserved
  ],
  labelAbbreviation: [
    'R', // 0-1 = Reserved
    'D', // 2-4 = Deliberate
    'D+', // 5-8 = Deliberate+
    'E', // 9-11 = Effortless
    'E+', // 12-13 = Effortless+
    'A', // 14-15 = Reserved
  ],
};

export const getScales = (geometry) => {
  const energies = undefined;

  const energyThresholds = _.mapValues(ENERGY_THRESHOLDS_RANGES, 'min');

  const scales = {};

  // balloons

  const energyThresholdsPairs = _.chain(energyThresholds)
    .omitBy((value, key) => key.endsWith('+'))
    .toPairs()
    .sortBy((pair) => _.indexOf(energies, pair[0]))
    .value();

  const thresholds = _.map(energyThresholdsPairs, (pair) => pair[1]);

  const sides = _.map(energyThresholdsPairs, (pair) => geometry.bandSideRatios[pair[0]]
    * geometry.maxBalloonSide);

  scales.getBalloonSize = (value) => _.find(ENERGY_THRESHOLDS_RANGES, ({ max, min }) => parseInt(
    value, 10) >= min && parseInt(value, 10) <= max).balloonSize;

  scales.balloonDiameter = d3.scaleLinear()
    .domain(thresholds)
    .range(sides);

  scales.energyRangeLabel = d3.scaleThreshold()
    .domain([0, 2, 5, 9, 12, 14])
    .range(window.innerWidth < 480
      ? THRESHOLDS_LABELS.labelAbbreviation
      : THRESHOLDS_LABELS.label);

  return scales;
};
