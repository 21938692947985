import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import htmlParser from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Slider from 'react-slick';

import { fireWindowEvent } from 'helpers';
import { HISTORY, I18N } from 'constants/props';
import { StyledButton } from 'generics/StyledFormComponents';
import * as sessionSelectors from 'app_modules/session/selectors';
import Translation from 'generics/Translation';

import styles from './welcomeCardSlider.scss';

// spell-checker:ignore HMMM
class welcomeCardSlider extends Component {
  componentDidMount() {
    const { refSlider } = this.props;

    if (refSlider) {
      refSlider(this);
    }

    setTimeout(() => {
      this.dispatchEventResize();
    }, 200);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    this.dispatchEventResize();
  }

  componentDidUpdate() {
    this.dispatchEventResize();
  }

  handleStartAssessment = () => {
    const { history } = this.props;
    history.push('/assessment');
  }

  handleNextStep = () => {
    this.slider.slickNext();
  }

  handlePreviousStep = () => {
    this.slider.slickPrev();
  }

  dispatchEventResize = () => {
    fireWindowEvent('resize');
    fireWindowEvent('onresize');
  }

  render() {
    const { assessment, i18n, userName } = this.props;

    const buttonLabelStep0 = assessment.isInProgress
      ? i18n.welcomeCardSlider.continueAssessment : i18n.welcomeCardSlider.continue;
    const buttonLabelStep2 = assessment.isInProgress
      ? i18n.welcomeCardSlider.continue : i18n.welcomeCardSlider.startAssessment;

    const settings = {
      adaptiveHeight: true,
      arrows: false,
      infinite: false,
      swipe: false,
    };

    return (
      <div
        className={styles['slider-container']}
      >
        <Slider
          ref={(element) => { this.slider = element; }}
          {...settings}
        >
          <div
            className={styles['slide-container']}
          >
            <section className={styles.section}>
              <header>
                <h2 className={styles.title}>
                  <span className={styles.title__welcome}>
                    {i18n.welcomeCardSlider.welcomeHeader}
                  </span>
                  {` ${userName}`}
                </h2>
              </header>
              <main className={styles.section__content}>
                <p>
                  {i18n.welcomeCardSlider.p[0]}
                </p>
                <p className={styles.description__take}>
                  {i18n.welcomeCardSlider.p[1]}
                  <span className={styles.description__highlight}>
                    {i18n.welcomeCardSlider.p[2]}
                  </span>
                  {i18n.welcomeCardSlider.p[3]}
                </p>
              </main>
            </section>
            <StyledButton
              className={styles.button}
              disabled={assessment.isCompleted}
              title={buttonLabelStep0}
              onClick={!assessment.isInProgress
                ? this.handleNextStep
                : this.handleStartAssessment}
              color="primary"
            >
              {buttonLabelStep0}
            </StyledButton>
          </div>

          {
            !assessment.isInProgress && (
              <div
                className={styles['slide-container']}
              >
                <section className={styles.section}>
                  <main className={styles.section__content}>
                    <p className={styles.description__once}>
                      {i18n.welcomeCardSlider.p[4]}
                      <span className={styles.description__highlight}>
                        &nbsp;
                        {i18n.welcomeCardSlider.p[5]}
                        &nbsp;
                      </span>
                      {i18n.welcomeCardSlider.p[6]}
                    </p>
                    <p className={styles.description__are}>
                      {i18n.welcomeCardSlider.p[7]}
                    </p>
                  </main>
                  <div
                    className={styles['button-relax-container']}
                  >
                    <div
                      className={styles['button-relax']}
                      onClick={this.handlePreviousStep}
                      onKeyPress={this.handlePreviousStep}
                      role="button"
                      tabIndex={0}
                    >
                      <span className={styles['button-relax__label1']}>
                        {i18n.welcomeCardSlider.buttonRelax.no[0]}
                      </span>
                      <span className={styles['button-relax__label2']}>
                        {htmlParser(i18n.welcomeCardSlider.buttonRelax.no[1])}
                      </span>
                    </div>
                    <div
                      className={styles['button-relax']}
                      onClick={this.handleNextStep}
                      onKeyPress={this.handleNextStep}
                      role="button"
                      tabIndex={0}
                    >
                      <span className={styles['button-relax__label1']}>
                        {i18n.welcomeCardSlider.buttonRelax.yes[0]}
                      </span>
                      <span className={styles['button-relax__label2']}>
                        {htmlParser(i18n.welcomeCardSlider.buttonRelax.yes[1])}
                      </span>
                    </div>
                  </div>
                </section>
              </div>
            )
          }

          {
            !assessment.isInProgress && (
              <div
                className={styles['slide-container']}
              >
                <section className={styles.section}>
                  <main className={styles.section__content}>
                    <p className={styles.description__ok}>
                      {i18n.welcomeCardSlider.assessmentInProgress[0]}
                    </p>
                    <p className={styles.description__youll}>
                      {i18n.welcomeCardSlider.assessmentInProgress[1]}
                      <span className={styles.description__highlight}>
                        {i18n.welcomeCardSlider.assessmentInProgress[2]}
                      </span>
                      {i18n.welcomeCardSlider.assessmentInProgress[3]}
                    </p>
                    <p className={styles.description__if}>
                      {i18n.welcomeCardSlider.assessmentInProgress[4]}
                    </p>
                    <p className={styles.description__please}>
                      <span className={styles.description__highlight}>
                        {i18n.welcomeCardSlider.assessmentInProgress[5]}
                      </span>
                    </p>
                  </main>
                </section>
                <StyledButton
                  disabled={assessment.isCompleted}
                  title={buttonLabelStep2}
                  onClick={this.handleStartAssessment}
                  color="primary"
                  className={styles.button}
                >
                  {buttonLabelStep2}
                </StyledButton>
              </div>
            )
          }
        </Slider>
      </div>
    );
  }
}

/* eslint-disable react/require-default-props */
welcomeCardSlider.propTypes = {
  assessment: PropTypes.shape({
    isCompleted: PropTypes.bool.isRequired,
    isInProgress: PropTypes.bool.isRequired,
  }),
  history: HISTORY,
  i18n: I18N.isRequired,
  userName: PropTypes.string,
  refSlider: PropTypes.func,
};
/* eslint-enable */

const mapStateToProps = (state) => ({
  assessment: {
    isCompleted: state.assessment.isCompleted,
    isInProgress: state.assessment.isInProgress,
  },
  userName: sessionSelectors.currentProfile(state).name,
});

export default withRouter(Translation(connect(mapStateToProps)(welcomeCardSlider), ['welcomeCardSlider']));
