import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as accountActions from 'app_modules/accounts/actions';
import * as accountSelectors from 'app_modules/accounts/selectors';
import * as sessionSelectors from 'app_modules/session/selectors';
import CustomizationsCard from 'components/PageAdministration/components/CustomizationsCard';

const mapStateToProps = (state) => ({
  accountId: sessionSelectors.accountId(state),
  customizations: accountSelectors.customizations(state),
});

const mapDispatchToProps = (dispatch) => ({
  accountActions: bindActionCreators(accountActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomizationsCard);
