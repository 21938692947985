import { size } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { RECORDS } from 'constants/props';
import { setIterationIndex } from 'helpers';
import ListItem from 'generics/ListItem';
import { Person } from 'classes';

import ModalErrors from '../ModalErrors';
import styles from './BadgeResultsList.scss';

function getProfileFromRecord(record) {
  return new Person({
    account: {
      id: record.id,
      isPersonal: true,
      name: record.firstName,
    },
    agreedToTermsOfService: true,
    genderPronounPreference: 'sr',
    ...record,
  });
}

/**
 * @description BadgeResultsList Component
 */
const BadgeResultsList = ({ isDepartmentEnabled, list, title }) => {
  setIterationIndex(list);

  return (
    size(list) > 0 && (
      <>
        <p className={styles.information}>
          {title}
        </p>
        <ul className={styles.users}>
          {
            list.map((record, idx) => {
              const profile = getProfileFromRecord(record, idx);

              const errorMessages = (
                <ModalErrors errors={profile.errors} />
              );

              profile.account.id = idx;
              profile.id = idx;

              return (
                <ListItem
                  isDepartmentEnabled={isDepartmentEnabled}
                  key={profile.id}
                  profile={profile}
                  errors={errorMessages}
                />
              );
            })
          }
        </ul>
      </>
    )
  );
};

BadgeResultsList.propTypes = {
  isDepartmentEnabled: PropTypes.bool.isRequired,
  list: RECORDS,
  title: PropTypes.string.isRequired,
};

BadgeResultsList.defaultProps = {
  list: [],
};

export default BadgeResultsList;
