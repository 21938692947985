/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from 'reactstrap';
import { pickHTMLProps } from 'pick-react-known-prop';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Card, Header } from 'generics/Card';
import { CHILDREN, I18N } from 'constants/props';
import Icon from 'generics/Icon';
import InformationCardHeader from 'generics/InformationCardHeader';
import Translation from 'generics/Translation';

import styles from './modal.scss';

const Modal = ({
  category,
  categoryColor,
  children,
  className,
  focusRef,
  i18n,
  icon,
  isBlog,
  onClose,
  title,
  modalCardClassName,
  modalTitleClassName,
  ...rest
}) => {
  const handleClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (focusRef?.current) {
      focusRef.current.focus();
    }
  }, []);

  return (
    <div // eslint-disable-line
      aria-modal="true"
      className={classnames(
        styles.modal,
        className,
      )}
      onClick={handleClick}
      role="dialog"
      {...pickHTMLProps(rest)}
    >
      <Container className={styles.container}>
        <Row>
          <Col>
            <Card
              category={category}
              barBackgroundColor={categoryColor}
              className={classnames(
                styles.card,
                modalCardClassName,
              )}
            >
              <Icon.Stroke7
                aria-label="Close Dialog"
                className={styles.close}
                name="close"
                onClick={onClose}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    onClose();
                  }
                }}
                role="button"
                tabIndex={0}
                title={i18n.generics.closeLabel}
              />
              {!isBlog && (
              <Header>
                <InformationCardHeader
                  category={category}
                  categoryColor={categoryColor}
                  icon={icon}
                  title={title}
                  titleClassName={modalTitleClassName}
                />
              </Header>
              )}
              <main
                className={styles.main}
              >
                {children}
              </main>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Modal.propTypes = {
  category: PropTypes.string,
  categoryColor: PropTypes.string.isRequired,
  children: CHILDREN,
  className: PropTypes.string,
  focusRef: PropTypes.shape().isRequired,
  i18n: I18N.isRequired,
  icon: PropTypes.string.isRequired,
  isBlog: PropTypes.bool,
  modalCardClassName: PropTypes.string,
  modalTitleClassName: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
};

Modal.defaultProps = {
  category: '',
  children: null,
  className: null,
  isBlog: false,
  modalCardClassName: null,
  modalTitleClassName: null,
  onClose: undefined,
};

export default Translation(Modal, ['generics']);
