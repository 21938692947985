import PropTypes from 'prop-types';

import { REQUIRED } from 'constants/inputTypes';
import { I18N } from 'constants/props';
import useForm from 'custom_hooks/useForm';
import useFormValidation from 'custom_hooks/useFormValidation';

const useFormProfileNotes = ({ i18n, initialValues, onSubmit }) => {
  const { validator } = useFormValidation({ i18n });

  const handleValidate = ({ description, title }) => ({
    description: validator.validate(REQUIRED, description),
    title: validator.validate(REQUIRED, title),
  });

  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values = { description: null },
  } = useForm({ initialValues, onSubmit, onValidate: handleValidate });

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  };
};

useFormProfileNotes.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
};

export default useFormProfileNotes;
