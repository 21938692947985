import { Col, Container, Row } from 'reactstrap';
import { isNil } from 'lodash';
import { pickHTMLProps } from 'pick-react-known-prop';
import PropTypes from 'prop-types';
import React from 'react';

import { Footer, Header, Main } from 'generics/Card';
import { I18N } from 'constants/props';
import { StyledButton, StyledTextField } from 'generics/StyledFormComponents';
import Translation from 'generics/Translation';

import styles from './FormLicensesQuantity.scss';
import useFormLicensesQuantity from './useFormLicensesQuantity';

const FormLicensesQuantity = ({ i18n, onClose, onSubmit, ...rest }) => {
  const {
    errors,
    isValid,
    handleQuantityChange,
    handleSubmit,
    values,
  } = useFormLicensesQuantity({ i18n, onSubmit });

  return (
    <>
      <Header>
        <h1
          className={styles.title}
          title={i18n.subscriptions.title}
        >
          {i18n.subscriptions.title}
        </h1>
        <p>
          {i18n.subscriptions.description}
        </p>
      </Header>
      <Main>
        <Container fluid>
          <form
            className={styles.form}
            {...pickHTMLProps(rest)}
          >
            <StyledTextField
              error={isNil(values.quantity) ? false : !!errors.quantity}
              helperText={isNil(values.quantity) ? ' ' : errors.quantity}
              label={`${i18n.subscriptions.quantity}:`}
              name="quantity"
              max="100"
              onChange={(e) => handleQuantityChange('quantity', e.currentTarget.value)}
              title={i18n.subscriptions.quantity}
              value={values.quantity || ''}
            />
          </form>
        </Container>
      </Main>
      <Footer>
        <Container>
          <Row>
            <Col xs="3" md="2">
              <StyledButton
                color="default"
                fullWidth
                onClick={() => onClose()}
                title={i18n.generics.cancelLabel}
                variant="text"
              >
                {i18n.generics.cancelLabel}
              </StyledButton>
            </Col>

            <Col xs={{ size: 3, offset: 6 }} md={{ size: 3, offset: 7 }}>
              <StyledButton
                color="primary"
                disabled={!isValid}
                fullWidth
                id="save-video-customization-btn"
                onClick={(e) => handleSubmit(e)}
                title={i18n.generics.doneLabel}
              >
                {i18n.generics.doneLabel}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </Footer>
    </>
  );
};

FormLicensesQuantity.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
};

export default Translation(FormLicensesQuantity, ['generics', 'subscriptions']);
