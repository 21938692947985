const NAME_SPACE = 'Bundles';

const actionTypes = {
  FETCH_BUNDLE_DETAILS_FAILURE: `${NAME_SPACE}/FETCH_BUNDLE_DETAILS_FAILURE`,
  FETCH_BUNDLE_DETAILS_REQUEST: `${NAME_SPACE}/FETCH_BUNDLE_DETAILS_REQUEST`,
  FETCH_BUNDLE_DETAILS_SUCCESS: `${NAME_SPACE}/FETCH_BUNDLE_DETAILS_SUCCESS`,
  FETCH_BUNDLE_DETAILS: `${NAME_SPACE}/FETCH_BUNDLE_DETAILS`,
  FETCH_BUNDLES_FAILURE: `${NAME_SPACE}/FETCH_BUNDLES_FAILURE`,
  FETCH_BUNDLES_REQUEST: `${NAME_SPACE}/FETCH_BUNDLES_REQUEST`,
  FETCH_BUNDLES_SUCCESS: `${NAME_SPACE}/FETCH_BUNDLES_SUCCESS`,
  FETCH_BUNDLES: `${NAME_SPACE}/FETCH_BUNDLES`,
};

export default actionTypes;
