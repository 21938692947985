import { formatRoute } from 'react-router-named-routes';
import { replace } from 'connected-react-router';

import { BarError } from 'classes/errors';
import { fromCallbacksToPromise } from 'helpers';
import { notifyError } from 'app_modules/notifications/actions';
import { TEAMS } from 'constants/urls';
import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/teams/constants';
import api from 'api';

// Actions

export const fetchTeamDetailsFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_TEAM_DETAILS_FAILURE,
});

export const fetchTeamDetailsRequest = () => ({
  type: actionTypes.FETCH_TEAM_DETAILS_REQUEST,
});

export const fetchTeamDetailsSuccess = (response) => ({
  payload: response,
  type: actionTypes.FETCH_TEAM_DETAILS_SUCCESS,
});

// Thunks

export const fetchTeamDetails = (teamId) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());

    dispatch(fetchTeamDetailsRequest());

    const handleResponse = (response) => {
      dispatch(fetchTeamDetailsSuccess(response));
      return response;
    };

    const handleError = ({ error }) => {
      dispatch(fetchTeamDetailsFailure(error));
      dispatch(notifyError(new BarError({ label: 'invalidAddress' })));
      dispatch(replace(formatRoute(TEAMS, { accountId })));
    };

    return fromCallbacksToPromise(api.teams.getTeam, { accountId, teamId })
      .then((response) => handleResponse(response))
      .catch((error) => handleError(error));
  };

  thunk.type = actionTypes.FETCH_TEAM_DETAILS;

  return thunk;
};

export default fetchTeamDetails;
