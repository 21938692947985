import { BarError } from 'classes/errors';
import { notifyError } from 'app_modules/notifications/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import accountsActionTypes from 'app_modules/accounts/constants';
import api from 'api';

// Actions

export const fetchAccountFailure = (error) => ({
  payload: error,
  type: accountsActionTypes.FETCH_ACCOUNT_FAILURE,
});

export const fetchAccountRequest = () => ({
  type: accountsActionTypes.FETCH_ACCOUNT_REQUEST,
});

export const fetchAccountSuccess = (profile) => ({
  payload: profile,
  type: accountsActionTypes.FETCH_ACCOUNT_SUCCESS,
});

// Thunks

export const fetchAccount = (id) => {
  const thunk = (dispatch, getState) => {
    const state = getState();

    const accountId = id || sessionSelectors.accountId(state);

    dispatch(fetchAccountRequest());

    const handleResponse = (response) => dispatch(fetchAccountSuccess(response));

    const handleError = ({ error, isConnectionError }) => {
      dispatch(fetchAccountFailure(error));
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'getAccountInformationError' })));
      }
    };

    return api.accounts.getAccount(accountId, handleResponse, handleError);
  };

  thunk.type = accountsActionTypes.FETCH_ACCOUNT;

  return thunk;
};

export default fetchAccount;
