import _ from 'lodash';

import { fetchLoginSuccess, setUser } from 'app_modules/session/actions';
import { USERS_CREATE_ACCOUNT } from 'constants/apiErrorCodes';
import actionTypes from 'app_modules/profile/constants';
import api from 'api';

export const fetchCreateUserRequest = () => ({
  type: actionTypes.FETCH_CREATE_USER_REQUEST,
});

export const fetchCreateUserSuccess = () => ({
  type: actionTypes.FETCH_CREATE_USER_SUCCESS,
});

export const fetchCreateUserFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_CREATE_USER_FAILURE,
});

export const createUser = (userData, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const {
      password,
      passwordConfirmation,
      personalEmail,
      ...rest
    } = userData;

    const { invites } = getState();

    const {
      email,
      token: invitationToken,
    } = invites.invitation;

    const profilesAttributes = [
      {
        email,
        ...rest,
      },
    ];

    if (personalEmail) {
      profilesAttributes.push({
        email: personalEmail,
        ...rest,
      });
    }

    const data = {
      user: {
        profilesAttributes,
        password,
        passwordConfirmation,
      },
      invitationToken,
    };

    dispatch(fetchCreateUserRequest());

    const responseHandler = ({ errors, user }) => {
      if (errors && errors.length > 0) {
        throw new Error(errors[0].detail);
      }

      if (_.isEmpty(user)) {
        throw new Error('User is empty');
      }

      Promise.all([
        dispatch(setUser(user)),
        dispatch(fetchLoginSuccess()),
        dispatch(fetchCreateUserSuccess()),
      ])
        .then(() => {
          if (onSuccess) {
            onSuccess();
          }
        });
    };

    const errorHandler = ({ error }) => {
      dispatch(fetchCreateUserFailure(error));
      if (onError && error.errorCode === USERS_CREATE_ACCOUNT) {
        onError({ personalEmail: error.message });
      }
    };

    return api.users.create(data, responseHandler, errorHandler);
  };
  thunk.type = actionTypes.FETCH_CREATE_USER;
  return thunk;
};

export default createUser;
