import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/network/constants';
import api from 'api';

// Actions

export const fetchSentRequestsFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_SENT_REQUESTS_FAILURE,
});

export const fetchSentRequestsRequest = (pageIndex) => ({
  payload: pageIndex,
  type: actionTypes.FETCH_SENT_REQUESTS_REQUEST,
});

export const fetchSentRequestsSuccess = (response) => ({
  payload: response,
  type: actionTypes.FETCH_SENT_REQUESTS_SUCCESS,
});

// Thunks

export const fetchSentRequests = (pageIndex = 1) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());

    dispatch(fetchSentRequestsRequest(pageIndex));

    const handleResponse = (response) => dispatch(fetchSentRequestsSuccess(response));

    const handleError = ({ error }) => {
      dispatch(fetchSentRequestsFailure(error));
      throw error;
    };

    return api.connectionRequests.get({
      accountId,
      list: 'sent',
      pageIndex,
    }, handleResponse, handleError);
  };

  thunk.type = actionTypes.FETCH_SENT_REQUESTS;

  return thunk;
};

export default fetchSentRequests;
