import actionTypes from '../constants';
import { fetchTeamDetails } from './fetchTeamDetails';
import { fetchTeamMembers } from './fetchTeamMembers';
import { fetchTeamModules } from './fetchTeamModules';

// Actions

export const fetchTeamDashboardFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_TEAM_DASHBOARD_FAILURE,
});

export const fetchTeamDashboardRequest = () => ({
  type: actionTypes.FETCH_TEAM_DASHBOARD_REQUEST,
});

export const fetchTeamDashboardSuccess = () => ({
  type: actionTypes.FETCH_TEAM_DASHBOARD_SUCCESS,
});

// Thunks

export const fetchTeamDashboard = ({ contentType, includeAllMembers = false, teamId }) => {
  const thunk = (dispatch) => {
    dispatch(fetchTeamDashboardRequest());

    const promises = [
      dispatch(fetchTeamDetails(teamId)),
      dispatch(fetchTeamModules({ contentType, teamId })),
    ];

    if (includeAllMembers) {
      promises.push(dispatch(fetchTeamMembers({ pageIndex: 0, teamId })));
    }

    return Promise
      .all(promises)
      .then((response) => {
        dispatch(fetchTeamDashboardSuccess());
        return response;
      })
      .catch(({ error }) => {
        dispatch(fetchTeamDashboardFailure(error));
        throw error;
      });
  };

  thunk.type = actionTypes.FETCH_TEAM_DASHBOARD;

  return thunk;
};

export default fetchTeamDashboard;
