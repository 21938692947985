import { fromCallbacksToPromise, sortModules } from 'helpers';
import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/teams/constants';
import api from 'api';

// Actions

export const fetchTeamModulesFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_TEAM_MODULES_FAILURE,
});

export const fetchTeamModulesRequest = () => ({
  type: actionTypes.FETCH_TEAM_MODULES_REQUEST,
});

export const fetchTeamModulesSuccess = (modules) => ({
  payload: modules,
  type: actionTypes.FETCH_TEAM_MODULES_SUCCESS,
});

// Thunks

export const fetchTeamModules = ({ contentType, teamId }) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());

    dispatch(fetchTeamModulesRequest());

    const handleResponse = (response) => {
      const { dashboardModules } = response;

      dispatch(fetchTeamModulesSuccess({
        id: teamId,
        modules: sortModules(dashboardModules),
      }));

      return response;
    };

    const handleError = ({ error }) => {
      dispatch(fetchTeamModulesFailure(error));
      throw error;
    };

    return fromCallbacksToPromise(
      api.contentManager.getContent,
      { accountId, contentType, teamId },
    )
      .then((response) => handleResponse(response))
      .catch((error) => handleError(error));
  };

  thunk.type = actionTypes.FETCH_TEAM_MODULES;

  return thunk;
};

export default fetchTeamModules;
