import { BarError } from 'classes/errors';
import * as notificationActions from 'app_modules/notifications/actions';
import * as teamsSelectors from 'app_modules/teams/selectors';
import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/teams/constants';
import api from 'api';

// Actions
/**
 * failure action dispatched when there is an error updating the logo
 */
export const updateLogoFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_UPDATE_LOGO_FAILURE,
});

/**
 * update logo request action dispatched
 */
export const updateLogoRequest = () => ({
  type: actionTypes.FETCH_UPDATE_LOGO_REQUEST,
});

/**
 * Success action dispatched when updating the logo
 */
export const updateLogoSuccess = (response) => ({
  payload: response,
  type: actionTypes.FETCH_UPDATE_LOGO_SUCCESS,
});

// Thunks

export const updateLogo = (logo) => {
  const thunk = (dispatch, getState) => {
    const { id: teamId } = teamsSelectors.team(getState());
    const accountId = sessionSelectors.accountId(getState());

    dispatch(updateLogoRequest());

    const handleResponse = (response) => {
      dispatch(updateLogoSuccess(response));
      return response;
    };

    const handleError = ({ error, isConnectionError }) => {
      dispatch(updateLogoFailure(error));
      if (!isConnectionError) {
        dispatch(notificationActions.notifyError(new BarError({ ...error, label: 'updateLogoError' })));
      }
    };

    return api.teams.updateLogo(
      { accountId, teamId, logo },
      handleResponse,
      handleError,
    );
  };

  thunk.type = actionTypes.FETCH_UPDATE_LOGO;

  return thunk;
};

export default updateLogo;
