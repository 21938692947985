import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as profileActions from 'app_modules/profile/actions';
import * as userSelectors from 'app_modules/user/selectors';
import PageSignUpSSO from 'components/PageSignUpSSO';

const mapStateToProps = (state) => ({
  ssoProvisionData: userSelectors.ssoProvisionData(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(profileActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageSignUpSSO);
