import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { HISTORY, I18N } from 'constants/props';
import { StyledButton } from 'generics/StyledFormComponents';
import Icon from 'generics/Icon';
import Translation from 'generics/Translation';

import styles from './BackButton.scss';

export const BackButton = (props) => {
  const {
    className,
    history,
    i18n,
    onClick,
    to,
  } = props;

  const redirect = () => history.push(to);

  const handleClick = onClick || redirect;

  // TODO: We can modify this to have a generic button as ActionButton Component.
  return (
    <StyledButton
      className={classnames(
        styles.button,
        className,
      )}
      onClick={handleClick}
      title={i18n.generics.back}
      variant="text"
    >
      <Icon.Stroke7
        className={styles.button__icon}
        name="angle-left"
      />

      <span className={styles.button__text}>
        {i18n.generics.back}
      </span>
    </StyledButton>
  );
};

BackButton.propTypes = {
  className: PropTypes.string,
  history: HISTORY,
  i18n: I18N.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

BackButton.defaultProps = {
  className: null,
  history: null,
  onClick: null,
  to: null,
};

export default Translation(BackButton, ['generics']);
