import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as sessionSelectors from 'app_modules/session/selectors';
import * as teamActions from 'app_modules/teams/actions';
import * as teamSelectors from 'app_modules/teams/selectors';
import PageTeams from 'components/PageTeams/';

const mapStateToProps = (state) => ({
  privateTeams: teamSelectors.privateTeams(state),
  currentProfile: sessionSelectors.currentProfile(state),
  publishedTeams: teamSelectors.publishedTeams(state),
  othersTeams: teamSelectors.othersTeams(state),
  corporateTeams: teamSelectors.corporateTeams(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(teamActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageTeams);
