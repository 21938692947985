import { formatRoute } from 'react-router-named-routes';
import { Link, withRouter } from 'react-router-dom';
import { sprintf } from 'sprintf-js';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { keyIndex } from 'helpers';
import { I18N, PERSON, TEAM } from 'constants/props';
import { TEAMS_DASHBOARD } from 'urls';
import Avatar from 'generics/Avatar';
import ListItemActions from 'generics/ListItemActions';
import ListItemFlags from 'generics/ListItemFlags';
import Translation from 'generics/Translation';

import styles from './TeamListItem.scss';

const TeamListItem = (props) => {
  const {
    accountId,
    actions,
    className,
    i18n,
    profile,
    sharedIcon,
    team,
  } = props;

  const {
    description,
    id: teamId,
    isPublic,
    isPublished,
    name,
    owner,
  } = team;

  const teamFlags = [];

  if (isPublic) {
    teamFlags.push({
      iconName: 'unlock',
      label: i18n.pageTeams.teamList.listItem.publicTeam,
    });
  } else {
    teamFlags.push({
      iconName: 'lock',
      label: i18n.pageTeams.teamList.listItem.privateTeam,
    });
  }

  if (!isPublished) {
    teamFlags.push({
      className: styles.draft,
      iconName: 'note',
      label: i18n.pageTeams.teamList.listItem.draft,
    });
  }

  if (owner.id === profile.id) {
    teamFlags.push({
      iconName: 'user',
      label: i18n.pageTeams.teamList.listItem.teamOwner,
    });
  }

  const teamDashboardRoute = formatRoute(TEAMS_DASHBOARD, {
    accountId,
    sharedIcon,
    teamId,
  });

  return (
    <li
      className={classnames(
        styles.item,
        className,
      )}
    >
      <Avatar
        className={styles.avatar}
        icon={{
          className: styles['avatar-icon'],
          name: sharedIcon || 'users',
        }}
        profile={team}
        title={sprintf(i18n.pageTeams.teamList.listItem.avatarTitle, name)}
      />

      <div className={styles['name-container']}>
        <Link
          className={styles['name-link']}
          href={teamDashboardRoute}
          title={name}
          to={teamDashboardRoute}
        >
          {name}
        </Link>

        <p className={styles.description}>
          {description}
        </p>

        <ListItemFlags flags={keyIndex(teamFlags)} />
      </div>

      <ListItemActions
        actions={actions}
        className={styles.actions}
      />
    </li>
  );
};

TeamListItem.propTypes = {
  accountId: PropTypes.number.isRequired,
  actions: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  i18n: I18N.isRequired,
  profile: PERSON.isRequired,
  sharedIcon: PropTypes.string.isRequired,
  team: TEAM.isRequired,
};

TeamListItem.defaultProps = {
  actions: null,
  className: null,
};

export default withRouter(Translation(TeamListItem, ['pageTeams']));
