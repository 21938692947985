import actionTypes from '../constants';

export const fetchFailure = (error) => ({
  type: actionTypes.FETCH_FAILURE,
  payload: error,
});

export const fetchRequest = () => ({
  type: actionTypes.FETCH_REQUEST,
});

export const fetchSuccess = () => ({
  type: actionTypes.FETCH_SUCCESS,
});

export const setModule = (module) => ({
  type: actionTypes.SET_MODULE,
  payload: module,
});

export const switchModalView = () => ({
  type: actionTypes.SWITCH_MODAL_VIEW,
});
