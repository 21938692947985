import { autobind } from 'core-decorators';
import { Component } from 'react';
import PropTypes from 'prop-types';

class FormComponent extends Component {
  constructor(props) {
    super(props);
    const { initialValues } = props;

    this.state = {
      values: {
        ...initialValues,
      },
    };
  }

  @autobind
  handleChange(fieldName, value, isArray = false, position = null) {
    if (!isArray) {
      this.setState(({ values: oldValues }) => ({
        values: {
          ...oldValues,
          [fieldName]: value,
        },
      }), () => {
        this.validate();
      });
    } else {
      const { values } = this.state;
      const newValues = [...values];
      const newValue = { ...newValues[position] };

      newValues[position] = {
        ...newValue,
        [fieldName]: value,
      };

      this.setState({ values: newValues }, () => this.validate(fieldName, position, value));
    }
  }

  @autobind
  handleSubmit(e, isArray = false) {
    const {
      onSubmit,
      resetOnSubmit,
    } = this.props;

    const {
      values,
      isValid,
    } = this.state;

    if (e) {
      e.preventDefault();
    }

    if (isValid) {
      if (!isArray) {
        onSubmit({ ...values });
      } else {
        onSubmit([...values]);
      }

      if (resetOnSubmit) {
        this.setState({ values: !isArray ? {} : [], isValid: false });
      }
    }
  }

  render() {
    return null;
  }
}

FormComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  resetOnSubmit: PropTypes.bool,
};

FormComponent.defaultProps = {
  initialValues: {},
  resetOnSubmit: false,
};

export default FormComponent;
