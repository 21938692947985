import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { COLORS } from 'constants/colors';
import { getCSSFromSVGs } from 'helpers';
import { Header, Footer, Main } from 'generics/Card';
import { MEMBERS, TEAM, CHILDREN, I18N } from 'constants/props';
import { Person } from 'classes';
import { StyledButton } from 'generics/StyledFormComponents';
import * as sessionSelectors from 'app_modules/session/selectors';
import * as teamsSelectors from 'app_modules/teams/selectors';
import api from 'api';
import InformationCardHeader from 'generics/InformationCardHeader';
import ListSelection from 'generics/ListSelection';
import Modal from 'generics/Modal';
import PDFEnergyMaps from 'generics/PDFEnergyMaps';
import Translation from 'generics/Translation';

import styles from './ModalCreatePdf.scss';

const MAX_TEAM_MEMBERS = 20;

const getTeamMembers = (teamMembers) => teamMembers.map(
  (teamMember) => teamMember.clone({ canDrag: teamMember.isVisible }),
);

const ModalCreatePDF = ({
  accountId,
  disableSubmit,
  formValues,
  i18n,
  members,
  module,
  onClose,
  onComplete,
  onFetch,
  team,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [teamMembers, setTeamMembers] = useState(getTeamMembers(members.list));

  const energyMaps = useRef(null);

  useEffect(() => {
    setTeamMembers(getTeamMembers(members.list));
  }, [members]);

  const handleCloseModal = () => {
    onClose();
  };

  const handleChangeList = (profiles = []) => {
    const newSelectedProfiles = profiles.map((profile) => new Person(profile));
    setSelectedProfiles(newSelectedProfiles);
  };

  const handleCreateValidation = () => {
    if (disableSubmit || selectedProfiles.length === 0) {
      return false;
    }

    return true;
  };

  const handleCreate = () => {
    const { svgs } = energyMaps.current;
    const { key } = module;
    const { id: teamId } = team;
    const css = getCSSFromSVGs(svgs);

    onFetch(key);

    handleCloseModal();

    const onFinish = () => {
      onComplete(key);
    };

    api.files.downloadPDF({
      accountId,
      css,
      pdfId: key,
      profiles: svgs
        .map(({ id, svg }) => ({ id, svg })),
      teamId,
      ...formValues,
    }, onFinish);
  };

  const handleFetchMoreMembers = (newPageIndex) => {
    const { onFetchMore } = members;

    setIsFetching(true);

    const onFinish = () => setIsFetching(false);

    onFetchMore(newPageIndex, onFinish);
  };

  const {
    hasMorePages: hasMoreMembers,
    pageIndex,
  } = members;

  if (module === null) {
    return null;
  }

  const {
    children,
    icon,
    maximumTeamMembers,
    title,
  } = module;

  const infiniteScroll = {
    hasMorePages: hasMoreMembers,
    listHeight: 250,
    onFetch: handleFetchMoreMembers,
    pageStart: pageIndex + 1,
  };

  return (
    <Modal
      card={{
        barClassName: styles.bar,
        fullHeight: true,
        isFetching,
      }}
      onClose={handleCloseModal}
    >
      <Header>
        <InformationCardHeader
          category="Resources"
          categoryColor={COLORS.resourcesCategory.rgba}
          icon={icon}
          title={title}
        />
      </Header>

      <Main>
        <Container fluid>
          {children}

          <ListSelection
            listA={{
              id: 'create-pdf-list1',
              infiniteScroll,
              profiles: teamMembers,
              title: i18n.pageTeamDashboard.modalCreatePdf.listATitle,
            }}
            listB={{
              emptyMessage: i18n.pageTeamDashboard.modalCreatePdf.emptyListMessage,
              id: 'create-pdf-list2',
              profiles: selectedProfiles,
              title: i18n.pageTeamDashboard.modalCreatePdf.listBTitle,
            }}
            maximumSelectableListItems={maximumTeamMembers || MAX_TEAM_MEMBERS}
            onChange={handleChangeList}
            showStatusLabel
          />
        </Container>
      </Main>

      <Footer>
        <Container>
          <Row>
            <Col xs={{ size: 4, offset: 8 }} md={{ size: 2, offset: 10 }}>
              <StyledButton
                disabled={!handleCreateValidation()}
                fullWidth
                onClick={handleCreate}
                color="primary"
                title={i18n.pageTeamDashboard.modalCreatePdf.submitText}
              >
                {i18n.pageTeamDashboard.modalCreatePdf.submitText}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </Footer>
      <PDFEnergyMaps
        profiles={selectedProfiles}
        innerRef={(nodeElement) => { energyMaps.current = nodeElement; }}
      />
    </Modal>
  );
};

ModalCreatePDF.propTypes = {
  accountId: PropTypes.number.isRequired,
  formValues: PropTypes.shape({}),
  disableSubmit: PropTypes.bool,
  i18n: I18N.isRequired,
  members: MEMBERS.isRequired,
  module: PropTypes.shape({
    children: CHILDREN,
    icon: PropTypes.string,
    key: PropTypes.string,
    maximumTeamMembers: PropTypes.number,
    title: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  onFetch: PropTypes.func.isRequired,
  team: TEAM.isRequired,
};

ModalCreatePDF.defaultProps = {
  disableSubmit: false,
  formValues: null,
  module: null,
  onComplete: undefined,
};

const mapStateToProps = (state) => ({
  accountId: sessionSelectors.accountId(state),
  team: teamsSelectors.team(state),
});

export default Translation(connect(mapStateToProps)(ModalCreatePDF), ['pageTeamDashboard']);
