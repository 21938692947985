import * as sessionSelectors from 'app_modules/session/selectors';
import actionTypes from 'app_modules/session/constants';
import api from 'api';

// Actions

export const fetchSetProfilePrivacyRequest = () => ({
  type: actionTypes.FETCH_SET_PROFILE_PRIVACY_REQUEST,
});

export const fetchSetProfilePrivacySuccess = (response) => ({
  payload: response,
  type: actionTypes.FETCH_SET_PROFILE_PRIVACY_SUCCESS,
});

export const fetchSetProfilePrivacyFailure = (error) => ({
  payload: error,
  type: actionTypes.FETCH_SET_PROFILE_PRIVACY_FAILURE,
});

// Thunks

export const setProfilePrivacy = (isPublic) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState());

    dispatch(fetchSetProfilePrivacyRequest());

    const handleResponse = (response) => {
      const { profile } = response;

      dispatch(fetchSetProfilePrivacySuccess(profile));

      return response;
    };

    const handleError = ({ error }) => {
      dispatch(fetchSetProfilePrivacyFailure(error));
      throw error;
    };

    return api.profiles.setPrivacy({
      accountId,
      isPublic,
    }, handleResponse, handleError);
  };

  thunk.type = actionTypes.FETCH_SET_PROFILE_PRIVACY;

  return thunk;
};

export default setProfilePrivacy;
