import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { isEmpty } from 'lodash';
import { sprintf } from 'sprintf-js';
import gsap from 'gsap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ACTIONS, I18N, USER } from 'constants/props';
import * as notificationsActions from 'app_modules/notifications/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import currentNotification from 'app_modules/notifications/selectors';
import Translation from 'generics/Translation';

import styles from './notifications.scss';

export class notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 5,
    };
  }

  componentDidMount() {
    const {
      actions,
      notification,
    } = this.props;

    const { delay } = this.state;

    this.tl = gsap.timeline({ paused: true });

    this.tl
      .add(this.tl.to(
        this.notifications,
        {
          duration: 0.6,
          ease: 'Expo.easeOut',
          opacity: 1,
          top: '100%',
        },
      ), 'init')
      .add(this.tl.to(
        this.notifications,
        {
          delay,
          duration: 0.5,
          ease: 'Expo.easeOut',
          onComplete: () => {
            actions.clearBarNotification();
          },
          opacity: 0,
          top: '100%',
        },
      ));

    if (Object.keys(notification).length) {
      this.tl.play('init');
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { options } = nextProps.notification;

    if (!isEmpty(options) && (options.delay)) {
      this.setState({
        ...options,
        delay: options.delay || 5,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    return Object.keys(nextProps.notification).length;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate() {
    this.tl.play('init');
  }

  componentWillUnmount() {
    this.tl.kill();
  }


  render() {
    const {
      className,
      i18n,
      notification,
      user,
    } = this.props;

    const {
      label,
      message,
      title,
    } = notification;

    const options = {
      type: 'success',
      ...notification.options,
    };

    const { type } = options;

    const notificationMessage = (label && i18n.notifications[label]) || message;

    return (
      <div>
        <div
          ref={(element) => { this.notifications = element; }}
          className={classnames(
            styles.notifications,
            styles[type],
            { [styles['has-content']]: title || label || message },
            className,
          )}
        >
          <Container>
            {
              title && (
                <span
                  className={styles.title}
                  title={title}
                >
                  {title}
                </span>
              )
            }
            {
              notificationMessage && (
                <span
                  className={styles.message}
                  title={notificationMessage}
                >
                  {notificationMessage}
                </span>
              )
            }
          </Container>
        </div>

        {
          user && user.impersonateToken && (
            <div
              className={classnames(
                styles.notifications__impersonate,
                styles[type],
                { [styles['has-content']]: true },
                className,
              )}
            >
              <Container>
                {
                  user.internalAdmin.name && (
                    <span
                      className={styles.title}
                      title={user.internalAdmin.name}
                    >
                      {sprintf(
                        i18n.notifications.isImpersonatingTo,
                        { internalAdminName: user.internalAdmin.name },
                      )}
                    </span>
                  )
                }
                {
                  <span
                    className={styles.message}
                    title={user.currentProfile.firstName}
                  >
                    {`${user.currentProfile.firstName} ${user.currentProfile.lastName}`}
                  </span>
                }
              </Container>
            </div>
          )
        }
      </div>
    );
  }
}

notifications.propTypes = {
  actions: ACTIONS.isRequired,
  className: PropTypes.string,
  i18n: I18N.isRequired,
  notification: PropTypes.shape({
    label: PropTypes.string,
    message: PropTypes.string,
    options: PropTypes.shape({
      delay: PropTypes.number,
    }),
    title: PropTypes.string,
  }),
  user: USER,
};

notifications.defaultProps = {
  className: null,
  notification: {
    title: '',
    message: '',
    options: {},
  },
  user: null,
};

const mapStateToProps = (state) => ({
  notification: currentNotification(state),
  user: sessionSelectors.user(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(notificationsActions, dispatch),
});

export default Translation(connect(mapStateToProps, mapDispatchToProps)(notifications), ['notifications']);
