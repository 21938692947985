/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';

import { TEXT } from 'constants/inputTypes';
import { I18N } from 'constants/props';
import useForm from 'custom_hooks/useForm';
import useFormValidation from 'custom_hooks/useFormValidation';
import { useEffect, useState } from 'react';

const useLearningChartForm = ({ i18n, initialValues, onSubmit }) => {
  const [callOnSubmit, setCallOnSubmit] = useState(false);
  const { validator } = useFormValidation({ i18n });

  const handleValidate = (values) => {
    const {
      name,
    } = values;

    const errorName = validator.validate(TEXT, name, 0, 40);

    setCallOnSubmit(true);

    return { name: errorName };
  };

  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values = { email: null, password: null },
  } = useForm({ onValidate: handleValidate, initialValues, onSubmit });

  useEffect(() => {
    if (callOnSubmit) {
      onSubmit(values, isValid);
      setCallOnSubmit(false);
    }
  }, [callOnSubmit]);

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  };
};

useLearningChartForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
};

export default useLearningChartForm;
