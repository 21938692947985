/**
 * @description Accounts controller endpoints settings
 * @module API controller / Accounts
 */
import { lowerCase } from 'lodash';

import { FORM } from 'constants/contentTypes';

/**
  * @description Updates account modules customization
  * @param  {object} options
  * @param  {number} options.accountId
  * @returns {object} - Request settings.
  */
const editCustomization = {
  settings({ accountId, values }) {
    return {
      body: values,
      endpoint: `/accounts/${accountId}`,
      method: 'PATCH',
    };
  },
};

/**
  * @description Updates account modules customization
  * @param  {object} options
  * @param  {number} options.accountId
  * @returns {object} - Request settings.
  */
const editAccount = {
  settings({ accountId, values }) {
    return {
      body: {
        ...values,
      },
      endpoint: `/accounts/${accountId}`,
      method: 'PUT',
    };
  },
};

/**
  * @description Updates account department
  * @param  {object} options
  * @param  {number} options.accountId
  * @param  {number} options.profileId
  * @param  {string} options.department
  * @returns {object} - Request settings.
  */
const editDepartment = {
  settings({ accountId, profileId, values }) {
    return {
      body: values,
      endpoint: `/account/${accountId}/profiles/${profileId}`,
      method: 'PUT',
    };
  },
};

const getAccount = {
  /**
   * @description Get request settings to get details for an account.
   * @param {number} accountId - The account ID.
   * @returns {object} - Request settings.
   */
  settings(accountId) {
    return {
      endpoint: `/accounts/${accountId}`,
      method: 'GET',
    };
  },
};

const getMyMembers = {
  ignoreNotFound: true,

  /**
   * @description Compute request settings to get members list for an specific account.
   * @param {object} options
   * @param {number} options.accountId - The account ID.
   * @param {number} options.pageIndex - The page index.
   * @param  {string} options.search - The filter to search.
   * @param  {string} options.workshopFilter - workshop filter.
   * @returns {object} - Request settings.
   */
  settings({ accountId, pageIndex, search, workshopFilter }) {
    const { filterBy, value: searchValue } = search || {};

    let endpoint = `/accounts/${accountId}/my_members`;
    const cleanSearch = searchValue ? searchValue.trim() : null;
    const searchBy = searchValue ? filterBy : null;

    if (pageIndex) {
      endpoint += `/page/${pageIndex}`;
    }

    if (searchBy) {
      endpoint += `/search_field/${searchBy}`;
    }

    if (cleanSearch) {
      endpoint += `/search/${cleanSearch}`;
    }

    if (workshopFilter && workshopFilter !== 'all') {
      endpoint += `?workshop_filter=${lowerCase(workshopFilter)}`;
    }

    return {
      endpoint,
      method: 'GET',
    };
  },
};

/**
  * @description Updates account logo
  * @param  {object} options
  * @param  {number} options.accountId
  * @param  {object} options.logo
  * @returns {object} - Request settings.
  */
const updateLogo = {
  settings({ accountId, logo }) {
    const form = new FormData();

    if (logo) {
      form.append('logo', logo);
    }

    return {
      body: form,
      endpoint: `/accounts/${accountId}/set_logo`,
      headers: {
        enctype: FORM,
      },
      method: 'PATCH',
    };
  },
};

/**
  * @description Sets Default Account for the current user
  * @param  {object} options
  * @param  {number} options.accountId
  * @param  {number} options.currentAccountId
  * @returns {object} - Request settings.
  */
const setDefaultAccount = {
  settings({ accountId, currentAccountId }) {
    return {
      body: { id: accountId },
      endpoint: `/accounts/${currentAccountId}/set_default`,
      method: 'PATCH',
    };
  },
};

module.exports = {
  editAccount,
  editCustomization,
  editDepartment,
  getAccount,
  getMyMembers,
  setDefaultAccount,
  updateLogo,
};
