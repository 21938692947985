export const en = {
  generalError: {
    title: 'Something went wrong!',
    content: 'It seems something is not working as intended at the moment. Please contact your Admin for further assistance',
  },
  noLicenses: {
    title: 'No Licenses Available',
    content: 'Your organization does not have any unused licenses available to create your account. Please contact your Simpli5<sup>&reg;</sup> administrator for assistance. We apologize for the inconvenience.',
  },
  linkedin: {
    generalError: {
      title: 'Something went wrong!',
      content: 'It seems something is not working as intended at the moment. Please contact your Admin for further assistance',
    },
    noLicenses: {
      title: 'No Licenses Available',
      content: 'Your organization does not have any unused licenses available to create your account. Please contact your Simpli5<sup>&reg;</sup> administrator for assistance. We apologize for the inconvenience.',
    },
  },
};

export const es = en;
