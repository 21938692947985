import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as networkActions from 'app_modules/network/actions';
import * as networkSelectors from 'app_modules/network/selectors';
import * as notificationActions from 'app_modules/notifications/actions';
import * as sessionSelectors from 'app_modules/session/selectors';
import PageProfileThirdPerson from 'components/PageProfileThirdPerson';

const mapStateToProps = (state) => ({
  currentProfile: sessionSelectors.currentProfile(state),
  isFetchingProfile: networkSelectors.thirdPerson.isFetching(state),
  thirdPersonProfile: networkSelectors.thirdPerson.profile(state),
});

const mapDispatchToProps = (dispatch) => ({
  networkActions: bindActionCreators(networkActions, dispatch),
  notificationActions: bindActionCreators(notificationActions, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageProfileThirdPerson));
