import moment from 'moment';

import { ACCOUNT_ADMIN } from 'roles';
import { HIDE_RESULTS, OVERVIEW_REPORT_MODULE, VIDEO_MODULE, VIDEO_MODULE_SECOND } from 'constants/customizationModules';
import {
  CORPORATE_PRE_ENTERPRISE_ACCOUNT_TYPE,
  LEARNING_ACCOUNT_TYPE,
  NON_PROFIT_ACCOUNT_TYPE,
  TRAINING_PARTNER_ACCOUNT_TYPE,
} from 'constants/accountTypes';
import { Person } from 'classes';

export const customizations = (state) => (
  {
    [HIDE_RESULTS]: {
      hideResults: state.user.currentProfile.account.hideResults,
      hideResultsUntil: state.user.currentProfile.account.hideResultsUntil,
    },
    [OVERVIEW_REPORT_MODULE]: {
      overviewRptIntro: state.user.currentProfile.account.overviewRptIntro,
    },
    [VIDEO_MODULE]: {
      bodyContent: state.user.currentProfile.account.bodyContent,
      heading: state.user.currentProfile.account.heading,
      embedCode: state.user.currentProfile.account.embedCode,
    },
    [VIDEO_MODULE_SECOND]: {
      bodyContentSecond: state.user.currentProfile.account.bodyContentSecond,
      headingSecond: state.user.currentProfile.account.headingSecond,
      embedCodeSecond: state.user.currentProfile.account.embedCodeSecond,
    },
  }
);

export const hasNewNotifications = (state) => state.user.currentProfile.newsNotitications;

/* eslint-disable import/prefer-default-export */
export const myMembers = (state) => ({
  ...state.accounts,
  list: state.accounts.list.map((profile) => new Person(profile)),
});

export const isStudentWaitingResults = (state) => {
  const {
    hideResults,
    hideResultsUntil,
  } = state.user?.currentProfile?.account || {};

  const isLearningAccount = state.user?.currentProfile?.account?.accountType
    === LEARNING_ACCOUNT_TYPE;
  const isStudent = isLearningAccount
    && !(new Person(state.user.currentProfile).hasRole(ACCOUNT_ADMIN));

  const date = moment(new Date()).utc().format('YYYY-MM-DD');
  const expirationDate = moment(new Date(hideResultsUntil)).utc().format('YYYY-MM-DD');
  const isDateExpired = expirationDate < date;

  return (
    isStudent
      && (hideResults
        && !isDateExpired)
  );
};

export const isLicenseBuyerRole = (state) => (
  state?.user?.currentProfile?.permissions?.find(({ isLicenseBuyer }) => isLicenseBuyer)
);

export const canAutomaticRenewal = (state) => {
  const accountType = state.user?.currentProfile?.account?.accountType;
  return (
    (
      accountType === CORPORATE_PRE_ENTERPRISE_ACCOUNT_TYPE
      || accountType === NON_PROFIT_ACCOUNT_TYPE)
    && isLicenseBuyerRole(state)
  );
};

export const isLicenseBuyerTypeAccount = (state) => {
  const accountType = state.user?.currentProfile?.account?.accountType;
  return (
      accountType === CORPORATE_PRE_ENTERPRISE_ACCOUNT_TYPE
      || accountType === NON_PROFIT_ACCOUNT_TYPE
      || accountType === TRAINING_PARTNER_ACCOUNT_TYPE
  );
};

export const canPurchaseLicenses = (state) => (
  isLicenseBuyerTypeAccount(state) && isLicenseBuyerRole(state)
);

export const hasSubscription = (state) => (
  (state.user?.currentProfile?.account?.stripeCustomerId !== ''
    && state.user?.currentProfile?.account?.stripeSubscriptionId !== '')
  && isLicenseBuyerRole(state)
);

export const isAdmin = (state) => (new Person(state.user.currentProfile).hasRole(ACCOUNT_ADMIN));

export const adminLicenseBuyer = (state) => {
  if (isAdmin(state) && !isLicenseBuyerRole(state) && isLicenseBuyerTypeAccount(state)) {
    return state.user?.currentProfile?.account?.adminLicenseBuyer;
  }
  return undefined;
};

export const isDepartmentEnabled = (state) => (
  isAdmin(state)
    && state?.user?.currentProfile?.account?.enableDepartment
);

export const hasSubscriptionNotifications = (state) => (
  state?.user?.currentProfile?.account?.subscriptionNotifications
);
