/**
 * @description Users controller.
 * @module API controller / Users
 */
module.exports = {
  create: {
    /**
     * @description Get requests settings (Create an user).
     * @param {Object} body - The body request.
     * @param {Number} body.param - Param1.
     * @param {String} body.param - Param2.
     * @returns {Object} Request settings.
     */
    settings(body) {
      return {
        body,
        endpoint: '/users/create',
        method: 'POST',
      };
    },
  },

  getAssessment: {
    /**
     * @description Gets request settings (Get Assessment)
     * @param {Boolean} isAssessmentInProgress - true if the assessment is in progress.
     * @returns {Object} Request settings.
     */
    settings(isAssessmentInProgress) {
      return {
        endpoint: '/users/assessment',
        method: isAssessmentInProgress ? 'GET' : 'POST',
      };
    },
  },

  updateAssessment: {
    /**
     * @description Get request settings (update assessment)
     * @param {Object} body - The body request.
     * @returns {Object} Request settings.
     */
    settings(body) {
      return {
        body,
        endpoint: '/users/assessment/questions',
        method: 'POST',
      };
    },
  },

  changePassword: {
    /**
     * @description Get Request settings (change password)
     * @param {Object} settings - The endpoint settings.
     * @param {String} settings.currentPassword - The current password.
     * @param {String} settings.password - The new password
     * @param {String} settings.passwordConfirmation - The new password confirmation.
     * @returns {Object} Request settings.
     */
    settings({ currentPassword, password, passwordConfirmation }) {
      return {
        body: {
          currentPassword,
          user: {
            password,
            passwordConfirmation,
          },
        },
        endpoint: '/users/update_password',
        method: 'PATCH',
      };
    },
  },

  forgotPassword: {
    /**
     * @description Get Request settings (forgot password)
     * @param {object} options
     * @param {String} options.email - The email to send a token verification.
     * @returns {Object} Request settings.
     */
    settings(email) {
      return {
        body: {
          email,
        },
        endpoint: '/users/forgot_password',
        method: 'POST',
      };
    },
  },

  getPasswordToken: {
    /**
     * @description Get request seetings (Get Password Token)
     * @param {String} token - Valid reset password token.
     * @returns {Object} Request settings.
     */
    settings(token) {
      return {
        endpoint: `/users/forgot_password_user_info/${token}`,
        method: 'GET',
      };
    },
  },

  resetPassword: {
    /**
     * @description Get Request settings (Reset Password)
     * @param {Object} settings - The endpoint settings.
     * @param {String} settings.password - The new password.
     * @param {String} settings.passwordConfirmation - The new password confirmation.
     * @param {String} settings.token - Valid reset password token.
     * @returns {Object} Request settings.
     */
    settings({ password, passwordConfirmation, token }) {
      return {
        body: {
          resetPasswordToken: token,
          user: {
            password,
            passwordConfirmation,
          },
        },
        endpoint: '/users/reset_password',
        method: 'PUT',
      };
    },
  },
};
