export const PEOPLE_FILTERS = (hasDepartment) => {
  let options = [
    { id: 'first_name', label: 'First Name' },
    { id: 'last_name', label: 'Last Name' },
    { id: 'email', label: 'Email' },
  ];

  if (hasDepartment) {
    options = [...options,
      { id: 'department', label: 'Department' },
    ];
  }

  return options;
};

export const TEAM_FILTERS = [
  { id: 'name', label: 'Name' },
  { id: 'description', label: 'Description' },
];

export const INVITATION_STATUS_FILTERS = [
  { id: 'default', label: 'All' },
  { id: 'pending', label: 'Pending' },
  { id: 'expired', label: 'Expired' },
  { id: 'declined', label: 'Declined' },
];
