import { BAR_NOTIFICATION } from 'constants/notificationTypes';

import AppError from './AppError.class';

export default class InviteFileError extends AppError {
  constructor(list, ...args) {
    super({
      label: 'fileInvitations',
      notificationType: BAR_NOTIFICATION,
      ...args,
    });
    this.messageList = list;
  }
}
