/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash';
import { useState, useEffect } from 'react';

import { API_NOT_FOUND } from 'constants/apiErrorCodes';
import { BarError } from 'classes/errors';
import api from 'api';

const useReAssignTokens = ({
  accountId,
  i18n,
  isAdmin,
  notificationActions,
  onFetchNetworkDetails,
  profileId,
}) => {
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWarningAvailableOwnersModalOpen, setIsWarningAvailableOwnersModalOpen] = useState(false);
  const [displayedWarningModal, setDisplayedWarningModal] = useState(false);
  const [owners, setOwners] = useState(null);
  const [allOwners, setAllOwners] = useState(null);
  const [reports, setReports] = useState([]);
  const [reportsMeta, setReportsMeta] = useState({ morePages: false });
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useState({});
  const [selectedTokens, setSelectedTokens] = useState({});

  useEffect(() => {
    onFetchNetworkDetails(accountId, profileId);
  }, []);

  useEffect(() => {
    if (isAdmin && selectedTokens && !isEmpty(selectedTokens)) {
      const newOwners = [...allOwners];
      let report = false;
      const selectedTokensArray = Object.keys(selectedTokens);
      for (let i = 0; i < selectedTokensArray.length; i += 1) {
        const key = selectedTokensArray[i];
        report = reports.find(({ id }) => id === Number(key));
        if (report) {
          const { id: senderId } = report.sender;
          const ownerIndex = newOwners.findIndex(({ id }) => id === senderId);
          if (ownerIndex > -1) {
            newOwners.splice(ownerIndex, 1);
          }
        }
      }
      setOwners(newOwners);
      if (!newOwners.length && !displayedWarningModal) {
        setIsWarningAvailableOwnersModalOpen(true);
        setDisplayedWarningModal(true);
      }
    }
  }, [selectedTokens]);

  const getAssignedTokens = ({ pageIndex, search, ownerFilter, isScroll }) => {
    setSearchParams({ pageIndex, search, ownerFilter, isScroll });
    if (!isScroll) {
      setIsLoading(true);
    }
    const onSuccess = ({ externalReports, meta }) => {
      if (pageIndex === 1) {
        setReports([...externalReports]);
      } else {
        const newReports = [];
        if (reports.length) {
          setReports(newReports.concat(reports, externalReports));
        } else {
          setReports(newReports.concat(externalReports));
        }
      }
      setReportsMeta(meta);
      setIsLoading(false);
    };

    const onError = ({ error }) => {
      setIsLoading(false);
      if (error.errorCode === API_NOT_FOUND) {
        setReports([]);
        setReportsMeta({ morePages: false });
      }
    };

    api.tokens.getAssignedTokens({ accountId, pageIndex, search, ownerFilter }, onSuccess, onError);
  };

  const getTokensAllocators = () => {
    const onSuccess = ({ profiles }) => {
      if (profiles) {
        if (!isAdmin) {
          const profileIndex = profiles.findIndex(({ id }) => id === profileId);
          if (profileIndex > -1) {
            profiles.splice(profileIndex, 1);
          }
        } else {
          setAllOwners(profiles.map(
            ({ id, firstName, lastName }) => ({ id, firstName, lastName }),
          ));
        }
        setOwners(
          profiles.map(
            ({ id, firstName, lastName }) => ({ id, firstName, lastName }),
          ),
        );
      }
    };

    const onError = (error) => {
      console.log(error);
    };

    api.tokens.getTokensAllocators({ accountId }, onSuccess, onError);
  };

  useEffect(() => {
    getTokensAllocators();
  }, []);

  const handleSelectReport = (name, value) => {
    if (value) {
      setSelectedTokens({ ...selectedTokens, [name]: value });
      return;
    }
    const newSelectedTokens = { ...selectedTokens };
    delete newSelectedTokens[name];
    setSelectedTokens(newSelectedTokens);
  };

  const reAssignTokens = ({ owner }) => {
    setIsLoading(true);

    const data = {
      reassignTo: owner,
      externalReportIds: Object.keys(selectedTokens).map((key) => Number(key)),
    };

    const onSuccess = () => {
      onFetchNetworkDetails(accountId, profileId);
      getAssignedTokens({ ...searchParams });
      setSelectedTokens({});
      notificationActions
        .notifySuccess(i18n.pageTokenAdministration.ownerChange.reAssignReportsSuccessful);
      setIsAssignModalOpen(false);
    };

    const onError = ({ error }) => {
      setIsLoading(false);
      notificationActions.notifyError(new BarError({
        ...error,
        message: error?.message,
      }));
      console.log(error);
    };

    api.tokens.reAssignTokens({ accountId, data }, onSuccess, onError);
  };

  return {
    getAssignedTokens,
    handleSelectReport,
    isAssignModalOpen,
    isLoading,
    isWarningAvailableOwnersModalOpen,
    owners,
    selectedTokens,
    setIsAssignModalOpen,
    setSelectedTokens,
    setIsWarningAvailableOwnersModalOpen,
    reAssignTokens,
    reportsMeta,
    reports,
  };
};

export default useReAssignTokens;
