/* eslint-disable global-require */
import loadPolyfills from './loadPolyfills';

loadPolyfills().then(() => {
  switch (process.env.NODE_ENV) {
    case 'production':
      module.exports = require('./index.prod');
      break;

    case 'development':
    default:
      module.exports = require('./index.dev');
      break;
  }
});
